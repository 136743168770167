import { Getter, ToJSON } from '../Decorators';

@Getter
@ToJSON
class CatalogProduct {
  _reference;

  _name;

  _description;

  _type;

  _price;

  _currency;

  _vatRate;

  _sortWeight;

  _productImages;

  _stock;

  _enabled;

  _tags;

  _categories;

  _timeSlots;

  _modifierCategories;

  constructor(args) {
    const {
      reference,
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      sortWeight,
      productImages,
      stock,
      enabled,
      tags,
      categories,
      timeSlots,
      modifierCategories
    } = args;

    this._reference = reference;
    this._name = name;
    this._description = description;
    this._type = type;
    this._price = price;
    this._currency = currency;
    this._vatRate = vatRate;
    this._sortWeight = sortWeight;
    this._productImages = productImages;
    this._stock = stock;
    this._enabled = enabled;
    this._tags = tags;
    this._categories = categories;
    this._timeSlots = timeSlots;
    this._modifierCategories = modifierCategories;
  }

  static fromJSON(json) {
    return new this(json);
  }

  /*
   * A product is wide if it has a mediumImageUrl or no smallImageUrl
   */
  get isWide() {
    const { _productImages: productImages } = this;

    return !!productImages.mediumImageUrl || !productImages.smallImageUrl;
  }
}

export { CatalogProduct as default };
