export interface ITerminalDetails {
  deviceModel: string | null;
  serialNumber: string | null;
  terminal: string | null;
  terminalStatus: string | null;
  lastActivityDateTime: string | null;
  lastTransactionDateTime: string | null;
  firmwareVersion: string | null;
  wifiIp: string | null;
  wifiMac: string | null;
  ethernetIp: string | null;
  ethernetMac: string | null;
  bluetoothIp: string | null;
  bluetoothMac: string | null;
  simStatus: string | null;
}

interface TerminalDetails extends ITerminalDetails {}
class TerminalDetails {
  readonly deviceModel;
  readonly serialNumber;
  readonly terminal;
  readonly terminalStatus;
  readonly lastActivityDateTime;
  readonly lastTransactionDateTime;
  readonly firmwareVersion;
  readonly wifiIp;
  readonly wifiMac;
  readonly ethernetIp;
  readonly ethernetMac;
  readonly bluetoothIp;
  readonly bluetoothMac;
  readonly simStatus;

  constructor(args: ITerminalDetails) {
    const {
      deviceModel,
      serialNumber,
      terminal,
      terminalStatus,
      lastActivityDateTime,
      lastTransactionDateTime,
      firmwareVersion,
      wifiIp,
      wifiMac,
      ethernetIp,
      ethernetMac,
      bluetoothIp,
      bluetoothMac,
      simStatus
    } = args;

    this.deviceModel = deviceModel;
    this.serialNumber = serialNumber;
    this.terminal = terminal;
    this.terminalStatus = terminalStatus;
    this.lastActivityDateTime = lastActivityDateTime;
    this.lastTransactionDateTime = lastTransactionDateTime;
    this.firmwareVersion = firmwareVersion;
    this.wifiIp = wifiIp;
    this.wifiMac = wifiMac;
    this.ethernetIp = ethernetIp;
    this.ethernetMac = ethernetMac;
    this.bluetoothIp = bluetoothIp;
    this.bluetoothMac = bluetoothMac;
    this.simStatus = simStatus;
  }

  get reassignPending() {
    const { terminalStatus } = this;
    return (
      terminalStatus === 'ReAssignToInventoryPending' ||
      terminalStatus === 'ReAssignToStorePending' ||
      terminalStatus === 'ReAssignToMerchantInventoryPending'
    );
  }

  static fromJSON(json: ITerminalDetails) {
    return new this(json);
  }
}

export { TerminalDetails };
