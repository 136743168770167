import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateCaspecoIntegrationDTO {
  _sellerReference;

  _systemName;

  _computerName;

  _guid;

  constructor(args) {
    const { sellerReference, systemName, computerName, guid } = args;

    this._sellerReference = sellerReference;
    this._systemName = systemName;
    this._computerName = computerName;
    this._guid = guid;
  }

  static fromCreateCaspecoIntegration(
    createCaspecoIntegration,
    sellerReference
  ) {
    const { systemName, computerName, guid } = createCaspecoIntegration;

    return new this({
      systemName,
      computerName,
      guid,
      sellerReference
    });
  }
}

export { CreateCaspecoIntegrationDTO };
