import type { Locale } from './LanguageStrings';

export type ComponentStringEntry = {
  [key in Locale]: string;
};
export type ComponentStrings = {
  [key: string]: ComponentStringEntry;
};

export type LocalizedComponentStringEntries<T> = {
  [key in keyof T]: string;
};

export function getStrings<T extends ComponentStrings>(
  componentStrings: T,
  locale: Locale
) {
  return Object.entries(componentStrings).reduce(
    (
      accumulator: LocalizedComponentStringEntries<T>,
      [key, translations]: [k: keyof T, translations: ComponentStringEntry]
    ) => {
      accumulator[key] = translations[locale];
      return accumulator;
    },
    {} as LocalizedComponentStringEntries<T>
  );
}
