import GeneralSet from '../GeneralSet';
import { sortByName } from '../../helpers/Functions';
import CatalogModifier from './CatalogModifier';
import CatalogModifierGroup from './CatalogModifierGroup';

class CatalogModifiersBuilder {
  _modifierGroups;

  constructor(modifierGroups) {
    this._modifierGroups = modifierGroups;
  }

  static fromModifierGroups(modifierGroups) {
    return new this(modifierGroups);
  }

  get _modifierToGroupMap() {
    const { _modifierGroups: modifierGroups } = this;

    return modifierGroups.reduce((map, modifierGroup) => {
      modifierGroup.modifiers.forEach(modifier => {
        const { reference: modifierReference } = modifier;
        if (!map.has(modifierReference)) {
          map.set(modifierReference, new GeneralSet([], referenceExtractor));
        }

        map.get(modifierReference).add(new CatalogModifierGroup(modifierGroup));
      });

      return map;
    }, new Map());
  }

  build() {
    const {
      _modifierGroups: modifierGroups,
      _modifierToGroupMap: modifierToGroupMap
    } = this;

    return modifierGroups
      .reduce(
        (accumulator, modifierGroup) =>
          accumulator.addAll(modifierGroup.modifiers),
        new GeneralSet([], referenceExtractor)
      )
      .toArray()
      .map(
        modifier =>
          new CatalogModifier({
            ...modifier,
            modifierGroups: modifierToGroupMap
              .get(modifier.reference)
              .toArray()
              .sort(sortByName)
          })
      )
      .sort(sortByName);
  }
}

function referenceExtractor(item) {
  return item.reference;
}

export { CatalogModifiersBuilder as default };
