import Catalog from '../domain/Catalog';
import CreateCatalogDTO from '../dto/Catalog/CreateCatalogDTO';
import UpdateCatalogDTO from '../dto/Catalog/UpdateCatalogDTO';
import { CreatePrettyNameDTO } from '../dto/PrettyName/CreatePrettyNameDTO';
import { partitionArray } from '../helpers/Functions';

class CatalogManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  get selectedCatalogReference() {
    const { sellerDataStore } = this._sellerStore;
    return sellerDataStore.selectedCatalogReference;
  }

  getCatalog = async catalogReference => {
    const { _momsClient: momsClient } = this;
    const { sellerDataStore } = this._sellerStore;
    const jsonCatalog = await momsClient.getCatalog(catalogReference);

    if (jsonCatalog) {
      // Remember that this catalog was selected
      sellerDataStore.setSelectedCatalogReference(catalogReference);
      return Catalog.fromJSON(jsonCatalog);
    } else {
      sellerDataStore.setSelectedCatalogReference(null);
      return null;
    }
  };

  createCatalog = async editCatalog => {
    const { sellerReference } = this._sellerStore;
    return this.createCatalogForSeller(editCatalog, sellerReference);
  };

  createCatalogForSeller = async (editCatalog, sellerReference) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const createCatalogDTO = new CreateCatalogDTO(editCatalog);
    const { tipEnabled } = editCatalog;

    const catalogReference = (
      await momsClient.createCatalog({
        createCatalogDTO,
        productRegisterReference
      })
    )?.reference;

    if (!catalogReference) {
      return false;
    }

    let result = await momsClient.mapCatalogToSeller({
      catalogReference,
      sellerReference,
      productRegisterReference
    });

    tipEnabled.ifChanged(enable => {
      if (enable) {
        result &&= momsClient.activateTipForCatalog({
          catalogReference,
          productRegisterReference
        });
      }
    });

    return result && catalogReference;
  };

  updateCatalog = async (catalogReference, editCatalog) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const updateCatalogDTO = new UpdateCatalogDTO(editCatalog);
    const { tipEnabled } = editCatalog;

    let result = await momsClient.updateCatalog({
      catalogReference,
      updateCatalogDTO,
      productRegisterReference
    });

    tipEnabled.ifChanged(enable => {
      if (enable) {
        result &&= momsClient.activateTipForCatalog({
          catalogReference,
          productRegisterReference
        });
      } else {
        result &&= momsClient.inactivateTipForCatalog({
          catalogReference,
          productRegisterReference
        });
      }
    });

    return result;
  };

  deleteCatalog = async catalogReference => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.deleteCatalog({
      catalogReference,
      productRegisterReference
    });
  };

  addProductsToCatalog = async (catalogReference, productReferences) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    const partitions = partitionArray(productReferences, 5);
    for (const partition of partitions) {
      const requests = partition.map(productReference =>
        momsClient.addProductToCatalog({
          catalogReference,
          productReference,
          productRegisterReference
        })
      );

      // eslint-disable-next-line no-await-in-loop
      const results = await Promise.all(requests);
      if (results.includes(false)) {
        return false;
      }
    }

    return true;
  };

  removeProductFromCatalog = async (catalogReference, productReference) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.removeProductFromCatalog({
      catalogReference,
      productReference,
      productRegisterReference
    });
  };

  updateCatalogProductOverride = async (
    catalogReference,
    productReference,
    editCatalogProductOverride
  ) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.updateCatalogProductOverride({
      catalogReference,
      productReference,
      productRegisterReference,
      editCatalogProductOverride
    });
  };

  updateSortWeightForCatalogProducts = async (catalogReference, weightMap) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    return momsClient.updateSortWeightForCatalogProducts({
      catalogReference,
      weightMap,
      productRegisterReference
    });
  };

  copyProductMappings = async (fromCatalogReference, toCatalogReference) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    return momsClient.copyProductMappings({
      fromCatalogReference,
      toCatalogReference,
      productRegisterReference
    });
  };

  updateCatalogSortWeights = async weightMap => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.updateCatalogSortWeights({
      weightMap,
      productRegisterReference
    });
  };

  addPrettyNamesForCatalog = async args => {
    const { catalogReference, prettyNames } = args;
    const { _momsClient: momsClient, _sellerStore: sellerStore } = this;
    const { sellerReference, productRegisterReference } = sellerStore;
    const createPrettyNamesDTO = prettyNames.map(prettyName =>
      CreatePrettyNameDTO.fromPrettyName(prettyName)
    );

    return momsClient.addPrettyNamesForCatalog({
      sellerReference,
      catalogReference,
      productRegisterReference,
      createPrettyNamesDTO
    });
  };

  setPreferredPrettyName = async (prettyNameReference, catalogReference) => {
    const { _momsClient: momsClient, _sellerStore: sellerStore } = this;
    const { productRegisterReference } = sellerStore;

    return momsClient.setPreferredPrettyName({
      catalogReference,
      productRegisterReference,
      prettyNameReference
    });
  };

  validatePrettyNames = async (prettyNames, sellerReference) => {
    const { _momsClient: momsClient, _sellerStore: sellerStore } = this;
    const { productRegisterReference } = sellerStore;

    const createPrettyNameDTOs = prettyNames.map(prettyName =>
      CreatePrettyNameDTO.fromPrettyName(prettyName)
    );

    const validateResult = await momsClient.validatePrettyNames({
      sellerReference,
      productRegisterReference,
      createPrettyNameDTOs
    });

    if (validateResult?.length) {
      return validateResult.some(({ result }) => result !== 'OK')
        ? {
            valid: false,
            invalidPrettyNames: validateResult.filter(
              ({ result }) => result !== 'OK'
            )
          }
        : { valid: true, invalidPrettyNames: [] };
    } else {
      return { valid: false, invalidPrettyNames: [] };
    }
  };
}

export { CatalogManager };
