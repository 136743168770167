import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateBookkeepingPaymentMethodMappingDTO {
  _paymentMethodType;

  _accountNumber;

  constructor(args) {
    const { paymentMethodType, accountNumber } = args;

    this._paymentMethodType = paymentMethodType;
    this._accountNumber = accountNumber;
  }

  static fromEditBookkeepingPaymentMethodMapping(
    editBookkeepingPaymentMethodMapping
  ) {
    return new this(editBookkeepingPaymentMethodMapping);
  }
}

export { CreateBookkeepingPaymentMethodMappingDTO };
