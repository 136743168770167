import type { IppPaymentMethod } from '../../domain/Adyen/IppPaymentMethod';
import { notNullUndefined } from '../../helpers/Functions';
import type { AdyenPaymentMethodType } from '../../pages/settings/paymentMethods/AdyenForm/ManageAdyenAccount/_constants';

export interface IppPaymentMethodDTO {
  id: string;
  type: string;
  storeId: string;
  enabled: boolean;
  currencies: string[];
}

export const IppPaymentMethodsDTO = {
  toIppPaymentMethods: function (dtoMethods: IppPaymentMethodDTO[]) {
    const builders = dtoMethods.reduce(
      (
        accumulator: Map<string, IPPPaymentMethodBuilder>,
        method: IppPaymentMethodDTO
      ) => {
        const { type, enabled, currencies } = method;

        const builder =
          accumulator.get(type) ?? new IPPPaymentMethodBuilder(type);
        builder.addCurrencies(currencies, enabled);
        accumulator.set(type, builder);

        return accumulator;
      },
      new Map()
    );

    return [...builders.values()]
      .map(builder => builder.build())
      .filter(notNullUndefined);
  }
};

class IPPPaymentMethodBuilder {
  type: AdyenPaymentMethodType;
  enabledCurrencies: string[] = [];
  disabledCurrencies: string[] = [];

  constructor(type: string) {
    this.type = type as AdyenPaymentMethodType;
  }

  addCurrencies = (currencies: string[], enabled: boolean) => {
    if (enabled) {
      this.enabledCurrencies.push(...currencies);
    } else {
      this.disabledCurrencies.push(...currencies);
    }
  };

  build = (): IppPaymentMethod | null => {
    const { type, enabledCurrencies, disabledCurrencies } = this;

    if (enabledCurrencies.length) {
      return {
        type,
        currencies: enabledCurrencies,
        enabled: true
      };
    } else if (disabledCurrencies.length) {
      return {
        type,
        currencies: disabledCurrencies,
        enabled: false
      };
    } else {
      return null;
    }
  };
}
