import type { MutableStoreDetails } from '../../domain/Adyen/MutableStoreDetails';

export interface IActivateTerminalsDTO {
  city: string;
  countryCode: string;
  postalCode: string;
  street: string;
  fullPhoneNumber: string;
  storeName?: string;
  stateOrProvince?: string;
  houseNumberOrName?: string;
  merchantCategoryCode?: string;
  classicStreet?: string;
}

export const ActivateTerminalsDTO = {
  fromStoreDetails(storeDetails: MutableStoreDetails): IActivateTerminalsDTO {
    const {
      street,
      postalCode,
      city,
      countryCode,
      fullPhoneNumber,
      storeName,
      stateOrProvince,
      houseNumberOrName,
      merchantCategoryCode,
      classicStreet
    } = storeDetails;

    return {
      city,
      countryCode,
      postalCode,
      street: classicStreet ?? street,
      fullPhoneNumber,
      storeName,
      stateOrProvince,
      houseNumberOrName,
      merchantCategoryCode
    };
  }
};
