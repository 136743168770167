import { observable, computed } from 'mobx';
import moment from 'moment';
import { Getter, Setter } from '../../Decorators';
import Optional from '../../Optional';

const HH_MM_FORMAT = 'HH:mm';

function timeInMinutes(timeMoment, midnightIs24Hours) {
  const minutes = timeMoment.hours() * 60 + timeMoment.minutes();

  if (minutes === 0 && midnightIs24Hours) {
    return 24 * 60;
  } else {
    return minutes;
  }
}

function timeStringToMoment(timeString) {
  return Optional.of(timeString)
    .filter(str => str.length >= 5)
    .map(str => str.slice(0, 5))
    .map(str => moment(str, HH_MM_FORMAT, true).utc(true))
    .orElse(null);
}

@Setter({ functions: true })
@Getter({ computed: true })
class EditOpeningHour {
  @observable
  _fromInclusive;

  @observable
  _toExclusive;

  constructor(args = {}) {
    const { fromInclusive = null, toExclusive = null } = args;

    this._fromInclusive = fromInclusive;
    this._toExclusive = toExclusive;
  }

  static fromJSON(json) {
    const { fromInclusive, toInclusive } = json;
    const toExclusive = Optional.of(toInclusive)
      .map(timeStringToMoment)
      .map(_inclusiveMoment => _inclusiveMoment.add(1, 'minute'))
      .map(_exclusiveMoment => _exclusiveMoment.format(HH_MM_FORMAT))
      .orElse(null);

    return new this({
      fromInclusive,
      toExclusive
    });
  }

  toJSON() {
    const { _fromInclusive: fromInclusive, _toExclusive: toExclusive } = this;
    const toInclusive = Optional.of(toExclusive)
      .map(timeStringToMoment)
      .map(_exclusiveMoment => _exclusiveMoment.subtract(1, 'minute'))
      .map(_inclusiveMoment => _inclusiveMoment.format(HH_MM_FORMAT))
      .orElse(null);

    return {
      fromInclusive,
      toInclusive
    };
  }

  @computed
  get isFromValid() {
    const { _fromInclusive: fromInclusive } = this;

    return fromInclusive !== null && fromInclusive !== '';
  }

  @computed
  get isFromInvalid() {
    const { _fromInclusive: fromInclusive } = this;

    return fromInclusive === '';
  }

  @computed
  get isToValid() {
    const { _fromInclusive: fromInclusive, _toExclusive: toExclusive } = this;

    if (toExclusive !== null && toExclusive !== '') {
      return fromInclusive ? this._isToAfterFrom : true;
    }

    return false;
  }

  @computed
  get isToInvalid() {
    const { _fromInclusive: fromInclusive, _toExclusive: toExclusive } = this;

    if (toExclusive === null) {
      return false;
    } else if (toExclusive === '') {
      return true;
    } else if (fromInclusive) {
      return !this._isToAfterFrom;
    } else {
      return false;
    }
  }

  @computed
  get _isToAfterFrom() {
    const { _fromInclusive: fromInclusive, _toExclusive: toExclusive } = this;

    const fromMinutes = Optional.of(fromInclusive)
      .map(timeStringToMoment)
      .map(_moment => timeInMinutes(_moment, false))
      .orElse(null);

    const toMinutes = Optional.of(toExclusive)
      .map(timeStringToMoment)
      .map(_moment => timeInMinutes(_moment, true))
      .orElse(null);

    return (
      fromMinutes !== null && toMinutes !== null && toMinutes > fromMinutes
    );
  }

  @computed
  get isValid() {
    return this.isFromValid && this.isToValid;
  }

  @computed
  get isEmpty() {
    const { _fromInclusive: fromInclusive, _toExclusive: toExclusive } = this;

    return fromInclusive === null && toExclusive === null;
  }
}

export { EditOpeningHour as default };
