import { action, computed, observable } from 'mobx';
import type ProductRegister from '../domain/ProductRegister';
import type CatalogPreview from '../domain/Catalog/CatalogPreview';
import type ModifierGroup from '../domain/ModifierGroup';
import type Category from '../domain/Category/Category';
import type { Discount } from '../domain/Discount';
import type { Bookkeeping } from '../domain/Bookkeeping/Bookkeeping';
import type { BookkeepingExport } from '../domain/Bookkeeping/BookkeepingExport';
import type { BookkeepingAccount } from '../domain/Bookkeeping/BookkeepingAccount';
import type { BookkeepingProductMapping } from '../domain/Bookkeeping/BookkeepingProductMapping';
import type { BookkeepingPaymentMethodMapping } from '../domain/Bookkeeping/BookkeepingPaymentMethodMapping';
import type { WelcomeTasks } from '../domain/WelcomeTasks/WelcomeTasks';
import type { DailyReport } from '../domain/DailyReport/DailyReport';

class SellerDataStore {
  @observable
  _productRegister?: ProductRegister;
  @observable
  _catalogPreviews: Record<string, CatalogPreview> = {};
  @observable
  _sellerCatalogPreviews: Record<string, CatalogPreview> = {};
  @observable
  _selectedCatalogReference: string | null = null;
  @observable
  _modifierGroups: Record<string, ModifierGroup> = {};
  @observable
  _categories: Record<string, Category> = {};
  @observable
  _tags: Record<string, any> = {};
  @observable
  _discounts: Record<string, Discount> = {};
  @observable
  _bookkeeping: Bookkeeping | null = null;
  @observable
  _bookkeepingExports: BookkeepingExport[] = [];
  @observable
  _bookkeepingAccounts: Record<string, BookkeepingAccount> = {};
  @observable
  _bookkeepingProductMappings: Record<string, BookkeepingProductMapping> = {};
  @observable
  _bookkeepingPaymentMethodMappings: BookkeepingPaymentMethodMapping[] = [];
  @observable
  _dailyReports: DailyReport[] = [];
  @observable
  _integrations: Record<string, any> = {};
  @observable
  private _welcomeTasks: WelcomeTasks | null = null;
  @observable
  private _isOnboarding: boolean | null = null;

  constructor(productRegister?: ProductRegister) {
    this._productRegister = productRegister;
  }

  @computed
  get productRegister() {
    return this._productRegister;
  }

  @computed
  get catalogPreviews() {
    return this._catalogPreviews;
  }

  @computed
  get sellerCatalogPreviews() {
    return this._sellerCatalogPreviews;
  }

  @computed
  get selectedCatalogReference() {
    return this._selectedCatalogReference;
  }

  @computed
  get modifierGroups() {
    return this._modifierGroups;
  }

  @computed
  get categories() {
    return this._categories;
  }

  @computed
  get tags() {
    return this._tags;
  }

  @computed
  get discounts() {
    return this._discounts;
  }

  @computed
  get bookkeeping() {
    return this._bookkeeping;
  }

  @computed
  get bookkeepingExports() {
    return this._bookkeepingExports;
  }

  @computed
  get bookkeepingAccounts() {
    return this._bookkeepingAccounts;
  }

  @computed
  get bookkeepingProductMappings() {
    return this._bookkeepingProductMappings;
  }

  @computed
  get bookkeepingPaymentMethodMappings() {
    return this._bookkeepingPaymentMethodMappings;
  }

  @computed
  get dailyReports() {
    return this._dailyReports;
  }

  @computed
  get integrations() {
    return this._integrations;
  }

  @computed
  get welcomeTasks() {
    return this._welcomeTasks;
  }

  @computed
  get isOnboarding() {
    return this._isOnboarding;
  }

  @action
  setProductRegister = (productRegister: ProductRegister) => {
    this._productRegister = productRegister;
  };

  @action
  setCatalogPreviews = (catalogPreviews: Record<string, CatalogPreview>) => {
    this._catalogPreviews = catalogPreviews;
  };

  @action
  setSellerCatalogPreviews = (
    sellerCatalogPreviews: Record<string, CatalogPreview>
  ) => {
    this._sellerCatalogPreviews = sellerCatalogPreviews;
  };

  @action
  setSelectedCatalogReference = (selectedCatalogReference: string | null) => {
    this._selectedCatalogReference = selectedCatalogReference;
  };

  @action
  setModifierGroups = (modifierGroups: Record<string, ModifierGroup>) => {
    this._modifierGroups = modifierGroups;
  };

  @action
  setCategories = (categories: Record<string, Category>) => {
    this._categories = categories;
  };

  @action
  setTags = (tags: Record<string, any>) => {
    this._tags = tags;
  };

  @action
  setDiscounts = (discounts: Record<string, Discount>) => {
    this._discounts = discounts;
  };

  @action
  setBookkeeping = (bookkeeping: Bookkeeping | null) => {
    this._bookkeeping = bookkeeping;
  };

  @action
  setBookkeepingExports = (bookkeepingExports: BookkeepingExport[]) => {
    this._bookkeepingExports = bookkeepingExports;
  };

  @action
  setBookkeepingAccounts = (
    bookkeepingAccounts: Record<string, BookkeepingAccount>
  ) => {
    this._bookkeepingAccounts = bookkeepingAccounts;
  };

  @action
  setBookkeepingProductMappings = (
    bookkeepingProductMappings: Record<string, BookkeepingProductMapping>
  ) => {
    this._bookkeepingProductMappings = bookkeepingProductMappings;
  };

  @action
  setBookkeepingPaymentMethodMappings = (
    bookkeepingPaymentMethodMappings: BookkeepingPaymentMethodMapping[]
  ) => {
    this._bookkeepingPaymentMethodMappings = bookkeepingPaymentMethodMappings;
  };

  @action
  setDailyReports = (dailyReports: DailyReport[]) => {
    this._dailyReports = dailyReports;
  };

  @action
  setIntegrations = (integrations: Record<string, any>) => {
    this._integrations = integrations;
  };

  @action
  setWelcomeTasks = (welcomeTasks: WelcomeTasks) => {
    this._welcomeTasks = welcomeTasks;
  };

  @action
  setIsOnboarding = (isOnboarding: boolean | null) => {
    this._isOnboarding = isOnboarding;
  };
}

export { SellerDataStore };
