import { computed } from 'mobx';
import CatalogPreview from '../domain/Catalog/CatalogPreview';
import { sortByName } from '../helpers/Functions';
import { PRODUCT_CATALOG, ENTRY_CATALOG } from '../constants';

class CatalogPreviewManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get catalogPreviews() {
    const { sellerDataStore } = this._sellerStore;
    const { catalogPreviews } = sellerDataStore;

    return catalogPreviews;
  }

  @computed
  get productCatalogPreviews() {
    const { catalogPreviews } = this;

    return Object.entries(catalogPreviews).reduce(
      (accumulator, [reference, catalogPreview]) => {
        const { type } = catalogPreview;
        if (type === PRODUCT_CATALOG) {
          accumulator[reference] = catalogPreview;
        }

        return accumulator;
      },
      {}
    );
  }

  @computed
  get entryCatalogPreviews() {
    const { catalogPreviews } = this;

    return Object.entries(catalogPreviews).reduce(
      (accumulator, [reference, catalogPreview]) => {
        const { type } = catalogPreview;
        if (type === ENTRY_CATALOG) {
          accumulator[reference] = catalogPreview;
        }

        return accumulator;
      },
      {}
    );
  }

  async fetchCatalogPreviews() {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference, sellerDataStore } = this._sellerStore;

    const catalogs = (
      await momsClient.getCatalogPreviews(productRegisterReference)
    )
      .sort(sortByName)
      .reduce((accumulator, jsonCatalog) => {
        const { reference } = jsonCatalog;
        const catalog = CatalogPreview.fromJSON(jsonCatalog);

        accumulator[reference] = catalog;
        return accumulator;
      }, {});

    sellerDataStore.setCatalogPreviews(catalogs);
  }
}

export { CatalogPreviewManager };
