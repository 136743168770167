import { v4 as uuidv4 } from 'uuid';

export interface IProductTopListEntry {
  productName: string;
  count: number;
  amount: string;
}

interface ProductTopListEntry extends IProductTopListEntry {}
class ProductTopListEntry {
  readonly productName;
  readonly count;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IProductTopListEntry) {
    const { productName, count, amount } = args;

    this.productName = productName;
    this.count = count;
    this.amount = amount;
  }

  static fromJSON(json: IProductTopListEntry) {
    return new this(json);
  }

  static keyExtractor(entry: ProductTopListEntry) {
    return entry.key;
  }
}

export { ProductTopListEntry };
