import { ToJSON } from '../../domain/Decorators';
import { acceptedUserRoles } from '../../domain/User/AcceptedUserRoles';

@ToJSON
class UpdateUserDTO {
  _emailAddress;

  _roles;

  constructor(args) {
    const { emailAddress, roles } = args;

    this._emailAddress = emailAddress;
    this._roles = roles;
  }

  static fromUpdateUserData(updateUserData) {
    const { emailAddress, roles } = updateUserData;

    return new this({
      emailAddress,
      roles: roles.toArray().filter(role => acceptedUserRoles.includes(role))
    });
  }
}

export { UpdateUserDTO };
