import type { ReferenceHolder } from '../Common/ReferenceHolder';

export type KeyExtractor<K, V> = (value: V) => K;
type Reducer<K, V, A> = (
  accumulator: A,
  currentValue: V,
  index: number,
  set: GeneralSet<K, V>
) => A;

const KeyExtractors = {
  Identity: <V>(value: V) => {
    return value;
  },
  Reference: <V extends ReferenceHolder>(value: V) => {
    return value.reference;
  }
};

export class GeneralSet<K, V> implements Iterable<V> {
  private readonly _map;
  private readonly keyExtractor;
  private readonly mapConstructor: () => Map<K, V>;
  readonly [Symbol.iterator] = this.values;

  constructor(
    iterable: Iterable<V> = [],
    keyExtractor?: KeyExtractor<K, V>,
    mapConstructor = () => new Map<K, V>()
  ) {
    if (keyExtractor === undefined) {
      // NOTE: Needed for compatibility with old JS code
      keyExtractor = identity as KeyExtractor<K, V>;
    }

    this._map = mapConstructor();
    this.keyExtractor = keyExtractor;
    this.mapConstructor = mapConstructor;

    for (const value of iterable) {
      this.add(value);
    }
  }

  static create<V>(iterable?: Iterable<V>): GeneralSet<V, V>;
  static create<K, V>(
    iterable: Iterable<V>,
    keyExtractor: KeyExtractor<K, V>,
    mapConstructor?: () => Map<K, V>
  ): GeneralSet<K, V>;
  static create<K, V = K>(
    iterable: Iterable<V>,
    keyExtractor?: KeyExtractor<K, V>,
    mapConstructor?: () => Map<K, V>
  ) {
    if (keyExtractor === undefined) {
      return new GeneralSet<V, V>(iterable, KeyExtractors.Identity);
    } else {
      return new GeneralSet<K, V>(iterable, keyExtractor, mapConstructor);
    }
  }

  add(value: V) {
    const key = this.keyExtractor(value);

    if (!this._map.has(key)) {
      this._map.set(key, value);
    }

    return this;
  }

  addAll(iterable: Iterable<V>) {
    for (const value of iterable) {
      this.add(value);
    }

    return this;
  }

  values() {
    return this._map.values();
  }

  has(value: V) {
    const key = this.keyExtractor(value);
    return this._map.has(key);
  }

  hasAll(values: Iterable<V>) {
    for (const value of values) {
      if (!this.has(value)) {
        return false;
      }
    }

    return true;
  }

  hasKey(key: K) {
    return this._map.has(key);
  }

  delete(value: V) {
    const key = this.keyExtractor(value);
    return this._map.delete(key);
  }

  deleteAll(values: Iterable<V>) {
    for (const value of values) {
      this.delete(value);
    }
  }

  deleteKey(key: K) {
    return this._map.delete(key);
  }

  get size() {
    return this._map.size;
  }

  clear() {
    this._map.clear();
  }

  pop() {
    const lastKey = Array.from(this._map.keys()).pop();
    if (lastKey !== undefined) {
      this._map.delete(lastKey);
    }
  }

  forEach(callback: (value: V, index: number, set: GeneralSet<K, V>) => {}) {
    const values = this._map.values();

    for (const [value, index] of enumerate(values)) {
      callback(value, index, this);
    }
  }

  map(mapper: (value: V, index: number, set: GeneralSet<K, V>) => V) {
    const newSet = new GeneralSet([], this.keyExtractor, this.mapConstructor);
    const values = this._map.values();

    for (const [value, index] of enumerate(values)) {
      newSet.add(mapper(value, index, this));
    }

    return newSet;
  }

  filter(
    predicate: (value: V, index: number, set: GeneralSet<K, V>) => boolean
  ) {
    const newSet = new GeneralSet([], this.keyExtractor, this.mapConstructor);
    const values = this._map.values();

    for (const [value, index] of enumerate(values)) {
      if (predicate(value, index, this)) {
        newSet.add(value);
      }
    }

    return newSet;
  }

  reduce<A = V>(reducer: Reducer<K, V, A>, initialValue?: A) {
    const { size } = this._map;
    const iterator = enumerate(this._map.values());

    let accumulator: A;
    if (initialValue) {
      accumulator = initialValue;
    } else if (size) {
      [accumulator] = iterator.next().value!;
    } else {
      throw new TypeError('reduce of empty GeneralSet with no initial value');
    }

    for (const [value, index] of iterator) {
      accumulator = reducer(accumulator, value, index, this);
    }

    return accumulator;
  }

  toArray(): V[] {
    return Array.from(this);
  }

  static get KeyExtractors() {
    return KeyExtractors;
  }
}

function identity<V>(arg: V) {
  return KeyExtractors.Identity(arg);
}

function* enumerate<V>(values: Iterable<V>): Generator<[V, number]> {
  let i = 0;

  for (const x of values) {
    yield [x, i];
    i += 1;
  }
}
