import { Terminal } from '../domain/Terminal/Terminal';
import { TerminalDetails } from '../domain/Terminal/TerminalDetails';
import { TerminalOrder } from '../domain/Terminal/TerminalOrder';
import { TerminalOrderPrerequisites } from '../domain/Terminal/TerminalOrderPrerequisites';
import { TerminalOrderDetails } from '../domain/Terminal/TerminalOrderDetails';
import { CreateAdyenTerminalOrderDTO } from '../dto/Terminal/CreateAdyenTerminalOrderDTO';
import { CreateAdyenTerminalShippingLocationDTO } from '../dto/Terminal/CreateAdyenTerminalShippingLocationDTO';
import type { WeiqCoreClient } from '../config/clients/WeiqCoreClient';
import type { SellerStore } from '../stores/SellerStore';
import type { MutableStoreDetails } from '../domain/Adyen/MutableStoreDetails';
import type { MutableTerminalOrder } from '../domain/Terminal/MutableTerminalOrder';
import type { TerminalReassignmentResult } from '../domain/Terminal/TerminalReassignmentResult';
import { assertNotUndefined } from '../helpers/Functions';
import { ActivateTerminalsDTO } from '../dto/Terminal/ActivateTerminals';

class TerminalManager {
  private readonly sellerStore;
  private readonly coreClient;

  constructor(coreClient: WeiqCoreClient, sellerStore: SellerStore) {
    this.sellerStore = sellerStore;
    this.coreClient = coreClient;
  }

  activateTerminals = async (storeDetails: MutableStoreDetails) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const activateTerminalsDTO =
      ActivateTerminalsDTO.fromStoreDetails(storeDetails);

    return coreClient.activateTerminals(
      selectedSellerReference,
      activateTerminalsDTO
    );
  };

  getTerminalsForSeller = async () => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonTerminals = await coreClient.getTerminalsForSeller(
      selectedSellerReference
    );

    return Array.isArray(jsonTerminals)
      ? jsonTerminals.map(terminal => Terminal.fromJSON(terminal))
      : [];
  };

  getTerminalsInInventory = async () => {
    const { coreClient } = this;

    const jsonTerminals = await coreClient.getTerminalsInInventory();
    return Array.isArray(jsonTerminals)
      ? jsonTerminals.map(terminal => Terminal.fromJSON(terminal))
      : [];
  };

  getInventoryTerminalDetails = async (terminalId: string) => {
    const { coreClient } = this;

    const jsonTerminalDetails =
      await coreClient.getInventoryTerminalDetails(terminalId);

    return jsonTerminalDetails
      ? TerminalDetails.fromJSON(jsonTerminalDetails)
      : null;
  };

  getAssignedTerminalDetails = async (terminalId: string) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonTerminalDetails = await coreClient.getAssignedTerminalDetails(
      selectedSellerReference,
      terminalId
    );

    return jsonTerminalDetails
      ? TerminalDetails.fromJSON(jsonTerminalDetails)
      : null;
  };

  getTerminalOrdersForSeller = async () => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonTerminalOrders = await coreClient.getTerminalOrdersForSeller(
      selectedSellerReference
    );

    return Array.isArray(jsonTerminalOrders)
      ? jsonTerminalOrders.map(terminal => TerminalOrder.fromJSON(terminal))
      : [];
  };

  getTerminalOrderDetails = async (orderReference: string) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonTerminalOrderDetails = await coreClient.getTerminalOrderDetails(
      selectedSellerReference,
      orderReference
    );

    return jsonTerminalOrderDetails
      ? TerminalOrderDetails.fromJSON(jsonTerminalOrderDetails)
      : null;
  };

  getTerminalOrderPrerequisites = async () => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonTerminalOrderPrerequisites =
      await coreClient.getTerminalOrderPrerequisites(selectedSellerReference);

    return jsonTerminalOrderPrerequisites
      ? TerminalOrderPrerequisites.fromJSON(jsonTerminalOrderPrerequisites)
      : null;
  };

  createTerminalOrder = async (
    mutableTerminalOrder: MutableTerminalOrder,
    shippingLocationId: string
  ) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const createAdyenTerminalOrderDTO =
      CreateAdyenTerminalOrderDTO.fromMutableTerminalOrder(
        mutableTerminalOrder,
        shippingLocationId
      );

    return coreClient.createTerminalOrder(
      selectedSellerReference,
      createAdyenTerminalOrderDTO
    );
  };

  createShippingEntity = async (mutableTerminalOrder: MutableTerminalOrder) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const createShippingLocationDTO =
      CreateAdyenTerminalShippingLocationDTO.fromMutableTerminalOrder(
        mutableTerminalOrder
      );

    return coreClient.createShippingLocation(
      selectedSellerReference,
      createShippingLocationDTO
    );
  };

  isShippingEntityCreated = async (shippingLocationId: string) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    return coreClient.isShippingLocationCreated(
      selectedSellerReference,
      shippingLocationId
    );
  };

  reassignTerminalToSeller = async (
    assignToSellerReference: string,
    terminalId: string
  ): Promise<TerminalReassignmentResult> => {
    const { coreClient } = this;
    const result = await coreClient.assignTerminal(
      assignToSellerReference,
      terminalId
    );

    if (result.success) {
      return { success: true };
    } else if (result.error?.startsWith('No store found for seller')) {
      return { success: false, error: 'NO_STORE' };
    } else {
      return { success: false, error: 'UNKNOWN' };
    }
  };

  assignTerminalToInventory = async (terminalId: string) => {
    const { coreClient } = this;
    const result = await coreClient.assignTerminal(null, terminalId);

    return result.success;
  };
}

export { TerminalManager };
