import { Subscription } from '../domain/Subscription';
import { SubscriptionDTO } from '../dto/Subscription/SubscriptionDTO';

class SubscriptionManager {
  _misoClient;

  _sellerStore;

  constructor(misoClient, sellerStore) {
    this._misoClient = misoClient;
    this._sellerStore = sellerStore;
  }

  getSubscriptions = async () => {
    const { _misoClient: misoClient, _sellerStore: sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    const jsonSubscriptions = await misoClient.getSubscriptions(
      selectedSellerReference
    );
    return Array.isArray(jsonSubscriptions)
      ? jsonSubscriptions.map(subscription =>
          Subscription.fromJSON(subscription)
        )
      : [];
  };

  createSubscription = async mutableSubscription => {
    const { _misoClient: misoClient, _sellerStore: sellerStore } = this;
    const { selectedSellerReference } = sellerStore;

    const subscriptionDTO =
      SubscriptionDTO.fromMutableSubscription(mutableSubscription);
    return misoClient.createSubscription(
      selectedSellerReference,
      subscriptionDTO
    );
  };

  updateSubscription = async (subscriptionReference, updateSubscription) => {
    const { _misoClient: misoClient } = this;

    const subscriptionDTO =
      SubscriptionDTO.fromMutableSubscription(updateSubscription);
    return misoClient.updateSubscription(
      subscriptionReference,
      subscriptionDTO
    );
  };

  deleteSubscription = async subscriptionReference => {
    const { _misoClient: misoClient } = this;

    return misoClient.deleteSubscription(subscriptionReference);
  };
}

export { SubscriptionManager };
