import { Getter } from '../Decorators';

@Getter
class User {
  _reference;

  _username;

  _emailAddress;

  _roles;

  _sellerAccess;

  constructor(args) {
    const { reference, username, email, roles, sellerAccess } = args;
    this._reference = reference;
    this._username = username;
    this._emailAddress = email;
    this._roles = roles;
    this._sellerAccess = sellerAccess;
  }

  static fromJSON(json) {
    const { sellerAccess, ...rest } = json;
    return new this({
      sellerAccess: sellerAccess.map(s => SellerAccess.fromJSON(s)),
      ...rest
    });
  }
}

@Getter
class SellerAccess {
  _reference;

  _name;

  constructor(args) {
    const { reference, name } = args;
    this._reference = reference;
    this._name = name;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { User };
