export interface IMonetaryAmount {
  amount: number;
  currency: string;
}
interface MonetaryAmount extends IMonetaryAmount {}

class MonetaryAmount {
  readonly amount;
  readonly currency;

  constructor(args: IMonetaryAmount) {
    const { amount, currency } = args;
    this.amount = amount;
    this.currency = currency;
  }

  get isZero() {
    return this.amount === 0;
  }

  static fromJSON(json: IMonetaryAmount) {
    return new this(json);
  }

  static create = (args: IMonetaryAmount) => new this(args);

  static zero = (currency: string) => new this({ amount: 0, currency });

  toJSON = (): IMonetaryAmount => {
    const { amount, currency } = this;

    return {
      amount,
      currency
    };
  };
}

export { MonetaryAmount };
