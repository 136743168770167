export interface IAdyenTerminalProduct {
  id: string;
  name: string;
  description: string | null;
  price: number;
  currency: string;
  belongsToModel: string;
}

class AdyenTerminalProduct {
  readonly id;
  readonly name;
  readonly description;
  readonly price;
  readonly currency;
  readonly belongsToModel;

  constructor(args: IAdyenTerminalProduct) {
    const { id, name, description, price, currency, belongsToModel } = args;

    this.id = id;
    this.name = name;
    this.description = description;
    this.price = price;
    this.currency = currency;
    this.belongsToModel = belongsToModel;
  }

  static fromJSON(json: IAdyenTerminalProduct) {
    return new this(json);
  }
}

export { AdyenTerminalProduct };
