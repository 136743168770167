import type { ICatalogLineItemsDiscountLimitation } from './CatalogLineItemsDiscountLimitation';
import { CatalogLineItemsDiscountLimitation } from './CatalogLineItemsDiscountLimitation';
import type { ICategoriesDiscountLimitation } from './CategoriesDiscountLimitation';
import { CategoriesDiscountLimitation } from './CategoriesDiscountLimitation';
import type { IDateTimeRangeDiscountLimitation } from './DateTimeRangeDiscountLimitation';
import { DateTimeRangeDiscountLimitation } from './DateTimeRangeDiscountLimitation';
import type { IMaxUsesPerUserDiscountLimitation } from './MaxUsesPerUserDiscountLimitation';
import { MaxUsesPerUserDiscountLimitation } from './MaxUsesPerUserDiscountLimitation';
import type { JMinOrderAmountDiscountLimitation } from './MinOrderAmountDiscountLimitation';
import { MinOrderAmountDiscountLimitation } from './MinOrderAmountDiscountLimitation';
import type { JWeekdayTimeRangeDiscountLimitation } from './WeekdayTimeRangeDiscountLimitation';
import { WeekdayTimeRangeDiscountLimitation } from './WeekdayTimeRangeDiscountLimitation';

export type DiscountLimitation = ReturnType<
  (typeof DiscountLimitationMapper)['fromJSON']
>;
export type DiscountLimitationType = DiscountLimitation['type'];

export type JDiscountLimitation =
  | ICatalogLineItemsDiscountLimitation
  | ICategoriesDiscountLimitation
  | IDateTimeRangeDiscountLimitation
  | IMaxUsesPerUserDiscountLimitation
  | JMinOrderAmountDiscountLimitation
  | JWeekdayTimeRangeDiscountLimitation;

class DiscountLimitationMapper {
  static fromJSON(json: JDiscountLimitation) {
    const { type } = json;

    switch (type) {
      case CatalogLineItemsDiscountLimitation.TYPE:
        return CatalogLineItemsDiscountLimitation.fromJSON(json);
      case CategoriesDiscountLimitation.TYPE:
        return CategoriesDiscountLimitation.fromJSON(json);
      case DateTimeRangeDiscountLimitation.TYPE:
        return DateTimeRangeDiscountLimitation.fromJSON(json);
      case MaxUsesPerUserDiscountLimitation.TYPE:
        return MaxUsesPerUserDiscountLimitation.fromJSON(json);
      case MinOrderAmountDiscountLimitation.TYPE:
        return MinOrderAmountDiscountLimitation.fromJSON(json);
      case WeekdayTimeRangeDiscountLimitation.TYPE:
        return WeekdayTimeRangeDiscountLimitation.fromJSON(json);

      default:
        throw new Error(`Unknown discount model type ${type}`);
    }
  }
}

export { DiscountLimitationMapper };
