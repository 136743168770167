import Order from '../domain/Order/Order';
import { OrderPreviewPageDTO } from '../dto/OrderPreview/OrderPreviewPageDTO';
import { SearchOrderPreviewsDTO } from '../dto/OrderPreview/SearchOrderPreviewsDTO';

class OrderManager {
  _sellerStore;

  _coreClient;

  constructor(coreClient, sellerStore) {
    this._sellerStore = sellerStore;
    this._coreClient = coreClient;
  }

  getOrders = async offsetOrderReference => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    if (selectedSellerReference) {
      const jsonOrders = await coreClient.getOrders(
        selectedSellerReference,
        offsetOrderReference
      );
      if (Array.isArray(jsonOrders)) {
        return jsonOrders.map(jsonOrder => Order.fromJSON(jsonOrder));
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  getOrder = async (orderReference, { json = false } = {}) => {
    const { _coreClient: coreClient } = this;

    const jsonOrder = await coreClient.getOrder(orderReference);

    if (jsonOrder) {
      return json ? jsonOrder : Order.fromJSON(jsonOrder);
    } else {
      return null;
    }
  };

  searchOrderPreviews = async (searchOrderPreviewFilter, searchIndex) => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    const searchOrderPreviewsDTO =
      SearchOrderPreviewsDTO.fromSearchOrderPreviewFilter(
        searchOrderPreviewFilter,
        searchIndex || ''
      );

    const orderPreviewPageDTO = await coreClient.searchOrderPreviews(
      selectedSellerReference,
      searchOrderPreviewsDTO
    );

    if (orderPreviewPageDTO) {
      return OrderPreviewPageDTO.toOrderPreviewPage(orderPreviewPageDTO);
    }
    return null;
  };

  getHistops = async orderReference => {
    const { _coreClient: coreClient } = this;

    if (orderReference) {
      return coreClient.getHistops(orderReference);
    } else {
      return null;
    }
  };

  confirmTicket = async (orderReference, ticketReference) => {
    const { _coreClient: coreClient } = this;
    if (orderReference && ticketReference) {
      return coreClient.confirmTicket(orderReference, ticketReference);
    } else {
      return false;
    }
  };

  activateTicket = async (orderReference, ticketReference) => {
    const { _coreClient: coreClient } = this;
    if (orderReference && ticketReference) {
      return coreClient.activateTicket(orderReference, ticketReference);
    } else {
      return false;
    }
  };

  triggerReadyForPickup = async orderReference => {
    const { _coreClient: coreClient } = this;
    if (orderReference) {
      return coreClient.triggerReadyForPickup(orderReference);
    } else {
      return false;
    }
  };

  deliverOrder = async orderReference => {
    const { _coreClient: coreClient } = this;
    if (orderReference) {
      return coreClient.deliverOrder(orderReference);
    } else {
      return false;
    }
  };

  rejectOrder = async orderReference => {
    const { _coreClient: coreClient } = this;
    if (orderReference) {
      return coreClient.rejectOrder(orderReference);
    } else {
      return false;
    }
  };

  creditOrder = async orderReference => {
    const { _coreClient: coreClient } = this;
    if (orderReference) {
      return coreClient.creditOrder(orderReference);
    } else {
      return false;
    }
  };
}

export { OrderManager };
