import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateOnslipIntegrationDTO {
  _realm;

  _keyIdentifier;

  _key;

  _deliveryLocationId;

  constructor(args) {
    const { realm, keyIdentifier, key, deliveryLocationId } = args;

    this._realm = realm;
    this._keyIdentifier = keyIdentifier;
    this._key = key;
    this._deliveryLocationId = deliveryLocationId;
  }

  static fromCreateOnslipIntegration(createOnslipIntegration) {
    const { realm, keyIdentifier, key, deliveryLocationId } =
      createOnslipIntegration;

    return new this({
      realm,
      keyIdentifier,
      key,
      deliveryLocationId
    });
  }
}

export { CreateOnslipIntegrationDTO };
