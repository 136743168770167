export interface IReceipt {
  receiptUrl: string | null;
  refund: boolean;
  reference: string;
  serialNumber: number;
  controlUnitGeneratedCode: string | null;
  controlUnitProductionNumber: string | null;
}

interface Receipt extends IReceipt {}
class Receipt {
  readonly receiptUrl;
  readonly refund;
  readonly reference;
  readonly serialNumber;
  readonly controlUnitGeneratedCode;
  readonly controlUnitProductionNumber;

  constructor(args: IReceipt) {
    const {
      receiptUrl,
      refund,
      reference,
      serialNumber,
      controlUnitGeneratedCode,
      controlUnitProductionNumber
    } = args;
    this.receiptUrl = receiptUrl;
    this.refund = refund;
    this.reference = reference;
    this.serialNumber = serialNumber;
    this.controlUnitGeneratedCode = controlUnitGeneratedCode;
    this.controlUnitProductionNumber = controlUnitProductionNumber;
  }

  static fromJSON(json: IReceipt) {
    return new this(json);
  }
}

export { Receipt };
