import {
  AUTHORIZED,
  CONFIRMATION_IN_PROGRESS,
  CONFIRMED,
  CONFIRMED_BY_PAYMENT_PROVIDER,
  CREATED,
  CREDIT_IN_PROGRESS,
  DELIVERED,
  ENTRY_TICKET,
  IN_PROGRESS,
  READY_FOR_PICKUP
} from '../../constants';
import type { MonetaryAmount } from '../Amount/MonetaryAmount';
import moment from 'moment';
import type { IRefundPermission } from '../Permission/RefundPermission';
import { cache } from 'decorator-cache-getter';

type BasicOrderLineItem = {
  type: string;
  deliveryLocationReference: string;
};
type BasicPayment = {
  state: string;
  separateCapture: boolean;
};

abstract class OrderMethods {
  protected abstract get ticketStatus(): string;
  protected abstract get ticketType(): string | undefined;
  protected abstract get initialDebt(): MonetaryAmount;
  protected abstract get createdAt(): string;
  protected abstract get currentPayment(): BasicPayment | undefined;
  protected abstract get orderLineItems(): BasicOrderLineItem[];

  get deliveryLocationReference() {
    return this.orderLineItems[0]?.deliveryLocationReference;
  }

  @cache
  get isProcessingPayment() {
    const { currentPayment } = this;
    return (
      currentPayment?.state === CONFIRMATION_IN_PROGRESS ||
      currentPayment?.state === CREDIT_IN_PROGRESS
    );
  }

  @cache
  get isZeroAmountOrder() {
    const { currentPayment, initialDebt } = this;
    return initialDebt.isZero && currentPayment === undefined;
  }

  @cache
  private get inRefundableState() {
    const { ticketStatus, ticketType, currentPayment, isZeroAmountOrder } =
      this;

    if (ticketType === ENTRY_TICKET) {
      return currentPayment?.state === CONFIRMED_BY_PAYMENT_PROVIDER;
    } else {
      return (
        ticketStatus === DELIVERED &&
        (currentPayment?.state === CONFIRMED_BY_PAYMENT_PROVIDER ||
          isZeroAmountOrder)
      );
    }
  }

  isRefundable(permission: IRefundPermission) {
    const { inRefundableState, createdAt } = this;
    const { granted, hours } = permission;

    return (
      inRefundableState &&
      granted &&
      moment().isBefore(moment(createdAt).add(hours, 'hours'))
    );
  }

  @cache
  get isRejectable() {
    const { currentPayment, ticketStatus, ticketType, isZeroAmountOrder } =
      this;

    if (ticketType === ENTRY_TICKET) {
      return false;
    }

    if (
      ![CREATED, IN_PROGRESS, CONFIRMED, READY_FOR_PICKUP].includes(
        ticketStatus
      )
    ) {
      return false;
    }

    return (
      isZeroAmountOrder ||
      (currentPayment?.state === AUTHORIZED &&
        currentPayment.separateCapture) ||
      (currentPayment?.state === CONFIRMED_BY_PAYMENT_PROVIDER &&
        !currentPayment.separateCapture)
    );
  }
}

export { OrderMethods };
