import type { IppPaymentMethod } from '../../domain/Adyen/IppPaymentMethod';

export interface IUpdateIppPaymentMethodDTO {
  type: string;
  enabled: boolean;
}

export const UpdateIppPaymentMethodDTO = {
  fromIppPaymentMethod(method: IppPaymentMethod): IUpdateIppPaymentMethodDTO {
    const { type, enabled } = method;

    return {
      type,
      enabled
    };
  }
};
