import type { ICatalogRow } from './CatalogRow';
import { CatalogRow } from './CatalogRow';
import type { JCatalogSummaryRow } from './CatalogSummaryRow';
import { CatalogSummaryRow } from './CatalogSummaryRow';

interface ICatalogSection {
  salesRows: CatalogRow[];
  summaryRow: CatalogSummaryRow;
}

export interface JCataogSection {
  salesRows: ICatalogRow[];
  summaryRow: JCatalogSummaryRow;
}

type CatalogSectionRow = CatalogRow | CatalogSummaryRow;

interface CatalogSection extends ICatalogSection {}
class CatalogSection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: ICatalogSection) {
    const { salesRows, summaryRow } = args;

    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): CatalogSectionRow[] => {
    const { salesRows: salesRows, summaryRow: summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JCataogSection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => CatalogRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: CatalogSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: CatalogSectionRow) {
    return row.key;
  }
}

export { CatalogSection };
