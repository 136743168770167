import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { baseManager } from '../../managers/Manager';
import type { AlertInstance } from '../../domain/Alert/Alert';
import { AlertModal } from '../AlertModal';

const { alertManager } = baseManager;

@observer
class AlertList extends Component {
  private renderAlert = (alert: AlertInstance) => {
    return <AlertModal alert={alert} key={alert.reference} />;
  };

  render() {
    const { alerts } = alertManager;
    return <>{Object.values(alerts).map(this.renderAlert)}</>;
  }
}

export { AlertList };
