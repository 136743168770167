import { ToJSON } from '../../domain/Decorators';

@ToJSON
class SubscriptionDTO {
  _name;

  _telephoneNumbers;

  _emailAddresses;

  _categories;

  constructor(args) {
    const { name, telephoneNumbers, emailAddresses, categories } = args;

    this._name = name;
    this._telephoneNumbers = telephoneNumbers;
    this._emailAddresses = emailAddresses;
    this._categories = categories;
  }

  static fromMutableSubscription(subscription) {
    const { name, telephoneNumbers, emailAddresses, categories } = subscription;
    return new this({
      name,
      telephoneNumbers: Array.from(telephoneNumbers),
      emailAddresses: Array.from(emailAddresses),
      categories: Array.from(categories)
    });
  }
}

export { SubscriptionDTO };
