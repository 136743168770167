import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.ROW;

export interface ICatalogRow {
  catalogName: string;
  count: number;
  amountVatExcluded: string;
  amount: string;
}

interface CatalogRow extends ICatalogRow {}
class CatalogRow {
  readonly catalogName;
  readonly count;
  readonly amountVatExcluded;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: ICatalogRow) {
    const { catalogName, count, amountVatExcluded, amount } = args;

    this.catalogName = catalogName;
    this.count = count;
    this.amountVatExcluded = amountVatExcluded;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: ICatalogRow) {
    return new this(json);
  }
}

export { CatalogRow };
