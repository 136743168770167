import type { JPaymentGroupSummaryRow } from './PaymentGroupSummaryRow';
import { PaymentGroupSummaryRow } from './PaymentGroupSummaryRow';
import type { IPaymentGroupRow } from './PaymentGroupRow';
import { PaymentGroupRow } from './PaymentGroupRow';

interface IPaymentGroupSection {
  salesRows: PaymentGroupRow[];
  summaryRow: PaymentGroupSummaryRow;
}

export interface JPaymentGroupSection {
  salesRows: IPaymentGroupRow[];
  summaryRow: JPaymentGroupSummaryRow;
}

type PaymentGroupSectionRow = PaymentGroupRow | PaymentGroupSummaryRow;

interface PaymentGroupSection extends IPaymentGroupSection {}
class PaymentGroupSection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: IPaymentGroupSection) {
    const { salesRows, summaryRow } = args;
    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): PaymentGroupSectionRow[] => {
    const { salesRows: salesRows, summaryRow: summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JPaymentGroupSection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => PaymentGroupRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: PaymentGroupSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: PaymentGroupSectionRow) {
    return row.key;
  }
}

export { PaymentGroupSection };
