import { computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { FortnoxIntegration } from '../domain/Integration/FortnoxIntegration';
import { IntegrationType } from '../domain/Integration/IntegrationType';
import { Srv4PosIntegration } from '../domain/Integration/Srv4PosIntegration';
import { EnableFortnoxIntegrationDTO } from '../dto/Integration/EnableFortnoxIntegrationDTO';

const FORTNOX_OATH_STATE = 'FORTNOX_OATH_STATE';

class IntegrationManager {
  _coreClient;

  _sellerStore;

  _sellerManager;

  constructor(coreClient, sellerStore, sellerManager) {
    this._coreClient = coreClient;
    this._sellerStore = sellerStore;
    this._sellerManager = sellerManager;
  }

  createSrv4PosIntegration = async (terminalSerialNumber = null) => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.createSrv4PosIntegration(
      sellerReference,
      terminalSerialNumber
    );
  };

  fetchIntegrations = async () => {
    const { _sellerManager: sellerManager } = this;

    await Promise.all([
      this.fetchFortnoxIntegration(),
      sellerManager.getOrFetchSelectedSeller()
    ]);
  };

  fetchFortnoxIntegration = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    const { sellerDataStore } = sellerStore;

    const fortnoxJson = await coreClient.getFortnoxIntegration(sellerReference);
    sellerDataStore.integrations[IntegrationType.FORTNOX] = fortnoxJson
      ? FortnoxIntegration.fromJSON(fortnoxJson)
      : null;
  };

  startFortnoxAuthFlow = () => {
    const { _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const requestState = `${sellerReference}_${uuidv4()}`;
    localStorage.setItem(FORTNOX_OATH_STATE, requestState);

    const redirectUrl = encodeURIComponent(
      `${process.env.REACT_APP_PUBLIC_URL}/settings/integrations/fortnox/auth`
    );
    const scopes = encodeURIComponent('bookkeeping profile');
    const state = encodeURIComponent(requestState);
    const url = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${scopes}&state=${state}&access_type=offline&response_type=code`;

    window.location.href = url;
  };

  handleFortnoxAuthResponse = async (code, state) => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const requestState = localStorage.getItem(FORTNOX_OATH_STATE);
    localStorage.removeItem(FORTNOX_OATH_STATE);

    if (!code) {
      console.error(
        'Can not handle fortnox oauth response. Code is missing from response.'
      );
      return false;
    } else if (!state || requestState !== state) {
      console.error(
        'Can not handle fortnox oauth response. Returned state does not match the stored request state'
      );
      return false;
    } else if (!state.includes(sellerReference)) {
      console.error(
        'Can not handle fortnox oauth response. Returned state is not for the selected selller'
      );
      return false;
    }

    const enableFortnoxIntegrationDTO = new EnableFortnoxIntegrationDTO(code);
    const result = await coreClient.enableFortnoxIntegration(
      sellerReference,
      enableFortnoxIntegrationDTO
    );

    return result;
  };

  deleteFortnoxIntegration = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.deleteFortnoxIntegration(sellerReference);
  };

  @computed
  get srv4PosIntegration() {
    const { _sellerStore: sellerStore } = this;
    const { selectedSeller } = sellerStore;
    return selectedSeller?.controlUnits?.length > 0
      ? Srv4PosIntegration.fromJSON(selectedSeller.controlUnits)
      : null;
  }

  @computed
  get integrations() {
    const { _sellerStore: sellerStore, srv4PosIntegration } = this;
    const { sellerDataStore } = sellerStore;
    const { integrations } = sellerDataStore;
    return {
      [IntegrationType.SRV4POS]: srv4PosIntegration,
      ...integrations
    };
  }
}

export { IntegrationManager };
