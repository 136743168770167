import { ToJSON } from '../../domain/Decorators';

const PAGE_LIMIT = 100;

@ToJSON
class SearchOrderPreviewsDTO {
  _limit = PAGE_LIMIT;

  _offset;

  _statuses;

  _from;

  _to;

  _query;

  constructor(args) {
    const { offset, statuses, from, to, query } = args;
    this._offset = offset;
    this._statuses = statuses;
    this._from = from;
    this._to = to;
    this._query = query;
  }

  static fromSearchOrderPreviewFilter(searchOrderPreviewFilter, searchIndex) {
    const { ticketStatuses, from, to, query } = searchOrderPreviewFilter;

    return new this({
      offset: searchIndex || null,
      statuses: ticketStatuses?.toArray() ?? [],
      from: from?.clone().startOf('day').toISOString() || null,
      to: to?.clone().add(1, 'day').startOf('day').toISOString() || null,
      query: query || null
    });
  }
}

export { SearchOrderPreviewsDTO };
