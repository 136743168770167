import { action, computed, observable } from 'mobx';
import { stringNotEmpty } from '../../helpers/Functions';
import { Getter, Setter, ToJSON } from '../Decorators';
import { ObservableSet } from '../GeneralSet';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class MutableBillingDetails {
  @observable
  _legalName;

  @observable
  _email;

  @observable
  _phone;

  @observable
  _address;

  @observable
  _city;

  @observable
  _zipCode;

  @observable
  _country;

  @observable
  _reportEmailAddresses;

  @observable
  _sendSieAsEmail;

  @observable
  _fiscalYearStart;

  constructor(args = {}) {
    const {
      legalName = '',
      email = '',
      phone = '',
      address = '',
      city = '',
      zipCode = '',
      country = '',
      reportEmailAddresses = new ObservableSet(),
      sendSieAsEmail = '',
      fiscalYearStart = '--01-01'
    } = args;

    this._legalName = legalName;
    this._email = email;
    this._phone = phone;
    this._address = address;
    this._city = city;
    this._zipCode = zipCode;
    this._country = country;
    this._reportEmailAddresses = reportEmailAddresses;
    this._sendSieAsEmail = sendSieAsEmail;
    this._fiscalYearStart = fiscalYearStart;
  }

  static fromBillingDetails(billingDetails) {
    const {
      legalName,
      email,
      phone,
      address,
      city,
      zipCode,
      country,
      reportEmailAddresses,
      sendSieAsEmail,
      fiscalYearStart
    } = billingDetails;

    return new this({
      legalName,
      email,
      phone,
      address,
      city,
      zipCode,
      country,
      reportEmailAddresses: new ObservableSet(reportEmailAddresses),
      sendSieAsEmail,
      fiscalYearStart
    });
  }

  @action
  setBookkeepingFields = args => {
    const { reportEmailAddresses, sendSieAsEmail, fiscalYearStart } = args;

    this._reportEmailAddresses = reportEmailAddresses;
    this._sendSieAsEmail = sendSieAsEmail;
    this._fiscalYearStart = fiscalYearStart;
  };

  @computed
  get isValid() {
    return (
      stringNotEmpty(this._legalName) &&
      stringNotEmpty(this._email) &&
      stringNotEmpty(this._phone) &&
      stringNotEmpty(this._address) &&
      stringNotEmpty(this._city) &&
      stringNotEmpty(this._zipCode) &&
      stringNotEmpty(this._country)
    );
  }
}

export { MutableBillingDetails };
