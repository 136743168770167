import type { IMonetaryAmount } from '../../Amount/MonetaryAmount';

const TYPE = 'amount' as const;

export interface IAmountDiscountModel {
  amount: IMonetaryAmount;
  type: typeof TYPE;
}

class AmountDiscountModel {
  readonly amount;
  readonly type = TYPE;

  constructor(args: IAmountDiscountModel) {
    const { amount } = args;
    this.amount = amount;
  }

  static fromJSON(json: IAmountDiscountModel) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { amount, type } = this;

    return {
      amount,
      type
    };
  };
}

export { AmountDiscountModel };
