import { Getter } from '../Decorators';

@Getter
class RatingOverview {
  _ratingMean;

  _ratingCount;

  constructor(args) {
    const { ratingMean, ratingCount } = args;

    this._ratingMean = ratingMean;
    this._ratingCount = ratingCount;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { RatingOverview };
