import { computed } from 'mobx';
import { AdyenMethod } from '../domain/PaymentMethod/AdyenMethod';
import { InitiateAdyenOnboardingDTO } from '../dto/Adyen/InitiateAdyenOnboardingDTO';
import { Tier } from '../domain/Tier/Tier';
import { IppTier } from '../domain/Tier/IppTier';
import { UpdateAdyenPaymentMethodDTO } from '../dto/Adyen/UpdateAdyenPaymentMethodDTO';
import { CreatePACPaymentMethodDTO } from '../dto/PAC/CreatePACPaymentMethodDTO';
import { CreateSwishPaymentMethodDTO } from '../dto/Swish/CreateSwishPaymentMethodDTO';
import { UpdateSwishPaymentMethodDTO } from '../dto/Swish/UpdateSwishPaymentMethodDTO';
import { UpdateAdyenPayoutScheduleDTO } from '../dto/Adyen/UpdateAdyenPayoutScheduleDTO';
import type { PaymentMethodStore } from '../stores/PaymentMethodStore';
import type { SellerStore } from '../stores/SellerStore';
import type { WeiqCoreClient } from '../config/clients/WeiqCoreClient';
import { assertNotUndefined } from '../helpers/Functions';
import type { MutableOnboardingDetails } from '../domain/Adyen/MutableOnboardingDetails';
import { OnboardFromAdyenAccountDTO } from '../dto/Adyen/OnboardFromAdyenAccountDTO';
import { AdyenAccountDTO } from '../dto/Adyen/AdyenAccountDTO';
import type { IAdyenPaymentMethodDTO } from '../dto/PaymentMethod/PaymentMethodDTO';
import {
  AdyenPaymentMethodDTO,
  PaymentMethodDTO
} from '../dto/PaymentMethod/PaymentMethodDTO';
import {
  PaymentMethodType,
  type PaymentMethod
} from '../domain/PaymentMethod/PaymentMethod';
import { IppPaymentMethodsDTO } from '../dto/Adyen/IppPaymentMethodDTO';
import type { MutablePayoutSchedule } from '../domain/Adyen/MutablePayoutSchedule';
import { UpdateIppPaymentMethodDTO } from '../dto/Adyen/UpdateIppPaymentMethodDTO';
import type { IppPaymentMethod } from '../domain/Adyen/IppPaymentMethod';
import type GeneralSet from '../domain/GeneralSet';
import type { MutableAdyenMethod } from '../domain/Adyen/MutableAdyenMethod';
import { UpdateAdyenAccountDTO } from '../dto/Adyen/UpdateAdyenAccountDTO';

class PaymentMethodManager {
  private readonly coreClient: WeiqCoreClient;
  private readonly sellerStore: SellerStore;
  private readonly paymentMethodStore: PaymentMethodStore;

  constructor(
    coreClient: WeiqCoreClient,
    sellerStore: SellerStore,
    paymentMethodStore: PaymentMethodStore
  ) {
    this.coreClient = coreClient;
    this.sellerStore = sellerStore;
    this.paymentMethodStore = paymentMethodStore;
  }

  fetchActivePaymentMethods = async () => {
    const { coreClient, sellerStore, paymentMethodStore } = this;
    const { selectedSellerReference } = sellerStore;

    if (selectedSellerReference) {
      const [jsonPaymentMethods, jsonAdyenAccount] = await Promise.all([
        coreClient.getActivePaymentMethods(selectedSellerReference),
        coreClient.getAdyenAccount(selectedSellerReference)
      ]);

      if (!Array.isArray(jsonPaymentMethods)) {
        return false;
      }

      const activePaymentMethods = jsonPaymentMethods.reduce(
        (
          accumulator: Map<PaymentMethodType, PaymentMethod>,
          jsonPaymentMethod: PaymentMethodDTO
        ) => {
          const method = PaymentMethodDTO.toPaymenMethod(
            jsonPaymentMethod,
            jsonAdyenAccount
          );
          if (method) {
            accumulator.set(method.type, method);
          }

          return accumulator;
        },
        new Map()
      );

      if (
        !activePaymentMethods.has(PaymentMethodType.ADYEN) &&
        jsonAdyenAccount
      ) {
        const adyenMethod =
          AdyenPaymentMethodDTO.toAdyenMethod(jsonAdyenAccount);
        if (adyenMethod) {
          activePaymentMethods.set(PaymentMethodType.ADYEN, adyenMethod);
        }
      }

      paymentMethodStore.setActivePaymentMethods(activePaymentMethods);
      return true;
    }
    return false;
  };

  getAdyenPaymentMethod = async (sellerReference: string) => {
    const { coreClient } = this;
    const [jsonPaymentMethods, jsonAdyenAccount] = await Promise.all([
      coreClient.getActivePaymentMethods(sellerReference),
      coreClient.getAdyenAccount(sellerReference)
    ]);

    if (jsonAdyenAccount) {
      const jsonAdyenPaymentMethod = jsonPaymentMethods?.find(
        method => method.type === PaymentMethodType.ADYEN
      ) as IAdyenPaymentMethodDTO | undefined;

      return AdyenPaymentMethodDTO.toAdyenMethod(
        jsonAdyenAccount,
        jsonAdyenPaymentMethod
      );
    }

    return null;
  };

  deletePaymentMethod = async (type: string) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    return coreClient.deletePaymentMethod(selectedSellerReference, type);
  };

  initiateOnboardingAdyen = async (
    onboardingDetails: MutableOnboardingDetails
  ) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const initiateAdyenOnboardingDTO =
      InitiateAdyenOnboardingDTO.fromOnboardingDetails(onboardingDetails);

    return coreClient.initiateAdyenOnboarding(
      selectedSellerReference,
      initiateAdyenOnboardingDTO
    );
  };

  onboardFromAdyenAccount = async (accountSellerReference: string) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const onboardFromAdyenAccountDTO =
      OnboardFromAdyenAccountDTO.fromAccountSellerReference(
        accountSellerReference
      );
    return coreClient.onboardFromAdyenAccount(
      selectedSellerReference,
      onboardFromAdyenAccountDTO
    );
  };

  getAdyenAccount = async (sellerReference: string) => {
    const { coreClient } = this;

    const jsonAdyenAccount = await coreClient.getAdyenAccount(sellerReference);

    if (jsonAdyenAccount) {
      return AdyenAccountDTO.toAdyenAccount(jsonAdyenAccount);
    }
  };

  updateAdyenAccount = async (adyenMethod: MutableAdyenMethod) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const updateAdyenAccountDTO =
      UpdateAdyenAccountDTO.fromAdyenMethod(adyenMethod);

    return coreClient.updateAdyenAccount(
      selectedSellerReference,
      updateAdyenAccountDTO
    );
  };

  triggerPayoutSchedule = async (scheduleId: string) => {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    return await coreClient.triggerPayoutSchedule(
      selectedSellerReference,
      scheduleId
    );
  };

  updatePayoutSchedules = async (schedules: MutablePayoutSchedule[]) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const results = await Promise.all(
      Object.values(schedules).map(schedule => {
        const { scheduleId } = schedule;

        const dto = UpdateAdyenPayoutScheduleDTO.fromPayoutSchedule(schedule);
        return coreClient.updatePayoutSchedule(
          selectedSellerReference,
          scheduleId,
          dto
        );
      })
    );

    return results.every(Boolean);
  };

  updateAdyenPaymentMethod = async (adyenMethod: MutableAdyenMethod) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const updateAdyenPaymentMethodDTO =
      UpdateAdyenPaymentMethodDTO.fromAdyenMethod(adyenMethod);

    return coreClient.updateAdyenPaymentMethodData(
      selectedSellerReference,
      updateAdyenPaymentMethodDTO
    );
  };

  addPacPaymentMethod = async () => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const createPACPaymentMethodDTO =
      CreatePACPaymentMethodDTO.fromSellerReference(selectedSellerReference);

    return coreClient.addPACPaymentMethod(createPACPaymentMethodDTO);
  };

  addSwishPaymentMethod = async (
    payeeAlias: string,
    paymentMethodPrefix: string
  ) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const createSwishPaymentMethodDTO =
      CreateSwishPaymentMethodDTO.fromPayeeAlias({
        sellerReference: selectedSellerReference,
        payeeAlias,
        paymentMethodPrefix
      });

    return coreClient.addSwishPaymentMethod(createSwishPaymentMethodDTO);
  };

  updateSwishPaymentMethod = async (paymentReferencePrefix: string) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    const updateSwishPaymentMethodDTO =
      UpdateSwishPaymentMethodDTO.fromPaymentReferencePrefix(
        paymentReferencePrefix
      );

    return coreClient.updateSwishPaymentMethod(
      selectedSellerReference,
      updateSwishPaymentMethodDTO
    );
  };

  fetchSwishCSR = async () => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    return coreClient.getCertificateForSwish(selectedSellerReference);
  };

  submitSwishPEM = async (swishPEM: string) => {
    const { coreClient } = this;
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    return coreClient.uploadSwishPEMCertificate(
      selectedSellerReference,
      swishPEM
    );
  };

  @computed
  get onboardingKycURL() {
    const { selectedSellerReference } = this.sellerStore;
    assertNotUndefined(selectedSellerReference);

    return `${process.env.REACT_APP_PUBLIC_URL}/adyen-kyc?sellerReference=${selectedSellerReference}`;
  }

  async getAdyenTiers() {
    const { coreClient } = this;
    const jsonTiers = await coreClient.getAdyenTiers();

    if (jsonTiers) {
      const tiers = Object.entries(jsonTiers).reduce(
        (accumulator: Record<string, Tier>, [tierName, jsonTier]) => {
          accumulator[tierName] = Tier.fromJSON(jsonTier);
          return accumulator;
        },
        {}
      );
      return tiers;
    }
  }

  async getAdyenIppTiers() {
    const { coreClient } = this;
    const jsonTiers = await coreClient.getAdyenIppTiers();
    if (jsonTiers) {
      const tiers = Object.entries(jsonTiers).reduce(
        (accumulator: Record<string, IppTier>, [tierName, jsonTier]) => {
          accumulator[tierName] = IppTier.fromJSON(jsonTier);
          return accumulator;
        },
        {}
      );

      return tiers;
    }
  }

  async getIppPaymentMethods(storeReference: string) {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const jsonMethods = await coreClient.getAdyenIppPaymentMethods(
      selectedSellerReference,
      storeReference
    );

    if (jsonMethods) {
      return IppPaymentMethodsDTO.toIppPaymentMethods(jsonMethods);
    }
  }

  async updateIppPaymentMethods(
    storeReference: string,
    ippMethods: GeneralSet<IppPaymentMethod, IppPaymentMethod>
  ) {
    const { coreClient, sellerStore } = this;
    const { selectedSellerReference } = sellerStore;
    assertNotUndefined(selectedSellerReference);

    const updateIppPaymentMethodDTOs = Array.from(ippMethods).map(method =>
      UpdateIppPaymentMethodDTO.fromIppPaymentMethod(method)
    );
    return coreClient.updateIppPaymentMethods(
      selectedSellerReference,
      storeReference,
      updateIppPaymentMethodDTOs
    );
  }

  @computed
  get activePaymentMethods() {
    const { activePaymentMethods } = this.paymentMethodStore;

    return activePaymentMethods;
  }

  @computed
  get activeAdyenPaymentMethod() {
    const { activePaymentMethods } = this.paymentMethodStore;

    const adyenPaymentMethod = activePaymentMethods?.get(
      PaymentMethodType.ADYEN
    );

    if (adyenPaymentMethod instanceof AdyenMethod) {
      return adyenPaymentMethod;
    }

    return null;
  }

  fetchAdyenOnboardingUrl = async (sellerReference: string) => {
    const { coreClient } = this;
    const redirectUrlDTO =
      await coreClient.getAdyenOnboardingUrl(sellerReference);

    return redirectUrlDTO?.redirectUrl;
  };
}

export { PaymentMethodManager };
