import { observable, computed } from 'mobx';
import { Getter, Setter, ToJSON } from '../Decorators';
import { stringIsEmpty } from '../../helpers/Functions';
import EditOpeningHourMap from './OpeningHour/EditOpeningHourMap';
import { Changeable } from '../Changeable';

const DEFAULT_TYPE = 'products';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class EditCatalog {
  @observable
  _name;

  @observable
  _description;

  @observable
  _type;

  @observable
  _openingHours;

  @observable
  _prettyNames;

  @observable
  _visible;

  @observable
  _thumbnailIcon;

  @observable
  _tipEnabled;

  @observable
  _variablePricesEnabled;

  constructor(args = {}) {
    const {
      name = '',
      description = '',
      type = DEFAULT_TYPE,
      openingHours = new EditOpeningHourMap(),
      prettyNames = [],
      visible = true,
      thumbnailIcon = null,
      tipEnabled = new Changeable(false),
      variablePricesEnabled = false
    } = args;

    this._name = name;
    this._description = description;
    this._type = type;
    this._openingHours = openingHours;
    this._prettyNames = prettyNames;
    this._visible = visible;
    this._thumbnailIcon = thumbnailIcon;
    this._tipEnabled = tipEnabled;
    this._variablePricesEnabled = variablePricesEnabled;
  }

  static fromJSON(json) {
    const {
      openingHours: jsonOpeningHours,
      tipEnabled: jsonTipEnabled,
      variablePricesEnabled: jsonVariablePricesEnabled,
      ...rest
    } = json;
    const openingHours = EditOpeningHourMap.fromJSON(jsonOpeningHours);
    const tipEnabled = new Changeable(jsonTipEnabled);
    const variablePricesEnabled = jsonVariablePricesEnabled;

    return new this({
      openingHours,
      tipEnabled,
      variablePricesEnabled,
      ...rest
    });
  }

  @computed
  get isValid() {
    const {
      _name: name,
      _description: description,
      _openingHours: openingHours
    } = this;

    return (
      !stringIsEmpty(name) &&
      !stringIsEmpty(description) &&
      openingHours.isValid
    );
  }
}

export { EditCatalog as default };
