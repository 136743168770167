import axios from 'axios';
import { assertTrue } from '../../helpers/Functions';

export function getAxiosError(error: unknown, jsonKey?: string) {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }

  const body = error.request?.response ?? null;
  if (typeof body !== 'string') {
    return undefined;
  }

  if (jsonKey) {
    // Attempt to deserialize JSON
    try {
      const obj = JSON.parse(body);
      assertTrue(obj && typeof obj === 'object');

      const fieldValue = obj[jsonKey];
      if (typeof fieldValue === 'object' && fieldValue !== null) {
        return JSON.stringify(fieldValue);
      } else {
        return String(fieldValue);
      }
    } catch (ex) {
      // Failed to deserialize. Data is not a valid JSON object
      return undefined;
    }
  } else {
    return body;
  }
}
