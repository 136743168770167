import { computed, observable } from 'mobx';
import { stringNotEmpty } from '../../helpers/Functions';
import { Getter, Setter, ToJSON } from '../Decorators';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class MutableMetadata {
  @observable
  _address;

  @observable
  _city;

  @observable
  _zipCode;

  @observable
  _country;

  @observable
  _banners;

  constructor(args = {}) {
    const {
      address = '',
      city = '',
      zipCode = '',
      country = '',
      banners = []
    } = args;
    this._address = address;
    this._city = city;
    this._zipCode = zipCode;
    this._country = country;
    this._banners = banners;
  }

  @computed
  get isValid() {
    return stringNotEmpty(this._address);
  }

  static fromMetadata(metadata) {
    const { banners = [], address, city, zipCode } = metadata;
    return new this({
      banners: banners.map(banner => MutableBanner.fromBanner(banner)),
      address,
      city,
      zipCode
    });
  }
}

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class MutableBanner {
  @observable
  _type;

  @observable
  _url;

  @observable
  _urlToLink;

  constructor(args = {}) {
    const { type, url, urlToLink } = args;
    this._type = type;
    this._url = url;
    this._urlToLink = urlToLink;
  }

  static fromBanner(banner) {
    const { type, url, urlToLink } = banner;
    return new this({ type, url, urlToLink });
  }
}

export { MutableMetadata };
