import { computed } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { TIP } from '../constants';
import { AccountTag } from '../domain/Bookkeeping/AccountTag';
import { Bookkeeping } from '../domain/Bookkeeping/Bookkeeping';
import { BookkeepingAccount } from '../domain/Bookkeeping/BookkeepingAccount';
import { BookkeepingExport } from '../domain/Bookkeeping/BookkeepingExport';
import { BookkeepingPaymentMethodMapping } from '../domain/Bookkeeping/BookkeepingPaymentMethodMapping';
import { BookkeepingProductMapping } from '../domain/Bookkeeping/BookkeepingProductMapping';
import { MutableSeller } from '../domain/Seller/MutableSeller';
import { CreateBookkeepingAccountDTO } from '../dto/Bookkeeping/CreateBookkeepingAccountDTO';
import { CreateBookkeepingExportDTO } from '../dto/Bookkeeping/CreateBookkeepingExportDTO';
import { CreateBookkeepingPaymentMethodMappingDTO } from '../dto/Bookkeeping/CreateBookkeepingPaymentMethodMappingDTO';
import { CreateBookkeepingProductMappingDTO } from '../dto/Bookkeeping/CreateBookkeepingProductMappingDTO';
import { UpdateBookkeepingSettingsDTO } from '../dto/Bookkeeping/UpdateBookkeepingSettingsDTO';
import { SellerDTO } from '../dto/Seller/SellerDTO';

class BookkeepingManager {
  _coreClient;

  _sellerStore;

  constructor(coreClient, sellerStore) {
    this._coreClient = coreClient;
    this._sellerStore = sellerStore;
  }

  fetchBookkeeping = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;

    const jsonBookkeeping = await coreClient.getBookkeeping(sellerReference);
    if (jsonBookkeeping) {
      sellerDataStore.setBookkeeping(Bookkeeping.fromJSON(jsonBookkeeping));
      return true;
    } else {
      return false;
    }
  };

  fetchBookkeepingExports = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;

    const bookkeepingExports = (
      await coreClient.getBookkeepingExports(sellerReference)
    )?.map(jsonExport => BookkeepingExport.fromJSON(jsonExport));

    sellerDataStore.setBookkeepingExports(bookkeepingExports);
  };

  fetchBookkeepingAccounts = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;

    const bookkeepingAccounts = (
      await coreClient.getBookkeepingAccounts(sellerReference)
    )?.reduce((accumulator, jsonAccount) => {
      const { number } = jsonAccount;
      accumulator[number] = BookkeepingAccount.fromJSON(jsonAccount);
      return accumulator;
    }, {});

    sellerDataStore.setBookkeepingAccounts(bookkeepingAccounts);
  };

  fetchBookkeepingProductMappings = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;

    const bookkeepingProductMappings = (
      await coreClient.getBookkeepingProductMappings(sellerReference)
    )?.reduce((accumulator, jsonMapping) => {
      const { lineItemReference } = jsonMapping;

      accumulator[lineItemReference] =
        BookkeepingProductMapping.fromJSON(jsonMapping);
      return accumulator;
    }, {});

    sellerDataStore.setBookkeepingProductMappings(bookkeepingProductMappings);
  };

  fetchBookkeepingPaymentMethodMappings = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;

    const bookkeepingPaymentMethodMappings = (
      await coreClient.getBookkeepingPaymentMethodMappings(sellerReference)
    )?.map(jsonMapping =>
      BookkeepingPaymentMethodMapping.fromJSON(jsonMapping)
    );

    sellerDataStore.setBookkeepingPaymentMethodMappings(
      bookkeepingPaymentMethodMappings
    );
  };

  @computed
  get bookkeeping() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeeping } = sellerDataStore;

    return bookkeeping;
  }

  @computed
  get bookkeepingExports() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingExports } = sellerDataStore;

    return bookkeepingExports;
  }

  @computed
  get bookkeepingAccounts() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingAccounts } = sellerDataStore;

    return bookkeepingAccounts;
  }

  @computed
  get bookkeepingDiscountAcount() {
    const { bookkeepingAccounts } = this;

    return Object.values(bookkeepingAccounts)?.find(
      account => account.accountTag === AccountTag.DISCOUNT_ACCOUNT
    );
  }

  @computed
  get bookkeepingProductMappings() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingProductMappings } = sellerDataStore;

    return Object.values(bookkeepingProductMappings)
      ?.filter(mapping => mapping.type !== TIP)
      .sort(sortBySalesAccountNumber);
  }

  getBookkeepingProductMapping = createTransformer(lineItemReference => {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingProductMappings } = sellerDataStore;

    return bookkeepingProductMappings[lineItemReference];
  });

  @computed
  get bookkeepingTipMapping() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingProductMappings } = sellerDataStore;

    return Object.values(bookkeepingProductMappings)?.find(
      mapping => mapping.type === TIP
    );
  }

  @computed
  get bookkeepingPaymentMethodMappings() {
    const { sellerDataStore } = this._sellerStore;
    const { bookkeepingPaymentMethodMappings } = sellerDataStore;

    return bookkeepingPaymentMethodMappings;
  }

  enableBookkeeping = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.enableBookkeeping(sellerReference);
  };

  downloadBookkeepingExport = async serial => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.downloadBookkeepingExport(sellerReference, serial);
  };

  createBookkeepingExport = async createBookkeepingExport => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.createBookkeepingExport(
      sellerReference,
      CreateBookkeepingExportDTO.fromCreateBookkeepingExport(
        createBookkeepingExport
      )
    );
  };

  saveBookkeepingProductMapping = async editBookkeepingProductMapping => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.createBookkeepingProductMapping(
      sellerReference,
      CreateBookkeepingProductMappingDTO.fromEditBookkeepingProductMapping(
        editBookkeepingProductMapping
      )
    );
  };

  saveBookkeepingPaymentMethodMapping =
    async editBookkeepingPaymentMethodMapping => {
      const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
      const { sellerReference } = sellerStore;

      return coreClient.createBookkeepingPaymentMethodMapping(
        sellerReference,
        CreateBookkeepingPaymentMethodMappingDTO.fromEditBookkeepingPaymentMethodMapping(
          editBookkeepingPaymentMethodMapping
        )
      );
    };

  saveBookkeepingAccount = async editBookkeepingAccount => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return coreClient.createBookkeepingAccount(
      sellerReference,
      CreateBookkeepingAccountDTO.fromEditBookkeepingAccount(
        editBookkeepingAccount
      )
    );
  };

  saveBookkeepingSettings = async editBookkeepingSettings => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference, selectedSeller } = sellerStore;

    const mutableSeller = MutableSeller.fromSeller(selectedSeller);
    mutableSeller.billingDetails.setBookkeepingFields(editBookkeepingSettings);
    mutableSeller.automaticDailyReports =
      editBookkeepingSettings.automaticDailyReports;
    mutableSeller.automaticDailyReportsHourOfDay =
      editBookkeepingSettings.automaticDailyReportsHourOfDay;

    const sellerDTO = SellerDTO.fromMutableSeller(mutableSeller);
    const updateBookkeepingSettingsDTO =
      UpdateBookkeepingSettingsDTO.fromEditBookkeepingSettings(
        editBookkeepingSettings
      );

    const results = await Promise.all([
      coreClient.updateSeller(sellerReference, sellerDTO),
      coreClient.updateBookkeepingSettings(
        sellerReference,
        updateBookkeepingSettingsDTO
      )
    ]);

    return results.every(Boolean);
  };
}

function sortBySalesAccountNumber(mappingA, mappingB) {
  const accountA = mappingA.salesAccount?.number ?? 0;
  const accountB = mappingB.salesAccount?.number ?? 0;

  return accountA - accountB;
}

export { BookkeepingManager };
