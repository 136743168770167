import type { ReferenceHolder } from '../domain/Common/ReferenceHolder';
import type { ValueOf } from '../domain/Common/Types';
import type { GeneralSet } from '../domain/GeneralSet';

interface NameHolder {
  name: string;
}

interface SortWeighted {
  sortWeight: number;
}

interface CreatedAtHolder {
  createdAt: string;
}

interface ResolvedAtHolder {
  resolvedAt: string;
}

export function referenceKeyExtractor(referenceHolder: ReferenceHolder) {
  return referenceHolder.reference;
}

export function sleep(ms: number) {
  return new Promise<void>((resolve: () => void) => {
    setTimeout(resolve, ms);
  });
}

export function partitionArray<T>(array: Array<T>, size: number) {
  const partitions = [];

  for (let i = 0; i < array.length; i += size) {
    partitions.push(array.slice(i, i + size));
  }
  return partitions;
}

export function setsAreEqual<K, V>(
  setA: GeneralSet<K, V>,
  setB: GeneralSet<K, V>
) {
  if (setA.size !== setB.size) {
    return false;
  }

  for (const value of setA) {
    if (!setB.has(value)) {
      return false;
    }
  }

  return true;
}

export function ArraysAreEqual(arrayA: any[], arrayB: any[]) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }
  const sortedArrayB = arrayB.slice().sort();

  return arrayA
    .slice()
    .sort()
    .every((value, index) => value === sortedArrayB[index]);
}

export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

export function stringIsEmpty(
  value: string | null | undefined
): value is null | undefined | '' {
  return value === null || value === undefined || value === '';
}

export function stringNotEmpty(
  value: string | null | undefined
): value is string {
  return !stringIsEmpty(value);
}

export class AssertionError extends Error {
  constructor(message?: string) {
    super(`Assertion failed: ${message}`);
  }
}

type ErrorConstructorType = {
  new (message?: string): Error;
  readonly prototype: Error;
};

export function assertNotNull<T>(
  value: T | null,
  message?: string,
  ErrorConstructor: ErrorConstructorType = AssertionError
): asserts value is T {
  if (value === null) {
    throw new ErrorConstructor(message ?? 'Value can not be null');
  }
}

export function assertNotUndefined<T>(
  value: T | undefined,
  message?: string,
  ErrorConstructor: ErrorConstructorType = AssertionError
): asserts value is T {
  if (value === undefined) {
    throw new ErrorConstructor(message ?? 'Value can not be undefined');
  }
}

export function isNullUndefined<T>(
  value: T | null | undefined
): value is null | undefined {
  return value === null || value === undefined;
}

export function notNullUndefined<T>(value: T | undefined | null): value is T {
  return !isNullUndefined(value);
}

export function assertNotNullUndefined<T>(
  value: T | undefined | null,
  message?: string
): asserts value is T {
  if (value === null || value === undefined) {
    throw new AssertionError(message ?? 'Value can not be null or undefined');
  }
}

export function assertTrue(
  value: unknown,
  message?: string
): asserts value is true {
  if (value !== true) {
    throw new AssertionError(message ?? 'Value is not true');
  }
}

export function assertFalse(
  value: unknown,
  message?: string
): asserts value is false {
  if (value !== false) {
    throw new AssertionError(message ?? 'Value is not false');
  }
}

export function assertStringNotEmpty(
  value: unknown,
  message?: string
): asserts value is string {
  if (typeof value !== 'string') {
    throw new AssertionError(message ?? `Value is not a string`);
  } else if (value === '') {
    throw new AssertionError(message ?? `Value is empty`);
  }
}

export function sortByName(firstItem: NameHolder, secondItem: NameHolder) {
  return firstItem.name.localeCompare(secondItem.name);
}

export function sortBySortWeight(
  firstItem: SortWeighted,
  secondItem: SortWeighted
) {
  return secondItem.sortWeight - firstItem.sortWeight;
}

export function sortByCreatedAt(
  firstItem: CreatedAtHolder,
  secondItem: CreatedAtHolder
) {
  return (
    new Date(secondItem.createdAt).getTime() -
    new Date(firstItem.createdAt).getTime()
  );
}

export function sortByCreatedAtAsc(
  firstItem: CreatedAtHolder,
  secondItem: CreatedAtHolder
) {
  return (
    new Date(firstItem.createdAt).getTime() -
    new Date(secondItem.createdAt).getTime()
  );
}

export function sortByResolvedAt(
  firstItem: ResolvedAtHolder,
  secondItem: ResolvedAtHolder
) {
  return (
    new Date(secondItem.resolvedAt).getTime() -
    new Date(firstItem.resolvedAt).getTime()
  );
}

export function truncateString(string: string, maxLength: number) {
  return string.length > maxLength
    ? `${string.substr(0, maxLength - 1)}...`
    : string;
}

export function validNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function validPositiveNumber(value: unknown) {
  return validNumber(value) && value > 0;
}

export function validNonNegativeNumber(value: unknown) {
  return validNumber(value) && value >= 0;
}

export function validNegativeNumber(value: unknown) {
  return validNumber(value) && value < 0;
}

export function accumulated<A, T>(
  handler: (accumulator: A, item: T, index?: number, array?: T[]) => void
) {
  return (accumulator: A, item: T, index: number, array: T[]) => {
    handler(accumulator, item, index, array);
    return accumulator;
  };
}

export function identity<T>(value: T) {
  return value;
}

export function nullFunction() {
  return null;
}

export function isKeyof<T extends Object>(
  object: T,
  value: keyof T | string
): value is keyof T {
  return value in object;
}

export function arrayToRecord<T extends ReferenceHolder>(
  array: T[]
): Record<string, T> {
  return array.reduce(
    (accumulator, item) => {
      accumulator[item.reference] = item;
      return accumulator;
    },
    {} as Record<string, T>
  );
}

export function extractArrayToRecord<T>(
  array: T[],
  keyExtractor: (item: T) => string
): Record<string, T> {
  return array.reduce(
    (accumulator, item) => {
      const key = keyExtractor(item);
      accumulator[key] = item;
      return accumulator;
    },
    {} as Record<string, T>
  );
}

export function mapArrayToRecord<T extends ReferenceHolder, R>(
  array: T[],
  mapper: (item: T) => R
): Record<string, R> {
  return array.reduce(
    (accumulator, item) => {
      accumulator[item.reference] = mapper(item);
      return accumulator;
    },
    {} as Record<string, R>
  );
}

export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`;
export const objectKeys = Object.keys as <Type extends object>(
  value: Type
) => Array<ObjectKeys<Type>>;

// Same as `Object.entries()` but with type inference
type Entries<T> = [keyof T, ValueOf<T>][];
export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}
