import { action, observable, computed } from 'mobx';
import { SellerDataStore } from './SellerDataStore';
import type { ManagedSeller } from '../domain/ManagedSeller';
import type { Seller } from '../domain/Seller/Seller';

const SELECTED_SELLER_REFERENCE_KEY = 'selectedSellerReference';

class SellerStore {
  @observable
  private _managedSellers: Record<string, ManagedSeller> = {};
  @observable
  private _selectedSellerReference?: string;
  @observable
  private _selectedSeller?: Seller;
  @observable
  private _sellerDataStore = new SellerDataStore();

  @computed
  get managedSellers() {
    return this._managedSellers;
  }

  @computed
  get selectedSellerReference() {
    return this._selectedSellerReference;
  }

  @computed
  get selectedSeller() {
    return this._selectedSeller;
  }

  @computed
  get sellerDataStore() {
    return this._sellerDataStore;
  }

  @computed
  get productRegisterReference() {
    const { _sellerDataStore: sellerDataStore } = this;
    return sellerDataStore.productRegister?.reference;
  }

  @action
  setManagedSellers = (managedSellers: Record<string, ManagedSeller>) => {
    if (!managedSellers) {
      return;
    }

    this._managedSellers = managedSellers;
  };

  @action
  setSelectedSeller = (selectedSeller: Seller) => {
    this._selectedSeller = selectedSeller;
  };

  @action
  selectSeller = (sellerReference: string) => {
    this._selectedSellerReference = sellerReference;
    this._sellerDataStore = new SellerDataStore();

    localStorage.setItem(SELECTED_SELLER_REFERENCE_KEY, sellerReference);
  };

  // alias
  @computed
  get sellerReference() {
    return this._selectedSellerReference;
  }
}

export { SellerStore };
