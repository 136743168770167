import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateBookkeepingAccountDTO {
  _number;

  _name;

  _accountTag;

  _costCenter;

  constructor(args) {
    const { number, name, accountTag, costCenter } = args;

    this._number = number;
    this._name = name;
    this._accountTag = accountTag;
    this._costCenter = costCenter;
  }

  static fromEditBookkeepingAccount(editBookkeepingAccount) {
    return new this(editBookkeepingAccount);
  }
}

export { CreateBookkeepingAccountDTO };
