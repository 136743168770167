import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateSwishPaymentMethodDTO {
  _sellerReference;

  _payeeAlias;

  _paymentMethodPrefix;

  constructor(args) {
    const { sellerReference, payeeAlias, paymentMethodPrefix = '' } = args;

    this._sellerReference = sellerReference;
    this._payeeAlias = payeeAlias;
    this._paymentMethodPrefix = paymentMethodPrefix;
  }

  static fromPayeeAlias({ sellerReference, payeeAlias, paymentMethodPrefix }) {
    return new this({ sellerReference, payeeAlias, paymentMethodPrefix });
  }
}

export { CreateSwishPaymentMethodDTO };
