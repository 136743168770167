import { Getter, ToJSON } from '../Decorators';

@Getter
@ToJSON
class CatalogModifierGroup {
  _reference;

  _name;

  constructor(args) {
    const { reference, name } = args;

    this._reference = reference;
    this._name = name;
  }
}

export { CatalogModifierGroup as default };
