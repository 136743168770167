import type { AxiosInstance } from 'axios';
import type { IWelcomeTaskListDTO } from '../../dto/WelcomeTaskList/WelcomeTaskListDTO';
import type { IUpdatedTaskListDTO } from '../../dto/WelcomeTaskList/UpdatedTaskListDTO';

const WASABI_URL =
  process.env.REACT_APP_WASABI_API_URL || 'https://wasabi.staging.weiq.meiq.se';
const V1_API = `${WASABI_URL}/api/v1`;

class WasabiClient {
  private readonly client;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getWelcomeTaskList(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/welcome/sellers/${sellerReference}/task_list`;
    try {
      const result = await client.get<IWelcomeTaskListDTO>(url);
      return result.data;
    } catch (exception) {
      console.error(
        `Failed to get welcome task list for seller with sellerReference ${sellerReference}`,
        exception
      );
    }
  }

  async updateWelcomeTaskList(
    sellerReference: string,
    updateWelcomeTaskListDTO: IUpdatedTaskListDTO
  ) {
    const { client } = this;

    const url = `${V1_API}/welcome/sellers/${sellerReference}/task_list`;
    try {
      await client.patch(url, updateWelcomeTaskListDTO);
      return true;
    } catch (exception) {
      console.error(
        `Failed to update welcome task list for seller with sellerReference ${sellerReference}`,
        exception
      );
    }
  }
}

export { WasabiClient };
