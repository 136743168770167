import { ClientResult } from './ClientResponse';
import { getAxiosError } from './AxiosErrorHelper';
import type { AxiosInstance } from 'axios';
import type { SellerDTO } from '../../dto/Seller/SellerDTO';
import type EnrollSellerDTO from '../../dto/Moms/EnrollSellerDTO';
import type { NewSellerUserDTO } from '../../dto/User/NewSellerUserDTO';
import type { UpdateUserDTO } from '../../dto/User/UpdateUserDTO';
import type { IInitiateAdyenOnboardingDTO } from '../../dto/Adyen/InitiateAdyenOnboardingDTO';
import type { IUpdateAdyenPaymentMethodDTO } from '../../dto/Adyen/UpdateAdyenPaymentMethodDTO';
import type { ITerminal } from '../../domain/Terminal/Terminal';
import type { ITerminalDetails } from '../../domain/Terminal/TerminalDetails';
import type { ICreateAdyenTerminalOrderDTO } from '../../dto/Terminal/CreateAdyenTerminalOrderDTO';
import type { ICreateAdyenTerminalShippingLocationDTO } from '../../dto/Terminal/CreateAdyenTerminalShippingLocationDTO';
import type { CreatePACPaymentMethodDTO } from '../../dto/PAC/CreatePACPaymentMethodDTO';
import type { IUpdateAdyenPayoutScheduleDTO } from '../../dto/Adyen/UpdateAdyenPayoutScheduleDTO';
import type { CreateSwishPaymentMethodDTO } from '../../dto/Swish/CreateSwishPaymentMethodDTO';
import type { UpdateSwishPaymentMethodDTO } from '../../dto/Swish/UpdateSwishPaymentMethodDTO';
import type { SearchOrderPreviewsDTO } from '../../dto/OrderPreview/SearchOrderPreviewsDTO';
import type { DiscountDTO } from '../../dto/Discount/DiscountDTO';
import type { CreateDiscountCodeDTO } from '../../dto/DiscountCodes/CreateDiscountCodeDTO';
import type { GenerateDiscountCodesDTO } from '../../dto/DiscountCodes/GenerateDiscountCodesDTO';
import type { CreateBookkeepingExportDTO } from '../../dto/Bookkeeping/CreateBookkeepingExportDTO';
import type { CreateBookkeepingProductMappingDTO } from '../../dto/Bookkeeping/CreateBookkeepingProductMappingDTO';
import type { CreateBookkeepingPaymentMethodMappingDTO } from '../../dto/Bookkeeping/CreateBookkeepingPaymentMethodMappingDTO';
import type { CreateBookkeepingAccountDTO } from '../../dto/Bookkeeping/CreateBookkeepingAccountDTO';
import type { UpdateBookkeepingSettingsDTO } from '../../dto/Bookkeeping/UpdateBookkeepingSettingsDTO';
import type { DeliveryLocationDTO } from '../../dto/DeliveryLocation/DeliveryLocationDTO';
import type { EnableFortnoxIntegrationDTO } from '../../dto/Integration/EnableFortnoxIntegrationDTO';
import type { CreatePrettyNamesDTO } from '../../dto/PrettyName/CreatePrettyNamesDTO';
import type { IManagedSeller } from '../../domain/ManagedSeller';
import type { IBannerData } from '../../domain/Banner/BannerData';
import type { IOrderPreviewPageDTO } from '../../dto/OrderPreview/OrderPreviewPageDTO';
import type { Seller } from '../../domain/Seller/Seller';
import type { JDiscount } from '../../domain/Discount';
import type { IDiscountCode } from '../../domain/DiscountCode';
import type { ISellerPrettyNameDTO } from '../../dto/PrettyName/SellerPrettyNameDTO';
import type { IActivateTerminalsDTO } from '../../dto/Terminal/ActivateTerminals';
import type { JTier } from '../../domain/Tier/Tier';
import type { IOnboardFromAdyenAccountDTO } from '../../dto/Adyen/OnboardFromAdyenAccountDTO';
import type { JIppTier } from '../../domain/Tier/IppTier';
import type { IAdyenAccountDTO } from '../../dto/Adyen/AdyenAccountDTO';
import type { PaymentMethodDTO } from '../../dto/PaymentMethod/PaymentMethodDTO';
import type { IppPaymentMethodDTO } from '../../dto/Adyen/IppPaymentMethodDTO';
import type { RedirectUrlDTO } from '../../dto/Adyen/RedirectUrlDTO';
import type { IUpdateIppPaymentMethodDTO } from '../../dto/Adyen/UpdateIppPaymentMethodDTO';
import type { IUpdateAdyenAccountDTO } from '../../dto/Adyen/UpdateAdyenAccountDTO';
import type { JTerminalOrderPrerequisites } from '../../domain/Terminal/TerminalOrderPrerequisites';
import type { JTerminalOrderDetails } from '../../domain/Terminal/TerminalOrderDetails';
import type { JTerminalOrder } from '../../domain/Terminal/TerminalOrder';

const BASE_URL = process.env.REACT_APP_WEIQ_CORE_URL;

export class WeiqCoreClient {
  private readonly client;

  constructor(axiosClient: AxiosInstance) {
    this.client = axiosClient;
  }

  async whoami() {
    const { client } = this;
    const url = `${BASE_URL}/auth/whoami`;

    try {
      const result = await client.get(url);
      return result.data;
    } catch (exception) {
      console.error('Whoami failed with error', exception);
    }

    return null;
  }

  async getManagedSellers(userReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/users/${userReference}/managed_sellers`;

    try {
      const result = await client.get<IManagedSeller[]>(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to fetch managed sellers. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to fetch managed sellers', exception);
    }

    return null;
  }

  async getSeller(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}`;

    try {
      const result = await client.get<Seller>(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get seller', exception);
    }

    return null;
  }

  async createSeller(sellerDTO: SellerDTO) {
    const { client } = this;
    const url = `${BASE_URL}/sellers`;

    try {
      const response = await client.post(url, sellerDTO);
      if (response?.status === 200) {
        return response.data;
      } else {
        console.error(
          'Failed to create seller. Core responded with',
          response.status
        );
        return false;
      }
    } catch (error) {
      console.error(`Failed to create seller`, error);
    }
    return false;
  }

  async enrollSellerForMoms(
    sellerReference: string,
    enrollSellerDTO: EnrollSellerDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/moms/${sellerReference}`;
    try {
      await client.post(url, enrollSellerDTO);
      return true;
    } catch (error) {
      console.error(
        `Failed to enroll seller with reference ${sellerReference}`,
        error
      );
    }
    return false;
  }

  async updateSeller(sellerReference: string, sellerDTO: SellerDTO) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}`;

    try {
      const response = await client.put(url, sellerDTO);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to update seller. Core responded with',
          response.status
        );
        return false;
      }
    } catch (error) {
      console.error(
        `Failed to update seller with reference ${sellerReference}`,
        error
      );
    }
    return false;
  }

  async updateCoverImage(sellerReference: string, image: string | Blob) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/header_image`;
    const formData = new FormData();
    formData.append('file', image);

    try {
      const response = await client.post(url, formData);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to update cover image. Core responded with',
          response.status
        );
        return false;
      }
    } catch (error) {
      console.error('Failed to update cover image.', error);
    }
    return false;
  }

  async addBanner(
    sellerReference: string,
    bannerImage: string | Blob,
    bannerData: IBannerData
  ) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/banner_image`;
    const formData = new FormData();
    formData.append('file', bannerImage);
    formData.append(
      'SellerBannerDTO',
      new Blob([JSON.stringify(bannerData)], {
        type: 'application/json'
      })
    );

    try {
      const response = await client.post(url, formData);
      if (response?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to upload banner. Core responded with',
          response.status
        );
        return false;
      }
    } catch (error) {
      console.error('Failed to upload banner.', error);
    }
    return false;
  }

  async deleteBanner(sellerReference: string, bannerUrl: string) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/remove_banner_image?banner-url=${bannerUrl}`;
    try {
      const response = await client.delete(url);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete banner. Core responded with',
          response.status
        );
        return false;
      }
    } catch (error) {
      console.error('Failed to delete banner.', error);
    }
    return false;
  }

  async getSellerUsers(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/users/sellers/${sellerReference}/seller_user`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get users for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get users for seller', exception);
    }

    return null;
  }

  async getSellerUser(userReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/user/seller-user/${userReference}`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Failed to get user with reference ${userReference}`);
    }
    return null;
  }

  async createNewSellerUser(
    sellerReference: string,
    newSellerUserDTO: NewSellerUserDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/users/sellers/${sellerReference}/seller_user`;

    try {
      const result = await client.post(url, newSellerUserDTO);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to create new user. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create new user', exception);
    }
    return false;
  }

  async addExistingSellerUser(sellerReference: string, userReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/users/${userReference}/seller_user`;
    const body = {
      sellerReference
    };
    try {
      const result = await client.post(url, body);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to add existing user. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to add existing user', exception);
    }
    return false;
  }

  async searchUsers(query: string) {
    const { client } = this;
    const url = `${BASE_URL}/users/search?q=${query}`;

    try {
      const result = await client.get(url);

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get search result. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get search result', exception);
    }
    return null;
  }

  async sendPasswordResetEmailForUser(userReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/users/${userReference}/reset_password`;

    try {
      const result = await client.post(url);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to reset password. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to reset password', exception);
    }
    return false;
  }

  async updateUser(userReference: string, updateUserDTO: UpdateUserDTO) {
    const { client } = this;
    const url = `${BASE_URL}/users/${userReference}`;
    try {
      const result = await client.put(url, updateUserDTO);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to update user. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update user', exception);
    }
    return false;
  }

  async getActivePaymentMethods(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/seller/${sellerReference}`;
    try {
      const result = await client.get<PaymentMethodDTO[]>(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get active payment methods for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get active payment methods for seller',
        exception
      );
    }
    return null;
  }

  async deletePaymentMethod(sellerReference: string, type: string) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/seller/${sellerReference}/${type}`;
    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete payment method for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to delete payment method for seller', exception);
    }
    return false;
  }

  async getAdyenAccount(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/account`;
    try {
      const result = await client.get<IAdyenAccountDTO>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get Adyen account for seller', exception);
    }

    return null;
  }

  async updateAdyenAccount(
    sellerReference: string,
    patch: IUpdateAdyenAccountDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/account`;
    try {
      await client.patch(url, patch);
      return true;
    } catch (exception) {
      console.error('Failed to update adyen account', exception);
      return false;
    }
  }

  async getAdyenOnboardingUrl(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/account/hop`;

    try {
      const result = await client.get<RedirectUrlDTO>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get Adyen Onboarding url', exception);
    }
    return null;
  }

  async getAdyenIppPaymentMethods(
    sellerReference: string,
    storeReference: string
  ) {
    const { client } = this;
    const url =
      `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}` +
      `/stores/${storeReference}/payment-methods`;
    try {
      const result = await client.get<IppPaymentMethodDTO[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get Adyen IPP payment methods', exception);
    }
    return null;
  }

  async updateIppPaymentMethods(
    sellerReference: string,
    storeReference: string,
    updateIppPaymentMethodDTOs: IUpdateIppPaymentMethodDTO[]
  ) {
    const { client } = this;
    const url =
      `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}` +
      `/stores/${storeReference}/payment-methods`;
    try {
      await client.put(url, updateIppPaymentMethodDTOs);
      return true;
    } catch (exception) {
      console.error('Failed to get Adyen IPP payment methods', exception);
    }
    return false;
  }

  async initiateAdyenOnboarding(
    sellerReference: string,
    initiateAdyenOnboardingDTO: IInitiateAdyenOnboardingDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/onboard`;

    try {
      await client.post(url, initiateAdyenOnboardingDTO);
      return true;
    } catch (exception) {
      console.error('Failed to initiate Adyen onboarding', exception);
      return false;
    }
  }

  async onboardFromAdyenAccount(
    sellerReference: string,
    onboardFromAdyenAccountDTO: IOnboardFromAdyenAccountDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/onboard/from-account`;

    try {
      await client.post(url, onboardFromAdyenAccountDTO);
      return true;
    } catch (exception) {
      console.error(
        'Failed to onboard seller from existing Adyen account',
        exception
      );
      return false;
    }
  }

  async updatePayoutSchedule(
    sellerReference: string,
    scheduleId: string,
    patch: IUpdateAdyenPayoutScheduleDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/payout-schedules/${scheduleId}`;
    try {
      await client.patch(url, patch);
      return true;
    } catch (exception) {
      console.error('Failed to update payout schedule', exception);
      return false;
    }
  }

  async triggerPayoutSchedule(sellerReference: string, scheduleId: string) {
    const { client } = this;

    const url = `${BASE_URL}/api/v2/adyen/balance-platform/seller/${sellerReference}/payout-schedules/${scheduleId}/trigger`;
    try {
      await client.post(url);
      return true;
    } catch (exception) {
      console.error('Failed to trigger payout schedule', exception);
      return false;
    }
  }

  async updateAdyenPaymentMethodData(
    sellerReference: string,
    updateAdyenPaymentMethodDTO: IUpdateAdyenPaymentMethodDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/adyen/${sellerReference}`;

    try {
      const result = await client.put(url, updateAdyenPaymentMethodDTO);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to update Adyen payment methods. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update Adyen payment methods', exception);
    }
    return false;
  }

  async activateTerminals(
    sellerReference: string,
    activate: IActivateTerminalsDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/activate-ipp`;

    try {
      const result = await client.post(url, activate);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to activate terminals. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to activate terminals', exception);
    }
    return false;
  }

  async getTerminalsForSeller(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminals`;

    try {
      const result = await client.get<ITerminal[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminals', exception);
      return null;
    }
  }

  async getAssignedTerminalDetails(
    sellerReference: string,
    terminalId: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminals/${terminalId}`;

    try {
      const result = await client.get<ITerminalDetails>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminal details', exception);
      return null;
    }
  }

  async getInventoryTerminalDetails(terminalId: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/terminals/${terminalId}/inventory`;

    try {
      const result = await client.get<ITerminalDetails>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminal details', exception);
      return null;
    }
  }

  async getTerminalOrdersForSeller(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminal-orders`;

    try {
      const result = await client.get<JTerminalOrder[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminal orders', exception);
      return null;
    }
  }

  async getTerminalOrderDetails(
    sellerReference: string,
    orderReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminal-orders/${orderReference}`;

    try {
      const result = await client.get<JTerminalOrderDetails>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminal order details', exception);
    }
    return null;
  }

  async getTerminalOrderPrerequisites(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminal-orders/prerequisites`;

    try {
      const result = await client.get<JTerminalOrderPrerequisites>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get terminal order prerequisites', exception);
    }
    return null;
  }

  async createTerminalOrder(
    sellerReference: string,
    createTerminalOrderDTO: ICreateAdyenTerminalOrderDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/terminal-orders`;

    try {
      await client.post(url, createTerminalOrderDTO);
      return true;
    } catch (exception) {
      console.error('Failed to create terminal order', exception);
    }
    return false;
  }

  async assignTerminal(
    assignToSellerReference: string | null,
    terminalId: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/terminals/${terminalId}/assign`;

    try {
      await client.post(url, {
        assignToSellerReference
      });

      return ClientResult.success();
    } catch (ex) {
      console.error('Failed to get assign terminal to seller', ex);
      const error = getAxiosError(ex, 'details');
      return ClientResult.error(error);
    }
  }

  async getTerminalsInInventory() {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/terminals/inventory`;

    try {
      const result = await client.get<ITerminal[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get Adyen account for seller', exception);
      return null;
    }
  }

  async createShippingLocation(
    sellerReference: string,
    createShippingLocationDTO: ICreateAdyenTerminalShippingLocationDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/shipping-entities`;

    try {
      const result = await client.post(
        url,
        createShippingLocationDTO.shippingDetails
      );

      if (result?.status === 200) {
        return result.data.reference;
      } else {
        console.error(
          'Failed to create shipping location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create shipping location', exception);
    }
    return false;
  }

  async isShippingLocationCreated(
    sellerReference: string,
    shippingLocationId: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/adyen/management/seller/${sellerReference}/shipping-entities/${shippingLocationId}/created`;

    try {
      const result = await client.get(url);

      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to get shipping location is created',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get shipping location is created', exception);
    }
    return false;
  }

  async addPACPaymentMethod(
    createPACPaymentMethodDTO: CreatePACPaymentMethodDTO
  ) {
    const { client } = this;

    const url = `${BASE_URL}/payments/payment_methods/pac`;

    try {
      const result = await client.post(url, createPACPaymentMethodDTO);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to add PAC payment method. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to add PAC payment method');
    }
    return false;
  }

  async getAdyenTiers() {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/payment/paymentmethod/adyen_pricelist`;

    try {
      const result = await client.get<Record<string, JTier>>(url);

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get Adyen tiers. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get Adyen tiers', exception);
    }
    return null;
  }

  async getAdyenIppTiers() {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/payment/paymentmethod/adyen_pricelist/ipp`;

    try {
      const result = await client.get<Record<string, JIppTier>>(url);

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get Adyen ipp tiers. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get Adyen ipp tiers', exception);
    }
    return null;
  }

  async addSwishPaymentMethod(
    createSwishPaymentMethodDTO: CreateSwishPaymentMethodDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/swish`;
    try {
      const result = await client.post(url, createSwishPaymentMethodDTO);

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to add Swish payment method. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to add Swish payment method', exception);
    }
    return false;
  }

  async updateSwishPaymentMethod(
    sellerReference: string,
    updateSwishPaymentMethodDTO: UpdateSwishPaymentMethodDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/${sellerReference}/swish`;
    try {
      const result = await client.put(url, updateSwishPaymentMethodDTO);

      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to update Swish payment method. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update Swish payment method', exception);
    }
    return false;
  }

  async getCertificateForSwish(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/${sellerReference}/swish/csr`;
    try {
      const result = await client.get(url);

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get CSR for Swish. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get CSR for Swish', exception);
    }
    return false;
  }

  async uploadSwishPEMCertificate(
    sellerReference: string,
    PEMCertificate: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/payments/payment_methods/${sellerReference}/swish/certificate/pem`;
    const headers = { 'Content-Type': 'text/plain' };

    try {
      const result = await client.post(url, PEMCertificate, { headers });

      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to upload PEM certificate for Swish. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to upload PEM certificate for swish', exception);
    }
    return false;
  }

  async getOrders(sellerReference: string, offsetOrderReference: string) {
    const { client } = this;
    const reference = offsetOrderReference
      ? `&order_reference=${offsetOrderReference}`
      : '';

    const url = `${BASE_URL}/orders/seller/${sellerReference}?limit=100${reference}`;
    try {
      const result = await client.get(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get orders', exception);
    }
    return null;
  }

  async getOrder(orderReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderReference}`;
    try {
      const result = await client.get(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get order', exception);
    }
    return null;
  }

  async searchOrderPreviews(
    sellerReference: string,
    searchOrderPreviewsDTO: SearchOrderPreviewsDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/sellers/${sellerReference}/orders/preview/search`;
    try {
      const result = await client.post<IOrderPreviewPageDTO>(
        url,
        searchOrderPreviewsDTO
      );
      return result.data;
    } catch (exception) {
      console.error('Failed to get order preview search results', exception);
    }
    return null;
  }

  async getHistops(orderReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderReference}/histops`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get histops. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get histops', exception);
    }
    return null;
  }

  async confirmTicket(orderRef: string, ticketRef: string) {
    const { client } = this;

    const url = `${BASE_URL}/orders/${orderRef}/tickets/${ticketRef}/confirm`;
    try {
      const result = await client.post(url);
      if ([204, 200].includes(result?.status)) {
        return true;
      } else {
        console.error(
          'Failed to confirm ticket. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to confirm ticket.', exception);
    }
    return false;
  }

  async activateTicket(orderRef: string, ticketRef: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderRef}/tickets/${ticketRef}/activate`;
    try {
      const result = await client.post(url);
      if ([204, 200].includes(result?.status)) {
        return true;
      } else {
        console.error(
          'Failed to activate ticket. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to activate ticket ', exception);
    }
    return false;
  }

  async triggerReadyForPickup(orderRef: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderRef}/trigger_ready_for_pickup_sendout`;
    try {
      const result = await client.post(url);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to trigger ready for pickup. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to trigger ready for pickup', exception);
    }
    return false;
  }

  async deliverOrder(orderRef: string) {
    const { client } = this;
    try {
      const url = `${BASE_URL}/orders/${orderRef}/fully_deliver`;
      const result = await client.post(url);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to deliver order. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to deliver order', exception);
    }
    return false;
  }

  async rejectOrder(orderRef: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderRef}/fully_reject`;
    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to reject order. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to reject order', exception);
    }
    return false;
  }

  async creditOrder(orderRef: string) {
    const { client } = this;
    const url = `${BASE_URL}/orders/${orderRef}/fully_credit`;
    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to credit order. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to credit order', exception);
    }
    return false;
  }

  async getDiscounts(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/sellers/${sellerReference}/discounts`;

    try {
      const result = await client.get<JDiscount[]>(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get discounts for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get discounts for seller', exception);
    }

    return null;
  }

  async getDiscount(discountReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get discount. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get discount', exception);
    }

    return null;
  }

  async deleteDiscount(discountReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete discount. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to delete discount', exception);
    }

    return false;
  }

  async createDiscount(discountDTO: DiscountDTO) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts`;

    try {
      const result = await client.post(url, discountDTO);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to create discount. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create discount', exception);
    }

    return null;
  }

  async updateDiscount(reference: string, discountDTO: DiscountDTO) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${reference}`;

    try {
      const result = await client.put(url, discountDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to update discount. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update discount', exception);
    }

    return false;
  }

  async mapDiscountToSeller(
    discountReference: string,
    sellerReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/sellers/${sellerReference}`;

    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to map discount to seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to map discount to seller', exception);
    }

    return false;
  }

  async getDiscountCodesForDiscount(discountReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/discount_codes`;

    try {
      const result = await client.get<IDiscountCode[]>(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get discount codes. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get discount codes', exception);
    }

    return null;
  }

  async deleteDiscountCode(
    discountReference: string,
    discountCodeReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/discount_codes/${discountCodeReference}`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete discount code. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to delete discount code', exception);
    }

    return false;
  }

  async createDiscountCode(
    discountReference: string,
    createDiscountCodeDTO: CreateDiscountCodeDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/discount_codes`;

    try {
      const result = await client.post(url, createDiscountCodeDTO);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to create discount code. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create discount code', exception);
    }

    return null;
  }

  async generateDiscountCodes(
    discountReference: string,
    generateDiscountCodeDTO: GenerateDiscountCodesDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/discount_codes/generate`;

    try {
      const result = await client.post(url, generateDiscountCodeDTO);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to generate discount codes. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to generate discount codes', exception);
    }

    return null;
  }

  async updateDiscountCodeTerminalVisibility(
    discountReference: string,
    discountCodeReference: string,
    showInTerminal: boolean
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/discounts/${discountReference}/discount_codes/${discountCodeReference}/terminal`;

    try {
      const result = await client.patch<void>(url, { showInTerminal });
      return true;
    } catch (exception) {
      console.error(
        'Failed to update terminal visibility for discount code',
        exception
      );
      return false;
    }
  }

  async getBookkeeping(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get bookkeeping for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get bookkeeping for seller', exception);
    }

    return null;
  }

  async enableBookkeeping(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}`;

    try {
      const result = await client.post(url);

      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to enable bookkeeping for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to enable bookkeeping for seller', exception);
    }

    return false;
  }

  async getBookkeepingExports(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/export_events`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get bookkeeping exports for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get bookkeeping exports for seller', exception);
    }

    return null;
  }

  async downloadBookkeepingExport(sellerReference: string, serial: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/exported_file?serial=${serial}`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download bookkeeping export. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download bookkeeping export', exception);
    }

    return null;
  }

  async createBookkeepingExport(
    sellerReference: string,
    createBookkeepingExportDTO: CreateBookkeepingExportDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/export_s3`;

    try {
      const result = await client.get(url, {
        params: createBookkeepingExportDTO.toJSON()
      });

      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to create bookkeeping export. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create bookkeeping export', exception);
    }

    return false;
  }

  async getBookkeepingAccounts(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/accounts`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get bookkeeping accounts for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get bookkeeping accounts for seller', exception);
    }

    return null;
  }

  async getBookkeepingProductMappings(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/catalog_line_items_map`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get bookkeeping product mappings for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get bookkeeping product mappings for seller',
        exception
      );
    }

    return null;
  }

  async getBookkeepingPaymentMethodMappings(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/payment_methods_map`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get bookkeeping payment method mappings for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get bookkeeping payment method mappings for seller',
        exception
      );
    }

    return null;
  }

  async createBookkeepingProductMapping(
    sellerReference: string,
    createBookkeepingProductMappingDTO: CreateBookkeepingProductMappingDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/catalog_line_items_map`;

    try {
      const result = await client.post(url, createBookkeepingProductMappingDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create bookkeeping product mapping. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create bookkeeping product mapping', exception);
    }

    return false;
  }

  async createBookkeepingPaymentMethodMapping(
    sellerReference: string,
    createBookkeepingPaymentMethodMappingDTO: CreateBookkeepingPaymentMethodMappingDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/payment_methods_map`;

    try {
      const result = await client.post(
        url,
        createBookkeepingPaymentMethodMappingDTO
      );
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create bookkeeping payment method mapping. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to create bookkeeping payment method mapping',
        exception
      );
    }

    return false;
  }

  async createBookkeepingAccount(
    sellerReference: string,
    createBookkeepingAccountDTO: CreateBookkeepingAccountDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/accounts`;

    try {
      const result = await client.post(url, createBookkeepingAccountDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create bookkeeping account. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create bookkeeping account', exception);
    }

    return false;
  }

  async updateBookkeepingSettings(
    sellerReference: string,
    updateBookkeepingSettingsDTO: UpdateBookkeepingSettingsDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/settings`;

    try {
      const result = await client.put(url, updateBookkeepingSettingsDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to update bookkeeping settings. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update bookkeeping settings', exception);
    }

    return false;
  }

  async createDeliveryLocation(
    sellerReference: string,
    deliveryLocationDTO: DeliveryLocationDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/delivery_location`;

    try {
      const result = await client.post(url, deliveryLocationDTO);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to create delivery location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create delivery location', exception);
    }

    return null;
  }

  async updateDeliveryLocation(
    sellerReference: string,
    deliveryLocationReference: string,
    deliveryLocationDTO: DeliveryLocationDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/delivery_location/${deliveryLocationReference}`;

    try {
      const result = await client.put(url, deliveryLocationDTO);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to update delivery location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to update delivery location', exception);
    }

    return false;
  }

  async openDeliveryLocation(
    sellerReference: string,
    deliveryLocationReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/delivery_location/${deliveryLocationReference}/open`;

    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to open delivery location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to open delivery location', exception);
    }

    return false;
  }

  async closeDeliveryLocation(
    sellerReference: string,
    deliveryLocationReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/sellers/${sellerReference}/delivery_location/${deliveryLocationReference}/close`;

    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to close delivery location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to close delivery location', exception);
    }

    return false;
  }

  async deleteDeliveryLocation(
    sellerReference: string,
    deliveryLocationReference: string
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/sellers/${sellerReference}/delivery_locations/${deliveryLocationReference}`;

    try {
      const result = await client.delete(url);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to delete delivery location. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to delete delivery location', exception);
    }

    return false;
  }

  async createSrv4PosIntegration(
    sellerReference: string,
    terminalSerialNumber: string | null = null
  ) {
    const { client } = this;
    const url = terminalSerialNumber
      ? `${BASE_URL}/sellers/${sellerReference}/register?ippSerialNumber=${terminalSerialNumber}`
      : `${BASE_URL}/sellers/${sellerReference}/register`;

    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to create Srv4Pos integration for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to create Srv4Pos integration for seller',
        exception
      );
    }

    return false;
  }

  async getFortnoxIntegration(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/fortnox`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get fortnox integration for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get fortnox integration for seller', exception);
    }

    return null;
  }

  async enableFortnoxIntegration(
    sellerReference: string,
    enableFortnoxIntegrationDTO: EnableFortnoxIntegrationDTO
  ) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/fortnox/oauth2`;

    try {
      const result = await client.post(url, enableFortnoxIntegrationDTO);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to enable fortnox integration for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to enable fortnox integration for seller',
        exception
      );
    }

    return false;
  }

  async deleteFortnoxIntegration(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/bookkeeping/${sellerReference}/fortnox`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete fortnox integration for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to delete fortnox integration for seller',
        exception
      );
    }

    return false;
  }

  async getRatingOverview(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/rating/${sellerReference}/rating_overview`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get rating overview for seller. Core responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get rating overview for seller', exception);
    }

    return null;
  }

  async addPrettyNames(args: {
    sellerReference: string;
    createPrettyNamesDTO: CreatePrettyNamesDTO;
  }) {
    const { client } = this;
    const { sellerReference, createPrettyNamesDTO } = args;
    const url = `${BASE_URL}/api/v2/seller/${sellerReference}/pretty-name`;
    try {
      const result = await client.post(url, createPrettyNamesDTO);
      return result.data;
    } catch (exception) {
      console.error('Failed to add pretty names', exception);
    }
    return null;
  }

  async setPreferredPrettyName(args: {
    sellerReference: string;
    prettyNameReference: string;
  }) {
    const { client } = this;
    const { sellerReference, prettyNameReference } = args;

    const url = `${BASE_URL}/api/v2/seller/${sellerReference}/pretty-name/${prettyNameReference}/preferred`;
    try {
      const result = await client.post(url);
      if (result) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to set preferred pretty name', exception);
    }
    return false;
  }

  async getSellerPrettyNames(sellerReference: string) {
    const { client } = this;
    const url = `${BASE_URL}/api/v2/seller/${sellerReference}/pretty-name`;
    try {
      const result = await client.get<ISellerPrettyNameDTO[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get pretty names', exception);
    }
    return [];
  }
}
