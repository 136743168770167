const TYPE = 'max_uses_per_user' as const;

export interface IMaxUsesPerUserDiscountLimitation {
  maxUsesPerUser: number;
  type: typeof TYPE;
}

class MaxUsesPerUserDiscountLimitation {
  readonly maxUsesPerUser;
  readonly type = TYPE;

  constructor(args: IMaxUsesPerUserDiscountLimitation) {
    const { maxUsesPerUser } = args;
    this.maxUsesPerUser = maxUsesPerUser;
  }

  static fromJSON(json: IMaxUsesPerUserDiscountLimitation) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { maxUsesPerUser, type } = this;
    return { maxUsesPerUser, type };
  };
}

export { MaxUsesPerUserDiscountLimitation };
