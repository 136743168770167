import { ToJSON } from '../../domain/Decorators';

@ToJSON
class UpdateCatalogDTO {
  _name;

  _description;

  _openingHours;

  _visible;

  _thumbnailIcon;

  _variablePricesEnabled;

  constructor(editableCatalog) {
    const {
      name,
      description,
      openingHours,
      visible,
      thumbnailIcon,
      variablePricesEnabled
    } = editableCatalog;

    this._name = name;
    this._description = description;
    this._visible = visible;
    this._thumbnailIcon = thumbnailIcon;
    this._variablePricesEnabled = variablePricesEnabled;

    // Filter out opening hours that are null
    this._openingHours = Object.fromEntries(
      Object.entries(openingHours.toJSON())
        .filter(([, openingHourList]) => openingHourList)
        .map(([day, openingHourList]) => [
          day,
          openingHourList.filter(
            openingHour =>
              openingHour.fromInclusive !== null ||
              openingHour.toInclusive !== null
          )
        ])
    );
  }
}

export { UpdateCatalogDTO as default };
