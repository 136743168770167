export interface ITicketPreview {
  reference: string;
  createdAt: string;
  modifiedAt: string;
  deliveryTime: string | null;
  code: string;
  qrCode: string | null;
  skinImageUrl: string;
  ticketStatus: string;
  ticketType: string;
  deliveryFlowType: string;
}

class TicketPreview {
  readonly reference;
  readonly createdAt;
  readonly modifiedAt;
  readonly deliveryTime;
  readonly code;
  readonly qrCode;
  readonly skinImageUrl;
  readonly ticketStatus;
  readonly ticketType;
  readonly deliveryFlowType;

  constructor(args: ITicketPreview) {
    const {
      reference,
      createdAt,
      modifiedAt,
      deliveryTime,
      code,
      qrCode,
      skinImageUrl,
      ticketStatus,
      ticketType,
      deliveryFlowType
    } = args;

    this.reference = reference;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    this.deliveryTime = deliveryTime;
    this.code = code;
    this.qrCode = qrCode;
    this.skinImageUrl = skinImageUrl;
    this.ticketStatus = ticketStatus;
    this.ticketType = ticketType;
    this.deliveryFlowType = deliveryFlowType;
  }
}

export { TicketPreview };
