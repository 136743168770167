import Keycloak from '../../lib/keycloak';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const keycloak = new Keycloak({
  realm: 'master',
  url: AUTH_URL,
  clientId: 'tuna'
});

export class AuthClient {
  private initialized = false;

  init = async () => {
    if (this.initialized) {
      throw new Error('Keycloak can only be initialized once');
    }

    try {
      // Get existing tokens from storage
      const token = localStorage.getItem('token') ?? undefined;
      const refreshToken = localStorage.getItem('refreshToken') ?? undefined;

      // Register event listerns for token updates
      keycloak.onAuthSuccess = this.onTokenUpdate;
      keycloak.onAuthRefreshSuccess = this.onTokenUpdate;

      const authenticated = await keycloak.init({
        checkLoginIframe: false,
        token,
        refreshToken
      });

      this.initialized = true;

      return authenticated;
    } catch (ex) {
      console.error('Failed to initialize Keycloak', ex);
      return false;
    }
  };

  login = (args: { redirectUri?: string }) => {
    const { redirectUri } = args;
    keycloak.login({ redirectUri });
  };

  logout = (args: { redirectUri?: string } = {}) => {
    const redirectUri = args.redirectUri ?? APP_PUBLIC_URL;

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    keycloak.logout({ redirectUri });
  };

  isTokenExpired = (inSeconds: number) => keycloak.isTokenExpired(inSeconds);

  updateToken = async (minValidity?: number) => {
    try {
      const refreshed = await keycloak.updateToken(minValidity);
      if (refreshed) {
        console.warn('Token was refreshed');
      }
    } catch (ex) {
      console.error('Failed to refresh the token', ex);
    }
  };

  private onTokenUpdate = () => {
    console.log('Tokens were updated!');

    if (keycloak.token) {
      localStorage.setItem('token', keycloak.token);
    }

    if (keycloak.refreshToken) {
      localStorage.setItem('refreshToken', keycloak.refreshToken);
    }
  };

  get token() {
    return keycloak.token;
  }

  get parsedToken() {
    return keycloak.tokenParsed;
  }

  get authenticated() {
    return keycloak.authenticated;
  }

  get userReference() {
    return keycloak.subject;
  }
}
