import Product from '../domain/Product/Product';
import CreateProductDTO from '../dto/Product/CreateProductDTO';
import UpdateProductDTO from '../dto/Product/UpdateProductDTO';

class ProductManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  getProduct = async productReference => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const jsonProduct = await momsClient.getProduct(
      productRegisterReference,
      productReference
    );

    return jsonProduct ? Product.fromJSON(jsonProduct) : null;
  };

  createProduct = async editProduct => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const { editProductImages } = editProduct;
    const createProductDTO = CreateProductDTO.fromEditProduct(editProduct);

    const productReference = (
      await momsClient.createProduct({
        createProductDTO,
        productRegisterReference
      })
    )?.reference;

    if (productReference) {
      const result = (
        await Promise.all([
          this._setProductModifierOverrides(productReference, editProduct),
          this._uploadImages(productReference, editProductImages)
        ])
      ).every(Boolean);
      return result ? productReference : null;
    } else {
      return null;
    }
  };

  updateProduct = async (productReference, editProduct) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const { editProductImages } = editProduct;
    const updateProductDTO = UpdateProductDTO.fromEditProduct(editProduct);

    const result = await momsClient.updateProduct({
      productReference,
      productRegisterReference,
      updateProductDTO
    });

    if (result) {
      return (
        await Promise.all([
          this._setProductModifierOverrides(productReference, editProduct),
          this._uploadImages(productReference, editProductImages)
        ])
      ).every(Boolean);
    } else {
      return false;
    }
  };

  _uploadImages = async (productReference, editProductImages) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    const uploads = Object.entries(editProductImages.toJSON()).filter(
      ([, content]) => content && typeof content !== 'string'
    );

    for (const [imageSize, file] of uploads) {
      // MOMS does not support parallel image uploads for now
      // eslint-disable-next-line no-await-in-loop
      const result = await momsClient.uploadProductImage({
        productRegisterReference,
        productReference,
        imageSize,
        file
      });

      if (!result) {
        return false;
      }
    }

    return true;
  };

  _setProductModifierOverrides = async (productReference, editProduct) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    const { modifierCategories } = editProduct;

    const overrides = modifierCategories.flatMap(modifierCategory => {
      const { reference: modifierCategoryReference } = modifierCategory;

      return modifierCategory.modifiers
        .filter(modifier => modifier.modified)
        .map(modifier => ({
          productRegisterReference,
          productReference,
          modifierCategoryReference,
          modifierReference: modifier.reference,
          enabled: modifier.productOverrideEnabled
        }));
    });

    for (const override of overrides) {
      // NOTE: Parallel requests are currently not supported
      // eslint-disable-next-line no-await-in-loop
      const result = await momsClient.setProductModifierOverride(override);
      if (!result) {
        return false;
      }
    }

    return true;
  };

  deleteProduct = async productReference => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.deleteProduct({
      productReference,
      productRegisterReference
    });
  };
}

export { ProductManager };
