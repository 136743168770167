import type { OrderPreview } from './OrderPreview';

interface IOrderPreviewPage {
  totalCount: number;
  orderPreviews: OrderPreview[];
}

class OrderPreviewPage {
  readonly totalCount;
  readonly orderPreviews;

  constructor(args: IOrderPreviewPage) {
    const { totalCount, orderPreviews } = args;

    this.totalCount = totalCount;
    this.orderPreviews = orderPreviews;
  }
}

export { OrderPreviewPage };
