import type { IOverviewRow } from './OverviewRow';
import { OverviewRow } from './OverviewRow';
import type { JOverviewSummaryRow } from './OverviewSummaryRow';
import { OverviewSummaryRow } from './OverviewSummaryRow';

export interface IOverviewSection {
  salesAndRefundRows: OverviewRow[];
  summaryRow: OverviewSummaryRow;
  tipRow: OverviewRow;
}

export interface JOverviewSection {
  salesAndRefundRows: IOverviewRow[];
  summaryRow: JOverviewSummaryRow;
  tipRow: IOverviewRow;
}

export type OverviewSectionRow = OverviewRow | OverviewSummaryRow;

interface OverviewSection extends IOverviewSection {}
class OverviewSection {
  readonly salesAndRefundRows;
  readonly summaryRow;
  readonly tipRow;

  constructor(args: IOverviewSection) {
    const { salesAndRefundRows, summaryRow, tipRow } = args;
    this.salesAndRefundRows = salesAndRefundRows;
    this.summaryRow = summaryRow;
    this.tipRow = tipRow;
  }

  toArray = (): OverviewSectionRow[] => {
    const { salesAndRefundRows, summaryRow, tipRow } = this;
    return [...salesAndRefundRows, summaryRow, tipRow];
  };

  static fromJSON(json: JOverviewSection) {
    const { salesAndRefundRows, summaryRow, tipRow } = json;
    const rows = salesAndRefundRows.map(row => OverviewRow.fromJSON(row));

    return new this({
      salesAndRefundRows: rows,
      summaryRow: OverviewSummaryRow.fromJSON(summaryRow),
      tipRow: OverviewRow.fromJSON(tipRow)
    });
  }

  static keyExtractor(row: OverviewSectionRow) {
    return row.key;
  }
}

export { OverviewSection };
