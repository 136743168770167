import { objectEntries } from '../../helpers/Functions';
import type { JCurrencyFees } from './Fees';
import { Fees } from './Fees';
import type { JPriceList } from './PriceList';
import { PriceType } from './PriceType';

interface IProviderPriceList {
  domesticCredit: PriceType;
  domesticDebit: PriceType;
  internationalCredit: PriceType;
  internationalDebit: PriceType;
  intraEEACredit: PriceType;
  intraEEADebit: PriceType;
}
export type CardType = keyof IProviderPriceList;

class ProviderPriceList {
  readonly domesticCredit: PriceType;
  readonly domesticDebit: PriceType;
  readonly internationalCredit: PriceType;
  readonly internationalDebit: PriceType;
  readonly intraEEACredit: PriceType;
  readonly intraEEADebit: PriceType;

  constructor(args: IProviderPriceList) {
    const {
      domesticCredit,
      domesticDebit,
      internationalCredit,
      internationalDebit,
      intraEEACredit,
      intraEEADebit
    } = args;

    this.domesticCredit = domesticCredit;
    this.domesticDebit = domesticDebit;
    this.internationalCredit = internationalCredit;
    this.internationalDebit = internationalDebit;
    this.intraEEACredit = intraEEACredit;
    this.intraEEADebit = intraEEADebit;
  }

  static fromJSON(json: JPriceList) {
    const priceTypes = objectEntries(json).reduce(
      (
        accumulator: Record<CardType, PriceType>,
        [fullCardType, jsonCurrencyFees]
      ) => {
        if (fullCardType.match(/(?:Commercial)/)) {
          const cardType = fullCardType.replace(
            /(?:Commercial)/,
            ''
          ) as CardType;

          const priceType =
            accumulator[cardType] ?? PriceType.fromCardType(cardType);

          accumulator[cardType] = priceType.withCommercialFees(
            toFeesRecord(jsonCurrencyFees)
          );
        } else if (fullCardType.match(/(?:Consumer)/)) {
          const cardType = fullCardType.replace(/(?:Consumer)/, '') as CardType;

          const priceType =
            accumulator[cardType] ?? PriceType.fromCardType(cardType);
          accumulator[cardType] = priceType.withConsumerFees(
            toFeesRecord(jsonCurrencyFees)
          );
        }

        return accumulator;
      },
      {} as Record<CardType, PriceType>
    );

    return new this(priceTypes);
  }
}

function toFeesRecord(jsonCurrencyFees: JCurrencyFees) {
  return objectEntries(jsonCurrencyFees).reduce(
    (acc: Record<string, Fees>, [currency, jsonFees]) => {
      acc[currency.toUpperCase()] = Fees.fromJSON(currency, jsonFees);
      return acc;
    },
    {}
  );
}

export { ProviderPriceList };
