import { Getter, ToJSON } from '../Decorators';

@Getter
@ToJSON
class ProductRegisterPreview {
  _reference;

  _name;

  _currency;

  _sellerReferences = [];

  constructor(args) {
    const { reference, name, currency, sellerReferences } = args;

    this._reference = reference;
    this._name = name;
    this._currency = currency;
    this._sellerReferences = sellerReferences;
  }
}

export { ProductRegisterPreview as default };
