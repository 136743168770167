import { computed } from 'mobx';
import ProductRegister from '../domain/ProductRegister';
import { ENTRY_PRODUCT, PRODUCT, MODIFIER } from '../constants';
import ProductRegisterPreview from '../domain/ProductRegister/ProductRegisterPreview';

class ProductRegisterManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get productRegister() {
    const { sellerDataStore } = this._sellerStore;
    const { productRegister } = sellerDataStore;

    return productRegister;
  }

  @computed
  get products() {
    const { sellerDataStore } = this._sellerStore;
    const { productRegister } = sellerDataStore;

    return productRegister?.products;
  }

  @computed
  get entryProducts() {
    const { products } = this;

    return products?.filter(product => product.type === ENTRY_PRODUCT);
  }

  @computed
  get nonEntryProducts() {
    const { products } = this;

    return products?.filter(product => product.type !== ENTRY_PRODUCT);
  }

  @computed
  get regularProducts() {
    const { products } = this;

    return products?.filter(product => product.type === PRODUCT);
  }

  @computed
  get modifierProducts() {
    const { products } = this;

    return products?.filter(product => product.type === MODIFIER);
  }

  @computed
  get regularProductsAndModifiers() {
    const { products } = this;

    return products?.filter(
      ({ type }) => type === PRODUCT || type === MODIFIER
    );
  }

  async fetchProductRegister() {
    const { _momsClient: momsClient } = this;
    const { selectedSellerReference, sellerDataStore } = this._sellerStore;
    const jsonProductRegister = await momsClient.getProductRegisterForSeller(
      selectedSellerReference
    );

    if (jsonProductRegister) {
      sellerDataStore.setProductRegister(
        ProductRegister.fromJSON(jsonProductRegister)
      );
      return true;
    } else {
      return false;
    }
  }

  async fetchProductRegisterFor(sellerReference) {
    const { _momsClient: momsClient } = this;
    const jsonProductRegister =
      await momsClient.getProductRegisterForSeller(sellerReference);

    return ProductRegister.fromJSON(jsonProductRegister);
  }

  getAllProductRegisters = async () => {
    const { _momsClient: momsClient } = this;
    const registersJson = await momsClient.getAllProductRegisters();
    if (registersJson) {
      return registersJson.map(json => new ProductRegisterPreview(json));
    } else {
      return [];
    }
  };
}

export { ProductRegisterManager };
