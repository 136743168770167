import { action, computed } from 'mobx';

class RouterManager {
  _routerStore;

  constructor(routerStore) {
    this._routerStore = routerStore;
  }

  @computed
  get currentPath() {
    const { _routerStore: routerStore } = this;
    return routerStore.currentPath;
  }

  @computed
  get previousPath() {
    const { _routerStore: routerStore } = this;
    return routerStore.previousPath;
  }

  @action
  setCurrentPath(currentPath) {
    const { _routerStore: routerStore } = this;
    routerStore.setCurrentPath(currentPath);
  }
}

export { RouterManager };
