import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.SUM;

export interface IOverviewSummaryRow {
  amountVatExcluded: string;
  amountVat: string;
  amount: string;
}

export interface JOverviewSummaryRow {
  totalAmountVatExcluded: string;
  totalAmountVat: string;
  totalAmount: string;
}

interface OverviewSummaryRow extends IOverviewSummaryRow {}
class OverviewSummaryRow {
  readonly amountVatExcluded;
  readonly amountVat;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IOverviewSummaryRow) {
    const { amountVatExcluded, amountVat, amount } = args;

    this.amountVatExcluded = amountVatExcluded;
    this.amountVat = amountVat;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: JOverviewSummaryRow) {
    const { totalAmountVatExcluded, totalAmountVat, totalAmount } = json;

    return new this({
      amountVatExcluded: totalAmountVatExcluded,
      amountVat: totalAmountVat,
      amount: totalAmount
    });
  }
}

export { OverviewSummaryRow };
