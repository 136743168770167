import { computed } from 'mobx';
import { SummarySalesReport } from '../domain/NewSalesReport/SummarySalesReport';
import { DetailedSalesReport } from '../domain/NewSalesReport/DetailedSalesReport';
import type { RiceClient } from '../config/clients/RiceClient';
import type { SellerStore } from '../stores/SellerStore';
import { assertNotUndefined } from '../helpers/Functions';
import { DailyReportDTO } from '../dto/DailyReport/DailyReportDTO';

type FromToArgs = {
  from: string;
  to: string;
};

class ReportManager {
  private readonly riceClient;
  private readonly sellerStore;

  constructor(riceClient: RiceClient, sellerStore: SellerStore) {
    this.riceClient = riceClient;
    this.sellerStore = sellerStore;
  }

  fetchSummarySalesReport = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    const jsonSummarySalesReport = await riceClient.getSummarySalesReport({
      sellerReference,
      from,
      to
    });

    return jsonSummarySalesReport
      ? SummarySalesReport.fromJSON(jsonSummarySalesReport)
      : null;
  };

  downloadSummarySalesReportPdf = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getSummarySalesReportPdf({ sellerReference, from, to });
  };

  downloadSummarySalesReportXlsx = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getSummarySalesReportXlsx({ sellerReference, from, to });
  };

  fetchDetailedSalesReport = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    const jsonDetailedSalesReport = await riceClient.getDetailedSalesReport({
      sellerReference,
      from,
      to
    });

    return jsonDetailedSalesReport
      ? DetailedSalesReport.fromJSON(jsonDetailedSalesReport)
      : null;
  };

  downloadDetailedSalesReportPdf = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getDetailedSalesReportPdf({ sellerReference, from, to });
  };

  downloadDetailedSalesReportXlsx = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getDetailedSalesReportXlsx({ sellerReference, from, to });
  };

  fetchDailyReports = async () => {
    const { riceClient, sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;
    assertNotUndefined(sellerReference);

    const dailyReportDTOs = await riceClient.getDailyReports(sellerReference);

    if (dailyReportDTOs) {
      sellerDataStore.setDailyReports(
        dailyReportDTOs.map(DailyReportDTO.toDailyReport)
      );
    }
  };

  @computed
  get dailyReports() {
    const { sellerStore } = this;
    const { sellerDataStore } = sellerStore;
    const { dailyReports } = sellerDataStore;

    return dailyReports;
  }

  downloadDailyReportPdf = async (serialNumber: number) => {
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getDailyReportPdf(sellerReference, serialNumber);
  };

  downloadPayoutReportPdf = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getPayoutReportPdf({ sellerReference, from, to });
  };

  downloadPayoutReportXlsx = async (args: FromToArgs) => {
    const { from, to } = args;
    const { riceClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotUndefined(sellerReference);

    return riceClient.getPayoutReportXlsx({
      sellerReference,
      from,
      to
    });
  };
}

export { ReportManager };
