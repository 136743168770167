import { Getter } from '../Decorators';
import { SystemMessage } from './SystemMessage';

@Getter
class SystemMessageThread {
  _createdAt;

  _resolvedAt;

  _reference;

  _category;

  _severity;

  _isPublic;

  _subject;

  _unread;

  _messages;

  constructor(args) {
    const {
      createdAt,
      resolvedAt,
      reference,
      category,
      severity,
      isPublic,
      subject,
      unread,
      messages
    } = args;

    this._createdAt = createdAt;
    this._resolvedAt = resolvedAt;
    this._reference = reference;
    this._category = category;
    this._severity = severity;
    this._isPublic = isPublic;
    this._subject = subject;
    this._unread = unread;
    this._messages = messages;
  }

  static fromJSON(json) {
    const {
      createdAt,
      resolvedAt,
      reference,
      category,
      severity,
      isPublic,
      subject,
      unread,
      messages: jsonMessages
    } = json;
    const messages = jsonMessages.map(message =>
      SystemMessage.fromJSON(message)
    );

    return new this({
      createdAt,
      resolvedAt,
      reference,
      category,
      severity,
      isPublic,
      subject,
      unread,
      messages
    });
  }
}

export { SystemMessageThread };
