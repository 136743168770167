import { computed } from 'mobx';
import { Discount } from '../domain/Discount';
import { DiscountCode } from '../domain/DiscountCode';
import { DiscountDTO } from '../dto/Discount/DiscountDTO';
import { CreateDiscountCodeDTO } from '../dto/DiscountCodes/CreateDiscountCodeDTO';
import { GenerateDiscountCodesDTO } from '../dto/DiscountCodes/GenerateDiscountCodesDTO';
import { assertNotNullUndefined, mapArrayToRecord } from '../helpers/Functions';
import type { WeiqCoreClient } from '../config/clients/WeiqCoreClient';
import type { SellerStore } from '../stores/SellerStore';
import type { EditDiscount } from '../domain/Discount/EditDiscount';
import type { EditDiscountCode } from '../domain/DiscountCode/EditDiscountCode';
import type { GenerateDiscountCodes } from '../domain/DiscountCode/GenerateDiscountCodes';

class DiscountManager {
  private readonly coreClient;
  private readonly sellerStore;

  constructor(coreClient: WeiqCoreClient, sellerStore: SellerStore) {
    this.coreClient = coreClient;
    this.sellerStore = sellerStore;
  }

  fetchDiscounts = async () => {
    const { coreClient, sellerStore } = this;
    const { sellerReference, sellerDataStore } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const discountDTOs = await coreClient.getDiscounts(sellerReference);
    if (discountDTOs) {
      sellerDataStore.setDiscounts(
        mapArrayToRecord(discountDTOs, Discount.fromJSON)
      );
    }
  };

  fetchDiscount = async (discountReference: string) => {
    const { coreClient } = this;

    const jsonDiscount = await coreClient.getDiscount(discountReference);
    return jsonDiscount ? Discount.fromJSON(jsonDiscount) : null;
  };

  createDiscount = async (editDiscount: EditDiscount) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    const discountDTO = DiscountDTO.fromEditDiscount(editDiscount);

    assertNotNullUndefined(sellerReference);
    const reference = await coreClient.createDiscount(discountDTO);
    if (!reference) {
      return null;
    }

    const mapToSellerResult = await coreClient.mapDiscountToSeller(
      reference,
      sellerReference
    );

    return mapToSellerResult ? reference : null;
  };

  updateDiscount = async (
    editDiscount: EditDiscount,
    discountReference: string
  ) => {
    const { coreClient } = this;
    const discountDTO = DiscountDTO.fromEditDiscount(editDiscount);

    const result = await coreClient.updateDiscount(
      discountReference,
      discountDTO
    );

    return result;
  };

  @computed
  get discounts() {
    const { sellerDataStore } = this.sellerStore;
    return Object.values(sellerDataStore.discounts);
  }

  deleteDiscount = async (discountReference: string) => {
    const { coreClient } = this;

    return coreClient.deleteDiscount(discountReference);
  };

  fetchDiscountCodes = async (discountReference: string) => {
    const { coreClient } = this;

    return (
      (await coreClient.getDiscountCodesForDiscount(discountReference))
        ?.map(jsonDiscountCode => DiscountCode.fromJSON(jsonDiscountCode))
        .reverse() ?? null
    );
  };

  createDiscountCode = async (
    discountReference: string,
    editDiscountCode: EditDiscountCode
  ) => {
    const { coreClient } = this;
    const createDiscountCodeDTO =
      CreateDiscountCodeDTO.fromEditDiscountCode(editDiscountCode);

    return coreClient.createDiscountCode(
      discountReference,
      createDiscountCodeDTO
    );
  };

  generateDiscountCodes = async (
    discountReference: string,
    generateDiscountCodes: GenerateDiscountCodes
  ) => {
    const { coreClient } = this;
    const generateDiscountCodesDTO =
      GenerateDiscountCodesDTO.fromGenerateDiscountCodes(generateDiscountCodes);

    return coreClient.generateDiscountCodes(
      discountReference,
      generateDiscountCodesDTO
    );
  };

  deleteDiscountCode = async (
    discountReference: string,
    discountCodeReference: string
  ) => {
    const { coreClient } = this;

    return coreClient.deleteDiscountCode(
      discountReference,
      discountCodeReference
    );
  };

  updateTerminalVisibility = async (
    discountReference: string,
    discountCodeReference: string,
    showInTerminal: boolean
  ) => {
    const { coreClient } = this;

    return coreClient.updateDiscountCodeTerminalVisibility(
      discountReference,
      discountCodeReference,
      showInTerminal
    );
  };
}

export { DiscountManager };
