import { Getter, ToJSON } from '../Decorators';
import CatalogProduct from './CatalogProduct';
import CatalogModifiersBuilder from './CatalogModifiersBuilder';
import OpeningHourMap from './OpeningHour/OpeningHourMap';
import { TIP } from '../../constants';

@Getter
@ToJSON
class Catalog {
  _reference;

  _name;

  _description;

  _isOpen;

  _type;

  _openingHours;

  _products;

  _modifiers;

  _prettyNames;

  _visible;

  _thumbnailIcon;

  _tipEnabled;

  _variablePricesEnabled;

  constructor(args) {
    const {
      reference,
      name,
      description,
      isOpen,
      type,
      openingHours,
      products,
      modifiers,
      prettyNames,
      visible,
      thumbnailIcon,
      tipEnabled,
      variablePricesEnabled
    } = args;

    this._reference = reference;
    this._name = name;
    this._description = description;
    this._isOpen = isOpen;
    this._type = type;
    this._openingHours = openingHours;
    this._products = products;
    this._modifiers = modifiers;
    this._prettyNames = prettyNames;
    this._visible = visible;
    this._thumbnailIcon = thumbnailIcon;
    this._tipEnabled = tipEnabled;
    this._variablePricesEnabled = variablePricesEnabled;
  }

  static fromJSON(json) {
    const {
      products: jsonProducts,
      openingHours: jsonOpeningHours,
      modifierCategories: jsonModifierCategories,
      ...rest
    } = json;

    let tipEnabled = false;
    const products = jsonProducts
      .reduce((accumulator, jsonProduct) => {
        if (jsonProduct.type === TIP) {
          tipEnabled = true;
        } else {
          accumulator.push(CatalogProduct.fromJSON(jsonProduct));
        }

        return accumulator;
      }, [])
      .sort(sortByImageTypeAndWeight);

    const openingHours = OpeningHourMap.fromJSON(jsonOpeningHours);
    const modifiers = CatalogModifiersBuilder.fromModifierGroups(
      jsonModifierCategories
    ).build();

    return new this({
      products,
      modifiers,
      openingHours,
      tipEnabled,
      ...rest
    });
  }
}

function sortByImageTypeAndWeight(firstProduct, secondProduct) {
  if (firstProduct.isWide === secondProduct.isWide) {
    return secondProduct.sortWeight - firstProduct.sortWeight;
  } else if (firstProduct.isWide) {
    return -1;
  } else {
    return 1;
  }
}

export { Catalog as default };
