import React, { Suspense, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { LAYOUT_HORIZONTAL } from '../../constants/layout';

const Topbar = React.lazy(() => import('../Topbar'));
const Footer = React.lazy(() => import('../Footer'));
const RightSidebar = React.lazy(() => import('../RightSidebar'));

const loading = () => <div className="text-center" />;

const HorizontalLayout = ({ children }) => {
  // Set layout horizontal: layoutConstants.LAYOUT_HORIZONTAL
  useEffect(() => {
    document.body.setAttribute('data-layout', 'topnav');

    return () => {
      document.body.removeAttribute('data-layout');
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="content-page">
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar
                isMenuOpened={false}
                openLeftMenuCallBack={() => null}
                navCssClasses="topnav-navbar"
                layoutType={LAYOUT_HORIZONTAL}
              />
            </Suspense>

            <Container fluid="lg" className="horizontal-content-container">
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>

          <Suspense fallback={loading()}>
            <RightSidebar />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default HorizontalLayout;
