import { TicketPreview } from '../../domain/OrderPreview/TicketPreview';

export interface ITicketPreviewDTO {
  reference: string;
  createdAt: string;
  modifiedAt: string;
  deliveryTime: string | null;
  code: string;
  qrCode: string | null;
  skinImageUrl: string;
  ticketStatus: string;
  ticketType: string;
  deliveryFlowType: string;
}

class TicketPreviewDTO {
  static toTicketPreview(ticketPreviewDTO: ITicketPreviewDTO) {
    const {
      reference,
      createdAt,
      modifiedAt,
      deliveryTime,
      code,
      qrCode,
      skinImageUrl,
      ticketStatus,
      ticketType,
      deliveryFlowType
    } = ticketPreviewDTO;

    return new TicketPreview({
      reference,
      createdAt,
      modifiedAt,
      deliveryTime,
      code,
      qrCode,
      skinImageUrl,
      ticketStatus,
      ticketType,
      deliveryFlowType
    });
  }
}

export { TicketPreviewDTO };
