interface IFees {
  fixedFee: number;
  minimumFixedFee: number;
  variableFee: number;
  currency: string;
}

export type JCurrencyFees = {
  sek: JFees;
  dkk: JFees;
  nok: JFees;
};

export type JFees = {
  fixedFee: number;
  minimumFixedFee: number;
  variableFee: number;
};

class Fees {
  readonly fixedFee: number;
  readonly minimumFixedFee: number;
  readonly variableFee: number;
  readonly currency: string;

  constructor(args: IFees) {
    const { fixedFee, minimumFixedFee, variableFee, currency } = args;

    this.fixedFee = fixedFee;
    this.minimumFixedFee = minimumFixedFee;
    this.variableFee = variableFee;
    this.currency = currency.toUpperCase();
  }

  static fromJSON(currency: string, json: JFees) {
    const { fixedFee, minimumFixedFee, variableFee } = json;

    return new this({
      currency,
      fixedFee,
      minimumFixedFee,
      variableFee
    });
  }
}

export { Fees };
