import { SimpleUser } from '../domain/User/SimpleUser';
import { User } from '../domain/User/User';
import { NewSellerUserDTO } from '../dto/User/NewSellerUserDTO';
import { UpdateUserDTO } from '../dto/User/UpdateUserDTO';

class UserManager {
  _coreClient;

  _sellerStore;

  constructor(coreClient, sellerStore) {
    this._coreClient = coreClient;
    this._sellerStore = sellerStore;
  }

  getUsersForSeller = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    const users = (await coreClient.getSellerUsers(sellerReference))?.map(
      jsonUser => SimpleUser.fromJSON(jsonUser)
    );

    return users ?? [];
  };

  searchUsers = async query => {
    const { _coreClient: coreClient } = this;
    const searchResult = await coreClient.searchUsers(query);

    return searchResult?.length
      ? searchResult.map(user => SimpleUser.fromJSON(user))
      : null;
  };

  getSellerUser = async userReference => {
    const { _coreClient: coreClient } = this;
    const jsonUser = await coreClient.getSellerUser(userReference);
    if (jsonUser) {
      return User.fromJSON(jsonUser);
    }

    return null;
  };

  createNewSellerUser = async newUser => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    const newSellerUserDTO = NewSellerUserDTO.fromNewUser(newUser);
    return coreClient.createNewSellerUser(sellerReference, newSellerUserDTO);
  };

  addExistingSellerUser = async userReference => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    return coreClient.addExistingSellerUser(sellerReference, userReference);
  };

  sendPasswordResetEmailForUser = async userReference => {
    const { _coreClient: coreClient } = this;

    return coreClient.sendPasswordResetEmailForUser(userReference);
  };

  updateUser = async (updateUserData, userReference) => {
    const { _coreClient: coreClient } = this;
    const updateUserDTO = UpdateUserDTO.fromUpdateUserData(updateUserData);
    return coreClient.updateUser(userReference, updateUserDTO);
  };
}

export { UserManager };
