import type { MutablePayoutSchedule } from '../../domain/Adyen/MutablePayoutSchedule';
import type { PayoutScheduleType } from '../../pages/settings/paymentMethods/AdyenForm/ManageAdyenAccount/_constants';

export interface IUpdateAdyenPayoutScheduleDTO {
  type: PayoutScheduleType;
}

export const UpdateAdyenPayoutScheduleDTO = {
  fromPayoutSchedule(
    payoutSchedule: MutablePayoutSchedule
  ): IUpdateAdyenPayoutScheduleDTO {
    const { type } = payoutSchedule;

    return {
      type
    };
  }
};
