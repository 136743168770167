import {
  WEIQ_BAPP_USER,
  WEIQ_REFUND_USER,
  WEIQ_REPORT_VIEWER,
  WEIQ_SELLER,
  WEIQ_TAPP_USER,
  WEIQ_VARIABLE_PRICE
} from '../../constants';

export const acceptedUserRoles = [
  WEIQ_BAPP_USER,
  WEIQ_REFUND_USER,
  WEIQ_REPORT_VIEWER,
  WEIQ_SELLER,
  WEIQ_TAPP_USER,
  WEIQ_VARIABLE_PRICE
];
