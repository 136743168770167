export type ClientResponseType<T> =
  | {
      response: T;
    }
  | {
      response: null;
      error: string;
    };

export type ClientResultType =
  | {
      success: true;
    }
  | {
      success: false;
      error?: string;
    };

export const ClientResponse = {
  success<T>(response: T): ClientResponseType<T> {
    return { response };
  },
  error: (error?: string): ClientResponseType<unknown> => {
    return {
      response: null,
      error
    };
  }
};

export const ClientResult = {
  success(): ClientResultType {
    return { success: true };
  },
  error: (error?: string): ClientResultType => {
    return {
      success: false,
      error
    };
  }
};
