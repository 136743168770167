import type { AdyenPaymentMethodType } from '../../pages/settings/paymentMethods/AdyenForm/ManageAdyenAccount/_constants';
import { type AdyenAccount } from '../Adyen/AdyenAccount';
import { PaymentMethodType } from './PaymentMethod';

interface IAdyenMethod {
  adyenAccount?: AdyenAccount;
  settings?: IAdyenMethodSettings;
}

interface IAdyenMethodSettings {
  acceptedTypes: AdyenPaymentMethodType[];
  tier: string;
  ippTier: string;
  storeReference?: string;
  storeActivatedAt?: string;
}

class AdyenMethod {
  readonly _adyenAccount?: AdyenAccount;
  readonly _settings?: IAdyenMethodSettings;

  constructor(args: IAdyenMethod) {
    const { adyenAccount, settings } = args;

    this._adyenAccount = adyenAccount;
    this._settings = settings;
  }

  get adyenAccount() {
    return this._adyenAccount;
  }

  // TODO: Remove after migration
  get isClassic() {
    return !this._adyenAccount && !!this._settings;
  }

  get hasSettings() {
    return !!this._settings;
  }

  get bankAccountConnected() {
    return !!this._adyenAccount?.payoutSchedules?.length;
  }

  get terminalsActivated() {
    return (
      !!this._settings?.storeReference && !!this._settings?.storeActivatedAt
    );
  }

  get terminalsRequested() {
    return (
      !!this._settings?.storeReference && !this._settings?.storeActivatedAt
    );
  }

  get acceptedTypes() {
    return this._settings?.acceptedTypes;
  }

  get storeReference() {
    return this._settings?.storeReference;
  }

  get tier() {
    return this._settings?.tier;
  }

  get ippTier() {
    return this._settings?.ippTier;
  }

  get type() {
    return PaymentMethodType.ADYEN;
  }
}

export { AdyenMethod };
