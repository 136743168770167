import 'reflect-metadata';
import { computed } from 'mobx';

const JSON_SERIALIZER = 'JSON_SERIALIZER';

export function Getter(args) {
  if (typeof args === 'function') {
    // @Getter
    return NormalGetter(args);
  } else if (args.computed) {
    // @Getter({ computed: true })
    return ComputedGetter;
  } else {
    // @Getter({ computed: false })
    return NormalGetter;
  }
}

/*
 * Adds getters for all private fields (any field starting with `_`)
 * For example, this class
 *
 * @Getter
 * class Example {
 *   _name = '';
 * }
 *
 * will have a getter `name`, so that new Example().name is valid.
 */
function NormalGetter(Target) {
  function _constructor(...args) {
    const instance = Reflect.construct(Target, args);

    return new Proxy(instance, {
      get(target, property) {
        if (
          !Reflect.has(target, property) &&
          typeof property === 'string' &&
          property.charAt(0) !== '_' &&
          Reflect.has(target, `_${property}`)
        ) {
          return Reflect.get(target, `_${property}`);
        } else {
          return Reflect.get(target, property);
        }
      }
    });
  }

  // Add all static methods
  addStaticMethods(Target, _constructor);
  return _constructor;
}

/*
 * Adds getters for all private fields (any field starting with `_`).
 * The getters will be created using mobx's `computed` feature,
 * making it possible to observe them for changes, if the original fields
 * are observable.
 * For example, this class
 *
 * @Getter({ computed: true })
 * class Example {
 *   @observable
 *   _name = '';
 * }
 *
 * will have a computed getter `name`, so that new Example().name is valid,
 * and can be observed by an `observer` class.
 */
function ComputedGetter(Target) {
  const _constructor = function _(...args) {
    const computedProps = {};

    return new Proxy(new Target(...args), {
      get(target, property) {
        if (
          !Reflect.has(target, property) &&
          typeof property === 'string' &&
          property.charAt(0) !== '_' &&
          Reflect.has(target, `_${property}`)
        ) {
          if (computedProps[property]) {
            return computedProps[property].get();
          } else {
            const computedProp = computed(() =>
              Reflect.get(target, `_${property}`)
            );
            computedProps[property] = computedProp;
            return computedProp.get();
          }
        }

        return Reflect.get(target, property);
      }
    });
  };

  // Add all static methods
  addStaticMethods(Target, _constructor);
  return _constructor;
}

export function Setter(args) {
  if (typeof args === 'function') {
    // @Setter
    return SetterFields(args);
  } else if (args.functions) {
    // @Setter({ functions: true })
    return SetterFunctions;
  } else {
    // @Setter({ functions: false })
    return SetterFields;
  }
}

/*
 * Adds setters for all private fields (any field starting with `_`)
 * For example, this class
 *
 * @Setter
 * class Example {
 *   _name = '';
 * }
 *
 * will have a setter `name=`, so that new Example().name = 'A name' is valid.
 */
function SetterFields(Target) {
  const _constructor = function _(...args) {
    return new Proxy(new Target(...args), {
      set(target, property, value) {
        if (property.charAt(0) !== '_' && Reflect.has(target, `_${property}`)) {
          return Reflect.set(target, `_${property}`, value);
        } else {
          return Reflect.set(target, property, value);
        }
      }
    });
  };

  // Add all static methods
  addStaticMethods(Target, _constructor);
  return _constructor;
}

/*
 * Adds setter functions for all private fields (any field starting with `_`)
 * For example, this class
 *
 * @Setter({ functions: true })
 * class Example {
 *   _name = '';
 * }
 *
 * will have a function `setName(name)`, so that new Example().setName('A name') is valid.
 */
function SetterFunctions(Target) {
  const _constructor = function _(...args) {
    return new Proxy(new Target(...args), {
      get(target, property) {
        if (typeof property === 'string') {
          const start = property.substr(0, 3);
          const fieldName =
            property.charAt(3).toLowerCase() + property.slice(4);

          if (!Reflect.has(target, property)) {
            if (start === 'set' && fieldName.charAt(0) !== '_') {
              if (Reflect.has(target, `_${fieldName}`)) {
                return value => Reflect.set(target, `_${fieldName}`, value);
              }
            }
          }
        }

        return Reflect.get(target, property);
      }
    });
  };

  // Add all static methods
  addStaticMethods(Target, _constructor);
  return _constructor;
}

function addStaticMethods(source, destination) {
  for (const [name, descriptor] of Object.entries(
    Object.getOwnPropertyDescriptors(source)
  )) {
    if (
      typeof descriptor.value === 'function' ||
      typeof descriptor.get === 'function' ||
      typeof descriptor.set === 'function'
    ) {
      Object.defineProperty(destination, name, descriptor);
    }
  }
}

export function ToJSON(Target) {
  Target.prototype.toJSON = function _() {
    const privateProperties = Object.getOwnPropertyNames(this).filter(
      property => typeof property === 'string' && property.charAt(0) === '_'
    );

    return privateProperties.reduce((accumulator, property) => {
      const publicName = property.slice(1);
      const serializer =
        Reflect.getMetadata(JSON_SERIALIZER, this, property) ||
        _identityOrToJSON;

      accumulator[publicName] = serializer(Reflect.get(this, property));
      return accumulator;
    }, {});
  };

  return Target;
}

// field decorator
export function JSONSerializer(serializer) {
  return function _(target, property) {
    Reflect.defineMetadata(JSON_SERIALIZER, serializer, target, property);
  };
}

function _identityOrToJSON(value) {
  if (Array.isArray(value)) {
    return value.map(_identityOrToJSON);
  } else if (
    typeof value === 'object' &&
    value !== null &&
    Reflect.has(value, 'toJSON')
  ) {
    const serializer = Reflect.get(value, 'toJSON');
    return typeof serializer === 'function' ? serializer.call(value) : value;
  } else {
    return value;
  }
}
