import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import HorizontalLayout from '../layouts/Horizontal/index';
import VerticalLayout from '../layouts/Vertical';
import StaticLayout from '../layouts/Static';
import { baseManager } from '../managers/Manager';
import {
  WEIQ_ADMIN,
  WEIQ_REPORT_VIEWER,
  WEIQ_SELLER,
  WEIQ_TECH_URL
} from '../constants';

const { authManager, sellerManager, productRegisterManager, onboardManager } =
  baseManager;

/*
 * A route that requires the user to be authenticated,
 * and that a seller with a product register has been selected.
 *
 * If the user is not authenticated, it will be redirected to the
 * login page.
 *
 * If no seller has been selected, we will attempt to use the last
 * selected seller from storage. If that fails, we will redirect to the
 * select seller screen, unless the user only can manage one seller, in
 * which case that seller will be selected.
 */
export const SellerRoute = ({ component, layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <LoadingWrapper layout={layout} component={component} {...props} />
    )}
  />
);

class LoadingWrapper extends React.Component {
  state = {
    initialized: false
  };

  async componentDidMount() {
    const { selectedSellerReference, productRegisterReference } = sellerManager;
    const { isAuthorized } = this;
    const { history } = this.props;

    if (isAuthorized) {
      if (!selectedSellerReference) {
        await sellerManager.fetchManagedSellers();
        const storedSellerRef = localStorage.getItem('selectedSellerReference');

        if (
          storedSellerRef &&
          sellerManager.getManagedSeller(storedSellerRef)
        ) {
          sellerManager.selectSeller(storedSellerRef);
        } else if (sellerManager.managedSellers.length === 1) {
          // The user only has access to one seller. Select it.
          sellerManager.selectSeller(
            sellerManager.managedSellers[0].sellerReference
          );
        } else {
          // Go to select seller screen
          history.push('/sellers');
          return;
        }
      }

      if (onboardManager.sellerIsOnboarding === null) {
        await onboardManager.fetchWelcomeTasks();
      }

      if (!productRegisterReference) {
        const result = await productRegisterManager.fetchProductRegister();
        if (!result) {
          // Go to select seller screen
          history.push('/sellers');
          return;
        }
      }

      this.setState({ initialized: true });
    }
  }

  get isAuthorized() {
    const { authenticated, primaryRole } = authManager;
    const { allowedRoles } = this;

    if (!authenticated) {
      return false;
    }

    return allowedRoles.includes(primaryRole);
  }

  get allowedRoles() {
    return [WEIQ_ADMIN, WEIQ_SELLER, WEIQ_REPORT_VIEWER];
  }

  get Layout() {
    const { layout } = this.props;

    switch (layout) {
      case 'horizontal':
        return HorizontalLayout;
      case 'vertical':
        return VerticalLayout;
      case 'static':
        return StaticLayout;
      default:
        return React.Fragment;
    }
  }

  render() {
    const { component: Component, layout: _layout, ...rest } = this.props;
    const { location } = rest;
    const { Layout, isAuthorized } = this;
    const { initialized } = this.state;

    if (!authManager.authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location.pathname }
          }}
        />
      );
    } else if (!isAuthorized) {
      return (
        <Redirect
          to={{
            pathname: '/unauthorized',
            state: { redirectTo: WEIQ_TECH_URL }
          }}
        />
      );
    }

    const children = initialized ? <Component {...rest} /> : <></>;
    return <Layout>{children}</Layout>;
  }
}
