import type { IDeliveryFlowTypeRow } from './DeliveryFlowTypeRow';
import { DeliveryFlowTypeRow } from './DeliveryFlowTypeRow';
import type { JDeliveryFlowTypeSummaryRow } from './DeliveryFlowTypeSummaryRow';
import { DeliveryFlowTypeSummaryRow } from './DeliveryFlowTypeSummaryRow';

interface IDeliveryFlowTypeSection {
  salesRows: DeliveryFlowTypeRow[];
  summaryRow: DeliveryFlowTypeSummaryRow;
}

export interface JDeliveryFlowTypeSection {
  salesRows: IDeliveryFlowTypeRow[];
  summaryRow: JDeliveryFlowTypeSummaryRow;
}

type DeliveryFlowTypeSectionRow =
  | DeliveryFlowTypeRow
  | DeliveryFlowTypeSummaryRow;

interface DeliveryFlowTypeSection extends IDeliveryFlowTypeSection {}
class DeliveryFlowTypeSection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: IDeliveryFlowTypeSection) {
    const { salesRows, summaryRow } = args;
    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): DeliveryFlowTypeSectionRow[] => {
    const { salesRows, summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JDeliveryFlowTypeSection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => DeliveryFlowTypeRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: DeliveryFlowTypeSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: DeliveryFlowTypeSectionRow) {
    return row.key;
  }
}

export { DeliveryFlowTypeSection };
