import type { MonetaryAmount } from '../Amount/MonetaryAmount';
import { OrderMethods } from '../Order/OrderMethods';
import type { OrderLineItemPreview } from './OrderLineItemPreview';
import type { PaymentPreview } from './PaymentPreview';
import type { TicketPreview } from './TicketPreview';

export interface IOrderPreview {
  reference: string;
  status: string;
  initialDebt: MonetaryAmount;
  deliveryLocationOption: string | null;
  createdAt: string;
  closedAt: string | null;
  ticket: TicketPreview;
  orderLineItems: OrderLineItemPreview[];
  payments: PaymentPreview[];
  tip: number;
}

class OrderPreview extends OrderMethods {
  readonly reference;
  readonly status;
  readonly initialDebt;
  readonly deliveryLocationOption;
  readonly createdAt;
  readonly closedAt;
  readonly ticket;
  readonly orderLineItems;
  readonly payments;
  readonly tip;

  constructor(args: IOrderPreview) {
    const {
      reference,
      status,
      initialDebt,
      deliveryLocationOption,
      createdAt,
      closedAt,
      ticket,
      orderLineItems,
      payments,
      tip
    } = args;
    super();

    this.reference = reference;
    this.status = status;
    this.initialDebt = initialDebt;
    this.deliveryLocationOption = deliveryLocationOption;
    this.createdAt = createdAt;
    this.closedAt = closedAt;
    this.ticket = ticket;
    this.orderLineItems = orderLineItems;
    this.payments = payments;
    this.tip = tip;
  }

  get currentPayment() {
    return this.payments.at(-1);
  }

  protected get ticketStatus() {
    return this.ticket.ticketStatus;
  }

  protected get ticketType() {
    return this.ticket.ticketType;
  }
}

export { OrderPreview };
