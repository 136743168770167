import { NO_PAYMENT } from '../constants';
import { CatalogSalesEntry } from '../domain/CatalogSales/CatalogSalesEntry';
import { CategorySalesEntry } from '../domain/CategorySales/CategorySalesEntry';
import { DeliveryFlowTypeSalesEntry } from '../domain/DeliveryFlowTypeSales/DeliveryFlowTypeSalesEntry';
import { PaymentTypeSalesEntry } from '../domain/PaymentTypeSales/PaymentTypeSalesEntry';
import { ProductTopListEntry } from '../domain/ProductTopList/ProductTopListEntry';
import { RatingOverview } from '../domain/RatingOverview/RatingOverview';
import { RecentSales } from '../domain/RecentSales/RecentSales';
import { SalesGraph } from '../domain/SalesGraph/SalesGraph';

const DONUT_CHART_CONFIG_1 = 'DONUT_CHART_CONFIG_1';
const DONUT_CHART_CONFIG_2 = 'DONUT_CHART_CONFIG_2';

class DashboardManager {
  _coreClient;

  _riceClient;

  _sellerStore;

  constructor(coreClient, riceClient, sellerStore) {
    this._coreClient = coreClient;
    this._riceClient = riceClient;
    this._sellerStore = sellerStore;
  }

  getSalesGraph = async (from, to) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonSalesGraph = await riceClient.getSalesGraph(
      sellerReference,
      from,
      to
    );

    return jsonSalesGraph ? SalesGraph.fromJSON(jsonSalesGraph) : null;
  };

  getRecentSales = async () => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    const jsonRecentSales = await riceClient.getRecentSales(sellerReference);

    return jsonRecentSales ? RecentSales.fromJSON(jsonRecentSales) : null;
  };

  getRatingOverview = async () => {
    const { _coreClient: coreClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;
    const jsonRatingOverview =
      await coreClient.getRatingOverview(sellerReference);

    return jsonRatingOverview
      ? RatingOverview.fromJSON(jsonRatingOverview)
      : null;
  };

  getProductTopList = async (from, to, limit = 10) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonProductTopList = await riceClient.getProductTopList(
      sellerReference,
      from,
      to,
      limit
    );

    if (jsonProductTopList) {
      const { sales } = jsonProductTopList;
      return sales.map(entry => ProductTopListEntry.fromJSON(entry));
    }
    return null;
  };

  getDeliveryFlowTypeSales = async (from, to) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonDeliveryFlowTypeSales = await riceClient.getDeliveryFlowTypeSales(
      sellerReference,
      from,
      to
    );

    if (jsonDeliveryFlowTypeSales) {
      const { sales } = jsonDeliveryFlowTypeSales;

      return sales.map(entry => DeliveryFlowTypeSalesEntry.fromJSON(entry));
    }
    return null;
  };

  getCategorySales = async (from, to, limit = 5) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonCategorySales = await riceClient.getCategorySales(
      sellerReference,
      from,
      to,
      limit
    );

    if (jsonCategorySales) {
      const { sales } = jsonCategorySales;

      return sales.map(entry => CategorySalesEntry.fromJSON(entry));
    }
    return null;
  };

  getPaymentTypeSales = async (from, to) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonPaymentTypeSales = await riceClient.getPaymentTypeSales(
      sellerReference,
      from,
      to
    );

    if (jsonPaymentTypeSales) {
      const { sales } = jsonPaymentTypeSales;

      return sales
        .map(entry => PaymentTypeSalesEntry.fromJSON(entry))
        .filter(entry => entry.paymentType !== NO_PAYMENT);
    }
    return null;
  };

  getCatalogSales = async (from, to, limit = 5) => {
    const { _riceClient: riceClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonCatalogSales = await riceClient.getCatalogSales(
      sellerReference,
      from,
      to,
      limit
    );

    if (jsonCatalogSales) {
      const { sales } = jsonCatalogSales;

      return sales.map(entry => CatalogSalesEntry.fromJSON(entry));
    }
    return null;
  };

  setFirstDonutChartConfiguration(config) {
    localStorage.setItem(DONUT_CHART_CONFIG_1, config);
  }

  setSecondDonutChartConfiguration(config) {
    localStorage.setItem(DONUT_CHART_CONFIG_2, config);
  }

  getDonutChartConfigurations() {
    const donutChartConfig1 = localStorage.getItem(DONUT_CHART_CONFIG_1);
    const donutChartConfig2 = localStorage.getItem(DONUT_CHART_CONFIG_2);
    return [donutChartConfig1, donutChartConfig2];
  }
}

export { DashboardManager };
