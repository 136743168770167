import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.ROW;

export interface IOverviewRow {
  overviewType: string;
  amountVatExcluded: string;
  amountVat: string;
  amount: string;
}

interface OverviewRow extends IOverviewRow {}
class OverviewRow {
  readonly overviewType;
  readonly amountVatExcluded;
  readonly amountVat;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IOverviewRow) {
    const { overviewType, amountVatExcluded, amountVat, amount } = args;

    this.overviewType = overviewType;
    this.amountVatExcluded = amountVatExcluded;
    this.amountVat = amountVat;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: IOverviewRow) {
    return new this(json);
  }
}

export { OverviewRow };
