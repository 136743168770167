import type { JOrderLineItem } from './OrderLineItem';
import { OrderLineItem } from './OrderLineItem';
import type { JTicket } from './Ticket';
import { Ticket } from './Ticket';
import type { IReceipt } from './Receipt';
import { Receipt } from './Receipt';
import type { IAmount } from '../Amount/Amount';
import { Amount } from '../Amount/Amount';
import type { IMonetaryAmount } from '../Amount/MonetaryAmount';
import { MonetaryAmount } from '../Amount/MonetaryAmount';
import type { IPayment } from './Payment';
import { OrderMethods } from './OrderMethods';
import { INACTIVE } from '../../constants';

interface IOrder {
  reference: string;
  orderLineItems: OrderLineItem[];
  payments: IPayment[];
  tickets: Ticket[];
  receipts: Receipt[];
  initialDebt: Amount;
  openDebt: Amount;
  status: string;
  sellerReference: string;
  createdAt: string;
  closedAt: string;
  deliveryLocationOption: string | null;
  discountCodeReference: string | null;
  emailAddress: string | null;
  shortReference: string;
  totalDiscountAmount: MonetaryAmount;
  totalProductAmount: MonetaryAmount;
  totalTipAmount: MonetaryAmount;
  updatedAt: string;
  userReference: string;
  version: number;
  tabReference: string | null;
}

// TODO: Move to OrderDTO
export interface JOrder {
  reference: string;
  orderLineItems: JOrderLineItem[];
  payments: IPayment[];
  tickets: JTicket[];
  receipts: IReceipt[];
  initialDebt: IAmount;
  openDebt: IAmount;
  status: string;
  sellerReference: string;
  createdAt: string;
  closedAt: string;
  deliveryLocationOption: string | null;
  discountCodeReference: string | null;
  emailAddress: string | null;
  shortReference: string;
  totalDiscountAmount: IMonetaryAmount;
  totalProductAmount: IMonetaryAmount;
  totalTipAmount: IMonetaryAmount;
  updatedAt: string;
  userReference: string;
  version: number;
  tabReference: string | null;
}

class Order extends OrderMethods {
  readonly reference;
  readonly orderLineItems;
  readonly payments;
  readonly tickets;
  readonly receipts;
  readonly initialDebt;
  readonly openDebt;
  readonly status;
  readonly sellerReference;
  readonly createdAt;
  readonly closedAt;
  readonly deliveryLocationOption;
  readonly discountCodeReference;
  readonly emailAddress;
  readonly shortReference;
  readonly totalDiscountAmount;
  readonly totalProductAmount;
  readonly totalTipAmount;
  readonly updatedAt;
  readonly userReference;
  readonly version;
  readonly tabReference;

  constructor(args: IOrder) {
    const {
      reference,
      orderLineItems,
      payments,
      tickets,
      receipts,
      initialDebt,
      openDebt,
      status,
      sellerReference,
      createdAt,
      closedAt,
      deliveryLocationOption,
      discountCodeReference,
      emailAddress,
      shortReference,
      totalDiscountAmount,
      totalProductAmount,
      totalTipAmount,
      updatedAt,
      userReference,
      version,
      tabReference
    } = args;
    super();

    this.reference = reference;
    this.receipts = receipts;
    this.initialDebt = initialDebt;
    this.openDebt = openDebt;
    this.status = status;
    this.sellerReference = sellerReference;
    this.payments = payments;
    this.orderLineItems = orderLineItems;
    this.tickets = tickets;
    this.createdAt = createdAt;
    this.closedAt = closedAt;
    this.deliveryLocationOption = deliveryLocationOption;
    this.discountCodeReference = discountCodeReference;
    this.emailAddress = emailAddress;
    this.shortReference = shortReference;
    this.totalDiscountAmount = totalDiscountAmount;
    this.totalProductAmount = totalProductAmount;
    this.totalTipAmount = totalTipAmount;
    this.updatedAt = updatedAt;
    this.userReference = userReference;
    this.version = version;
    this.tabReference = tabReference;
  }

  get currentPayment() {
    return this.payments.at(-1);
  }

  protected get ticketStatus() {
    return this.tickets.at(0)?.ticketStatus ?? INACTIVE;
  }

  protected get ticketType() {
    return this.tickets.at(0)?.type;
  }

  static fromJSON(json: JOrder) {
    const {
      orderLineItems: jsonOrderLineItems,
      tickets: jsonTickets,
      receipts: jsonReceipts,
      initialDebt: jsonInitialDebt,
      openDebt: jsonOpenDebt,
      totalDiscountAmount: jsonTotalDiscountAmount,
      totalProductAmount: jsonTotalProductAmount,
      totalTipAmount: jsonTotalTipAmount,
      ...rest
    } = json;

    const { reference: orderReference } = json;
    const orderLineItems = jsonOrderLineItems.map(jsonLineItem =>
      OrderLineItem.fromJSON(jsonLineItem)
    );

    const ticketToLineItemsMap = this.buildTicketToLineItemsMap(
      jsonTickets,
      orderLineItems
    );
    const tickets = jsonTickets.map(jsonTicket => {
      const { reference } = jsonTicket;
      const lineItemsForTicket = ticketToLineItemsMap[reference] ?? [];

      return Ticket.fromJSON(jsonTicket, orderReference, lineItemsForTicket);
    });

    const receipts = jsonReceipts.map(jsonReceipt =>
      Receipt.fromJSON(jsonReceipt)
    );
    const initialDebt = Amount.fromJSON(jsonInitialDebt);
    const openDebt = Amount.fromJSON(jsonOpenDebt);
    const totalDiscountAmount = MonetaryAmount.fromJSON(
      jsonTotalDiscountAmount
    );
    const totalProductAmount = MonetaryAmount.fromJSON(jsonTotalProductAmount);
    const totalTipAmount = MonetaryAmount.fromJSON(jsonTotalTipAmount);
    return new this({
      orderLineItems,
      tickets,
      receipts,
      initialDebt,
      openDebt,
      totalDiscountAmount,
      totalProductAmount,
      totalTipAmount,
      ...rest
    });
  }

  static buildTicketToLineItemsMap(
    tickets: JTicket[],
    orderLineItems: OrderLineItem[]
  ) {
    const map: Record<string, OrderLineItem[]> = {};
    for (const ticket of tickets) {
      const { reference: ticketReference } = ticket;
      map[ticketReference] = [];
    }

    for (const orderLineItem of orderLineItems) {
      const { ticketReference } = orderLineItem;
      if (ticketReference && ticketReference in map) {
        map[ticketReference]!.push(orderLineItem);
      } else if (ticketReference) {
        console.error(
          `OrderLineItem has ticket reference ${ticketReference},
          but no such ticket exists on the order.`
        );
      }
    }

    return map;
  }
}

export { Order as default };
