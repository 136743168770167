import { Getter, ToJSON } from '../../Decorators';
import OpeningHour from './OpeningHour';

@Getter
@ToJSON
class OpeningHourMap {
  _MONDAY;

  _TUESDAY;

  _WEDNESDAY;

  _THURSDAY;

  _FRIDAY;

  _SATURDAY;

  _SUNDAY;

  constructor(args) {
    const {
      MONDAY = null,
      TUESDAY = null,
      WEDNESDAY = null,
      THURSDAY = null,
      FRIDAY = null,
      SATURDAY = null,
      SUNDAY = null
    } = args;

    this._MONDAY = MONDAY;
    this._TUESDAY = TUESDAY;
    this._WEDNESDAY = WEDNESDAY;
    this._THURSDAY = THURSDAY;
    this._FRIDAY = FRIDAY;
    this._SATURDAY = SATURDAY;
    this._SUNDAY = SUNDAY;
  }

  static fromJSON(openingHourMapJson) {
    return new this(
      Object.fromEntries(
        Object.entries(openingHourMapJson).map(([day, jsonOpeningHours]) => [
          day,
          Object.freeze(jsonOpeningHours?.map(o => OpeningHour.fromJSON(o)))
        ])
      )
    );
  }
}

export { OpeningHourMap as default };
