export interface IPaymentPreview {
  reference: string;
  type: string;
  state: string;
  method: string | null;
  separateCapture: boolean;
}

class PaymentPreview {
  readonly reference;
  readonly type;
  readonly state;
  readonly method;
  readonly separateCapture;

  constructor(args: IPaymentPreview) {
    const { reference, type, state, method, separateCapture } = args;

    this.reference = reference;
    this.type = type;
    this.state = state;
    this.method = method;
    this.separateCapture = separateCapture;
  }
}

export { PaymentPreview };
