import { Getter } from '../Decorators';

@Getter
class CategorySalesEntry {
  _categoryName;

  _totalAmount;

  constructor(args) {
    const { categoryName, totalAmount } = args;

    this._categoryName = categoryName;
    this._totalAmount = totalAmount;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { CategorySalesEntry };
