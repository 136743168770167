import type { ICategoryRow } from './CategoryRow';
import { CategoryRow } from './CategoryRow';
import type { JCategorySummaryRow } from './CategorySummaryRow';
import { CategorySummaryRow } from './CategorySummaryRow';

export interface ICategorySection {
  salesRows: CategoryRow[];
  summaryRow: CategorySummaryRow;
}

export interface JCategorySection {
  salesRows: ICategoryRow[];
  summaryRow: JCategorySummaryRow;
}

type CategorySectionRow = CategoryRow | CategorySummaryRow;

interface CategorySection extends ICategorySection {}
class CategorySection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: ICategorySection) {
    const { salesRows, summaryRow } = args;

    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): CategorySectionRow[] => {
    const { salesRows, summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JCategorySection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => CategoryRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: CategorySummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: CategorySectionRow) {
    return row.key;
  }
}

export { CategorySection };
