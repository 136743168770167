import type {
  DiscountLimitation,
  JDiscountLimitation
} from './DiscountLimitation';
import { DiscountLimitationMapper } from './DiscountLimitation';
import type { JDiscountModel, DiscountModel } from './DiscountModel';
import { DiscountModelMapper } from './DiscountModel';

interface IDiscount {
  reference: string;
  name: string;
  description: string;
  model: DiscountModel;
  limitations: DiscountLimitation[];
}

export type JDiscount = Omit<IDiscount, 'model' | 'limitations'> & {
  model: JDiscountModel;
  limitations: JDiscountLimitation[];
};

class Discount {
  readonly reference;
  readonly name;
  readonly description;
  readonly model;
  readonly limitations;

  constructor(args: IDiscount) {
    const { reference, name, description, model, limitations } = args;

    this.reference = reference;
    this.name = name;
    this.description = description;
    this.model = model;
    this.limitations = limitations;
  }

  static fromJSON = (json: JDiscount) => {
    const { model: jsonModel, limitations: jsonLimitations, ...rest } = json;
    const model = DiscountModelMapper.fromJSON(jsonModel);
    const limitations = jsonLimitations?.map(jsonLimitation =>
      DiscountLimitationMapper.fromJSON(jsonLimitation)
    );

    return new this({
      model,
      limitations,
      ...rest
    });
  };
}

export { Discount };
