import type { IVatRateRow } from './VatRateRow';
import { VatRateRow } from './VatRateRow';
import type { JVatRateSummaryRow } from './VatRateSummaryRow';
import { VatRateSummaryRow } from './VatRateSummaryRow';

interface IVatRateSection {
  salesRows: VatRateRow[];
  summaryRow: VatRateSummaryRow;
}

export interface JVatRateSection {
  salesRows: IVatRateRow[];
  summaryRow: JVatRateSummaryRow;
}

type VatRateSectionRow = VatRateRow | VatRateSummaryRow;

interface VatRateSection extends IVatRateSection {}
class VatRateSection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: IVatRateSection) {
    const { salesRows, summaryRow } = args;

    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): VatRateSectionRow[] => {
    const { salesRows, summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JVatRateSection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => VatRateRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: VatRateSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: VatRateSectionRow) {
    return row.key;
  }
}

export { VatRateSection };
