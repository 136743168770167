import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreatePACPaymentMethodDTO {
  _sellerReference;

  constructor(args) {
    const { sellerReference } = args;

    this._sellerReference = sellerReference;
  }

  static fromSellerReference(sellerReference) {
    return new this({ sellerReference });
  }
}
export { CreatePACPaymentMethodDTO };
