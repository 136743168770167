import { action, computed, observable } from 'mobx';
import { stringNotEmpty } from '../../helpers/Functions';
import { Getter, Setter } from '../Decorators';
import { ObservableSet } from '../GeneralSet';
import { DeliveryFlowType } from './DeliveryFlowType';
import { DeliveryLocationType } from './DeliveryLocationType';
import { EditDeliveryLocationDefinition } from './EditDeliveryLocationDefinition';
import { EditDeliveryTimeOptions } from './EditDeliveryTimeOptions';

@Setter({ functions: true })
@Getter({ computed: true })
class EditDeliveryLocation {
  @observable
  _name;

  @observable
  _description;

  @observable
  _open;

  @observable
  _qrEnabled;

  @observable
  _deliveryLocationDefinition;

  @observable
  _deliveryTimeOptions;

  @observable
  _active;

  @observable
  _deliveryFlowType;

  @observable
  _autoConfirmOrders;

  @observable
  _autoCaptureDelay;

  @observable
  _availableOnCatalogs;

  @observable
  _type;

  @observable
  _validationSkin;

  @observable
  _code;

  @observable
  _tabsEnabled;

  constructor(args = {}) {
    const {
      name = '',
      description = '',
      open = true,
      qrEnabled = false,
      deliveryLocationDefinition = null,
      deliveryTimeOptions = null,
      active = true,
      deliveryFlowType = DeliveryFlowType.TABLE_DELIVERY,
      autoConfirmOrders = false,
      autoCaptureDelay = 'PT3H',
      availableOnCatalogs = null,
      type = DeliveryLocationType.PRODUCT_DELIVERY_LOCATION,
      validationSkin,
      code,
      tabsEnabled = false
    } = args;

    this._name = name;
    this._description = description;
    this._open = open;
    this._qrEnabled = qrEnabled;
    this._deliveryLocationDefinition = deliveryLocationDefinition;
    this._deliveryTimeOptions = deliveryTimeOptions;
    this._active = active;
    this._deliveryFlowType = deliveryFlowType;
    this._autoConfirmOrders = autoConfirmOrders;
    this._autoCaptureDelay = autoCaptureDelay;
    this._availableOnCatalogs = availableOnCatalogs;
    this._type = type;
    this._validationSkin = validationSkin;
    this._code = code;
    this._tabsEnabled = tabsEnabled;
  }

  @action
  setFlowTypeToTableDelivery = () => {
    this._deliveryFlowType = DeliveryFlowType.TABLE_DELIVERY;
  };

  @action
  setFlowTypeToPickup = () => {
    this._deliveryFlowType = DeliveryFlowType.PICK_UP;
  };

  @action
  setFlowTypeToTakeAway = () => {
    this._deliveryFlowType = DeliveryFlowType.TAKE_AWAY;
  };

  @computed
  get isValid() {
    const {
      _name: name,
      _description: description,
      _type: type,
      _code: code,
      _validationSkin: validationSkin
    } = this;

    if (type === DeliveryLocationType.ENTRY_TICKET_DELIVERY_LOCATION) {
      return (
        stringNotEmpty(name) &&
        stringNotEmpty(description) &&
        stringNotEmpty(code) &&
        stringNotEmpty(validationSkin) &&
        code?.length === 4
      );
    } else {
      return stringNotEmpty(name) && stringNotEmpty(description);
    }
  }

  @computed
  get isProductLocation() {
    const { _type: type } = this;
    return type === DeliveryLocationType.PRODUCT_DELIVERY_LOCATION;
  }

  @computed
  get isEntryTicketLocation() {
    const { _type: type } = this;
    return type === DeliveryLocationType.ENTRY_TICKET_DELIVERY_LOCATION;
  }

  static fromDeliveryLocation(deliveryLocation) {
    const {
      name,
      description,
      open,
      qrEnabled,
      deliveryLocationDefinition: definition,
      deliveryTimeOptions: timeOptions,
      active,
      deliveryFlowType,
      autoConfirmOrders,
      autoCaptureDelay: delay,
      availableOnCatalogs: catalogs,
      type,
      validationSkin,
      code,
      tabsEnabled
    } = deliveryLocation;

    const deliveryLocationDefinition =
      definition && EditDeliveryLocationDefinition.fromDefinition(definition);
    const deliveryTimeOptions =
      timeOptions &&
      EditDeliveryTimeOptions.fromDeliveryTimeOptions(timeOptions);
    const availableOnCatalogs = catalogs?.length
      ? new ObservableSet(catalogs)
      : null;
    const autoCaptureDelay = delay ?? '';

    return new this({
      name,
      description,
      open,
      qrEnabled,
      deliveryLocationDefinition,
      deliveryTimeOptions,
      active,
      deliveryFlowType,
      autoConfirmOrders,
      autoCaptureDelay,
      availableOnCatalogs,
      type,
      validationSkin,
      code,
      tabsEnabled
    });
  }
}

export { EditDeliveryLocation };
