import { registerLocale, setDefaultLocale } from 'react-datepicker';
import svDateLocale from 'date-fns/locale/sv';
import daDateLocale from 'date-fns/locale/da';
import enDateLocale from 'date-fns/locale/en-GB';
import { daDK, enGB, svSE } from '../constants';

const DATE_LOCALE_MAP = new Map([
  [svSE, svDateLocale],
  [daDK, daDateLocale],
  [enGB, enDateLocale]
]);

class DatePickerConfig {
  constructor(configStore) {
    configStore.addLocaleListener(this.onLocaleChanged);
    this.onLocaleChanged(configStore.locale);
  }

  onLocaleChanged = locale => {
    const dateLocale = DATE_LOCALE_MAP.get(locale);
    if (!dateLocale) {
      console.error('Could not find date locale for locale', locale);
      return;
    }

    registerLocale(locale, dateLocale);
    setDefaultLocale(locale, dateLocale);
  };
}

export { DatePickerConfig };
