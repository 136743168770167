import { action, observable } from 'mobx';
import { Getter, Setter } from '../Decorators';
import { ObservableSet } from '../GeneralSet';

@Setter({ functions: true })
@Getter({ computed: true })
class EditDeliveryTimeOptions {
  @observable
  _suggestedDurations;

  @observable
  _timeZone;

  @observable
  _allowCustomDeliveryTime;

  @observable
  _allowInstantDeliveryTime;

  constructor(args = {}) {
    const {
      suggestedDurations = new ObservableSet(),
      timeZone = 'Europe/Stockholm',
      allowCustomDeliveryTime = true,
      allowInstantDeliveryTime = true
    } = args;

    this._suggestedDurations = suggestedDurations;
    this._timeZone = timeZone;
    this._allowCustomDeliveryTime = allowCustomDeliveryTime;
    this._allowInstantDeliveryTime = allowInstantDeliveryTime;
  }

  @action
  toggleCustomDeliveryTime = () => {
    this._allowCustomDeliveryTime = !this._allowCustomDeliveryTime;
  };

  @action
  toggleInstantDeliveryTime = () => {
    this._allowInstantDeliveryTime = !this._allowInstantDeliveryTime;
  };

  static fromDeliveryTimeOptions(deliveryTimeOptions) {
    const {
      suggestedDurations,
      timeZone,
      allowCustomDeliveryTime,
      allowInstantDeliveryTime
    } = deliveryTimeOptions;

    return new this({
      suggestedDurations: new ObservableSet(suggestedDurations),
      timeZone,
      allowCustomDeliveryTime,
      allowInstantDeliveryTime
    });
  }
}

export { EditDeliveryTimeOptions };
