import type { AdyenAccount } from '../../domain/Adyen/AdyenAccount';
import type { PayoutScheduleType } from '../../pages/settings/paymentMethods/AdyenForm/ManageAdyenAccount/_constants';

export interface IAdyenAccountDTO {
  createdAt: string;
  reference: string;
  sellerReference: string;
  legalEntityId: string;
  accountHolderId: string;
  balanceAccountId: string;
  salesDayClosingHour: number;
  settlementDelayDays: number;
  balance: IAdyenAccountBalanceDTO;
  payoutSchedules: IAdyenPayoutScheduleDTO[];
}

interface IAdyenAccountBalanceDTO {
  balance: number;
  available: number;
  reserved: number;
  pending: number;
}

interface IAdyenPayoutScheduleDTO {
  scheduleId: string;
  type: PayoutScheduleType;
  bankAccountNumber: string;
}

export const AdyenAccountDTO = {
  toAdyenAccount: function (dto: IAdyenAccountDTO): AdyenAccount {
    return dto;
  }
};
