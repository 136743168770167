import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseManager } from '../managers/Manager';

const { routerManager } = baseManager;

function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    routerManager.setCurrentPath(location?.pathname);
  }, [location]);

  return null;
}

export { RouteTracker as default };
