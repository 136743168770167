import { observable } from 'mobx';
import { Getter, Setter, ToJSON } from '../Decorators';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class EditProductImages {
  @observable
  _thumbnail = null;

  @observable
  _medium = null;

  @observable
  _large = null;

  @observable
  _small = null;

  constructor(args = {}) {
    const { thumbnail, medium, large, small } = args;

    this._thumbnail = thumbnail;
    this._medium = medium;
    this._large = large;
    this._small = small;
  }

  static fromProductImages(productImages) {
    const { thumbnailImageUrl, mediumImageUrl, largeImageUrl, smallImageUrl } =
      productImages;

    return new this({
      thumbnail: thumbnailImageUrl,
      medium: mediumImageUrl,
      large: largeImageUrl,
      small: smallImageUrl
    });
  }
}

export { EditProductImages as default };
