import { Getter, ToJSON } from '../../Decorators';

@Getter
@ToJSON
class OpeningHour {
  _fromInclusive;

  _toInclusive;

  constructor(args) {
    const { fromInclusive, toInclusive } = args;

    this._fromInclusive = fromInclusive;
    this._toInclusive = toInclusive;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { OpeningHour as default };
