import type { MutableTerminalOrderLineItem } from '../../domain/Terminal/MutableTerminalOrderLineItem';

export interface IAdyenOrderLineItemDTO {
  id: string;
  name: string;
  quantity: number;
}

export const AdyenOrderLineItemDTO = {
  fromItem: (item: MutableTerminalOrderLineItem): IAdyenOrderLineItemDTO => {
    const { id, name, quantity } = item;

    return {
      id,
      name,
      quantity
    };
  }
};
