import React from 'react';
import { notNullUndefined } from '../helpers/Functions';
import { PrivateRoute } from './PrivateRoute';
import { SellerRoute } from './SellerRoute';

// lazy load all the views
const Landing = React.lazy(() => import('../pages/Landing'));
const OnboardingPage = React.lazy(() => import('../pages/onboarding'));
const SellersPage = React.lazy(() => import('../pages/sellers'));
const NewSellerPage = React.lazy(() => import('../pages/sellers/new'));
const DashboardPage = React.lazy(() => import('../pages/dashboard'));
const OrderPage = React.lazy(() => import('../pages/orders'));
const ShowOrderPage = React.lazy(() => import('../pages/orders/show'));
const MenuPage = React.lazy(() => import('../pages/menu'));
const CatalogsPage = React.lazy(() => import('../pages/menu/catalogs'));
const EditCatalogPage = React.lazy(() => import('../pages/menu/catalogs/edit'));
const ProductsPage = React.lazy(() => import('../pages/menu/products'));
const EditProductPage = React.lazy(() => import('../pages/menu/products/edit'));
const NewProductPage = React.lazy(() => import('../pages/menu/products/new'));
const ModifierGroupsPage = React.lazy(
  () => import('../pages/menu/modifierGroups')
);
const EditModifierGroupPage = React.lazy(
  () => import('../pages/menu/modifierGroups/edit')
);
const CategoriesPage = React.lazy(() => import('../pages/menu/categories'));
const EditCategoryPage = React.lazy(
  () => import('../pages/menu/categories/edit')
);
const SortCatalogsPage = React.lazy(
  () => import('../pages/menu/catalogs/sort')
);
const EntryPage = React.lazy(() => import('../pages/entry'));
const EntryCatalogsPage = React.lazy(() => import('../pages/entry/catalogs'));
const EditEntryCatalogPage = React.lazy(
  () => import('../pages/entry/catalogs/edit')
);
const EntryProductsPage = React.lazy(() => import('../pages/entry/products'));
const EditEntryTicketPage = React.lazy(
  () => import('../pages/entry/products/edit')
);
const NewEntryTicketPage = React.lazy(
  () => import('../pages/entry/products/new')
);
const SortEntryCatalogsPage = React.lazy(
  () => import('../pages/entry/catalogs/sort')
);
const SettingsPage = React.lazy(() => import('../pages/settings'));
const PaymentMethodSettingsPage = React.lazy(
  () => import('../pages/settings/paymentMethods')
);
const UserSettingsPage = React.lazy(() => import('../pages/settings/users'));
const SellerDetailsSettingsPage = React.lazy(
  () => import('../pages/settings/sellerDetails')
);
const ProfileSettingsPage = React.lazy(
  () => import('../pages/settings/profile')
);
const DeliveryLocationsPage = React.lazy(
  () => import('../pages/settings/deliveryLocations')
);
const IntegrationsPage = React.lazy(
  () => import('../pages/settings/integrations')
);
const TerminalsPage = React.lazy(() => import('../pages/settings/terminals'));
const FortnoxAuthResponsePage = React.lazy(
  () => import('../pages/settings/integrations/fortnox/authResponse')
);
const KYCPage = React.lazy(() => import('../pages/kyc'));
const DiscountsPage = React.lazy(() => import('../pages/discounts'));
const EditDiscountPage = React.lazy(() => import('../pages/discounts/edit'));
const DeveloperPage = React.lazy(() => import('../pages/developer'));
const BookkeepingPage = React.lazy(() => import('../pages/bookkeeping'));
const BookkeepingExportsPage = React.lazy(
  () => import('../pages/bookkeeping/exports')
);
const BookkeepingMappingsPage = React.lazy(
  () => import('../pages/bookkeeping/mappings')
);
const BookkeepingSettingsPage = React.lazy(
  () => import('../pages/bookkeeping/settings')
);
const BookkeepingAccountsPage = React.lazy(
  () => import('../pages/bookkeeping/accounts')
);
const EnableBookkeepingPage = React.lazy(
  () => import('../pages/bookkeeping/enable')
);
const ReportsPage = React.lazy(() => import('../pages/reports'));
const SummarySalesReportPage = React.lazy(
  () => import('../pages/reports/summarySalesReport')
);
const DetailedSalesReportPage = React.lazy(
  () => import('../pages/reports/detailedSalesReport')
);
const ZReportsPage = React.lazy(() => import('../pages/reports/zReport'));
const PayoutReportPage = React.lazy(
  () => import('../pages/reports/payoutReport')
);

const HelpPage = React.lazy(() => import('../pages/help'));

const MarketingPage = React.lazy(() => import('../pages/marketing'));

const NotificationPage = React.lazy(() => import('../pages/notifications'));
const CustomMessagePage = React.lazy(
  () => import('../pages/notifications/customMessage')
);

export const routes = [
  {
    path: '/',
    exact: true,
    component: Landing,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/sellers',
    exact: true,
    component: SellersPage,
    route: PrivateRoute,
    layout: 'horizontal'
  },
  {
    path: '/sellers/new',
    exact: true,
    component: NewSellerPage,
    route: PrivateRoute,
    layout: 'horizontal'
  },

  /*
   * Dashboard
   */
  {
    path: '/dashboard',
    exact: true,
    component: DashboardPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/get-started',
    exact: true,
    component: OnboardingPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  /*
   * Orders
   */

  {
    path: '/orders',
    exact: true,
    component: OrderPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/orders/:reference',
    exact: true,
    component: ShowOrderPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Menu
   */
  {
    path: '/menu',
    exact: true,
    component: MenuPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/catalogs/new',
    exact: true,
    component: EditCatalogPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/catalogs/:reference/edit',
    exact: true,
    component: EditCatalogPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/catalogs',
    exact: true,
    component: CatalogsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/catalogs/sort',
    exact: true,
    component: SortCatalogsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/products',
    exact: true,
    component: ProductsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/products/new',
    exact: true,
    component: NewProductPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/products/:reference/edit',
    exact: true,
    component: EditProductPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/modifier-groups',
    exact: true,
    component: ModifierGroupsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/modifier-groups/new',
    exact: true,
    component: EditModifierGroupPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/modifier-groups/:reference/edit',
    exact: true,
    component: EditModifierGroupPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/categories',
    exact: true,
    component: CategoriesPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/categories/new',
    exact: true,
    component: EditCategoryPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/menu/categories/:reference/edit',
    exact: true,
    component: EditCategoryPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Entry
   */
  {
    path: '/entry',
    exact: true,
    component: EntryPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/catalogs',
    exact: true,
    component: EntryCatalogsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/catalogs/new',
    exact: true,
    component: EditEntryCatalogPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/catalogs/:reference/edit',
    exact: true,
    component: EditEntryCatalogPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/products',
    exact: true,
    component: EntryProductsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/products/new',
    exact: true,
    component: NewEntryTicketPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/products/:reference/edit',
    exact: true,
    component: EditEntryTicketPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/entry/catalogs/sort',
    exact: true,
    component: SortEntryCatalogsPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Settings
   */
  {
    path: '/settings',
    exact: true,
    component: SettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/payment-methods',
    exact: true,
    component: PaymentMethodSettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/users',
    exact: true,
    component: UserSettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/profile',
    exact: true,
    component: ProfileSettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/seller-details',
    exact: true,
    component: SellerDetailsSettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/terminals',
    exact: true,
    component: TerminalsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/delivery-locations',
    exact: true,
    component: DeliveryLocationsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/integrations',
    exact: true,
    component: IntegrationsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/settings/integrations/fortnox/auth',
    exact: true,
    component: FortnoxAuthResponsePage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * KYC
   */
  {
    path: '/adyen-kyc',
    exact: true,
    component: KYCPage,
    route: PrivateRoute,
    layout: 'static'
  },

  /*
   * Discounts
   */
  {
    path: '/discounts',
    exact: true,
    component: DiscountsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/discounts/:reference/edit',
    exact: true,
    component: EditDiscountPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/discounts/new',
    exact: true,
    component: EditDiscountPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Bookkeeping
   */
  {
    path: '/bookkeeping',
    exact: true,
    component: BookkeepingPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/bookkeeping/exports',
    exact: true,
    component: BookkeepingExportsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/bookkeeping/mappings',
    exact: true,
    component: BookkeepingMappingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/bookkeeping/settings',
    exact: true,
    component: BookkeepingSettingsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/bookkeeping/accounts',
    exact: true,
    component: BookkeepingAccountsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/bookkeeping/enable',
    exact: true,
    component: EnableBookkeepingPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Reports
   */
  {
    path: '/reports',
    exact: true,
    component: ReportsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/reports/summary_report',
    exact: true,
    component: SummarySalesReportPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/reports/detailed_report',
    exact: true,
    component: DetailedSalesReportPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/reports/z_report',
    exact: true,
    component: ZReportsPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/reports/payout_report',
    exact: true,
    component: PayoutReportPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Developer
   */
  {
    path: '/developer',
    exact: true,
    component: DeveloperPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Help
   */
  {
    path: '/help',
    exact: true,
    component: HelpPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Marketing
   */
  {
    path: '/marketing',
    exact: true,
    component: MarketingPage,
    route: SellerRoute,
    layout: 'vertical'
  },

  /*
   * Notifications
   */
  {
    path: '/notifications',
    exact: true,
    component: NotificationPage,
    route: SellerRoute,
    layout: 'vertical'
  },
  {
    path: '/notifications/custom',
    exact: true,
    component: CustomMessagePage,
    route: SellerRoute,
    layout: 'vertical'
  }
].filter(notNullUndefined);
