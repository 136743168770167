import { ToJSON } from '../../domain/Decorators';

@ToJSON
class NewSellerUserDTO {
  _username;

  _password;

  _emailAddress;

  _roles;

  constructor(args) {
    const { username, emailAddress, password, roles } = args;

    this._username = username;
    this._emailAddress = emailAddress;
    this._password = password;
    this._roles = roles;
  }

  static fromNewUser(newUser) {
    const { username, password, emailAddress, roles } = newUser;

    return new this({
      username,
      password,
      emailAddress,
      roles: roles.toArray()
    });
  }
}

export { NewSellerUserDTO };
