import { Getter } from '../../Decorators';

@Getter
class GlobalMessage {
  _reference;

  _createdAt;

  _updatedAt;

  _status;

  _shortDescription;

  _longDescription;

  _count;

  constructor(args) {
    const {
      reference,
      createdAt,
      updatedAt,
      status,
      shortDescription,
      longDescription,
      count
    } = args;

    this._reference = reference;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
    this._status = status;
    this._shortDescription = shortDescription;
    this._longDescription = longDescription;
    this._count = count;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { GlobalMessage };
