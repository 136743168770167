import { Getter } from '../Decorators';
import { IntegrationType } from './IntegrationType';

@Getter
class FortnoxIntegration {
  _createdAt;

  constructor(args) {
    const { createdAt } = args;

    this._createdAt = createdAt;
  }

  get type() {
    return IntegrationType.FORTNOX;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { FortnoxIntegration };
