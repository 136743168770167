import { PaymentPreview } from '../../domain/OrderPreview/PaymentPreview';

export interface IPaymentPreviewDTO {
  reference: string;
  type: string;
  state: string;
  method: string | null;
  separateCapture: boolean;
}

class PaymentPreviewDTO {
  static toPaymentPreview(paymentPreviewDTO: IPaymentPreviewDTO) {
    const { reference, type, state, method, separateCapture } =
      paymentPreviewDTO;

    return new PaymentPreview({
      reference,
      type,
      state,
      method,
      separateCapture
    });
  }
}

export { PaymentPreviewDTO };
