import type { IBookkeepingAccount } from './BookkeepingAccount';
import { BookkeepingAccount } from './BookkeepingAccount';

export interface IBookkeepingProductMapping {
  lineItemReference: string;
  lineItemName: string;
  type: string;
  salesAccount: BookkeepingAccount | null;
  vatAccount: BookkeepingAccount | null;
}

export interface JBookkeepingProductMapping {
  lineItemReference: string;
  lineItemName: string;
  type: string;
  salesAccount: IBookkeepingAccount | null;
  vatAccount: IBookkeepingAccount | null;
}

class BookkeepingProductMapping {
  readonly lineItemReference;
  readonly lineItemName;
  readonly type;
  readonly salesAccount;
  readonly vatAccount;

  constructor(args: IBookkeepingProductMapping) {
    const { lineItemReference, lineItemName, type, salesAccount, vatAccount } =
      args;

    this.lineItemReference = lineItemReference;
    this.lineItemName = lineItemName;
    this.type = type;
    this.salesAccount = salesAccount;
    this.vatAccount = vatAccount;
  }

  static fromJSON(json: JBookkeepingProductMapping) {
    const {
      salesAccount: jsonSalesAccount,
      vatAccount: jsonVatAccount,
      ...rest
    } = json;

    const salesAccount = jsonSalesAccount
      ? BookkeepingAccount.fromJSON(jsonSalesAccount)
      : null;
    const vatAccount = jsonVatAccount
      ? BookkeepingAccount.fromJSON(jsonVatAccount)
      : null;

    return new this({
      salesAccount,
      vatAccount,
      ...rest
    });
  }

  static keyExtractor(bookkeepingProductMapping: BookkeepingProductMapping) {
    return bookkeepingProductMapping.lineItemReference;
  }
}

export { BookkeepingProductMapping };
