import type { IAdyenBillingEntity } from './AdyenBillingEntity';
import { AdyenBillingEntity } from './AdyenBillingEntity';
import type { IAdyenTerminalProduct } from './AdyenTerminalProduct';
import { AdyenTerminalProduct } from './AdyenTerminalProduct';

interface ITerminalOrderPrerequisites {
  adyenTerminalProducts: AdyenTerminalProduct[];
  adyenBillingEntities: AdyenBillingEntity[];
}

export interface JTerminalOrderPrerequisites {
  adyenTerminalProducts: IAdyenTerminalProduct[];
  adyenBillingEntities: IAdyenBillingEntity[];
}

class TerminalOrderPrerequisites {
  readonly adyenTerminalProducts;
  readonly adyenBillingEntities;

  constructor(args: ITerminalOrderPrerequisites) {
    const { adyenTerminalProducts, adyenBillingEntities } = args;

    this.adyenTerminalProducts = adyenTerminalProducts;
    this.adyenBillingEntities = adyenBillingEntities;
  }

  static fromJSON(json: JTerminalOrderPrerequisites) {
    const { adyenTerminalProducts, adyenBillingEntities } = json;

    return new this({
      adyenTerminalProducts: adyenTerminalProducts
        .map(product => AdyenTerminalProduct.fromJSON(product))
        .sort(sortTerminalProducts),
      adyenBillingEntities: adyenBillingEntities.map(entity =>
        AdyenBillingEntity.fromJSON(entity)
      )
    });
  }
}

/*
 * Terminals have id's starting with TBOX, while accessories
 * have id's starting with PART. Sorts the product array so
 * that terminals come first.
 */
const TBOX = 'TBOX';
function sortTerminalProducts(
  productA: AdyenTerminalProduct,
  productB: AdyenTerminalProduct
) {
  const startA = productA.id.slice(0, 4);
  const startB = productB.id.slice(0, 4);

  if (startA === TBOX && startB !== TBOX) {
    return -1;
  } else if (startA !== TBOX && startB === TBOX) {
    return 1;
  } else {
    return 0;
  }
}

export { TerminalOrderPrerequisites };
