import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RouteTracker from './RouteTracker';
import { routes } from './index';
import { PrivateRoute } from './PrivateRoute';

const Login = React.lazy(() => import('../pages/Login'));
const Logout = React.lazy(() => import('../pages/Logout'));
const NotFound = React.lazy(() => import('../pages/error/NotFound'));
const Unauthorized = React.lazy(() => import('../pages/error/Unauthorized'));

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/">
        <Switch>
          {routes.map(
            (route, index) =>
              !route.children && (
                <route.route
                  key={`${route.path}-${index}`}
                  path={route.path}
                  roles={route.roles}
                  exact={route.exact}
                  component={route.component}
                  layout={route.layout}
                />
              )
          )}
          <PrivateRoute
            path="/unauthorized"
            component={Unauthorized}
            layout="static"
          />
          <PrivateRoute path="*" component={NotFound} layout="static" />
        </Switch>
      </Route>
    </Switch>
    <RouteTracker />
  </BrowserRouter>
);

export default Routes;
