import { RowTypes } from '../RowTypes';
import { v4 as uuidv4 } from 'uuid';

const ROW_TYPE = RowTypes.SUM;

export interface IPayoutSummaryRow {
  turnover: string;
  commission: string;
  payout: string;
}

export interface JPayoutSummaryRow {
  totalTurnover: string;
  totalCommission: string;
  totalPayout: string;
}

interface PayoutSummaryRow extends IPayoutSummaryRow {}
class PayoutSummaryRow {
  readonly turnover;
  readonly commission;
  readonly payout;
  readonly key = uuidv4();

  constructor(args: IPayoutSummaryRow) {
    const { turnover, commission, payout } = args;

    this.turnover = turnover;
    this.commission = commission;
    this.payout = payout;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: JPayoutSummaryRow) {
    const { totalTurnover, totalCommission, totalPayout } = json;

    return new this({
      turnover: totalTurnover,
      commission: totalCommission,
      payout: totalPayout
    });
  }
}

export { PayoutSummaryRow };
