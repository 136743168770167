import type { AxiosInstance } from 'axios';
import type { AuthClient } from './AuthClient';
import type { AxiosInterceptor } from './AxiosInterceptor';

const DEFAULT_MIN_TOKEN_SECONDS = 60 * 10; // 10 minutes

export class TokenRequestInterceptor implements AxiosInterceptor {
  private readonly authClient;
  private readonly minTokenSeconds;

  constructor(
    authClient: AuthClient,
    minTokenSeconds = DEFAULT_MIN_TOKEN_SECONDS
  ) {
    this.authClient = authClient;
    this.minTokenSeconds = minTokenSeconds;
  }

  register(client: AxiosInstance) {
    client.interceptors.request.use(
      async config => {
        const { authClient, minTokenSeconds } = this;

        // Try to renew the access token if less than minTokenSeconds remain
        await authClient.updateToken(minTokenSeconds);

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${authClient.token}`;
        return config;
      },
      error => error
    );
  }
}
