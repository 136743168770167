import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.ROW;

export interface IVatRateRow {
  vatRate: string;
  amountVatExcluded: string;
  amountVat: string;
  amount: string;
}

interface VatRateRow extends IVatRateRow {}
class VatRateRow {
  readonly vatRate;
  readonly amountVatExcluded;
  readonly amountVat;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IVatRateRow) {
    const { vatRate, amountVatExcluded, amountVat, amount } = args;

    this.vatRate = vatRate;
    this.amountVatExcluded = amountVatExcluded;
    this.amountVat = amountVat;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: IVatRateRow) {
    return new this(json);
  }
}

export { VatRateRow };
