import { IntegrationType } from './IntegrationType';
import { ECOM } from '../../constants';

export interface ISrv4PosControlUnit {
  reference: string;
  productionNumber: string;
  cashRegisterName: string;
  purpose: string;
  ippSerialNumber: string | null;
  controlUnitSerial: string;
}

class Srv4PosControlUnit {
  readonly productionNumber;
  readonly cashRegisterName;
  readonly purpose;
  readonly ippSerialNumber;
  readonly controlUnitSerial;

  constructor(args: ISrv4PosControlUnit) {
    const {
      productionNumber,
      cashRegisterName,
      purpose,
      ippSerialNumber,
      controlUnitSerial
    } = args;

    this.productionNumber = productionNumber;
    this.cashRegisterName = cashRegisterName;
    this.purpose = purpose;
    this.ippSerialNumber = ippSerialNumber;
    this.controlUnitSerial = controlUnitSerial;
  }

  get type() {
    return IntegrationType.SRV4POS;
  }

  get isEcom() {
    return this.purpose === ECOM;
  }

  static fromJSON(json: ISrv4PosControlUnit) {
    return new this(json);
  }
}

export { Srv4PosControlUnit };
