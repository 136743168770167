import moment from 'moment';
import { observable, computed } from 'mobx';
import { Getter, Setter } from '../Decorators';
import Optional from '../Optional';

const MAX_ALLOWED_YEAR = 2037;

@Setter({ functions: true })
@Getter({ computed: true })
class EditTimeSlot {
  @observable
  _from;

  @observable
  _to;

  constructor(args = {}) {
    const { from = null, to = null } = args;
    this._from = from;
    this._to = to;
  }

  static fromJSON(json) {
    const from = Optional.of(json.from)
      .map(moment)
      .map(m => m.format(moment.HTML5_FMT.DATETIME_LOCAL))
      .orElse(null);

    const to = Optional.of(json.to)
      .map(moment)
      .map(m => m.format(moment.HTML5_FMT.DATETIME_LOCAL))
      .orElse(null);

    return new this({
      from,
      to
    });
  }

  toJSON() {
    const from = Optional.of(this._from)
      .map(moment)
      .map(m => m.toISOString())
      .orElse(null);

    const to = Optional.of(this._to)
      .map(moment)
      .map(m => m.toISOString())
      .orElse(null);

    return {
      from,
      to
    };
  }

  @computed
  get isFromValid() {
    const { _from: from } = this;

    if (from === null || from === '') {
      return false;
    }

    return moment(from).year() <= MAX_ALLOWED_YEAR;
  }

  @computed
  get isFromInvalid() {
    const { _from: from } = this;

    if (from === null) {
      return false;
    } else if (from === '') {
      return true;
    } else if (moment(from).year() > MAX_ALLOWED_YEAR) {
      return true;
    }

    return false;
  }

  @computed
  get isToValid() {
    const { _from: from, _to: to } = this;

    if (to === null || to === '') {
      return false;
    } else if (moment(from).year() > MAX_ALLOWED_YEAR) {
      return false;
    } else {
      return from ? this._isToAfterFrom : true;
    }
  }

  @computed
  get isToInvalid() {
    const { _from: from, _to: to } = this;

    if (to === null) {
      return false;
    } else if (to === '') {
      return true;
    } else if (moment(to).year() > MAX_ALLOWED_YEAR) {
      return false;
    } else if (from) {
      return !this._isToAfterFrom;
    } else {
      return false;
    }
  }

  @computed
  get _isToAfterFrom() {
    const { _from: from, _to: to } = this;

    const fromMoment = Optional.of(from)
      .filter(str => str.length >= 16)
      .map(moment)
      .orElse(null);

    const toMoment = Optional.of(to)
      .filter(str => str.length >= 16)
      .map(moment)
      .orElse(null);

    return fromMoment && toMoment && toMoment.isAfter(fromMoment);
  }

  @computed
  get isValid() {
    return this.isFromValid && this.isToValid;
  }
}

export { EditTimeSlot as default };
