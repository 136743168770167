import { observable } from 'mobx';
import type { KeyExtractor } from './GeneralSet';
import { GeneralSet } from './GeneralSet';

export class ObservableSet<K, V> extends GeneralSet<K, V> {
  constructor(iterable: Iterable<V> = [], keyExtractor?: KeyExtractor<K, V>) {
    super(iterable, keyExtractor, observable.map<K, V>);
  }

  static create<V>(iterable?: Iterable<V>): ObservableSet<V, V>;

  static create<K, V>(
    iterable?: Iterable<V>,
    keyExtractor?: KeyExtractor<K, V>
  ): ObservableSet<V, V>;

  static create<K, V = K>(
    iterable?: Iterable<V>,
    keyExtractor?: KeyExtractor<K, V>
  ) {
    if (keyExtractor === undefined) {
      return new ObservableSet<V, V>(
        iterable,
        GeneralSet.KeyExtractors.Identity
      );
    } else {
      return new ObservableSet<K, V>(iterable, keyExtractor);
    }
  }
}
