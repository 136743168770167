import type { AuthClient } from '../config/clients/AuthClient';
import {
  WEIQ_ADMIN,
  WEIQ_REPORT_VIEWER,
  WEIQ_SELLER
} from '../constants/index';

class AuthManager {
  private readonly authClient;

  constructor(authClient: AuthClient) {
    this.authClient = authClient;
  }

  get authenticated() {
    return this.authClient.authenticated;
  }

  get userReference() {
    const { authClient } = this;
    return authClient.userReference;
  }

  get username() {
    const { authClient } = this;
    const { authenticated, parsedToken } = authClient;

    if (authenticated && parsedToken) {
      const { preferred_username: username } = parsedToken;
      return username;
    }

    return null;
  }

  get fullUserName() {
    const { authClient } = this;
    const { authenticated, parsedToken } = authClient;

    if (authenticated && parsedToken) {
      const { family_name: familyName, given_name: givenName } = parsedToken;
      if (givenName) {
        return `${givenName} ${familyName ?? ''}`;
      }
    }

    return null;
  }

  get roles() {
    const { authClient } = this;
    const { authenticated, parsedToken } = authClient;

    if (authenticated && parsedToken?.realm_access) {
      const { roles } = parsedToken.realm_access;
      return roles ?? [];
    }

    return [];
  }

  get primaryRole() {
    const { roles } = this;
    if (roles) {
      if (roles.includes(WEIQ_ADMIN)) {
        return WEIQ_ADMIN;
      } else if (roles.includes(WEIQ_SELLER)) {
        return WEIQ_SELLER;
      } else if (roles.includes(WEIQ_REPORT_VIEWER)) {
        return WEIQ_REPORT_VIEWER;
      }
    }

    return null;
  }

  hasRole = (role: string) => {
    const { roles } = this;
    return roles?.includes(role);
  };

  init = async () => this.authClient.init();

  login = ({ redirectUri }: { redirectUri: string }) =>
    this.authClient.login({ redirectUri });

  logout = ({ redirectUri }: { redirectUri?: string } = {}) =>
    this.authClient.logout({ redirectUri });
}

export { AuthManager };
