import { ToJSON } from '../../domain/Decorators';

@ToJSON
class MessageContentDTO {
  _subject;

  _shortDescription;

  _longDescription;

  constructor(args) {
    const { subject, shortDescription, longDescription } = args;

    this._subject = subject;
    this._shortDescription = shortDescription;
    this._longDescription = longDescription;
  }

  static fromMessageContent(messageContent) {
    return new this(messageContent);
  }
}

export { MessageContentDTO };
