import { ToJSON } from '../../domain/Decorators';

@ToJSON
class EnableFortnoxIntegrationDTO {
  _authorizationCode;

  constructor(authorizationCode) {
    this._authorizationCode = authorizationCode;
  }
}
export { EnableFortnoxIntegrationDTO };
