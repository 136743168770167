import { MODIFIER, PRODUCT, PRODUCT_CATALOG } from '../constants';
import Catalog from '../domain/Catalog';
import CatalogPreview from '../domain/Catalog/CatalogPreview';
import EditCatalog from '../domain/Catalog/EditCatalog';
import EditCatalogProductOverride from '../domain/Catalog/EditCatalogProductOverride';
import Category from '../domain/Category/Category';
import EditCategory from '../domain/Category/EditCategory';
import { Getter } from '../domain/Decorators';
import ModifierGroup from '../domain/ModifierGroup';
import EditModifierGroup from '../domain/ModifierGroup/EditModifierGroup';
import EditProduct, {
  EditProductModifier,
  EditProductModifierCategory
} from '../domain/Product/EditProduct';
import Product from '../domain/Product/Product';
import ProductRegister from '../domain/ProductRegister';
import EditTag from '../domain/Tag/EditTag';
import CreateCatalogDTO from '../dto/Catalog/CreateCatalogDTO';
import CreateProductDTO from '../dto/Product/CreateProductDTO';
import {
  assertNotNullUndefined,
  partitionArray,
  referenceKeyExtractor,
  sortByName,
  sortBySortWeight
} from '../helpers/Functions';

class CopyMenu {
  _momsClient;

  constructor(momsClient) {
    this._momsClient = momsClient;
  }

  async copyMenu(fromSellerRef, toSellerRef) {
    // 0. Fetch the product registers for both sellers
    const [fromProductRegister, toProductRegister] = await Promise.all([
      this.fetchProductRegisterFor(fromSellerRef),
      this.fetchProductRegisterFor(toSellerRef)
    ]);
    const { reference: fromProductRegisterRef } = fromProductRegister;
    const { reference: toProductRegisterRef } = toProductRegister;

    // 1. Copy the catalogs
    const fromCatalogPreviews =
      await this.fetchSellerCatalogPreviews(fromSellerRef);
    const toCatalogRefs = await this.batchRun(
      this.createCatalog,
      fromCatalogPreviews,
      [toSellerRef, toProductRegisterRef]
    );
    const catalogMap = fromCatalogPreviews.reduce(
      (accumulator, fromCatalog, idx) => {
        accumulator[fromCatalog.reference] = toCatalogRefs[idx];
        return accumulator;
      },
      {}
    );

    // 2. Copy the categories
    const fromCategories = await this.fetchCategories(fromProductRegisterRef);
    const toCategoryRefs = await this.batchRun(
      this.createCategory,
      fromCategories,
      [toProductRegisterRef]
    );
    const categoryMap = fromCategories.reduce(
      (accumulator, fromCatalog, idx) => {
        accumulator[fromCatalog.reference] = toCategoryRefs[idx];
        return accumulator;
      },
      {}
    );

    // 2.1 Copy category sort weights
    const toCategoryWeightMap = fromCategories.reduce(
      (accumulator, fromCategory) => {
        const { reference: fromCategoryRef, sortWeight } = fromCategory;
        const toCategoryRef = categoryMap[fromCategoryRef];
        assertNotNullUndefined(toCategoryRef, 'Category mapping not found');

        accumulator[toCategoryRef] = sortWeight;
        return accumulator;
      },
      {}
    );
    await this.updateSortWeightForCategories(
      toCategoryWeightMap,
      toProductRegisterRef
    );

    // 3. Copy tags
    const fromTags = await this.fetchTags(fromProductRegisterRef);
    const toTagRefs = await this.batchRun(this.createTag, fromTags, [
      toProductRegisterRef
    ]);
    const tagMap = fromTags.reduce((accumulator, fromTag, idx) => {
      accumulator[fromTag.reference] = toTagRefs[idx];
      return accumulator;
    }, {});

    // 4. Copy modifier groups
    const fromModifierGroups = await this.fetchModifierGroups(
      fromProductRegisterRef
    );
    const toModifierGroupRefs = await this.batchRun(
      this.createModifierGroup,
      fromModifierGroups,
      [toProductRegisterRef]
    );
    const modifierGroupMap = fromModifierGroups.reduce(
      (accumulator, fromModifierGroup, idx) => {
        accumulator[fromModifierGroup.reference] = toModifierGroupRefs[idx];
        return accumulator;
      },
      {}
    );

    // 5. Copy modifiers
    const fromModifierRefs = fromProductRegister.products
      .filter(({ type }) => type === MODIFIER)
      .map(referenceKeyExtractor);
    const fromModifiers = await this.batchRun(
      this.fetchProduct,
      fromModifierRefs,
      [fromProductRegisterRef]
    );
    const toModifierRefs = await this.batchRun(
      this.createProduct,
      this.mapProducts(fromModifiers, {
        catalogMap,
        categoryMap,
        tagMap,
        modifierGroupMap,
        modifierMap: {}
      }),
      [toProductRegisterRef]
    );

    const modifierMap = fromModifierRefs.reduce((accumulator, fromRef, idx) => {
      accumulator[fromRef] = toModifierRefs[idx];
      return accumulator;
    }, {});

    // 6. Add modifiers to modifier groups
    await this.batchRun(
      this.addProductToModifierGroup,
      this.mapProductsInModifierGroups(fromModifierGroups, {
        modifierGroupMap,
        modifierMap
      }),
      [toProductRegisterRef]
    );

    // 6.1 Copy sort weight for modifiers in modifier groups
    const updateModifierGroupWeightMaps = fromModifierGroups.map(
      fromModifierGroup => {
        const { reference: fromModifierGroupRef } = fromModifierGroup;
        const toModifierGroupRef = modifierGroupMap[fromModifierGroupRef];
        assertNotNullUndefined(
          toModifierGroupRef,
          'Modifier group mapping not found'
        );

        const toWeightMap = fromModifierGroup.products.reduce(
          (accumulator, fromProduct) => {
            const { reference: fromProductRef, sortWeight } = fromProduct;
            const toProductRef = modifierMap[fromProductRef];
            assertNotNullUndefined(toProductRef, 'Modifier mapping not found');

            accumulator[toProductRef] = sortWeight;
            return accumulator;
          },
          {}
        );

        return new UpdateModifierGroupWeightMap(
          toModifierGroupRef,
          toWeightMap
        );
      }
    );
    await this.batchRun(
      this.updateSortWeightForModifierGroup,
      updateModifierGroupWeightMaps,
      [toProductRegisterRef]
    );

    // 7. Products
    const fromProductRefs = fromProductRegister.products
      .filter(({ type }) => type === PRODUCT)
      .map(referenceKeyExtractor);
    const fromProducts = await this.batchRun(
      this.fetchProduct,
      fromProductRefs,
      [fromProductRegisterRef]
    );
    const toProductRefs = await this.batchRun(
      this.createProduct,
      this.mapProducts(fromProducts, {
        catalogMap,
        categoryMap,
        tagMap,
        modifierGroupMap,
        modifierMap
      }),
      [toProductRegisterRef],
      undefined,
      1
    );

    const productMap = fromProductRefs.reduce((accumulator, fromRef, idx) => {
      accumulator[fromRef] = toProductRefs[idx];
      return accumulator;
    }, {});

    // 8. Copy product overrides (at catalog level)
    const fromCatalogs = await this.fetchCatalogs(
      fromCatalogPreviews.map(referenceKeyExtractor)
    );
    const toDisableProducts = fromCatalogs.flatMap(fromCatalog => {
      const toCatalogRef = catalogMap[fromCatalog.reference];
      assertNotNullUndefined(toCatalogRef, 'Catalog mapping not found');

      return fromCatalog.products
        .filter(fromProduct => !fromProduct.enabled)
        .map(fromProduct => {
          const { reference: fromProductRef } = fromProduct;
          const toProductRef =
            productMap[fromProductRef] ?? modifierMap[fromProductRef];
          assertNotNullUndefined(toProductRef, 'Product mapping not found');

          const editCatalogProductOverride =
            EditCatalogProductOverride.fromCatalogProduct(fromProduct);
          editCatalogProductOverride.setEnabled(false);

          return new DisableProduct(
            toProductRef,
            toCatalogRef,
            editCatalogProductOverride
          );
        });
    });

    await this.batchRun(
      this.disableProduct,
      toDisableProducts,
      [toProductRegisterRef],
      undefined,
      1
    );

    // 9. Copy modifier overrides (at catalog level)
    const toDisableModifiers = fromCatalogs.flatMap(fromCatalog => {
      const toCatalogRef = catalogMap[fromCatalog.reference];
      assertNotNullUndefined(toCatalogRef, 'Catalog mapping not found');

      return fromCatalog.modifiers
        .filter(fromModifier => !fromModifier.enabled)
        .map(fromModifier => {
          const { reference: fromModifierRef } = fromModifier;
          const toModifierRef = modifierMap[fromModifierRef];
          assertNotNullUndefined(toModifierRef, 'Modifier mapping not found');

          const editCatalogProductOverride =
            EditCatalogProductOverride.fromCatalogProduct(fromModifier);
          editCatalogProductOverride.setEnabled(false);

          return new DisableProduct(
            toModifierRef,
            toCatalogRef,
            editCatalogProductOverride
          );
        });
    });

    await this.batchRun(
      this.disableProduct,
      toDisableModifiers,
      [toProductRegisterRef],
      undefined,
      1
    );

    // 10. Copy sort weight for catalog products
    for (const fromCatalog of fromCatalogs) {
      const toCatalogRef = catalogMap[fromCatalog.reference];
      assertNotNullUndefined(toCatalogRef, 'Catalog mapping not found');

      const toSortWeightMap = fromCatalog.products.reduce(
        (accumulator, fromProduct) => {
          const { reference: fromProductRef, sortWeight } = fromProduct;
          const toProductRef =
            productMap[fromProductRef] ?? modifierMap[fromProductRef];
          assertNotNullUndefined(toProductRef, 'Product mapping not found');

          accumulator[toProductRef] = sortWeight;
          return accumulator;
        },
        {}
      );

      // eslint-disable-next-line no-await-in-loop
      await this.updateSortWeightForCatalogProducts(
        toSortWeightMap,
        toCatalogRef,
        toProductRegisterRef
      );
    }

    // Finally, combine the modifierMap and the productMap and return it
    return {
      ...modifierMap,
      ...productMap
    };
  }

  mapProducts(
    fromProducts,
    { catalogMap, categoryMap, tagMap, modifierGroupMap, modifierMap }
  ) {
    return fromProducts.map(fromProduct => {
      const editProduct = EditProduct.fromProduct(fromProduct, {
        copyOverrides: true
      });

      editProduct.setCatalogs(
        editProduct.catalogs
          // Filte out catalogs belonging to other sellers in the same product register
          .filter(fromCatalogReference => fromCatalogReference in catalogMap)
          .map(fromCatalogReference => {
            const toCatalogRef = catalogMap[fromCatalogReference];
            assertNotNullUndefined(toCatalogRef, 'Catalog mapping not found');

            return toCatalogRef;
          })
      );

      editProduct.setCategoryReferences(
        editProduct.categoryReferences.map(fromCategoryReference => {
          const toCategoryRef = categoryMap[fromCategoryReference];
          assertNotNullUndefined(toCategoryRef, 'Category mapping not found');

          return toCategoryRef;
        })
      );

      editProduct.setTagReferences(
        editProduct.tagReferences.map(fromTagReference => {
          const toTagRef = tagMap[fromTagReference];
          assertNotNullUndefined(toTagRef, 'Tag mapping not found');

          return toTagRef;
        })
      );

      editProduct.setModifierCategories(
        editProduct.modifierCategories.map(fromEditProductModifierCategory => {
          const toModifierGroupRef =
            modifierGroupMap[fromEditProductModifierCategory.reference];
          assertNotNullUndefined(
            toModifierGroupRef,
            'Modifier group mapping not found'
          );

          const toModifiers = fromEditProductModifierCategory.modifiers.map(
            fromEditProductModifier => {
              const toModifierRef =
                modifierMap[fromEditProductModifier.reference];
              assertNotNullUndefined(
                toModifierRef,
                'Modifier mapping not found'
              );

              return EditProductModifier.fromProductModifier(
                {
                  ...fromEditProductModifier.toJSON(),
                  reference: toModifierRef
                },
                true
              );
            }
          );

          return new EditProductModifierCategory({
            ...fromEditProductModifierCategory.toJSON(),
            reference: toModifierGroupRef,
            modifiers: toModifiers
          });
        })
      );

      return editProduct;
    });
  }

  mapProductsInModifierGroups(
    fromModifierGroups,
    { modifierGroupMap, modifierMap }
  ) {
    return fromModifierGroups.flatMap(fromModifierGroup =>
      fromModifierGroup.products.map(fromProduct => {
        const toProductRef = modifierMap[fromProduct.reference];
        const toModifierGroupRef =
          modifierGroupMap[fromModifierGroup.reference];
        assertNotNullUndefined(toProductRef, 'Product mapping not found');
        assertNotNullUndefined(
          toModifierGroupRef,
          'Modifier group mapping not found'
        );

        return new AddProductToModifierGroup(toProductRef, toModifierGroupRef);
      })
    );
  }

  async fetchSellerCatalogPreviews(sellerReference) {
    const { _momsClient: momsClient } = this;

    return (await momsClient.getSellerCatalogPreviews(sellerReference))
      .filter(jsonCatalog => jsonCatalog.type === PRODUCT_CATALOG)
      .map(jsonCatalog => CatalogPreview.fromJSON(jsonCatalog))
      .sort(sortBySortWeight);
  }

  createCatalog = async (catalogPreview, toSellerRef, toProductRegisterRef) => {
    const { _momsClient: momsClient } = this;
    const editCatalog = EditCatalog.fromJSON(catalogPreview.toJSON());
    const createCatalogDTO = new CreateCatalogDTO(editCatalog);

    // NOTE: Ignores tip enabled/disabled

    const catalogReference = (
      await momsClient.createCatalog({
        createCatalogDTO,
        productRegisterReference: toProductRegisterRef
      })
    )?.reference;

    if (!catalogReference) {
      throw new CopyMenuException('Did not get a new catalog reference');
    }

    const result = await momsClient.mapCatalogToSeller({
      catalogReference,
      sellerReference: toSellerRef,
      productRegisterReference: toProductRegisterRef
    });
    if (!result) {
      throw new CopyMenuException('Failed to map catalog to seller');
    }

    return catalogReference;
  };

  fetchProductRegisterFor = async sellerReference => {
    const { _momsClient: momsClient } = this;
    const jsonProductRegister =
      await momsClient.getProductRegisterForSeller(sellerReference);

    return ProductRegister.fromJSON(jsonProductRegister);
  };

  fetchCatalog = async catalogReference => {
    const { _momsClient: momsClient } = this;
    const jsonCatalog = await momsClient.getCatalog(catalogReference);

    if (jsonCatalog) {
      // Remember that this catalog was selected
      return Catalog.fromJSON(jsonCatalog);
    } else {
      throw new CopyMenuException('Failed to fetch catalog');
    }
  };

  fetchCategories = async productRegisterReference => {
    const { _momsClient: momsClient } = this;

    return (await momsClient.getCategories(productRegisterReference)).map(
      jsonCategory => Category.fromJSON(jsonCategory)
    );
  };

  createCategory = async (category, productRegisterReference) => {
    const { _momsClient: momsClient } = this;
    const editCategory = new EditCategory(category.toJSON());

    const categoryReference = (
      await momsClient.createCategory({
        editCategory,
        productRegisterReference
      })
    )?.reference;

    if (!categoryReference) {
      throw new CopyMenuException('Did not receive a category reference');
    }

    return categoryReference;
  };

  fetchTags = async productRegisterReference => {
    const { _momsClient: momsClient } = this;

    return (await momsClient.getTags(productRegisterReference)).sort(
      sortByName
    );
  };

  createTag = async (tag, productRegisterReference) => {
    const { _momsClient: momsClient } = this;
    const editTag = new EditTag(tag);

    const tagReference = (
      await momsClient.createTag({
        editTag,
        productRegisterReference
      })
    )?.reference;

    if (!tagReference) {
      throw new CopyMenuException(
        'Did not receive a tag reference for EditTag',
        editTag
      );
    }

    return tagReference;
  };

  fetchModifierGroups = async productRegisterReference => {
    const { _momsClient: momsClient } = this;

    return (await momsClient.getModifierGroups(productRegisterReference))
      .sort(sortByName)
      .map(jsonGroup => ModifierGroup.fromJSON(jsonGroup));
  };

  createModifierGroup = async (modifierGroup, productRegisterReference) => {
    const { _momsClient: momsClient } = this;
    const editModifierGroup = new EditModifierGroup(modifierGroup.toJSON());

    const modifierGroupReference = (
      await momsClient.createModifierGroup({
        editModifierGroup,
        productRegisterReference
      })
    )?.reference;

    if (!modifierGroupReference) {
      throw new CopyMenuException(
        'Did not receive a modifier group reference. Attempted to create using',
        editModifierGroup
      );
    }

    return modifierGroupReference;
  };

  addProductToModifierGroup = async (
    addProductToModifierGroup,
    productRegisterReference
  ) => {
    const { _momsClient: momsClient } = this;

    const result = await momsClient.addProductToModifierGroup({
      modifierGroupReference: addProductToModifierGroup.modifierGroupReference,
      productReference: addProductToModifierGroup.productReference,
      productRegisterReference
    });

    if (result === null) {
      throw new CopyMenuException(
        'Could not add product to modifier group',
        addProductToModifierGroup,
        result
      );
    }
  };

  fetchCatalogs = async catalogReferences => {
    const { _momsClient: momsClient } = this;

    return (await momsClient.getCatalogs(catalogReferences)).map(jsonCatalog =>
      Catalog.fromJSON(jsonCatalog)
    );
  };

  fetchProduct = async (productReference, productRegiterReference) => {
    const { _momsClient: momsClient } = this;
    const jsonProduct = await momsClient.getProduct(
      productRegiterReference,
      productReference
    );

    if (jsonProduct) {
      return Product.fromJSON(jsonProduct);
    } else {
      throw new CopyMenuException(
        'Could not get product for reference and product register',
        productReference,
        productRegiterReference
      );
    }
  };

  createProduct = async (editProduct, productRegisterReference) => {
    const { _momsClient: momsClient } = this;
    const createProductDTO = CreateProductDTO.fromEditProduct(editProduct);

    const productReference = (
      await momsClient.createProduct({
        createProductDTO,
        productRegisterReference
      })
    )?.reference;

    if (!productReference) {
      throw new CopyMenuException(
        'Failed to create product for editProduct',
        editProduct
      );
    }

    const modifierGroupResult = await this.setProductModifierOverrides(
      productReference,
      productRegisterReference,
      editProduct
    );
    if (!modifierGroupResult) {
      throw new CopyMenuException(
        'Failed to set modifier overrides for product with reference',
        productReference
      );
    }

    return productReference;
  };

  setProductModifierOverrides = async (
    productReference,
    productRegisterReference,
    editProduct
  ) => {
    const { _momsClient: momsClient } = this;
    const { modifierCategories } = editProduct;

    const overrides = modifierCategories.flatMap(modifierCategory => {
      const { reference: modifierCategoryReference } = modifierCategory;

      return modifierCategory.modifiers
        .filter(modifier => modifier.modified)
        .map(modifier => ({
          productRegisterReference,
          productReference,
          modifierCategoryReference,
          modifierReference: modifier.reference,
          enabled: modifier.productOverrideEnabled
        }));
    });

    for (const override of overrides) {
      // NOTE: Parallel requests are currently not supported
      // eslint-disable-next-line no-await-in-loop
      const result = await momsClient.setProductModifierOverride(override);
      if (!result) {
        return false;
      }
    }

    return true;
  };

  updateSortWeightForCatalogProducts = async (
    weightMap,
    catalogReference,
    productRegisterReference
  ) => {
    const { _momsClient: momsClient } = this;
    return momsClient.updateSortWeightForCatalogProducts({
      catalogReference,
      weightMap,
      productRegisterReference
    });
  };

  updateSortWeightForCategories = async (
    weightMap,
    productRegisterReference
  ) => {
    const { _momsClient: momsClient } = this;
    return momsClient.updateCategorySortWeights({
      weightMap,
      productRegisterReference
    });
  };

  updateSortWeightForModifierGroup = async (
    updateModifierGroupWeightMap,
    productRegisterReference
  ) => {
    const { _momsClient: momsClient } = this;
    const { modifierGroupReference, weightMap } = updateModifierGroupWeightMap;
    return momsClient.updateSortWeightForProductsInModifierGroup({
      modifierGroupReference,
      weightMap,
      productRegisterReference
    });
  };

  disableProduct = async (disableProduct, productRegisterReference) => {
    const { _momsClient: momsClient } = this;
    const { productReference, catalogReference, editCatalogProductOverride } =
      disableProduct;

    return momsClient.updateCatalogProductOverride({
      catalogReference,
      productReference,
      productRegisterReference,
      editCatalogProductOverride
    });
  };

  async batchRun(
    func,
    partitionableData,
    extraArgs = [],
    thisArg,
    batchSize = 5
  ) {
    const partitions = partitionArray(partitionableData, batchSize);
    const results = [];

    for (const partition of partitions) {
      // eslint-disable-next-line no-await-in-loop
      const partitionResults = await Promise.all(
        partition.map(entry => func.call(thisArg, entry, ...extraArgs))
      );

      results.push(...partitionResults);
    }

    return results;
  }
}

@Getter
class AddProductToModifierGroup {
  _productReference;

  _modifierGroupReference;

  constructor(productReference, modifierGroupReference) {
    this._productReference = productReference;
    this._modifierGroupReference = modifierGroupReference;
  }
}

@Getter
class UpdateModifierGroupWeightMap {
  _modifierGroupReference;

  _weightMap;

  constructor(modifierGroupReference, weightMap) {
    this._modifierGroupReference = modifierGroupReference;
    this._weightMap = weightMap;
  }
}

@Getter
class DisableProduct {
  _productReference;

  _catalogReference;

  _editCatalogProductOverride;

  constructor(productReference, catalogReference, editCatalogProductOverride) {
    this._productReference = productReference;
    this._catalogReference = catalogReference;
    this._editCatalogProductOverride = editCatalogProductOverride;
  }
}

class CopyMenuException extends Error {
  constructor(message, ...args) {
    super(`${message}, [${args?.map(arg => JSON.stringify(arg))}]`);
  }
}

export { CopyMenu, CopyMenuException };
