export interface ITerminalOrderLineItem {
  id: string;
  name: string;
  quantity: number;
}

class TerminalOrderLineItem {
  readonly id;
  readonly name;
  readonly quantity;

  constructor(args: ITerminalOrderLineItem) {
    const { id, name, quantity } = args;

    this.id = id;
    this.name = name;
    this.quantity = quantity;
  }

  static fromJSON(json: ITerminalOrderLineItem) {
    return new this(json);
  }
}

export { TerminalOrderLineItem };
