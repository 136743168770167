import { computed } from 'mobx';
import Category from '../domain/Category/Category';
import { sortBySortWeight } from '../helpers/Functions';

class CategoryManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get categories() {
    const { sellerDataStore } = this._sellerStore;
    const { categories } = sellerDataStore;

    return categories;
  }

  async fetchCategories({ sortingFunction = sortBySortWeight } = {}) {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference, sellerDataStore } = this._sellerStore;

    const categories = (
      await momsClient.getCategories(productRegisterReference)
    )
      .sort(sortingFunction)
      .reduce((accumulator, jsonCategory) => {
        const { reference } = jsonCategory;
        const category = Category.fromJSON(jsonCategory);

        accumulator[reference] = category;
        return accumulator;
      }, {});

    sellerDataStore.setCategories(categories);
  }

  getCategory = async categoryReference => {
    if (this.categories && this.categories[categoryReference]) {
      return this.categories[categoryReference];
    } else {
      // Try to fetch the groups
      await this.fetchCategories();
    }

    return this.categories[categoryReference];
  };

  createCategory = async editCategory => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.createCategory({
      editCategory,
      productRegisterReference
    });
  };

  updateCategory = async (categoryReference, editCategory) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.updateCategory({
      categoryReference,
      editCategory,
      productRegisterReference
    });
  };

  deleteCategory = async categoryReference => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.deleteCategory({
      categoryReference,
      productRegisterReference
    });
  };

  updateCategorySortWeights = async weightMap => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.updateCategorySortWeights({
      weightMap,
      productRegisterReference
    });
  };
}

export { CategoryManager };
