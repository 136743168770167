export interface ITerminal {
  id: string;
  model: string;
  serialNumber: string;
}

interface Terminal extends ITerminal {}
class Terminal {
  readonly id;
  readonly model;
  readonly serialNumber;

  constructor(args: ITerminal) {
    const { id, model, serialNumber } = args;

    this.id = id;
    this.model = model;
    this.serialNumber = serialNumber;
  }

  static fromJSON(json: ITerminal) {
    return new this(json);
  }

  static keyExtractor(terminal: ITerminal) {
    return terminal.id;
  }
}

export { Terminal };
