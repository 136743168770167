import { Getter, ToJSON } from '../../domain/Decorators';

@Getter
@ToJSON
class ProductImagesDTO {
  _thumbnailImageUrl;

  _mediumImageUrl;

  _largeImageUrl;

  _smallImageUrl;

  constructor(editProductImages) {
    const { thumbnail, medium, large, small } = editProductImages;

    this._thumbnailImageUrl = typeof thumbnail === 'string' ? thumbnail : null;
    this._mediumImageUrl = typeof medium === 'string' ? medium : null;
    this._largeImageUrl = typeof large === 'string' ? large : null;
    this._smallImageUrl = typeof small === 'string' ? small : null;
  }
}

export { ProductImagesDTO as default };
