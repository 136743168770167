import { Getter } from '../Decorators';

@Getter
class ContactRecord {
  _emailAddresses;

  _telephoneNumbers;

  constructor({ emailAddresses = [], telephoneNumbers = [] }) {
    this._emailAddresses = emailAddresses;
    this._telephoneNumbers = telephoneNumbers;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { ContactRecord };
