import { Getter } from '../Decorators';

@Getter
class CatalogSalesEntry {
  _catalogName;

  _totalAmount;

  constructor(args) {
    const { catalogName, totalAmount } = args;

    this._catalogName = catalogName;
    this._totalAmount = totalAmount;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { CatalogSalesEntry };
