import { ToJSON } from '../../domain/Decorators';
import { DeliveryLocationType } from '../../domain/DeliveryLocation/DeliveryLocationType';
import { EditDeliveryLocation } from '../../domain/DeliveryLocation/EditDeliveryLocation';
import {
  EditDeliveryLocationDefinitionChoices,
  EditDeliveryLocationFreeTextType
} from '../../domain/DeliveryLocation/EditDeliveryLocationDefinition';
import { stringIsEmpty } from '../../helpers/Functions';

@ToJSON
class DeliveryLocationDTO {
  _name;

  _description;

  _open;

  _qrEnabled;

  _definitionDTO;

  _deliveryTimeOptions;

  _type;

  _validationSkin;

  _code;

  _active;

  _deliveryFlowType;

  _autoConfirmOrders;

  _autoCaptureDelay;

  _availableOnCatalogs;

  _tabsEnabled;

  constructor(args) {
    const {
      name,
      description,
      open,
      qrEnabled,
      definitionDTO,
      deliveryTimeOptions,
      type,
      validationSkin,
      code,
      active,
      deliveryFlowType,
      autoConfirmOrders,
      autoCaptureDelay,
      availableOnCatalogs,
      tabsEnabled
    } = args;
    this._name = name;
    this._description = description;
    this._open = open;
    this._qrEnabled = qrEnabled;
    this._definitionDTO = definitionDTO;
    this._deliveryTimeOptions = deliveryTimeOptions;
    this._type = type;
    this._validationSkin = validationSkin;
    this._code = code;
    this._active = active;
    this._deliveryFlowType = deliveryFlowType;
    this._autoConfirmOrders = autoConfirmOrders;
    this._autoCaptureDelay = autoCaptureDelay;
    this._availableOnCatalogs = availableOnCatalogs;
    this._tabsEnabled = tabsEnabled;
  }

  static fromEditDeliveryLocation(editDeliveryLocation) {
    const {
      name,
      description,
      open,
      qrEnabled,
      deliveryLocationDefinition: editDefinition,
      deliveryTimeOptions: editTimeOptions,
      active,
      deliveryFlowType,
      autoConfirmOrders,
      autoCaptureDelay: delay,
      availableOnCatalogs: catalogSet,
      type,
      validationSkin,
      code,
      tabsEnabled
    } = editDeliveryLocation;

    const definitionDTO = editDefinition
      ? DeliveryLocationDefinitionDTO.from(editDefinition)
      : null;
    const deliveryTimeOptions = editTimeOptions
      ? DeliveryTimeOptionsDTO.from(editTimeOptions)
      : null;
    const availableOnCatalogs = catalogSet?.toArray() ?? [];
    const autoCaptureDelay =
      stringIsEmpty(delay) ||
      type === DeliveryLocationType.ENTRY_TICKET_DELIVERY_LOCATION
        ? null
        : delay;

    return new this({
      name,
      description,
      open,
      qrEnabled,
      definitionDTO,
      deliveryTimeOptions,
      type,
      validationSkin,
      code,
      active,
      deliveryFlowType,
      autoConfirmOrders,
      autoCaptureDelay,
      availableOnCatalogs,
      tabsEnabled
    });
  }
}

class DeliveryLocationDefinitionDTO {
  static from(editDeliveryLocationDefinition) {
    if (
      editDeliveryLocationDefinition.type ===
      EditDeliveryLocationDefinitionChoices.TYPE
    ) {
      return DeliveryLocationChoicesDTO.from(editDeliveryLocationDefinition);
    } else if (
      editDeliveryLocationDefinition.type ===
      EditDeliveryLocationFreeTextType.TYPE
    ) {
      return DeliveryLocationFreeTextDTO.from(EditDeliveryLocation);
    } else {
      throw new Error(
        `Unknown delivery location definition type ${editDeliveryLocationDefinition?.type}`
      );
    }
  }
}

@ToJSON
class DeliveryLocationChoicesDTO {
  _type = EditDeliveryLocationDefinitionChoices.TYPE;

  _choices;

  constructor(args) {
    const { choices } = args;
    this._choices = choices;
  }

  static from(editDeliveryLocationDefinition) {
    const { choices: choicesSet } = editDeliveryLocationDefinition;

    return new this({
      choices: choicesSet.toArray()
    });
  }
}

@ToJSON
class DeliveryLocationFreeTextDTO {
  _type = DeliveryLocationFreeTextDTO.TYPE;

  _regexp;

  constructor(args) {
    const { regexp } = args;
    this._regexp = regexp;
  }

  static from(editDeliveryLocationDefinition) {
    return new this(editDeliveryLocationDefinition);
  }
}

@ToJSON
class DeliveryTimeOptionsDTO {
  _suggestedDurations;

  _timeZone;

  _allowCustomDeliveryTime;

  _allowInstantDeliveryTime;

  constructor(args) {
    const {
      suggestedDurations,
      timeZone,
      allowCustomDeliveryTime,
      allowInstantDeliveryTime
    } = args;

    this._suggestedDurations = suggestedDurations;
    this._timeZone = timeZone;
    this._allowCustomDeliveryTime = allowCustomDeliveryTime;
    this._allowInstantDeliveryTime = allowInstantDeliveryTime;
  }

  static from(editDeliveryTimeOptions) {
    const {
      suggestedDurations,
      timeZone,
      allowCustomDeliveryTime,
      allowInstantDeliveryTime
    } = editDeliveryTimeOptions;

    return new this({
      suggestedDurations: suggestedDurations.toArray(),
      timeZone,
      allowCustomDeliveryTime,
      allowInstantDeliveryTime
    });
  }
}

export { DeliveryLocationDTO };
