import type { JCataogSection } from './Catalog/CatalogSection';
import { CatalogSection } from './Catalog/CatalogSection';
import type { JCategorySection } from './Category/CategorySection';
import { CategorySection } from './Category/CategorySection';
import type { JDeliveryFlowTypeSection } from './DeliveryFlowType/DeliveryFlowTypeSection';
import { DeliveryFlowTypeSection } from './DeliveryFlowType/DeliveryFlowTypeSection';
import type { JOverviewSection } from './Overview/OverviewSection';
import { OverviewSection } from './Overview/OverviewSection';
import type { JPaymentGroupSection } from './PaymentGroup/PaymentGroupSection';
import { PaymentGroupSection } from './PaymentGroup/PaymentGroupSection';
import type { JPayoutSection } from './Payout/PayoutSection';
import { PayoutSection } from './Payout/PayoutSection';
import type { JProductSection } from './Product/ProductSection';
import { ProductSection } from './Product/ProductSection';
import type { JVatRateSection } from './VatRate/VatRateSection';
import { VatRateSection } from './VatRate/VatRateSection';

interface IDetailedSalesReport {
  from: string;
  to: string;
  totalAmount: string;
  totalCount: number;
  totalTip: string;
  overviewSection: OverviewSection;
  vatRateSection: VatRateSection;
  deliveryFlowTypeSection: DeliveryFlowTypeSection;
  paymentGroupSection: PaymentGroupSection | null;
  payoutSection: PayoutSection | null;
  categorySection: CategorySection;
  catalogSection: CatalogSection;
  productSection: ProductSection;
}

export interface JDetailedSalesReport {
  periodFrom: string;
  periodTo: string;
  totalAmount: string;
  totalCount: number;
  totalTip: string;
  overviewSection: JOverviewSection;
  vatRateSection: JVatRateSection;
  deliveryFlowTypeSection: JDeliveryFlowTypeSection;
  paymentGroupSection: JPaymentGroupSection | null;
  payoutSection: JPayoutSection | null;
  categorySection: JCategorySection;
  catalogSection: JCataogSection;
  productSection: JProductSection;
}

interface DetailedSalesReport extends IDetailedSalesReport {}
class DetailedSalesReport {
  readonly from;
  readonly to;
  readonly totalAmount;
  readonly totalCount;
  readonly totalTip;
  readonly overviewSection;
  readonly vatRateSection;
  readonly deliveryFlowTypeSection;
  readonly paymentGroupSection;
  readonly payoutSection;
  readonly categorySection;
  readonly catalogSection;
  readonly productSection;

  constructor(args: IDetailedSalesReport) {
    const {
      from,
      to,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection,
      vatRateSection,
      deliveryFlowTypeSection,
      paymentGroupSection,
      payoutSection,
      categorySection,
      catalogSection,
      productSection
    } = args;

    this.from = from;
    this.to = to;
    this.totalAmount = totalAmount;
    this.totalCount = totalCount;
    this.totalTip = totalTip;
    this.overviewSection = overviewSection;
    this.vatRateSection = vatRateSection;
    this.deliveryFlowTypeSection = deliveryFlowTypeSection;
    this.paymentGroupSection = paymentGroupSection;
    this.payoutSection = payoutSection;
    this.categorySection = categorySection;
    this.catalogSection = catalogSection;
    this.productSection = productSection;
  }

  static fromJSON(json: JDetailedSalesReport) {
    const {
      periodFrom,
      periodTo,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection,
      vatRateSection,
      deliveryFlowTypeSection,
      paymentGroupSection,
      payoutSection,
      categorySection,
      catalogSection,
      productSection
    } = json;

    return new this({
      from: periodFrom,
      to: periodTo,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection: OverviewSection.fromJSON(overviewSection),
      vatRateSection: VatRateSection.fromJSON(vatRateSection),
      deliveryFlowTypeSection: DeliveryFlowTypeSection.fromJSON(
        deliveryFlowTypeSection
      ),
      paymentGroupSection: paymentGroupSection
        ? PaymentGroupSection.fromJSON(paymentGroupSection)
        : null,
      payoutSection: payoutSection
        ? PayoutSection.fromJSON(payoutSection)
        : null,
      categorySection: CategorySection.fromJSON(categorySection),
      catalogSection: CatalogSection.fromJSON(catalogSection),
      productSection: ProductSection.fromJSON(productSection)
    });
  }
}

export { DetailedSalesReport };
