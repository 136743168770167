import { AdyenMethod } from '../../domain/PaymentMethod/AdyenMethod';
import { PacMethod } from '../../domain/PaymentMethod/PacMethod';
import { PaymentMethodType } from '../../domain/PaymentMethod/PaymentMethod';
import { SwishMethod } from '../../domain/PaymentMethod/SwishMethod';
import type { AdyenPaymentMethodType } from '../../pages/settings/paymentMethods/AdyenForm/ManageAdyenAccount/_constants';
import {
  AdyenAccountDTO,
  type IAdyenAccountDTO
} from '../Adyen/AdyenAccountDTO';

export interface IAdyenPaymentMethodDTO {
  type: PaymentMethodType.ADYEN;
  acceptedTypes: AdyenPaymentMethodType[];
  tier: string;
  ippTier: string;
  storeReference?: string;
  storeActivatedAt?: string;
}

export interface ISwishPaymentMethodDTO {
  type: PaymentMethodType.SWISH;
  payeeAlias: string;
  paymentReferencePrefix?: string;
  hasCertificate: boolean;
}

export interface IPACPaymentMethodDTO {
  type: PaymentMethodType.PAY_AT_COUNTER;
}

export type PaymentMethodDTO =
  | IAdyenPaymentMethodDTO
  | ISwishPaymentMethodDTO
  | IPACPaymentMethodDTO;

export const AdyenPaymentMethodDTO = {
  toAdyenMethod: function (
    dtoAdyenAccount: IAdyenAccountDTO | null,
    dtoMethod?: IAdyenPaymentMethodDTO
  ): AdyenMethod | null {
    if (dtoAdyenAccount) {
      const adyenAccount = AdyenAccountDTO.toAdyenAccount(dtoAdyenAccount);

      if (dtoMethod) {
        const {
          acceptedTypes,
          tier,
          ippTier,
          storeReference,
          storeActivatedAt
        } = dtoMethod;

        const settings = {
          acceptedTypes,
          tier,
          ippTier,
          storeReference,
          storeActivatedAt
        };
        return new AdyenMethod({
          adyenAccount,
          settings
        });
      } else {
        return new AdyenMethod({
          adyenAccount
        });
      }
    } else if (dtoMethod) {
      // TODO: Remove this case after migration
      const { acceptedTypes, tier, ippTier, storeReference, storeActivatedAt } =
        dtoMethod;

      const settings = {
        acceptedTypes,
        tier,
        ippTier,
        storeReference,
        storeActivatedAt
      };
      return new AdyenMethod({
        settings
      });
    } else {
      return null;
    }
  }
};

export const SwishPaymentMethodDTO = {
  toSwishMethod: function (dto: ISwishPaymentMethodDTO): SwishMethod {
    const { payeeAlias, paymentReferencePrefix, hasCertificate } = dto;

    return new SwishMethod({
      payeeAlias,
      paymentReferencePrefix,
      hasCertificate
    });
  }
};

export const PaymentMethodDTO = {
  toPaymenMethod: function (
    dtoMethod: PaymentMethodDTO,
    dtoAdyenAccount: IAdyenAccountDTO | null
  ) {
    const { type } = dtoMethod;

    switch (type) {
      case PaymentMethodType.ADYEN:
        return AdyenPaymentMethodDTO.toAdyenMethod(dtoAdyenAccount, dtoMethod);
      case PaymentMethodType.SWISH:
        return SwishPaymentMethodDTO.toSwishMethod(dtoMethod);
      case PaymentMethodType.PAY_AT_COUNTER:
        return new PacMethod();
    }
  }
};
