interface ISellerPrettyName {
  reference: string;
  name: string;
  preferred: boolean;
}

class SellerPrettyName {
  readonly reference;
  readonly name;
  readonly preferred;

  constructor(args: ISellerPrettyName) {
    const { reference, name, preferred } = args;

    this.reference = reference;
    this.name = name;
    this.preferred = preferred;
  }
}

export { SellerPrettyName };
