import React, { Component } from 'react';
import { observer } from 'mobx-react';

import './polyfills';
import './assets/Global.scss';
import Routes from './routes/Routes';
import { baseManager } from './managers/Manager';
import { AlertList } from './components/AlertList';

const LightTheme = React.lazy(() => import('./themes/LightTheme'));
const DarkTheme = React.lazy(() => import('./themes/DarkTheme'));
const { configStore, authManager, systemMessageManager } = baseManager;

@observer
class App extends Component {
  state = {
    initialized: false
  };

  async componentDidMount() {
    await authManager.init();
    await systemMessageManager.initialize();
    this.setState({ initialized: true });
  }

  componentWillUnmount() {
    configStore.unmount();
    systemMessageManager.unmount();
  }

  render() {
    const { initialized } = this.state;
    const { theme } = configStore;
    const Theme = theme === 'light' ? LightTheme : DarkTheme;

    if (!initialized) {
      return null;
    }

    return (
      <React.Suspense fallback={<></>}>
        <Theme />
        <Routes />
        <AlertList />
      </React.Suspense>
    );
  }
}

export default App;
