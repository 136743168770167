import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { LAYOUT_STATIC } from '../../constants/layout';
import styles from './styles.module.scss';

const Topbar = React.lazy(() => import('../Topbar'));

const loading = () => <div className="text-center" />;

const StaticLayout = ({ children }) => {
  useEffect(() => {
    document.body.setAttribute('data-layout', 'topnav');

    return () => {
      document.body.removeAttribute('data-layout');
    };
  }, []);
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className={classNames(styles.wrapper, 'wrapper')}>
        <div className="d-flex flex-column content-page">
          <Suspense fallback={loading()}>
            <Topbar
              isMenuOpened={false}
              openLeftMenuCallBack={() => null}
              navCssClasses="topnav-navbar"
              layoutType={LAYOUT_STATIC}
            />
          </Suspense>

          <Container className="horizontal-content-container d-flex flex-1 align-items-start align-items-md-center">
            <Suspense fallback={loading()}>{children}</Suspense>
          </Container>

          <Suspense fallback={loading()}>
            <footer className="footer footer-alt">
              {currentYear} © MEIQ Systems AB
            </footer>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default StaticLayout;
