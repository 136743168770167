import axios from 'axios';
import pkg from '../../../package.json';
import type { AxiosInterceptor } from './AxiosInterceptor';

const TUNA = 'TUNA';
const TIMEOUT = 10000;
const { version } = pkg;

export class AxiosClientBuilder {
  private readonly interceptors: AxiosInterceptor[] = [];

  withInterceptor(interceptor: AxiosInterceptor) {
    this.interceptors.push(interceptor);
    return this;
  }

  build() {
    const { interceptors } = this;

    const client = axios.create({
      timeout: TIMEOUT,
      headers: {
        CLIENT_VERSION: `${TUNA}-${version}`,
        CLIENT_VRESION: `${TUNA}-${version}`
      }
    });

    // Register any provded interceptors
    interceptors.forEach(interceptor => interceptor.register(client));

    return client;
  }
}
