import { action, observable } from 'mobx';
import { Getter } from '../domain/Decorators';

@Getter({ computed: true })
class RouterStore {
  @observable
  _currentPath = '';

  @observable
  _previousPath = '';

  @action
  setCurrentPath = path => {
    if (this._currentPath !== path) {
      this._previousPath = this._currentPath;
      this._currentPath = path;
    }
  };
}

export { RouterStore };
