type OptionalValue<T> = T | null | undefined;
type Constructor = new (...args: any[]) => any;

class Optional<T> {
  private readonly value: OptionalValue<T>;

  constructor(value: OptionalValue<T> = null) {
    this.value = value;
  }

  isPresent = () => _isPresent(this.value);

  isEmpty = () => _isEmpty(this.value);

  get = () => {
    if (_isPresent(this.value)) {
      return this.value;
    } else {
      throw new Error('Can not use `get` on an empty optional');
    }
  };

  orElse = <O>(other: O) => (_isPresent(this.value) ? this.value : other);

  orElseGet = <S>(supplier: () => S) => {
    if (_isPresent(this.value)) {
      return this.value;
    } else if (typeof supplier === 'function') {
      return supplier();
    } else {
      throw new Error('Supplier must be a function');
    }
  };

  orElseThrow = <S>(supplier: () => S) => {
    if (_isPresent(this.value)) {
      return this.value;
    } else if (typeof supplier === 'function') {
      throw supplier();
    } else {
      throw new Error('Supplier must be a function');
    }
  };

  map = <M>(mapper: (value: T) => OptionalValue<M>) => {
    if (_isEmpty(this.value)) {
      return new Optional<M>();
    } else if (typeof mapper === 'function') {
      return new Optional<M>(mapper(this.value));
    } else {
      throw new Error('Mapper must be a function');
    }
  };

  flatMap = <M>(mapper: (value: T) => Optional<M>) => {
    if (_isEmpty(this.value)) {
      return new Optional<M>();
    } else if (typeof mapper === 'function') {
      return mapper(this.value);
    } else {
      throw new Error('Mapper must be a function');
    }
  };

  filter = (predicate: (value: T) => boolean) => {
    if (_isEmpty(this.value)) {
      return new Optional<T>();
    } else if (typeof predicate === 'function') {
      if (predicate(this.value)) {
        return new Optional<T>(this.value);
      } else {
        return new Optional<T>();
      }
    } else {
      throw new Error('Predicate must be a function');
    }
  };

  filterInstance = <C extends Constructor>(clazz: C) => {
    const { value } = this;
    if (_isInstanceOf(value, clazz)) {
      return new Optional<InstanceType<C>>(value);
    } else {
      return new Optional<InstanceType<C>>();
    }
  };

  ifPresent = (consumer: (value: T) => void) => {
    if (_isPresent(this.value)) {
      if (typeof consumer === 'function') {
        consumer(this.value);
      } else {
        throw new Error('Consumer must be a function');
      }
    }
  };

  ifPresentAsync = (consumer: (value: T) => Promise<void>) => {
    if (_isPresent(this.value)) {
      if (typeof consumer === 'function') {
        return consumer(this.value);
      } else {
        throw new Error('Consumer must be a function');
      }
    }
  };

  ifPresentOrElse = (
    presentConsumer: (value: T) => void,
    emptyConsumer: () => void
  ) => {
    if (_isPresent(this.value)) {
      if (typeof presentConsumer === 'function') {
        presentConsumer(this.value);
      } else {
        throw new Error('Consumer must be a function');
      }
    } else if (typeof emptyConsumer === 'function') {
      emptyConsumer();
    } else {
      throw new Error('Consumer must be a function');
    }
  };
}

function _isPresent<T>(value: OptionalValue<T>): value is T {
  return value !== null && value !== undefined;
}

function _isEmpty<T>(value: OptionalValue<T>): value is null | undefined {
  return !_isPresent(value);
}

function _isInstanceOf<T, C extends Constructor>(
  value: OptionalValue<T>,
  clazz: C
): value is InstanceType<C> {
  return value instanceof clazz;
}

function of<T>(value: OptionalValue<T>) {
  return new Optional<T>(value);
}

function empty<T>() {
  return new Optional<T>();
}

export default { of, empty };
