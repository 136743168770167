import { computed } from 'mobx';
import CatalogPreview from '../domain/Catalog/CatalogPreview';
import { sortBySortWeight } from '../helpers/Functions';
import { PRODUCT_CATALOG, ENTRY_CATALOG } from '../constants';

class SellerCatalogPreviewManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get sellerCatalogPreviews() {
    const { sellerDataStore } = this._sellerStore;
    const { sellerCatalogPreviews } = sellerDataStore;

    return sellerCatalogPreviews;
  }

  @computed
  get sellerProductCatalogPreviews() {
    const { sellerCatalogPreviews } = this;

    return Object.entries(sellerCatalogPreviews).reduce(
      (accumulator, [reference, catalogPreview]) => {
        const { type } = catalogPreview;
        if (type === PRODUCT_CATALOG) {
          accumulator[reference] = catalogPreview;
        }

        return accumulator;
      },
      {}
    );
  }

  @computed
  get sellerEntryCatalogPreviews() {
    const { sellerCatalogPreviews } = this;

    return Object.entries(sellerCatalogPreviews).reduce(
      (accumulator, [reference, catalogPreview]) => {
        const { type } = catalogPreview;
        if (type === ENTRY_CATALOG) {
          accumulator[reference] = catalogPreview;
        }

        return accumulator;
      },
      {}
    );
  }

  async fetchSellerCatalogPreviews() {
    const { _momsClient: momsClient } = this;
    const { sellerReference, sellerDataStore } = this._sellerStore;

    const catalogs = (
      await momsClient.getSellerCatalogPreviews(sellerReference)
    )
      ?.sort(sortBySortWeight)
      .reduce((accumulator, jsonCatalog) => {
        const { reference } = jsonCatalog;
        const catalog = CatalogPreview.fromJSON(jsonCatalog);

        accumulator[reference] = catalog;
        return accumulator;
      }, {});

    sellerDataStore.setSellerCatalogPreviews(catalogs);
  }
}

export { SellerCatalogPreviewManager };
