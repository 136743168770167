import { ToJSON } from '../../domain/Decorators';

@ToJSON
class EnrollSellerDTO {
  _catalogName;

  _catalogDescription;

  _currency;

  _productRegisterReference;

  constructor(args) {
    const {
      catalogName,
      catalogDescription,
      currency,
      productRegisterReference = null
    } = args;

    this._catalogName = catalogName;
    this._catalogDescription = catalogDescription;
    this._currency = currency;
    this._productRegisterReference = productRegisterReference;
  }
}

export { EnrollSellerDTO as default };
