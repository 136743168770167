import type { IMonetaryAmount } from '../../Amount/MonetaryAmount';
import { MonetaryAmount } from '../../Amount/MonetaryAmount';

const TYPE = 'min_order_amount' as const;

interface ConstructorArgs {
  minOrderAmount: MonetaryAmount;
}

export interface JMinOrderAmountDiscountLimitation {
  minOrderAmount: IMonetaryAmount;
  type: typeof TYPE;
}

class MinOrderAmountDiscountLimitation {
  minOrderAmount;
  type = TYPE;

  constructor(args: ConstructorArgs) {
    const { minOrderAmount } = args;
    this.minOrderAmount = minOrderAmount;
  }

  static fromJSON(json: JMinOrderAmountDiscountLimitation) {
    const { minOrderAmount: jsonAmount } = json;
    const minOrderAmount = MonetaryAmount.fromJSON(jsonAmount);

    return new this({ minOrderAmount });
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { minOrderAmount, type } = this;

    return {
      minOrderAmount: minOrderAmount.toJSON(),
      type
    };
  };
}

export { MinOrderAmountDiscountLimitation };
