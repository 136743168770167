interface ITerminalOrder {
  orderNumber: string;
  weiqOrderReference: string;
  orderDate: string;
  assignedTerminals: string[];
  status: string;
  tracking: string | null;
  history: { newStatus: string; timestamp: string }[];
}

export interface JTerminalOrder {
  adyenOrderId: string;
  weiqOrderReference: string;
  createdAt: string;
  assignedTerminals: string[];
  status: string;
  tracking: string | null;
  history: { newStatus: string; timestamp: string }[];
}

class TerminalOrder {
  readonly orderNumber;
  readonly weiqOrderReference;
  readonly orderDate;
  readonly assignedTerminals;
  readonly status;
  readonly tracking;
  readonly history;

  constructor(args: ITerminalOrder) {
    const {
      orderNumber,
      weiqOrderReference,
      orderDate,
      assignedTerminals,
      status,
      tracking,
      history
    } = args;

    this.orderNumber = orderNumber;
    this.weiqOrderReference = weiqOrderReference;
    this.orderDate = orderDate;
    this.assignedTerminals = assignedTerminals;
    this.status = status;
    this.tracking = tracking;
    this.history = history;
  }

  static fromJSON(json: JTerminalOrder) {
    const {
      createdAt,
      adyenOrderId,
      tracking,
      weiqOrderReference,
      assignedTerminals,
      status,
      history
    } = json;

    return new this({
      orderNumber: adyenOrderId,
      orderDate: createdAt,
      weiqOrderReference,
      assignedTerminals,
      status,
      tracking,
      history
    });
  }

  static keyExtractor(terminalOrder: TerminalOrder) {
    return terminalOrder.weiqOrderReference;
  }
}

export { TerminalOrder };
