import { sortBySortWeight } from '../../helpers/Functions';
import type { ICatalogPreview } from '../Catalog/CatalogPreview';
import type { ICategory } from '../Category/Category';

interface IProduct {
  reference: string;
  name: string;
  description: string;
  type: string;
  price: number;
  vatRate: number;
  currency: string;
  catalogs: ICatalogPreview[];
  modifierCategories: ProductModifierCategory[];
  timeSlots: any[]; // TODO: Add type
  categories: ICategory[];
  tags: any[]; // TODO: Add type;
  productImages: any; // TODO: Add type
}
type JProduct = Omit<IProduct, 'modifierCategories'> & {
  modifierCategories: JProductModifierCategory[];
};
interface Product extends IProduct {}

class Product {
  readonly reference;
  readonly name;
  readonly description;
  readonly type;
  readonly price;
  readonly vatRate;
  readonly currency;
  readonly catalogs;
  readonly modifierCategories;
  readonly timeSlots;
  readonly categories;
  readonly tags;
  readonly productImages;

  constructor(args: IProduct) {
    const {
      reference,
      name,
      description,
      type,
      price,
      vatRate,
      currency,
      catalogs,
      modifierCategories,
      timeSlots,
      categories,
      tags,
      productImages
    } = args;

    this.reference = reference;
    this.name = name;
    this.description = description;
    this.type = type;
    this.price = price;
    this.vatRate = vatRate;
    this.currency = currency;
    this.catalogs = catalogs;
    this.modifierCategories = modifierCategories;
    this.timeSlots = timeSlots;
    this.categories = categories;
    this.tags = tags;
    this.productImages = productImages;
  }

  static fromJSON(json: JProduct) {
    const { modifierCategories, ...rest } = json;

    return new this({
      modifierCategories: modifierCategories
        .map(c => ProductModifierCategory.fromJSON(c))
        .sort(sortBySortWeight),
      ...rest
    });
  }
}

interface IProductModifierCategory {
  reference: string;
  name: string;
  min: number | null;
  max: number | null;
  sortWeight: number;
  modifiers: ProductModifier[];
}
type JProductModifierCategory = Omit<IProductModifierCategory, 'modifiers'> & {
  modifiers: IProductModifier[];
};
interface ProductModifierCategory extends IProductModifierCategory {}

class ProductModifierCategory {
  readonly reference;
  readonly name;
  readonly min;
  readonly max;
  readonly sortWeight;
  readonly modifiers;

  constructor(args: IProductModifierCategory) {
    const { reference, name, min, max, sortWeight, modifiers } = args;

    this.reference = reference;
    this.name = name;
    this.min = min;
    this.max = max;
    this.sortWeight = sortWeight;
    this.modifiers = modifiers;
  }

  static fromJSON(json: JProductModifierCategory) {
    const { modifiers, ...rest } = json;
    return new this({
      modifiers: modifiers.map(m => ProductModifier.fromJSON(m)),
      ...rest
    });
  }
}

interface IProductModifier {
  reference: string;
  name: string;
  productOverrideEnabled: boolean;
  price: number;
  currency: string;
}
interface ProductModifier extends IProductModifierCategory {}

class ProductModifier {
  readonly reference;
  readonly name;
  readonly productOverrideEnabled;
  readonly price;
  readonly currency;

  constructor(args: IProductModifier) {
    const { reference, name, productOverrideEnabled, price, currency } = args;

    this.reference = reference;
    this.name = name;
    this.productOverrideEnabled = productOverrideEnabled;
    this.price = price;
    this.currency = currency;
  }

  static fromJSON(json: IProductModifier) {
    return new this(json);
  }
}

export { Product as default, ProductModifierCategory, ProductModifier };
