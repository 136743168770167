import type { AxiosInstance } from 'axios';
import type { AuthClient } from './AuthClient';
import type { AxiosInterceptor } from './AxiosInterceptor';

export class TokenResponseInterceptor implements AxiosInterceptor {
  private readonly authClient;

  constructor(authClient: AuthClient) {
    this.authClient = authClient;
  }

  register(client: AxiosInstance) {
    client.interceptors.response.use(
      response => response,
      async error => {
        const { authClient } = this;
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;

          console.warn(
            'TokenResponseInterceptor',
            'Recieved status code 401. Trying to update tokens.'
          );

          // Try to get a new token immediately
          await authClient.updateToken(0);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${authClient.token}`;

          return client(originalRequest);
        } else if (error.response?.status === 401 && originalRequest.retry) {
          console.error(
            'TokenResponseInterceptor: Token update failed',
            `Failed original request, updated tokens and failed new request. Forcing logout. Got error ${error}`
          );

          authClient.logout();
        }

        return Promise.reject(error);
      }
    );
  }
}
