import { observable, computed, action } from 'mobx';
import { stringIsEmpty } from '../../helpers/Functions';

type EditModifierGroupArgs = {
  name?: string;
  min?: number | null;
  max?: number | null;
};

class EditModifierGroup {
  @observable
  name;
  @observable
  min;
  @observable
  max;

  constructor(args: EditModifierGroupArgs = {}) {
    const { name = '', min = null, max = null } = args;

    this.name = name;
    this.min = min;
    this.max = max;
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @action
  setMin = (min: number | null) => {
    this.min = min;
  };

  @action
  setMax = (max: number | null) => {
    this.max = max;
  };

  @computed
  get isValid() {
    return !stringIsEmpty(this.name);
  }

  toJSON = () => {
    const { name, min, max } = this;

    return {
      name,
      min,
      max
    };
  };
}

export { EditModifierGroup as default };
