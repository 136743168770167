import type { DailyReport } from '../../domain/DailyReport/DailyReport';

export interface IDailyReportDTO {
  serial: number;
  createdAt: string;
  pdfUrl: string;
  printableOnCastle: boolean;
}

export const DailyReportDTO = {
  toDailyReport(dto: IDailyReportDTO): DailyReport {
    const { serial, createdAt, pdfUrl, printableOnCastle } = dto;
    return {
      serialNumber: serial,
      createdAt,
      pdfUrl,
      printableOnCastle
    };
  }
};
