import { observable } from 'mobx';
import { Getter, Setter, ToJSON } from '../Decorators';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class EditTag {
  @observable
  _name;

  constructor(args = {}) {
    const { name = '' } = args;
    this._name = name;
  }
}

export { EditTag as default };
