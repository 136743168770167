import { getStrings } from '../../config/locale/Functions';
import type { Locale } from '../../config/locale/LanguageStrings';
import { MODIFIER_GROUP_STRINGS } from '../../config/locale/LanguageStrings';
import { sortBySortWeight, truncateString } from '../../helpers/Functions';
import Optional from '../Optional';
import type { IModifierGroupProductPreview } from './ProductPreview';
import ModifierGroupProductPreview from './ProductPreview';

export interface IModifierGroup {
  reference: string;
  name: string;
  min: number | null;
  max: number | null;
  products: ModifierGroupProductPreview[];
}

export interface JModifierGroup {
  reference: string;
  name: string;
  min: number | null;
  max: number | null;
  products: IModifierGroupProductPreview[];
}

interface ModifierGroup extends IModifierGroup {}

class ModifierGroup {
  readonly reference;
  readonly name;
  readonly min;
  readonly max;
  readonly products;

  constructor(args: IModifierGroup) {
    const { reference, name, min, max, products } = args;

    this.reference = reference;
    this.name = name;
    this.min = min;
    this.max = max;
    this.products = products;
  }

  static fromJSON(json: JModifierGroup) {
    const { products, ...rest } = json;

    return new this({
      products: products
        .sort(sortBySortWeight)
        .map(p => ModifierGroupProductPreview.fromJSON(p)),
      ...rest
    });
  }

  toJSON = () => {
    const { reference, name, min, max, products } = this;

    return {
      reference,
      name,
      min,
      max,
      products: products.map(product => product.toJSON())
    };
  };

  getShortProductList(locale: Locale) {
    const strings = getStrings(MODIFIER_GROUP_STRINGS, locale);
    const { products } = this;
    const productNames = products
      .slice(0, 3)
      .map(product => truncateString(product.name, 20))
      .join(', ');

    const moreProducts = Optional.of(products.length)
      .map((length: number) => length - 3)
      .filter((length: number) => length > 0)
      .map((length: number) => ` +${length} ${strings.more}`)
      .orElse('');

    if (productNames) {
      return `${productNames}${moreProducts}`;
    } else {
      return strings.empty;
    }
  }
}

export { ModifierGroup as default };
