import { computed } from 'mobx';
import { sortByName, partitionArray } from '../helpers/Functions';
import ModifierGroup from '../domain/ModifierGroup';

class ModifierGroupManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get modifierGroups() {
    const { sellerDataStore } = this._sellerStore;
    const { modifierGroups } = sellerDataStore;

    return modifierGroups;
  }

  async fetchModifierGroups() {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference, sellerDataStore } = this._sellerStore;

    const modifierGroups = (
      await momsClient.getModifierGroups(productRegisterReference)
    )
      .sort(sortByName)
      .reduce((accumulator, jsonGroup) => {
        const { reference } = jsonGroup;
        const modifierGroup = ModifierGroup.fromJSON(jsonGroup);

        accumulator[reference] = modifierGroup;
        return accumulator;
      }, {});

    sellerDataStore.setModifierGroups(modifierGroups);
  }

  getModifierGroup = async reference => {
    if (this.modifierGroups && this.modifierGroups[reference]) {
      return this.modifierGroups[reference];
    } else {
      // Try to fetch the groups
      await this.fetchModifierGroups();
    }

    return this.modifierGroups[reference];
  };

  createModifierGroup = async editModifierGroup => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.createModifierGroup({
      editModifierGroup,
      productRegisterReference
    });
  };

  updateModifierGroup = async (modifierGroupReference, editModifierGroup) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.updateModifierGroup({
      modifierGroupReference,
      editModifierGroup,
      productRegisterReference
    });
  };

  deleteModifierGroup = async modifierGroupReference => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.deleteModifierGroup({
      modifierGroupReference,
      productRegisterReference
    });
  };

  addProductsToModifierGroup = async (
    modifierGroupReference,
    productReferences
  ) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    const partitions = partitionArray(productReferences, 5);
    for (const partition of partitions) {
      const requests = partition.map(productReference =>
        momsClient.addProductToModifierGroup({
          modifierGroupReference,
          productReference,
          productRegisterReference
        })
      );

      // eslint-disable-next-line no-await-in-loop
      const results = await Promise.all(requests);
      if (results.includes(false)) {
        return false;
      }
    }

    return true;
  };

  removeProductFromModifierGroup = async (
    modifierGroupReference,
    productReference
  ) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.removeProductFromModifierGroup({
      modifierGroupReference,
      productReference,
      productRegisterReference
    });
  };

  updateSortWeightForProductsInModifierGroup = async (
    modifierGroupReference,
    weightMap
  ) => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;
    return momsClient.updateSortWeightForProductsInModifierGroup({
      modifierGroupReference,
      weightMap,
      productRegisterReference
    });
  };
}

export { ModifierGroupManager };
