const TYPE = 'percentage' as const;

export interface IPercentageDiscountModel {
  rate: number;
  type: typeof TYPE;
}

class PercentageDiscountModel {
  readonly rate;
  readonly type = TYPE;

  constructor(args: IPercentageDiscountModel) {
    const { rate } = args;
    this.rate = rate;
  }

  static fromJSON(json: IPercentageDiscountModel) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { rate, type } = this;
    return { rate, type };
  };
}

export { PercentageDiscountModel };
