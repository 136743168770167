import type { MutableAdyenMethod } from '../../domain/Adyen/MutableAdyenMethod';

export interface IUpdateAdyenPaymentMethodDTO {
  paymentMethodTypes: string[];
  tier: string;
  ippTier: string;
}

export const UpdateAdyenPaymentMethodDTO = {
  fromAdyenMethod(
    adyenMethod: MutableAdyenMethod
  ): IUpdateAdyenPaymentMethodDTO {
    const { paymentMethodTypes, tier, ippTier } = adyenMethod;

    return {
      paymentMethodTypes: Array.from(paymentMethodTypes),
      tier,
      ippTier
    };
  }
};
