import { Getter } from '../Decorators';
import { IntegrationType } from './IntegrationType';
import { Srv4PosControlUnit } from './Srv4PosControlUnit';

@Getter
class Srv4PosIntegration {
  _controlUnits;

  constructor(args) {
    const { controlUnits } = args;

    this._controlUnits = controlUnits;
  }

  get type() {
    return IntegrationType.SRV4POS;
  }

  get ecomControlUnits() {
    return this._controlUnits.filter(cu => cu.isEcom);
  }

  get ippControlUnits() {
    return this._controlUnits.filter(cu => !cu.isEcom);
  }

  static fromJSON(json) {
    return new this({
      controlUnits: json.map(cu => Srv4PosControlUnit.fromJSON(cu))
    });
  }
}

export { Srv4PosIntegration };
