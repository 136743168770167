import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import HorizontalLayout from '../layouts/Horizontal/index';
import VerticalLayout from '../layouts/Vertical';
import StaticLayout from '../layouts/Static';
import { baseManager } from '../managers/Manager';

const { authManager } = baseManager;

export const PrivateRoute = ({ component: Component, layout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!authManager.authenticated) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location.pathname,
                search: props.location.search
              }
            }}
          />
        );
      }

      const Layout = (() => {
        switch (layout) {
          case 'horizontal':
            return HorizontalLayout;
          case 'vertical':
            return VerticalLayout;
          case 'static':
            return StaticLayout;
          default:
            return React.Fragment;
        }
      })();

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);
