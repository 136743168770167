import { Getter } from '../Decorators';
import { BookkeepingAccount } from './BookkeepingAccount';

@Getter
class BookkeepingPaymentMethodMapping {
  _paymentMethodType;

  _account;

  constructor(args) {
    const { paymentMethodType, account } = args;

    this._paymentMethodType = paymentMethodType;
    this._account = account;
  }

  static fromJSON(json) {
    const { account: jsonAccount, ...rest } = json;

    const account = jsonAccount
      ? BookkeepingAccount.fromJSON(jsonAccount)
      : null;

    return new this({
      account,
      ...rest
    });
  }
}

export { BookkeepingPaymentMethodMapping };
