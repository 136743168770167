import { RowTypes } from '../RowTypes';
import { v4 as uuidv4 } from 'uuid';

const ROW_TYPE = RowTypes.ROW;

export interface IPayoutRow {
  date: string;
  turnover: string;
  commission: string;
  payout: string;
}

interface PayoutRow extends IPayoutRow {}
class PayoutRow {
  readonly date;
  readonly turnover;
  readonly commission;
  readonly payout;
  readonly key = uuidv4();

  constructor(args: IPayoutRow) {
    const { date, turnover, commission, payout } = args;

    this.date = date;
    this.turnover = turnover;
    this.commission = commission;
    this.payout = payout;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: IPayoutRow) {
    return new this(json);
  }
}

export { PayoutRow };
