export enum Task {
  describeRestaurant = 'describeRestaurant',
  verifyAccount = 'verifyAccount',
  loginToAdmin = 'loginToAdmin',
  createInitialProducts = 'createInitialProducts',
  createMoreProducts = 'createMoreProducts',
  bookIntroMeeting = 'bookIntroMeeting',
  submitBankDetails = 'submitBankDetails'
}

export type AccountTasks =
  | Task.describeRestaurant
  | Task.verifyAccount
  | Task.loginToAdmin;

export type MenuTasks = Task.createInitialProducts | Task.createMoreProducts;

export type MeetingTasks = Task.bookIntroMeeting | Task.submitBankDetails;

export type TaskItems<K extends Task> = Record<K, boolean>;

interface IWelcomeTasks {
  sellerReference: string;
  completed: boolean;
  accountTasks: TaskItems<AccountTasks>;
  menuTasks: TaskItems<MenuTasks>;
  meetingTasks: TaskItems<MeetingTasks>;
}

class WelcomeTasks implements IWelcomeTasks {
  sellerReference;
  completed;
  accountTasks;
  menuTasks;
  meetingTasks;

  constructor(args: IWelcomeTasks) {
    const {
      sellerReference,
      completed,
      accountTasks,
      menuTasks,
      meetingTasks
    } = args;

    this.sellerReference = sellerReference;
    this.completed = completed;
    this.accountTasks = accountTasks;
    this.menuTasks = menuTasks;
    this.meetingTasks = meetingTasks;
  }
}

export { WelcomeTasks };
