import classNames from 'classnames';
import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';

type ButtonType = 'button' | 'submit' | 'reset' | undefined;

type LoadingButtonProps = {
  className?: string;
  onClick?: React.EventHandler<React.MouseEvent>;
  style?: React.CSSProperties;
  loading?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  target?: string;
  hidden?: boolean;
  variant?: string;
  type?: ButtonType;
};

class LoadingButton extends Component<LoadingButtonProps> {
  render() {
    const {
      className,
      onClick,
      style,
      loading,
      children,
      disabled,
      href,
      target,
      hidden,
      variant,
      type = 'button'
    } = this.props;

    return (
      <Button
        className={classNames('position-relative', className)}
        style={style}
        href={href}
        target={target}
        onClick={onClick}
        disabled={loading || disabled}
        hidden={hidden}
        type={type}
        variant={variant}
      >
        {loading ? (
          <Spinner animation="border" size="sm" className={styles.spinner} />
        ) : null}
        <span className={loading ? 'invisible' : undefined}>{children}</span>
      </Button>
    );
  }
}

export default LoadingButton;
