import { action, computed, observable } from 'mobx';
import type { Locale } from '../config/locale/LanguageStrings';

const LOCALE = 'locale';
const THEME = 'theme';
export const LIGHT = 'light';
export const DARK = 'dark';

type LocaleListener = (locale: string) => void;

class ConfigStore {
  @observable
  private _locale: Locale;
  @observable
  private readonly localeListeners: LocaleListener[] = [];
  @observable
  private _theme;
  @observable
  private windowWidth;

  constructor() {
    this._locale = (localStorage.getItem(LOCALE) as Locale) ?? 'sv-SE';
    this._theme = localStorage.getItem(THEME) ?? LIGHT;
    this.windowWidth = window.innerWidth;

    document.documentElement.setAttribute('lang', this._locale.substring(0, 2));
    window.addEventListener('resize', this.onWindowResize);
  }

  unmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  @computed
  get locale() {
    return this._locale;
  }

  @computed
  get theme() {
    return this._theme;
  }

  @action
  setLocale = (locale: Locale) => {
    this._locale = locale;

    document.documentElement.setAttribute('lang', locale.substring(0, 2));
    localStorage.setItem(LOCALE, locale);
    this.localeListeners.forEach(listener => listener(locale));
  };

  @action
  addLocaleListener = (listener: LocaleListener) => {
    this.localeListeners.push(listener);
  };

  @action
  setTheme = (theme: string) => {
    this._theme = theme;
    localStorage.setItem(THEME, theme);
  };

  @action
  toggleTheme = () => {
    const { _theme: theme } = this;
    if (theme === LIGHT) {
      this.setTheme(DARK);
    } else {
      this.setTheme(LIGHT);
    }
  };

  @computed
  get isDark() {
    return this._theme === DARK;
  }

  @action
  onWindowResize = () => {
    this.windowWidth = window.innerWidth;
  };

  @computed
  get overSmBreakpoint() {
    return this.windowWidth >= 576;
  }

  @computed
  get overMdBreakpoint() {
    return this.windowWidth >= 768;
  }

  @computed
  get overLgBreakpoint() {
    return this.windowWidth >= 992;
  }

  @computed
  get overXlBreakpoint() {
    return this.windowWidth >= 1200;
  }

  @computed
  get overXxlBreakpoint() {
    return this.windowWidth >= 1400;
  }
}

export { ConfigStore };
