import { RowTypes } from '../RowTypes';
import { v4 as uuidv4 } from 'uuid';

const ROW_TYPE = RowTypes.ROW;

export interface ICategoryRow {
  categoryName: string;
  count: number;
  amountVatExcluded: string;
  amount: string;
}

interface CategoryRow extends ICategoryRow {}
class CategoryRow {
  readonly categoryName;
  readonly count;
  readonly amountVatExcluded;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: ICategoryRow) {
    const { categoryName, count, amountVatExcluded, amount } = args;

    this.categoryName = categoryName;
    this.count = count;
    this.amountVatExcluded = amountVatExcluded;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: ICategoryRow) {
    return new this(json);
  }
}

export { CategoryRow };
