import { observable, computed, action } from 'mobx';
import { Getter, ToJSON } from '../../Decorators';
import EditOpeningHour from './EditOpeningHour';

@Getter({ computed: true })
@ToJSON
class EditOpeningHourMap {
  @observable
  _MONDAY;

  @observable
  _TUESDAY;

  @observable
  _WEDNESDAY;

  @observable
  _THURSDAY;

  @observable
  _FRIDAY;

  @observable
  _SATURDAY;

  @observable
  _SUNDAY;

  constructor(args = {}) {
    const {
      MONDAY = null,
      TUESDAY = null,
      WEDNESDAY = null,
      THURSDAY = null,
      FRIDAY = null,
      SATURDAY = null,
      SUNDAY = null
    } = args;

    this._MONDAY = MONDAY;
    this._TUESDAY = TUESDAY;
    this._WEDNESDAY = WEDNESDAY;
    this._THURSDAY = THURSDAY;
    this._FRIDAY = FRIDAY;
    this._SATURDAY = SATURDAY;
    this._SUNDAY = SUNDAY;
  }

  static fromJSON(openingHourMapJson) {
    return new this(
      Object.fromEntries(
        Object.entries(openingHourMapJson).map(([day, jsonOpeningHours]) => [
          day,
          Object.freeze(jsonOpeningHours?.map(o => EditOpeningHour.fromJSON(o)))
        ])
      )
    );
  }

  @computed
  get hasOpeningHours() {
    const {
      _MONDAY,
      _TUESDAY,
      _WEDNESDAY,
      _THURSDAY,
      _FRIDAY,
      _SATURDAY,
      _SUNDAY
    } = this;

    return [
      _MONDAY,
      _TUESDAY,
      _WEDNESDAY,
      _THURSDAY,
      _FRIDAY,
      _SATURDAY,
      _SUNDAY
    ].every(openingHours => openingHours);
  }

  @action
  setHasOpeningHours = hasOpeningHours => {
    const value = hasOpeningHours ? [] : null;

    this._MONDAY = value;
    this._TUESDAY = value;
    this._WEDNESDAY = value;
    this._THURSDAY = value;
    this._FRIDAY = value;
    this._SATURDAY = value;
    this._SUNDAY = value;
  };

  @computed
  get isValid() {
    const {
      _MONDAY,
      _TUESDAY,
      _WEDNESDAY,
      _THURSDAY,
      _FRIDAY,
      _SATURDAY,
      _SUNDAY
    } = this;

    return [
      _MONDAY,
      _TUESDAY,
      _WEDNESDAY,
      _THURSDAY,
      _FRIDAY,
      _SATURDAY,
      _SUNDAY
    ]
      .filter(day => day)
      .flatMap(day =>
        day
          .filter(openingHour => !openingHour.isEmpty)
          .map(openingHour => openingHour.isValid)
      )
      .every(Boolean);
  }
}

export { EditOpeningHourMap as default };
