import { Getter } from '../Decorators';

@Getter
class SimpleUser {
  _reference;

  _username;

  _emailAddress;

  constructor(args) {
    const { reference, username, emailAddress } = args;

    this._reference = reference;
    this._username = username;
    this._emailAddress = emailAddress;
  }

  static fromJSON(json) {
    const { userReference, userName, emailAddress } = json;
    return new this({
      reference: userReference,
      username: userName,
      emailAddress
    });
  }
}

export { SimpleUser };
