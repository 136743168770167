import { computed } from 'mobx';
import type { AuthManager } from './AuthManager';
import { WEIQ_ADMIN, WEIQ_REFUND_USER } from '../constants';
import type { IRefundPermission } from '../domain/Permission/RefundPermission';
import { RefundPermission } from '../domain/Permission/RefundPermission';

class PermissionManager {
  private readonly authManager;

  constructor(authManager: AuthManager) {
    this.authManager = authManager;
  }

  @computed
  get primaryRole() {
    return this.authManager.primaryRole;
  }

  @computed
  get refundPermission(): IRefundPermission {
    const { authManager } = this;

    if (authManager.hasRole(WEIQ_ADMIN)) {
      return RefundPermission.forHours(365 * 24);
    } else if (authManager.hasRole(WEIQ_REFUND_USER)) {
      return RefundPermission.forHours(48);
    } else {
      return RefundPermission.DENIED;
    }
  }

  @computed
  get isAdmin() {
    return this.primaryRole === WEIQ_ADMIN;
  }
}

export { PermissionManager };
