import { computed } from 'mobx';
import { sortByName } from '../helpers/Functions';

class TagManager {
  _sellerStore;

  _momsClient;

  constructor(sellerStore, momsClient) {
    this._sellerStore = sellerStore;
    this._momsClient = momsClient;
  }

  @computed
  get tags() {
    const { sellerDataStore } = this._sellerStore;
    const { tags } = sellerDataStore;

    return tags;
  }

  async fetchTags() {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference, sellerDataStore } = this._sellerStore;

    const tags = (await momsClient.getTags(productRegisterReference))
      .sort(sortByName)
      .reduce((accumulator, jsonTag) => {
        const { reference } = jsonTag;

        // TODO: Create domain object and use instead
        accumulator[reference] = jsonTag;
        return accumulator;
      }, {});

    sellerDataStore.setTags(tags);
  }

  createTag = async editTag => {
    const { _momsClient: momsClient } = this;
    const { productRegisterReference } = this._sellerStore;

    return momsClient.createTag({
      editTag,
      productRegisterReference
    });
  };
}

export { TagManager };
