import type {
  AccountTasks,
  MeetingTasks,
  MenuTasks,
  TaskItems
} from '../../domain/WelcomeTasks/WelcomeTasks';
import { Task } from '../../domain/WelcomeTasks/WelcomeTasks';
import { WelcomeTasks } from '../../domain/WelcomeTasks/WelcomeTasks';

export interface IWelcomeTaskListDTO {
  sellerReference: string;
  completed: boolean;
  describeRestaurant: boolean;
  verifyAccount: boolean;
  loginToAdmin: boolean;
  createInitialProducts: boolean;
  createMoreProducts: boolean;
  bookIntroMeeting: boolean;
  submitBankDetails: boolean;
}

export const WelcomeTaskListDTO = {
  toWelcomeTasks(args: IWelcomeTaskListDTO) {
    const {
      sellerReference,
      completed,
      describeRestaurant,
      verifyAccount,
      loginToAdmin,
      createInitialProducts,
      createMoreProducts,
      bookIntroMeeting,
      submitBankDetails
    } = args;

    const accountTasks: TaskItems<AccountTasks> = {
      [Task.describeRestaurant]: describeRestaurant,
      [Task.verifyAccount]: verifyAccount,
      [Task.loginToAdmin]: loginToAdmin
    };

    const menuTasks: TaskItems<MenuTasks> = {
      [Task.createInitialProducts]: createInitialProducts,
      [Task.createMoreProducts]: createMoreProducts
    };

    const meetingTasks: TaskItems<MeetingTasks> = {
      [Task.bookIntroMeeting]: bookIntroMeeting,
      [Task.submitBankDetails]: submitBankDetails
    };

    return new WelcomeTasks({
      sellerReference,
      completed,
      accountTasks,
      menuTasks,
      meetingTasks
    });
  }
};
