import type { JCurrencyFees } from './Fees';
import type { IppProvider } from './IppTier';
import type { PriceType } from './PriceType';
import { ProviderPriceList } from './ProviderPriceList';
import type { EcomProvider } from './Tier';

interface IPriceList {
  provider: string;
  priceTypes: PriceType[];
}

export type JPriceList = {
  domesticConsumerDebit: JCurrencyFees;
  domesticConsumerCredit: JCurrencyFees;
  domesticCommercialDebit: JCurrencyFees;
  domesticCommercialCredit: JCurrencyFees;

  intraEEAConsumerDebit: JCurrencyFees;
  intraEEAConsumerCredit: JCurrencyFees;
  intraEEACommercialDebit: JCurrencyFees;
  intraEEACommercialCredit: JCurrencyFees;

  internationalConsumerDebit: JCurrencyFees;
  internationalConsumerCredit: JCurrencyFees;
  internationalCommercialDebit: JCurrencyFees;
  internationalCommercialCredit: JCurrencyFees;
};

class PriceList {
  provider: string;
  priceTypes: PriceType[];

  constructor(args: IPriceList) {
    const { provider, priceTypes } = args;

    this.provider = provider;
    this.priceTypes = priceTypes;
  }

  static fromJSON(provider: EcomProvider | IppProvider, json: JPriceList) {
    const providerPriceList = ProviderPriceList.fromJSON(json);
    const priceTypes: PriceType[] = Object.values(providerPriceList);

    return new this({ provider, priceTypes });
  }
}

export { PriceList };
