import { ToJSON } from '../../domain/Decorators';

@ToJSON
class UpdateBookkeepingSettingsDTO {
  _verificationSeries;

  _exportType;

  constructor(args) {
    const { verificationSeries, exportType } = args;

    this._verificationSeries = verificationSeries;
    this._exportType = exportType;
  }

  static fromEditBookkeepingSettings(editBookkeepingSettings) {
    return new this(editBookkeepingSettings);
  }
}

export { UpdateBookkeepingSettingsDTO };
