import { Getter } from '../Decorators';

@Getter
class SalesGraph {
  _totalVatExcludedAmount;

  _totalVatAmount;

  _totalAmount;

  _dates;

  _dailySales;

  constructor(args) {
    const {
      totalVatExcludedAmount,
      totalVatAmount,
      totalAmount,
      dates,
      dailySales
    } = args;

    this._totalVatExcludedAmount = totalVatExcludedAmount;
    this._totalVatAmount = totalVatAmount;
    this._totalAmount = totalAmount;
    this._dates = dates;
    this._dailySales = dailySales;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { SalesGraph };
