import React from 'react';

class ExtendedComponent<P = {}, S = {}, SS = any> extends React.Component<
  P,
  S,
  SS
> {
  withBoolState = async <
    F extends (...args: any[]) => any,
    R extends Awaited<ReturnType<F>>
  >(
    stateName: keyof S,
    method: F,
    ...args: Parameters<F>
  ): Promise<R> => {
    this.setState({ [stateName]: true } as unknown as Pick<S, keyof S>);
    const result: R = await method.call(this, ...args);
    this.setState({ [stateName]: false } as unknown as Pick<S, keyof S>);

    return result;
  };
}

export { ExtendedComponent };
