import { RowTypes } from '../RowTypes';
import { v4 as uuidv4 } from 'uuid';

const ROW_TYPE = RowTypes.SUM;

export interface ICategorySummaryRow {
  count: number;
  amountVatExcluded: string;
  amount: string;
}

export interface JCategorySummaryRow {
  totalCount: number;
  totalAmountVatExcluded: string;
  totalAmount: string;
}

interface CategorySummaryRow extends ICategorySummaryRow {}
class CategorySummaryRow {
  readonly count;
  readonly amountVatExcluded;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: ICategorySummaryRow) {
    const { count, amountVatExcluded, amount } = args;

    this.count = count;
    this.amountVatExcluded = amountVatExcluded;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: JCategorySummaryRow) {
    const { totalCount, totalAmountVatExcluded, totalAmount } = json;

    return new this({
      count: totalCount,
      amountVatExcluded: totalAmountVatExcluded,
      amount: totalAmount
    });
  }
}

export { CategorySummaryRow };
