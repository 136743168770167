import type { IAmountDiscountModel } from './AmountDiscountModel';
import { AmountDiscountModel } from './AmountDiscountModel';
import type { IPercentageDiscountModel } from './PercentageDiscountModel';
import { PercentageDiscountModel } from './PercentageDiscountModel';

export type DiscountModel = ReturnType<
  (typeof DiscountModelMapper)['fromJSON']
>;
export type JDiscountModel = IAmountDiscountModel | IPercentageDiscountModel;

class DiscountModelMapper {
  static fromJSON(json: JDiscountModel) {
    const { type } = json;

    switch (type) {
      case AmountDiscountModel.TYPE:
        return AmountDiscountModel.fromJSON(json);
      case PercentageDiscountModel.TYPE:
        return PercentageDiscountModel.fromJSON(json);
      default:
        throw new Error(`Unknown discount model type ${type}`);
    }
  }
}

export { DiscountModelMapper };
