import React, { Suspense, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { LAYOUT_VERTICAL } from '../constants/layout';

const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));
const RightSidebar = React.lazy(() => import('./RightSidebar'));

const loading = () => <div className="" />;

const VerticalLayout = ({ children }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isCondensed, setIsCondensed] = useState(false);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened(prevState => {
      setIsMenuOpened(!prevState);
    });

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable');
      } else {
        document.body.classList.add('sidebar-enable');
      }
    }
  };

  const updateDimensions = () => {
    // activate the condensed sidebar for smaller devices like ipad or tablet
    if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
      document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
      setIsCondensed(true);
    } else {
      document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
      setIsCondensed(false);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isCondensed} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar
                openLeftMenuCallBack={openMenu}
                hideLogo
                showNotificationDropdown
                layoutType={LAYOUT_VERTICAL}
              />
            </Suspense>
            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>

      <Suspense fallback={loading()}>
        <RightSidebar />
      </Suspense>
    </>
  );
};
export default VerticalLayout;
