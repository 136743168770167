import { observable } from 'mobx';
import { Getter, Setter } from '../Decorators';
import { ObservableSet } from '../GeneralSet';

const CHOICES_TYPE = 'choices';
const FREE_TEXT_TYPE = 'free_text';

class EditDeliveryLocationDefinition {
  static fromDefinition(definition) {
    const { type } = definition;

    if (type === EditDeliveryLocationDefinitionChoices.TYPE) {
      return EditDeliveryLocationDefinitionChoices.fromDefinition(definition);
    } else if (type === EditDeliveryLocationFreeTextType.TYPE) {
      return EditDeliveryLocationFreeTextType.fromDefinition(definition);
    } else {
      throw new Error('Unsupported definition type');
    }
  }
}

@Setter({ functions: true })
@Getter({ computed: true })
class EditDeliveryLocationDefinitionChoices {
  @observable
  _choices;

  constructor(args = {}) {
    const { choices = new ObservableSet() } = args;
    this._choices = choices;
  }

  static fromDefinition(definition) {
    const { choices } = definition;
    return new this({
      choices: new ObservableSet(choices)
    });
  }

  static get TYPE() {
    return CHOICES_TYPE;
  }

  get type() {
    return CHOICES_TYPE;
  }
}

@Setter({ functions: true })
@Getter({ computed: true })
class EditDeliveryLocationFreeTextType {
  @observable
  _regexp;

  constructor(args = {}) {
    const { regexp } = args;
    this._regexp = regexp;
  }

  static fromDefinition(definition) {
    return new this(definition);
  }

  static get TYPE() {
    return FREE_TEXT_TYPE;
  }

  get type() {
    return FREE_TEXT_TYPE;
  }
}

export {
  EditDeliveryLocationDefinition,
  EditDeliveryLocationDefinitionChoices,
  EditDeliveryLocationFreeTextType
};
