import type { AxiosInstance } from 'axios';
import type EditModifierGroup from '../../domain/ModifierGroup/EditModifierGroup';
import type CreateProductRegister from '../../domain/ProductRegister/CreateProductRegister';
import type CreateProductDTO from '../../dto/Product/CreateProductDTO';
import type UpdateProductDTO from '../../dto/Product/UpdateProductDTO';
import type EditTag from '../../domain/Tag/EditTag';
import type CreateCatalogDTO from '../../dto/Catalog/CreateCatalogDTO';
import type UpdateCatalogDTO from '../../dto/Catalog/UpdateCatalogDTO';
import type EditCatalogProductOverride from '../../domain/Catalog/EditCatalogProductOverride';
import type EditCategory from '../../domain/Category/EditCategory';
import type { CreatePrettyNamesDTO } from '../../dto/PrettyName/CreatePrettyNamesDTO';

const MOMS_URL =
  process.env.REACT_APP_MOMS_API_URL || 'https://moms.staging.weiq.meiq.se';
const V1_API = `${MOMS_URL}/api/v1`;

class MomsClient {
  private readonly client;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getProductRegisterForSeller(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/seller/${sellerReference}`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get product register for seller', exception);
    }

    return null;
  }

  async getAllProductRegisters() {
    const { client } = this;
    const url = `${V1_API}/product-register`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get product register for seller', exception);
    }

    return null;
  }

  async createProductRegister(productRegister: CreateProductRegister) {
    const { client } = this;
    const url = `${V1_API}/product-register`;

    try {
      const result = await client.post(url, productRegister.toJSON());

      if (result && result.status === 200) {
        return result.data.reference;
      }
    } catch (exception) {
      console.error('Failed to create product register', exception);
    }

    return null;
  }

  async createProductRegisterSellerMapping(
    registerReference: string,
    sellerReference: string
  ) {
    const { client } = this;
    const url = `${V1_API}/product-register/${registerReference}/seller`;

    try {
      const result = await client.post(url, {
        sellerReference
      });
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(
        'Failed to delete product register seller mapping',
        exception
      );
    }

    return false;
  }

  async deleteProductRegisterSellerMapping(
    registerReference: string,
    sellerReference: string
  ) {
    const { client } = this;
    const url = `${V1_API}/product-register/${registerReference}/seller/${sellerReference}`;

    try {
      const result = await client.delete(url);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(
        'Failed to delete product register seller mapping',
        exception
      );
    }

    return false;
  }

  async getModifierGroups(productRegisterReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get modifier groups', exception);
    }

    return null;
  }

  async createModifierGroup(args: {
    productRegisterReference: string;
    editModifierGroup: EditModifierGroup;
  }) {
    const { client } = this;
    const { editModifierGroup, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category`;

    try {
      const result = await client.post(url, editModifierGroup.toJSON());
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to create modifier group', exception);
    }

    return null;
  }

  async updateModifierGroup(args: {
    editModifierGroup: EditModifierGroup;
    modifierGroupReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const {
      editModifierGroup,
      modifierGroupReference,
      productRegisterReference
    } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category/${modifierGroupReference}`;

    try {
      const result = await client.put(url, editModifierGroup.toJSON());
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to create modifier group', exception);
    }

    return false;
  }

  async deleteModifierGroup(args: {
    modifierGroupReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { modifierGroupReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category/${modifierGroupReference}`;

    try {
      const result = await client.delete(url);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to create modifier group', exception);
    }

    return false;
  }

  async addProductToModifierGroup(args: {
    modifierGroupReference: string;
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const {
      modifierGroupReference,
      productReference,
      productRegisterReference
    } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category/${modifierGroupReference}/product/${productReference}`;

    try {
      const result = await client.post(url);
      if (result && result.status === 204) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to add product to modifier group', exception);
    }

    return null;
  }

  async removeProductFromModifierGroup(args: {
    modifierGroupReference: string;
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const {
      modifierGroupReference,
      productReference,
      productRegisterReference
    } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category/${modifierGroupReference}/product/${productReference}`;

    try {
      const result = await client.delete(url);
      if (result && result.status === 204) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to remove product from modifier group', exception);
    }

    return null;
  }

  async updateSortWeightForProductsInModifierGroup(args: {
    modifierGroupReference: string;
    weightMap: Record<string, number>;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { modifierGroupReference, weightMap, productRegisterReference } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/modifier-category/${modifierGroupReference}/product`;

    try {
      const result = await client.put(url, { weightMap });
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(
        'Failed to update sort sort weights for products in modifier group',
        exception
      );
    }

    return false;
  }

  async getProduct(productRegisterReference: string, productReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/${productRegisterReference}/product/${productReference}`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get product', exception);
    }

    return null;
  }

  async createProduct(args: {
    createProductDTO: CreateProductDTO;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { createProductDTO, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/product`;

    try {
      const result = await client.post(url, createProductDTO);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to create product', exception);
    }

    return null;
  }

  async updateProduct(args: {
    updateProductDTO: UpdateProductDTO;
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { updateProductDTO, productReference, productRegisterReference } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/product/${productReference}`;

    try {
      const result = await client.put(url, updateProductDTO);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update product', exception);
    }

    return false;
  }

  async deleteProduct(args: {
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { productReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/product/${productReference}`;

    try {
      const result = await client.delete(url);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to delete product', exception);
    }

    return false;
  }

  async uploadProductImage(args: {
    productRegisterReference: string;
    productReference: string;
    imageSize: string;
    file: File;
  }) {
    const { client } = this;
    const { productRegisterReference, productReference, imageSize, file } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/product/${productReference}/image?size=${imageSize}`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileSize', String(file.size));

    try {
      const result = await client.post(url, formData);
      if (result && result.status === 200) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to create product', exception);
    }

    return false;
  }

  async getCategories(productRegisterReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/${productRegisterReference}/category`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get categories', exception);
    }

    return null;
  }

  async getTags(productRegisterReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/${productRegisterReference}/tag`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get tags', exception);
    }

    return null;
  }

  async createTag(args: {
    editTag: EditTag;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { editTag, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/tag`;

    try {
      const result = await client.post(url, editTag);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to create tag', exception);
    }

    return null;
  }

  async getCatalogPreviews(productRegisterReference: string) {
    const { client } = this;
    const url = `${V1_API}/product-register/${productRegisterReference}/catalog`;

    try {
      const result = await client.get(url);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get catalogs', exception);
    }

    return null;
  }

  async getSellerCatalogPreviews(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/seller/${sellerReference}/catalog`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error(
        `Failed to get catalogs for seller ${sellerReference}`,
        exception
      );
    }

    return null;
  }

  async getCatalog(catalogReference: string) {
    const catalogs = await this.getCatalogs([catalogReference]);
    if (catalogs?.length) {
      return catalogs[0];
    } else {
      return null;
    }
  }

  async getCatalogs(catalogReferences: string[]) {
    const { client } = this;
    const url = `${V1_API}/catalog/batch-get?includeTipProducts=true`;

    try {
      const result = await client.post(url, catalogReferences);
      if (result?.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to get catalogs', exception);
    }

    return null;
  }

  async createCatalog(args: {
    createCatalogDTO: CreateCatalogDTO;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { createCatalogDTO, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog`;

    try {
      const result = await client.post(url, createCatalogDTO);
      if (result?.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to create catalog', exception);
    }

    return null;
  }

  async mapCatalogToSeller(args: {
    catalogReference: string;
    sellerReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, sellerReference, productRegisterReference } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/seller`;

    try {
      const result = await client.post(url, { sellerReference });
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(
        `Failed to map catalog ${catalogReference} to seller ${sellerReference}`,
        exception
      );
    }

    return false;
  }

  async copyProductMappings(args: {
    fromCatalogReference: string;
    toCatalogReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const {
      fromCatalogReference,
      toCatalogReference,
      productRegisterReference
    } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${fromCatalogReference}/copy-product-mappings`;

    try {
      const result = await client.post(url, {
        destinationCatalogReference: toCatalogReference
      });
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(`Failed to copy catalog product mappings`, exception);
    }

    return false;
  }

  async updateCatalog(args: {
    updateCatalogDTO: UpdateCatalogDTO;
    catalogReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { updateCatalogDTO, catalogReference, productRegisterReference } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}`;

    try {
      const result = await client.put(url, updateCatalogDTO);
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update catalog', exception);
    }

    return false;
  }

  async activateTipForCatalog(args: {
    catalogReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/tip`;

    try {
      const result = await client.post(url);
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to activate tip for catalog', exception);
    }

    return false;
  }

  async inactivateTipForCatalog(args: {
    catalogReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/tip`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to inactivate tip for catalog', exception);
    }

    return false;
  }

  async deleteCatalog(args: {
    catalogReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to delete catalog', exception);
    }

    return false;
  }

  async updateCatalogSortWeights(args: {
    weightMap: Record<string, number>;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { weightMap, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/sort-weights`;

    try {
      const result = await client.put(url, { weightMap });
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update catalog sort weights', exception);
    }

    return false;
  }

  async addProductToCatalog(args: {
    catalogReference: string;
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, productReference, productRegisterReference } =
      args;

    try {
      const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/product`;
      const result = await client.post(url, { productReference });
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to add product to catalog', exception);
    }

    return false;
  }

  async removeProductFromCatalog(args: {
    catalogReference: string;
    productReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, productReference, productRegisterReference } =
      args;

    try {
      const result = await client.delete(
        `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/product/${productReference}`
      );
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to remove product from catalog', exception);
    }

    return false;
  }

  async updateCatalogProductOverride(args: {
    catalogReference: string;
    productReference: string;
    productRegisterReference: string;
    editCatalogProductOverride: EditCatalogProductOverride;
  }) {
    const { client } = this;
    const {
      catalogReference,
      productReference,
      productRegisterReference,
      editCatalogProductOverride
    } = args;

    try {
      const result = await client.put(
        `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/product/${productReference}`,
        editCatalogProductOverride
      );
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update catalog product override', exception);
    }

    return false;
  }

  async setProductModifierOverride(args: {
    productRegisterReference: string;
    productReference: string;
    modifierCategoryReference: string;
    modifierReference: string;
    enabled: boolean;
  }) {
    const { client } = this;
    const {
      productRegisterReference,
      productReference,
      modifierCategoryReference,
      modifierReference,
      enabled
    } = args;

    try {
      const result = await client.put(
        `${V1_API}/product-register/${productRegisterReference}/product/${productReference}/modifier-category/${modifierCategoryReference}/modifier/${modifierReference}`,
        { enabled }
      );
      if (result?.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to set product modifier override', exception);
    }

    return false;
  }

  async updateSortWeightForCatalogProducts(args: {
    catalogReference: string;
    weightMap: Record<string, number>;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { catalogReference, weightMap, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/product/sort-weights`;

    try {
      const result = await client.post(url, { weightMap });
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error(
        'Failed to update sort sort weights for catalog products',
        exception
      );
    }

    return false;
  }

  async createCategory(args: {
    editCategory: EditCategory;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { editCategory, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/category`;

    try {
      const result = await client.post(url, editCategory);
      if (result && result.status === 200) {
        return result.data;
      }
    } catch (exception) {
      console.error('Failed to create category', exception);
    }

    return null;
  }

  async updateCategory(args: {
    editCategory: EditCategory;
    categoryReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { editCategory, categoryReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/category/${categoryReference}`;

    try {
      const result = await client.put(url, editCategory);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update category', exception);
    }

    return false;
  }

  async deleteCategory(args: {
    categoryReference: string;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { categoryReference, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/category/${categoryReference}`;

    try {
      const result = await client.delete(url);
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to delete category', exception);
    }

    return false;
  }

  async updateCategorySortWeights(args: {
    weightMap: Record<string, number>;
    productRegisterReference: string;
  }) {
    const { client } = this;
    const { weightMap, productRegisterReference } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/category/sort-weights`;

    try {
      const result = await client.put(url, { weightMap });
      if (result && result.status === 204) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to update category sort weights', exception);
    }

    return false;
  }

  async addPrettyNamesForCatalog(args: {
    productRegisterReference: string;
    catalogReference: string;
    sellerReference: string;
    createPrettyNamesDTO: CreatePrettyNamesDTO;
  }) {
    const { client } = this;
    const {
      productRegisterReference,
      catalogReference,
      sellerReference,
      createPrettyNamesDTO
    } = args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/seller/${sellerReference}/add-pretty-names`;
    try {
      const result = await client.put(url, createPrettyNamesDTO);
      return result.data;
    } catch (exception) {
      console.error('Failed to add pretty names for catalog', exception);
    }
    return null;
  }

  async setPreferredPrettyName(args: {
    productRegisterReference: string;
    catalogReference: string;
    prettyNameReference: string;
  }) {
    const { client } = this;
    const { productRegisterReference, catalogReference, prettyNameReference } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/${catalogReference}/pretty-name/${prettyNameReference}/preferred`;
    try {
      const result = await client.post(url);
      if (result) {
        return true;
      }
    } catch (exception) {
      console.error('Failed to set preferred pretty name', exception);
    }
    return false;
  }

  async validatePrettyNames(args: {
    productRegisterReference: string;
    sellerReference: string;
    createPrettyNameDTOs: CreatePrettyNamesDTO;
  }) {
    const { client } = this;
    const { productRegisterReference, sellerReference, createPrettyNameDTOs } =
      args;

    const url = `${V1_API}/product-register/${productRegisterReference}/catalog/seller/${sellerReference}/validate-pretty-names`;

    try {
      const result = await client.post(url, createPrettyNameDTOs);
      return result.data;
    } catch (exception) {
      console.error('Failed to validate pretty names', exception);
    }
    return null;
  }
}

export { MomsClient };
