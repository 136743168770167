import { CreateWinposIntegrationDTO } from '../../dto/Posg/CreateWinposIntegrationDTO';
import { CreateCaspecoIntegrationDTO } from '../../dto/Posg/CreateCaspecoIntegrationDTO';
import { CreateOnslipIntegrationDTO } from '../../dto/Posg/CreateOnslipIntegrationDTO';
import { CreatePersonalkollenIntegrationDTO } from '../../dto/Posg/CreatePersonalkollenDTO';
import { CreateIntermailIntegrationDTO } from '../../dto/Posg/CreateIntermailIntegrationDTO';
import type { AxiosInstance } from 'axios';
import type { CreateWinposIntegration } from '../../domain/Posg/CreateWinposIntegration';
import type { CreateCaspecoIntegration } from '../../domain/Posg/CreateCaspecoIntegration';
import type { CreateOnslipIntegration } from '../../domain/Posg/CreateOnslipIntegration';
import type { CreatePersonalkollenIntegration } from '../../domain/Posg/CreatePersonalkollenIntegration';
import type { CreateIntermailIntegration } from '../../domain/Posg/CreateIntermailIntegration';

const POSG_URL =
  process.env.REACT_APP_POSG_API_URL || 'https://posg.staging.weiq.meiq.se';

class PosgClient {
  private readonly client;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async createWinposIntegration(
    sellerReference: string,
    createWinposIntegration: CreateWinposIntegration
  ) {
    const { client } = this;
    const url = `${POSG_URL}/integration/winpos/${sellerReference}`;
    const createWinposIntegrationDTO =
      CreateWinposIntegrationDTO.fromCreateWinposIntegration(
        createWinposIntegration
      );

    try {
      const result = await client.post(url, createWinposIntegrationDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create Winpos integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create Winpos integration', exception);
    }

    return false;
  }

  async deleteWinposIntegration(sellerReference: string) {
    const { client } = this;
    const url = `${POSG_URL}/integration/winpos/${sellerReference}`;

    try {
      const result = await client.delete(url);
      if (result?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete Winpos integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to delete Winpos integration', exception);
    }

    return false;
  }

  async createCaspecoIntegration(
    sellerReference: string,
    createCaspecoIntegration: CreateCaspecoIntegration
  ) {
    const { client } = this;
    const url = `${POSG_URL}/integration/caspeco/${sellerReference}`;
    const createCaspecoIntegrationDTO =
      CreateCaspecoIntegrationDTO.fromCreateCaspecoIntegration(
        createCaspecoIntegration,
        sellerReference
      );

    try {
      const result = await client.post(url, createCaspecoIntegrationDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create Caspeco integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create Caspeco integration', exception);
    }

    return false;
  }

  async createOnslipIntegration(
    sellerReference: string,
    createOnslipIntegration: CreateOnslipIntegration
  ) {
    const { client } = this;
    const url = `${POSG_URL}/integration/onslip/${sellerReference}`;
    const createOnslipIntegrationDTO =
      CreateOnslipIntegrationDTO.fromCreateOnslipIntegration(
        createOnslipIntegration
      );

    try {
      const result = await client.post(url, createOnslipIntegrationDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create Onslip integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create Onslip integration', exception);
    }

    return false;
  }

  async createPersonalkollenIntegration(
    sellerReference: string,
    createPersonalkollenIntegration: CreatePersonalkollenIntegration
  ) {
    const { client } = this;
    const url = `${POSG_URL}/integration/personalkollen/${sellerReference}`;
    const createPersonalkollenIntegrationDTO =
      CreatePersonalkollenIntegrationDTO.fromCreatePersonalkollenIntegration(
        createPersonalkollenIntegration
      );

    try {
      const result = await client.post(url, createPersonalkollenIntegrationDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create Personalkollen integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create Personalkollen integration', exception);
    }

    return false;
  }

  async createIntermailIntegration(
    sellerReference: string,
    createIntermailIntegration: CreateIntermailIntegration
  ) {
    const { client } = this;
    const url = `${POSG_URL}/integration/intermail/${sellerReference}`;
    const createIntermailIntegrationDTO =
      CreateIntermailIntegrationDTO.fromCreateIntermailIntegration(
        createIntermailIntegration
      );

    try {
      const result = await client.post(url, createIntermailIntegrationDTO);
      if (result?.status === 200) {
        return true;
      } else {
        console.error(
          'Failed to create Intermail integration. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to create Intermail integration', exception);
    }

    return false;
  }

  async getActiveIntegrationTypes(sellerReference: string) {
    const { client } = this;
    const url = `${POSG_URL}/integration/${sellerReference}/types`;

    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get active pos integrations. Posg responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get active pos integrations. Posg responded with',
        exception
      );
    }

    return null;
  }
}

export { PosgClient as default };
