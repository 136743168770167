import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateIntermailIntegrationDTO {
  _username;

  _password;

  _externalStoreId;

  constructor(args) {
    const { username, password, externalStoreId } = args;

    this._username = username;

    this._password = password;

    this._externalStoreId = externalStoreId;
  }

  static fromCreateIntermailIntegration(createIntermailIntegration) {
    const { username, password, externalStoreId } = createIntermailIntegration;

    return new this({
      username,
      password,
      externalStoreId
    });
  }
}

export { CreateIntermailIntegrationDTO };
