import { ToJSON } from '../../domain/Decorators';
import { MessageContentDTO } from './MessageContentDTO';

@ToJSON
class CustomMessageDTO {
  _hash;

  _status;

  _messageContent;

  constructor(args) {
    const { hash, status, messageContent } = args;

    this._hash = hash;
    this._status = status;
    this._messageContent = messageContent;
  }

  static fromCustomMessage(customMessage) {
    const { hash, status, messageContent } = customMessage;

    return new this({
      hash,
      status,
      messageContent: MessageContentDTO.fromMessageContent(messageContent)
    });
  }
}

export { CustomMessageDTO };
