import type { IAdyenBillingEntity } from './AdyenBillingEntity';
import { AdyenBillingEntity } from './AdyenBillingEntity';
import type { IAdyenShippingEntity } from './AdyenShippingEntity';
import { AdyenShippingEntity } from './AdyenShippingEntity';
import type { ITerminalOrderLineItem } from './TerminalOrderLineItem';
import { TerminalOrderLineItem } from './TerminalOrderLineItem';

interface ITerminalOrderDetails {
  billingEntity: AdyenBillingEntity;
  id: string;
  items: TerminalOrderLineItem[];
  orderDate: string;
  shippingLocation: AdyenShippingEntity;
  status: string;
  trackingUrl: string | null;
}

export interface JTerminalOrderDetails {
  billingEntity: IAdyenBillingEntity;
  id: string;
  items: ITerminalOrderLineItem[];
  orderDate: string;
  shippingLocation: IAdyenShippingEntity;
  status: string;
  trackingUrl: string | null;
}

class TerminalOrderDetails {
  readonly billingEntity;
  readonly id;
  readonly items;
  readonly orderDate;
  readonly shippingLocation;
  readonly status;
  readonly trackingUrl;

  constructor(args: ITerminalOrderDetails) {
    const {
      billingEntity,
      id,
      items,
      orderDate,
      shippingLocation,
      status,
      trackingUrl
    } = args;

    this.billingEntity = billingEntity;
    this.id = id;
    this.items = items;
    this.orderDate = orderDate;
    this.shippingLocation = shippingLocation;
    this.status = status;
    this.trackingUrl = trackingUrl;
  }

  static fromJSON(json: JTerminalOrderDetails) {
    const { billingEntity, shippingLocation, items: jsonItems, ...rest } = json;

    const items = jsonItems.map(item => TerminalOrderLineItem.fromJSON(item));

    return new this({
      billingEntity: AdyenBillingEntity.fromJSON(billingEntity),
      shippingLocation: AdyenShippingEntity.fromJSON(shippingLocation),
      items,
      ...rest
    });
  }
}

export { TerminalOrderDetails };
