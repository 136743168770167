import { action, computed, observable } from 'mobx';
import { WEIQ_TERMS_URL } from '../../constants';
import { stringNotEmpty } from '../../helpers/Functions';
import { Getter, Setter } from '../Decorators';
import { MutableBillingDetails } from './MutableBillingDetails';
import { MutableLocation } from './MutableLocation';
import { MutableMetadata } from './MutableSellerMetadata';
import { SellerStatus } from './SellerStatus';

@Setter({ functions: true })
@Getter({ computed: true })
class MutableSeller {
  @observable
  _location;

  @observable
  _status;

  @observable
  _name;

  @observable
  _description;

  @observable
  _termsUrl;

  @observable
  _metadata;

  @observable
  _corporateIdentificationNumber;

  @observable
  _vatIdentificationNumber;

  @observable
  _billingDetails;

  @observable
  _messageEnabled;

  @observable
  _locale;

  @observable
  _country;

  @observable
  _currency;

  @observable
  _timezone;

  @observable
  _automaticDailyReports;

  @observable
  _automaticDailyReportsHourOfDay;

  constructor(args = {}) {
    const {
      location = new MutableLocation(),
      status = SellerStatus.PREVIEW,
      name = '',
      description = '',
      termsUrl = WEIQ_TERMS_URL,
      metadata = new MutableMetadata(),
      corporateIdentificationNumber = '',
      vatIdentificationNumber = '',
      billingDetails = new MutableBillingDetails(),
      messageEnabled = false,
      locale = 'sv-SE',
      country = 'SE',
      currency = 'SEK',
      timezone = 'Europe/Stockholm',
      automaticDailyReports = true,
      automaticDailyReportsHourOfDay = 0
    } = args;

    this._location = location;
    this._status = status;
    this._name = name;
    this._description = description;
    this._termsUrl = termsUrl;
    this._metadata = metadata;
    this._corporateIdentificationNumber = corporateIdentificationNumber;
    this._vatIdentificationNumber = vatIdentificationNumber;
    this._billingDetails = billingDetails;
    this._messageEnabled = messageEnabled;
    this._locale = locale;
    this._country = country;
    this._currency = currency;
    this._timezone = timezone;
    this._automaticDailyReports = automaticDailyReports;
    this._automaticDailyReportsHourOfDay = automaticDailyReportsHourOfDay;
  }

  static fromSeller(seller) {
    const {
      location,
      status,
      metadata,
      billingDetails,
      name,
      description,
      termsUrl,
      corporateIdentificationNumber,
      vatIdentificationNumber,
      messageEnabled,
      locale,
      country,
      currency,
      timezone,
      automaticDailyReports,
      automaticDailyReportsHourOfDay
    } = seller;

    return new this({
      location: MutableLocation.fromLocation(location),
      status: status,
      metadata: MutableMetadata.fromMetadata(metadata),
      billingDetails: MutableBillingDetails.fromBillingDetails(billingDetails),
      name,
      description,
      termsUrl,
      corporateIdentificationNumber,
      vatIdentificationNumber,
      messageEnabled,
      locale,
      country,
      currency,
      timezone,
      automaticDailyReports,
      automaticDailyReportsHourOfDay
    });
  }

  @action
  toggleMessageEnabled = () => {
    this._messageEnabled = !this._messageEnabled;
  };

  @computed
  get isValid() {
    return (
      stringNotEmpty(this._name) &&
      stringNotEmpty(this._description) &&
      this._description.length <= 250 &&
      stringNotEmpty(this._termsUrl) &&
      stringNotEmpty(this._corporateIdentificationNumber) &&
      stringNotEmpty(this._vatIdentificationNumber) &&
      stringNotEmpty(this._locale) &&
      this._billingDetails.isValid &&
      this._metadata.isValid
    );
  }
}

export { MutableSeller };
