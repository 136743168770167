import { OrderLineItemPreview } from '../../domain/OrderPreview/OrderLineItemPreview';

export interface IOrderLineItemPreviewDTO {
  reference: string;
  categoryReference: string | null;
  type: string;
  name: string;
  quantity: number;
  message: string | null;
  deliveryLocationReference: string;
  catalogLineItemReference: string;
}

class OrderLineItemPreviewDTO {
  static toOrderLineItemPreview(
    orderLineItemPreviewDTO: IOrderLineItemPreviewDTO
  ) {
    const {
      reference,
      categoryReference,
      type,
      name,
      quantity,
      message,
      deliveryLocationReference,
      catalogLineItemReference
    } = orderLineItemPreviewDTO;

    return new OrderLineItemPreview({
      reference,
      categoryReference,
      type,
      name,
      quantity,
      message,
      deliveryLocationReference,
      catalogLineItemReference
    });
  }
}

export { OrderLineItemPreviewDTO };
