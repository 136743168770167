import { ToJSON } from '../../domain/Decorators';

@ToJSON
export class SellerDTO {
  _location;

  _status;

  _name;

  _description;

  _metadata;

  _billingDetails;

  _corporateIdentificationNumber;

  _vatIdentificationNumber;

  _termsUrl;

  _messageEnabled;

  _locale;

  _country;

  _currency;

  _timezone;

  _automaticDailyReports;

  _automaticDailyReportsHourOfDay;

  constructor(args) {
    const {
      location,
      status,
      name,
      description,
      metadata,
      billingDetails,
      corporateIdentificationNumber,
      vatIdentificationNumber,
      termsUrl,
      messageEnabled,
      locale,
      country,
      currency,
      timezone,
      automaticDailyReports,
      automaticDailyReportsHourOfDay
    } = args;

    this._location = location;
    this._status = status;
    this._name = name;
    this._description = description;
    this._metadata = metadata;
    this._billingDetails = billingDetails;
    this._corporateIdentificationNumber = corporateIdentificationNumber;
    this._vatIdentificationNumber = vatIdentificationNumber;
    this._termsUrl = termsUrl;
    this._messageEnabled = messageEnabled;
    this._locale = locale;
    this._country = country;
    this._currency = currency;
    this._timezone = timezone;
    this._automaticDailyReports = automaticDailyReports;
    this._automaticDailyReportsHourOfDay = automaticDailyReportsHourOfDay;
  }

  static fromMutableSeller(seller) {
    const {
      location,
      status,
      metadata,
      billingDetails,
      name,
      description,
      termsUrl,
      corporateIdentificationNumber,
      vatIdentificationNumber,
      messageEnabled,
      locale,
      country,
      currency,
      timezone,
      automaticDailyReports,
      automaticDailyReportsHourOfDay
    } = seller;

    return new this({
      location: LocationDTO.fromLocation(location),
      status: status,
      metadata: SellerMetadataDTO.fromMetadata(metadata),
      billingDetails:
        SellerBillingDetailsDTO.fromBillingDetails(billingDetails),
      name,
      description,
      termsUrl,
      corporateIdentificationNumber,
      vatIdentificationNumber,
      messageEnabled,
      locale,
      country,
      currency,
      timezone,
      automaticDailyReports,
      automaticDailyReportsHourOfDay
    });
  }
}

@ToJSON
class LocationDTO {
  _latitude;

  _longitude;

  constructor(args) {
    const { latitude, longitude } = args;

    this._latitude = latitude;
    this._longitude = longitude;
  }

  static fromLocation(location) {
    return new this(location);
  }
}

@ToJSON
class SellerMetadataDTO {
  _address;

  _city;

  _zipCode;

  _banners;

  constructor(args) {
    const { address, city, zipCode, banners } = args;
    this._address = address;
    this._city = city;
    this._zipCode = zipCode;
    this._banners = banners;
  }

  static fromMetadata(metadata) {
    const { banners, address, city, zipCode } = metadata;
    return new this({
      banners: banners.map(banner => SellerBannerDTO.fromBanner(banner)),
      address,
      city,
      zipCode
    });
  }
}

@ToJSON
class SellerBannerDTO {
  _url;

  _type;

  _urlToLink;

  constructor(args) {
    const { url, type, urlToLink } = args;
    this._url = url;
    this._type = type;
    this._urlToLink = urlToLink;
  }

  static fromBanner(banner) {
    return new this(banner);
  }
}

@ToJSON
class SellerBillingDetailsDTO {
  _legalName;

  _address;

  _email;

  _phone;

  _city;

  _zipCode;

  _country;

  _reportEmailAddresses;

  _sendSieAsEmail;

  _fiscalYearStart;

  constructor(args) {
    const {
      legalName,
      address,
      email,
      phone,
      city,
      zipCode,
      country,
      reportEmailAddresses,
      sendSieAsEmail,
      fiscalYearStart
    } = args;

    this._legalName = legalName;
    this._address = address;
    this._email = email;
    this._phone = phone;
    this._city = city;
    this._zipCode = zipCode;
    this._country = country;
    this._reportEmailAddresses = reportEmailAddresses;
    this._sendSieAsEmail = sendSieAsEmail;
    this._fiscalYearStart = fiscalYearStart;
  }

  static fromBillingDetails(billingDetails) {
    const {
      legalName,
      address,
      email,
      phone,
      city,
      zipCode,
      country,
      reportEmailAddresses,
      sendSieAsEmail,
      fiscalYearStart
    } = billingDetails;

    return new this({
      legalName,
      address,
      email,
      phone,
      city,
      zipCode,
      country,
      reportEmailAddresses: reportEmailAddresses.toArray(),
      sendSieAsEmail,
      fiscalYearStart
    });
  }
}
