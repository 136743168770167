import { Getter } from '../Decorators';

@Getter
class RecentSales {
  _totalSalesToday;

  _totalSalesCurrentMonth;

  constructor(args) {
    const { totalSalesToday, totalSalesCurrentMonth } = args;

    this._totalSalesToday = totalSalesToday;
    this._totalSalesCurrentMonth = totalSalesCurrentMonth;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { RecentSales };
