import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.ROW;

export interface IProductRow {
  productName: string;
  count: number;
  amountVatExcluded: string;
  amount: string;
}

interface ProductRow extends IProductRow {}
class ProductRow {
  readonly productName;
  readonly count;
  readonly amountVatExcluded;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IProductRow) {
    const { productName, count, amountVatExcluded, amount } = args;

    this.productName = productName;
    this.count = count;
    this.amountVatExcluded = amountVatExcluded;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: IProductRow) {
    return new this(json);
  }
}

export { ProductRow };
