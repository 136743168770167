import type { GenerateDiscountCodes } from '../../domain/DiscountCode/GenerateDiscountCodes';
import { assertNotNull } from '../../helpers/Functions';

interface IGenerateDiscountCodesDTO {
  prefix: string;
  quantity: number;
  balance: number | null;
}

class GenerateDiscountCodesDTO {
  readonly prefix;
  readonly quantity;
  readonly balance;

  constructor(args: IGenerateDiscountCodesDTO) {
    const { prefix, quantity, balance } = args;

    this.prefix = prefix;
    this.quantity = quantity;
    this.balance = balance;
  }

  static fromGenerateDiscountCodes(
    generateDiscountCodes: GenerateDiscountCodes
  ) {
    const { prefix, quantity, balance } = generateDiscountCodes;

    assertNotNull(quantity);
    return new this({
      prefix,
      quantity,
      balance
    });
  }

  toJSON = () => {
    const { prefix, quantity, balance } = this;
    return { prefix, quantity, balance };
  };
}

export { GenerateDiscountCodesDTO };
