import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreatePrettyNamesDTO {
  _prettyNames;

  constructor(prettyNames) {
    this._prettyNames = prettyNames;
  }

  static fromPrettyNames(prettyNames) {
    return new this(prettyNames);
  }
}

export { CreatePrettyNamesDTO };
