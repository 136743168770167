import type { IAmount } from '../Amount/Amount';
import { Amount } from '../Amount/Amount';
import { MonetaryAmount, type IMonetaryAmount } from '../Amount/MonetaryAmount';

export interface IOrderLineItem {
  reference: string;
  name: string;
  description: string | null;
  createdAt: string;
  modifiedAt: string;
  modifiers: string[];
  imageUrl: string | null;
  bigImageUrl: string | null;
  wideImageUrl: string | null;
  basketImageUrl: string | null;
  amount: IAmount;
  quantity: number;
  catalogReference: string;
  category: string | null;
  categoryReference: string | null;
  message: string | null;
  deliveryLocationReference: string;
  ticketReference: string;
  catalogLineItemRef: string;
  discountName: string | null;
  discountedAmount: MonetaryAmount;
  totalAmount: number;
  totalVatAmount: number;
  lineItemOptions: any; // TODO: Add interface if needed
  type: string;
}

export type JOrderLineItem = Omit<
  IOrderLineItem,
  'amount' | 'discountedAmount'
> & {
  amount: IAmount;
  discountedAmount: IMonetaryAmount;
};

interface OrderLineItem extends IOrderLineItem {}
class OrderLineItem {
  readonly reference;
  readonly name;
  readonly description;
  readonly createdAt;
  readonly modifiedAt;
  readonly modifiers;
  readonly imageUrl;
  readonly bigImageUrl;
  readonly wideImageUrl;
  readonly basketImageUrl;
  readonly amount;
  readonly quantity;
  readonly catalogReference;
  readonly category;
  readonly categoryReference;
  readonly message;
  readonly deliveryLocationReference;
  readonly ticketReference;
  readonly catalogLineItemRef;
  readonly discountName;
  readonly discountedAmount;
  readonly totalAmount;
  readonly totalVatAmount;
  readonly lineItemOptions;
  readonly type;

  constructor(args: IOrderLineItem) {
    const {
      reference,
      name,
      description,
      createdAt,
      modifiedAt,
      modifiers,
      imageUrl,
      bigImageUrl,
      wideImageUrl,
      basketImageUrl,
      amount,
      quantity,
      catalogReference,
      category,
      categoryReference,
      message,
      deliveryLocationReference,
      ticketReference,
      catalogLineItemRef,
      discountName,
      discountedAmount,
      totalAmount,
      totalVatAmount,
      lineItemOptions,
      type
    } = args;

    this.reference = reference;
    this.name = name;
    this.description = description;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    this.modifiers = modifiers;
    this.imageUrl = imageUrl;
    this.bigImageUrl = bigImageUrl;
    this.wideImageUrl = wideImageUrl;
    this.basketImageUrl = basketImageUrl;
    this.amount = Amount.fromJSON(amount);
    this.quantity = quantity;
    this.catalogReference = catalogReference;
    this.category = category;
    this.categoryReference = categoryReference;
    this.message = message;
    this.deliveryLocationReference = deliveryLocationReference;
    this.ticketReference = ticketReference;
    this.catalogLineItemRef = catalogLineItemRef;
    this.discountName = discountName;
    this.discountedAmount = discountedAmount;
    this.totalAmount = totalAmount;
    this.totalVatAmount = totalVatAmount;
    this.lineItemOptions = lineItemOptions;
    this.type = type;
  }

  static fromJSON(json: JOrderLineItem) {
    const {
      amount: jsonAmount,
      discountedAmount: jsonDiscountedAmount,
      ...rest
    } = json;

    const amount = Amount.fromJSON(jsonAmount);
    const discountedAmount = MonetaryAmount.fromJSON(jsonDiscountedAmount);
    return new this({ amount, discountedAmount, ...rest });
  }
}

export { OrderLineItem };
