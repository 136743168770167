import type { IPayoutRow } from './PayoutRow';
import { PayoutRow } from './PayoutRow';
import type { JPayoutSummaryRow } from './PayoutSummaryRow';
import { PayoutSummaryRow } from './PayoutSummaryRow';

export interface IPayoutSection {
  payoutRows: PayoutRow[];
  summaryRow: PayoutSummaryRow;
}

export interface JPayoutSection {
  payoutRows: IPayoutRow[];
  summaryRow: JPayoutSummaryRow;
}

type PayoutSectionRow = PayoutRow | PayoutSummaryRow;

interface PayoutSection extends IPayoutSection {}
class PayoutSection {
  readonly payoutRows;
  readonly summaryRow;

  constructor(args: IPayoutSection) {
    const { payoutRows, summaryRow } = args;

    this.payoutRows = payoutRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): PayoutSectionRow[] => {
    const { payoutRows, summaryRow } = this;
    return [...payoutRows, summaryRow];
  };

  static fromJSON(json: JPayoutSection) {
    const { payoutRows, summaryRow } = json;
    const rows = payoutRows.map(row => PayoutRow.fromJSON(row));

    return new this({
      payoutRows: rows,
      summaryRow: PayoutSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: PayoutSectionRow) {
    return row.key;
  }
}

export { PayoutSection };
