import type { MutableTerminalOrder } from '../../domain/Terminal/MutableTerminalOrder';
import type { IShippingDetailsDTO } from './ShippingDetailsDTO';
import { ShippingDetailsDTO } from './ShippingDetailsDTO';

export interface ICreateAdyenTerminalShippingLocationDTO {
  shippingDetails: IShippingDetailsDTO;
}

export const CreateAdyenTerminalShippingLocationDTO = {
  fromMutableTerminalOrder: (
    mutableTerminalOrder: MutableTerminalOrder
  ): ICreateAdyenTerminalShippingLocationDTO => {
    const { shippingDetails, contactDetails } = mutableTerminalOrder;

    const shippingDetailsDTO = ShippingDetailsDTO.fromMutable(
      shippingDetails,
      contactDetails
    );

    return {
      shippingDetails: shippingDetailsDTO
    };
  }
};
