import { action, computed, observable } from 'mobx';

class SystemMessageStore {
  @observable
  _activeSystemMessageThreads = [];

  @observable
  _activeSystemMessageThreadsForAdmin = [];

  @observable
  _activeGlobalMessageThreads = [];

  @action
  setActiveSystemMessageThreads = activeSystemMessageThreads => {
    this._activeSystemMessageThreads = activeSystemMessageThreads;
  };

  @action
  setActiveSystemMessageThreadsForAdmin =
    activeSystemMessageThreadsForAdmin => {
      this._activeSystemMessageThreadsForAdmin =
        activeSystemMessageThreadsForAdmin;
    };

  @action
  setActiveGlobalMessageThreads = activeGlobalMessageThreads => {
    this._activeGlobalMessageThreads = activeGlobalMessageThreads;
  };

  @computed
  get activeSystemMessageThreads() {
    return this._activeSystemMessageThreads;
  }

  @computed
  get activeSystemMessageThreadsForAdmin() {
    return this._activeSystemMessageThreadsForAdmin;
  }

  @computed
  get activeGlobalMessageThreads() {
    return this._activeGlobalMessageThreads;
  }
}
export { SystemMessageStore };
