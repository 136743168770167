import type { Task } from '../../domain/WelcomeTasks/WelcomeTasks';
import type { IWelcomeTaskListDTO } from './WelcomeTaskListDTO';

export interface IUpdatedTaskListDTO
  extends Partial<Omit<IWelcomeTaskListDTO, 'sellerReference' | 'completed'>> {}

export const UpdatedTaskListDTO = {
  fromUpdatedTasks(updatedTasks: Task[]): IUpdatedTaskListDTO {
    const completedTasks = updatedTasks.reduce(
      (accumulator: IUpdatedTaskListDTO, task: Task) => {
        accumulator[task] = true;
        return accumulator;
      },
      {}
    );

    return completedTasks;
  }
};
