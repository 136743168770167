interface IBookkeepingExport {
  serial: number;
  bookkeepingReference: string;
  exportedAt: string;
  contentHash: string;
  exportType: string;
  filename: string;
  from: string;
  to: string;
}

interface BookkeepingExport extends IBookkeepingExport {}
class BookkeepingExport {
  readonly serial;
  readonly bookkeepingReference;
  readonly exportedAt;
  readonly contentHash;
  readonly exportType;
  readonly filename;
  readonly from;
  readonly to;

  constructor(args: IBookkeepingExport) {
    const {
      serial,
      bookkeepingReference,
      exportedAt,
      contentHash,
      exportType,
      filename,
      from,
      to
    } = args;

    this.serial = serial;
    this.bookkeepingReference = bookkeepingReference;
    this.exportedAt = exportedAt;
    this.contentHash = contentHash;
    this.exportType = exportType;
    this.filename = filename;
    this.from = from;
    this.to = to;
  }

  static fromJSON(json: IBookkeepingExport) {
    return new this(json);
  }

  static keyExtractor(bookkeepingExport: BookkeepingExport) {
    return String(bookkeepingExport.serial);
  }
}

export { BookkeepingExport };
