class PosIntegrationManager {
  _posgClient;

  _sellerStore;

  constructor(posgClient, sellerStore) {
    this._posgClient = posgClient;
    this._sellerStore = sellerStore;
  }

  fetchActiveIntegrationTypes = async () => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.getActiveIntegrationTypes(sellerReference);
  };

  createWinposIntegration = async createWinposIntegration => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.createWinposIntegration(
      sellerReference,
      createWinposIntegration
    );
  };

  deleteWinposIntegration = async () => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.deleteWinposIntegration(sellerReference);
  };

  createCaspecoIntegration = async createCaspecoIntegration => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.createCaspecoIntegration(
      sellerReference,
      createCaspecoIntegration
    );
  };

  createOnslipIntegration = async createOnslipIntegration => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.createOnslipIntegration(
      sellerReference,
      createOnslipIntegration
    );
  };

  createPersonalkollenIntegration = async createPersonalkollenIntegration => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.createPersonalkollenIntegration(
      sellerReference,
      createPersonalkollenIntegration
    );
  };

  createIntermailIntegration = async createIntermailIntegration => {
    const { _posgClient: posgClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    return posgClient.createIntermailIntegration(
      sellerReference,
      createIntermailIntegration
    );
  };
}

export { PosIntegrationManager };
