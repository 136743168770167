import { action, observable, computed } from 'mobx';
import type {
  PaymentMethod,
  PaymentMethodType
} from '../domain/PaymentMethod/PaymentMethod';

class PaymentMethodStore {
  @observable
  private _activePaymentMethods = new Map<PaymentMethodType, PaymentMethod>();

  @action
  setActivePaymentMethods = (
    activePaymentMethods: Map<PaymentMethodType, PaymentMethod>
  ) => {
    if (activePaymentMethods) {
      this._activePaymentMethods = activePaymentMethods;
    }
  };

  @computed
  get activePaymentMethods() {
    return this._activePaymentMethods;
  }
}
export { PaymentMethodStore };
