import type { IMonetaryAmount } from '../Amount/MonetaryAmount';
import { MonetaryAmount } from '../Amount/MonetaryAmount';
import type { OrderLineItem } from './OrderLineItem';

interface ITicket {
  reference: string;
  statusExpiresAt: string | null;
  validFrom: string | null;
  validTo: string | null;
  code: string;
  skinImageUrl: string;
  qrCode: string | null;
  ticketStatus: string;
  type: string;
  deliveryTime: string | null;
  orderReference: string;
  createdAt: string;
  orderLineItems: OrderLineItem[];
  amount: MonetaryAmount;
  deliveryFlowType: string;
}

export type JTicket = Omit<
  ITicket,
  'orderReference' | 'orderLineItems' | 'amount'
> & {
  amount: IMonetaryAmount;
};

class Ticket {
  readonly reference;
  readonly statusExpiresAt;
  readonly validFrom;
  readonly validTo;
  readonly code;
  readonly skinImageUrl;
  readonly qrCode;
  readonly ticketStatus;
  readonly type;
  readonly deliveryTime;
  readonly orderReference;
  readonly createdAt;
  readonly orderLineItems;
  readonly amount;
  readonly deliveryFlowType;

  constructor(args: ITicket) {
    const {
      reference,
      statusExpiresAt,
      validFrom,
      validTo,
      code,
      skinImageUrl,
      qrCode,
      ticketStatus,
      type,
      deliveryTime,
      orderReference,
      createdAt,
      orderLineItems,
      amount,
      deliveryFlowType
    } = args;

    this.reference = reference;
    this.statusExpiresAt = statusExpiresAt;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.code = code;
    this.skinImageUrl = skinImageUrl;
    this.qrCode = qrCode;
    this.ticketStatus = ticketStatus;
    this.type = type;
    this.deliveryTime = deliveryTime;
    this.orderReference = orderReference;
    this.createdAt = createdAt;
    this.orderLineItems = orderLineItems;
    this.amount = amount;
    this.deliveryFlowType = deliveryFlowType;
  }

  static fromJSON(
    jsonTicket: JTicket,
    orderReference: string,
    orderLineItems: OrderLineItem[]
  ) {
    const { amount: jsonAmount, ...rest } = jsonTicket;
    const amount = MonetaryAmount.fromJSON(jsonAmount);

    return new this({
      amount,
      orderReference,
      orderLineItems,
      ...rest
    });
  }
}

export { Ticket };
