import { action, computed } from 'mobx';
import type { WasabiClient } from '../config/clients/WasabiClient';
import { assertNotNullUndefined } from '../helpers/Functions';
import type { SellerStore } from '../stores/SellerStore';
import { WelcomeTaskListDTO } from '../dto/WelcomeTaskList/WelcomeTaskListDTO';
import type { Task } from '../domain/WelcomeTasks/WelcomeTasks';
import { UpdatedTaskListDTO } from '../dto/WelcomeTaskList/UpdatedTaskListDTO';

class OnboardManager {
  private readonly client;
  private readonly sellerStore;

  constructor(wasabiClient: WasabiClient, sellerStore: SellerStore) {
    this.client = wasabiClient;
    this.sellerStore = sellerStore;
  }

  @action
  async fetchWelcomeTasks() {
    const { sellerStore, client } = this;
    const { sellerReference, sellerDataStore } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const taskListDTO = await client.getWelcomeTaskList(sellerReference);

    if (taskListDTO) {
      sellerDataStore.setWelcomeTasks(
        WelcomeTaskListDTO.toWelcomeTasks(taskListDTO)
      );
      sellerDataStore.setIsOnboarding(!taskListDTO.completed);
    } else {
      sellerDataStore.setIsOnboarding(false); // We assume that onboarding is completed if no welcomeTaskList was found
    }
  }

  @action
  async updateWelcomeTasks(updatedTasks: Task[]) {
    const { sellerStore, client } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const updatedTaskListDTO =
      UpdatedTaskListDTO.fromUpdatedTasks(updatedTasks);

    await client.updateWelcomeTaskList(sellerReference, updatedTaskListDTO);
  }

  @computed
  get sellerIsOnboarding() {
    const { sellerDataStore } = this.sellerStore;

    return sellerDataStore.isOnboarding;
  }

  @computed
  private get welcomeTasks() {
    const { sellerDataStore } = this.sellerStore;

    assertNotNullUndefined(sellerDataStore.welcomeTasks);

    return sellerDataStore.welcomeTasks;
  }

  @computed
  get accountTasks() {
    return this.welcomeTasks.accountTasks;
  }

  @computed
  get menuTasks() {
    return this.welcomeTasks.menuTasks;
  }

  @computed
  get meetingTasks() {
    return this.welcomeTasks.meetingTasks;
  }
}

export { OnboardManager };
