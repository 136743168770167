import { observable } from 'mobx';
import { Getter, Setter } from '../Decorators';

const DEFAULT_LONGITUDE = 14.582947;
const DEFAULT_LATITUDE = 59.115363;

@Setter({ functions: true })
@Getter({ computed: true })
class MutableLocation {
  @observable
  _latitude;

  @observable
  _longitude;

  constructor(args = {}) {
    const { longitude = DEFAULT_LONGITUDE, latitude = DEFAULT_LATITUDE } = args;
    this._latitude = latitude;
    this._longitude = longitude;
  }

  static fromLocation(location) {
    const { latitude, longitude } = location;
    return new this({ latitude, longitude });
  }
}

export { MutableLocation };
