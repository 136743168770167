import type { MutableAdyenMethod } from '../../domain/Adyen/MutableAdyenMethod';

export interface IUpdateAdyenAccountDTO {
  salesDayClosingHour?: number;
  settlementDelayDays?: number;
}

export const UpdateAdyenAccountDTO = {
  fromAdyenMethod(adyenMethod: MutableAdyenMethod): IUpdateAdyenAccountDTO {
    const { salesDayClosingHour, settlementDelayDays } = adyenMethod;

    return {
      salesDayClosingHour,
      settlementDelayDays
    };
  }
};
