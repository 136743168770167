import type { Fees } from './Fees';
import type { CardType } from './ProviderPriceList';

interface IPriceType {
  cardType: CardType;
  consumerFees: Record<string, Fees>;
  commercialFees: Record<string, Fees>;
}

class PriceType {
  readonly cardType: CardType;
  readonly consumerFees: Record<string, Fees>;
  readonly commercialFees: Record<string, Fees>;

  constructor(args: IPriceType) {
    const { cardType, consumerFees, commercialFees } = args;

    this.cardType = cardType;
    this.consumerFees = consumerFees;
    this.commercialFees = commercialFees;
  }

  static fromCardType(cardType: CardType) {
    return new this({
      cardType,
      consumerFees: {},
      commercialFees: {}
    });
  }

  getConsumerFee(currency: string) {
    return this.consumerFees[currency];
  }

  getCommecricalFee(currency: string) {
    return this.commercialFees[currency];
  }

  withConsumerFees(fees: Record<string, Fees>) {
    return new PriceType({
      cardType: this.cardType,
      consumerFees: fees,
      commercialFees: this.commercialFees
    });
  }

  withCommercialFees(fees: Record<string, Fees>) {
    return new PriceType({
      cardType: this.cardType,
      consumerFees: this.consumerFees,
      commercialFees: fees
    });
  }
}

export { PriceType };
