import type { IPermission } from './Permission';

const PERMISSION_NAME = 'REFUND_PERMISSION' as const;

export interface IRefundPermission extends IPermission {
  readonly name: typeof PERMISSION_NAME;
  readonly hours: number;
}

export const RefundPermission = {
  get DENIED(): IRefundPermission {
    return {
      name: PERMISSION_NAME,
      granted: false,
      hours: 0
    };
  },
  forHours: function (hours: number): IRefundPermission {
    return {
      name: PERMISSION_NAME,
      granted: true,
      hours
    };
  },
  asDays: function (refundPermission: IRefundPermission) {
    return Math.floor(refundPermission.hours / 24);
  }
};
