const TYPE = 'date_time_range' as const;

export interface IDateTimeRangeDiscountLimitation {
  startDateTime: string;
  endDateTime: string;
  type: typeof TYPE;
}

class DateTimeRangeDiscountLimitation {
  readonly startDateTime;
  readonly endDateTime;
  readonly type = TYPE;

  constructor(args: IDateTimeRangeDiscountLimitation) {
    const { startDateTime, endDateTime } = args;

    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
  }

  static fromJSON(json: IDateTimeRangeDiscountLimitation) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { startDateTime, endDateTime, type } = this;
    return { startDateTime, endDateTime, type };
  };
}

export { DateTimeRangeDiscountLimitation };
