import type { MutableTerminalOrder } from '../../domain/Terminal/MutableTerminalOrder';
import { assertNotNull } from '../../helpers/Functions';
import type { IAdyenOrderLineItemDTO } from './AdyenOrderLineItemDTO';
import { AdyenOrderLineItemDTO } from './AdyenOrderLineItemDTO';

export interface ICreateAdyenTerminalOrderDTO {
  billingEntityId: string;
  shippingEntityId: string;
  items: IAdyenOrderLineItemDTO[];
}

export const CreateAdyenTerminalOrderDTO = {
  fromMutableTerminalOrder: (
    mutableTerminalOrder: MutableTerminalOrder,
    shippingEntityId: string
  ): ICreateAdyenTerminalOrderDTO => {
    const { billingEntityId, items: terminalItems } = mutableTerminalOrder;

    assertNotNull(billingEntityId);
    const items = terminalItems
      .filter(item => item.quantity > 0)
      .map(item => AdyenOrderLineItemDTO.fromItem(item));

    return {
      billingEntityId,
      shippingEntityId,
      items
    };
  }
};
