import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreateBookkeepingProductMappingDTO {
  _catalogLineItemReference;

  _salesAccountNumber;

  _vatAccountNumber;

  constructor(args) {
    const { catalogLineItemReference, salesAccountNumber, vatAccountNumber } =
      args;

    this._catalogLineItemReference = catalogLineItemReference;
    this._salesAccountNumber = salesAccountNumber;
    this._vatAccountNumber = vatAccountNumber;
  }

  static fromEditBookkeepingProductMapping(editBookkeepingProductMapping) {
    const {
      lineItemReference: catalogLineItemReference,
      salesAccountNumber,
      vatAccountNumber
    } = editBookkeepingProductMapping;

    return new this({
      catalogLineItemReference,
      salesAccountNumber,
      vatAccountNumber
    });
  }
}

export { CreateBookkeepingProductMappingDTO };
