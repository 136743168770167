import { PriceList, type JPriceList } from './PriceList';
import { objectEntries } from '../../helpers/Functions';

interface IIppTier {
  card: PriceList;
  amex: PriceList;
  swish: PriceList;
}
export type IppProvider = keyof IIppTier;

export type JIppTier = {
  [K in IppProvider]: JPriceList;
};

class IppTier {
  readonly card: PriceList;
  readonly amex: PriceList;
  readonly swish: PriceList;

  constructor(args: IIppTier) {
    const { card, amex, swish } = args;

    this.card = card;
    this.amex = amex;
    this.swish = swish;
  }

  static fromJSON(json: JIppTier) {
    const priceLists = objectEntries(json).reduce(
      (accumulator, [ippProvider, jsonPriceList]) => {
        accumulator[ippProvider] = PriceList.fromJSON(
          ippProvider,
          jsonPriceList
        );
        return accumulator;
      },
      {} as Record<IppProvider, PriceList>
    );

    return new this(priceLists);
  }
}

export { IppTier };
