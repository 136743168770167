import { PaymentMethodType } from './PaymentMethod';

interface ISwishMethod {
  payeeAlias: string;
  paymentReferencePrefix?: string;
  hasCertificate: boolean;
}

class SwishMethod {
  readonly payeeAlias;
  readonly paymentReferencePrefix;
  readonly hasCertificate;

  constructor(args: ISwishMethod) {
    const { payeeAlias, paymentReferencePrefix, hasCertificate } = args;

    this.payeeAlias = payeeAlias;
    this.paymentReferencePrefix = paymentReferencePrefix;
    this.hasCertificate = hasCertificate;
  }

  get type() {
    return PaymentMethodType.SWISH;
  }
}

export { SwishMethod };
