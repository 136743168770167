import { objectEntries } from '../../helpers/Functions';
import type { JPriceList } from './PriceList';
import { PriceList } from './PriceList';

interface ITier {
  scheme: PriceList;
  mobilepay: PriceList;
  amex: PriceList;
  swish: PriceList;
}
export type EcomProvider = keyof ITier;

export type JTier = {
  [K in EcomProvider]: JPriceList;
};

class Tier {
  readonly scheme: PriceList;
  readonly mobilepay: PriceList;
  readonly amex: PriceList;
  readonly swish: PriceList;

  constructor(args: ITier) {
    const { scheme, mobilepay, amex, swish } = args;

    this.scheme = scheme;
    this.mobilepay = mobilepay;
    this.amex = amex;
    this.swish = swish;
  }

  static fromJSON(json: JTier) {
    const priceLists = objectEntries(json).reduce(
      (accumulator, [ecomProvider, jsonPriceList]) => {
        accumulator[ecomProvider] = PriceList.fromJSON(
          ecomProvider,
          jsonPriceList
        );
        return accumulator;
      },
      {} as Record<EcomProvider, PriceList>
    );

    return new this(priceLists);
  }
}
export { Tier };
