import { observable } from 'mobx';
import { Setter, Getter, ToJSON } from '../Decorators';

@Setter({ functions: true })
@Getter({ computed: true })
@ToJSON
class EditCatalogProductOverride {
  @observable
  _enabled;

  @observable
  _stock;

  constructor(args) {
    const { enabled, stock } = args;

    this._enabled = enabled;
    this._stock = stock;
  }

  static fromCatalogProduct(catalogProduct) {
    const { enabled, stock } = catalogProduct;
    return new this({ enabled, stock });
  }
}

export { EditCatalogProductOverride as default };
