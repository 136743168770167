import { ToJSON } from '../../domain/Decorators';
import { stringNotEmpty } from '../../helpers/Functions';

@ToJSON
class CreateWinposIntegrationDTO {
  _url;

  _username;

  _password;

  _cashier;

  _stockCenter;

  _profitCenter;

  _paymentNumber;

  _tipPosTransfer;

  _terminalId;

  constructor(args) {
    const {
      url,
      username,
      password,
      cashier,
      stockCenter,
      profitCenter,
      paymentNumber,
      tipPosTransfer,
      terminalId
    } = args;

    this._url = url;
    this._username = username;
    this._password = password;
    this._cashier = cashier;
    this._stockCenter = stockCenter;
    this._profitCenter = profitCenter;
    this._paymentNumber = paymentNumber;
    this._tipPosTransfer = tipPosTransfer;
    this._terminalId = terminalId;
  }

  static fromCreateWinposIntegration(createWinposIntegration) {
    const {
      url,
      username,
      password,
      cashier,
      stockCenter,
      profitCenter,
      paymentNumber,
      tipPosTransfer,
      terminalId: optionalTerminalid
    } = createWinposIntegration;

    const terminalId = stringNotEmpty(optionalTerminalid)
      ? optionalTerminalid
      : null;

    return new this({
      url,
      username,
      password,
      cashier,
      stockCenter,
      profitCenter,
      paymentNumber,
      tipPosTransfer,
      terminalId
    });
  }
}

export { CreateWinposIntegrationDTO };
