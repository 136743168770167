import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreatePrettyNameDTO {
  _prettyName;

  constructor(prettyName) {
    this._prettyName = prettyName;
  }

  static fromPrettyName(prettyName) {
    return new this(prettyName);
  }
}

export { CreatePrettyNameDTO };
