import { action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import type { AlertStore } from '../stores/AlertStore';
import type {
  AlertButton,
  AlertInstance,
  AlertOptions
} from '../domain/Alert/Alert';

const DEFAULT_BUTTONS = [
  {
    title: 'OK'
  }
] satisfies AlertButton[];

class AlertManager {
  private readonly alertStore;

  constructor(alertStore: AlertStore) {
    this.alertStore = alertStore;
  }

  @computed
  get alerts() {
    return this.alertStore.alerts;
  }

  @action
  show = (
    title: string,
    content?: string,
    buttons: AlertButton[] = DEFAULT_BUTTONS,
    options?: AlertOptions
  ) => {
    const reference = uuidv4();
    this.alertStore.add({
      reference,
      title,
      content,
      buttons,
      options
    });
  };

  @action
  hide = (alert: AlertInstance) => {
    this.alertStore.remove(alert);
  };
}

export { AlertManager };
