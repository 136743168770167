import type { MutableTerminalOrderContactDetails } from '../../domain/Terminal/MutableTerminalOrderContactDetails';
import type { MutableTerminalOrderShippingDetails } from '../../domain/Terminal/MutableTerminalOrderShippingDetails';

export interface IShippingDetailsDTO {
  city: string;
  companyName: string;
  country: string;
  postalCode: string;
  streetAddress: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export const ShippingDetailsDTO = {
  fromMutable: (
    shippingDetails: MutableTerminalOrderShippingDetails,
    contactDetails: MutableTerminalOrderContactDetails
  ): IShippingDetailsDTO => {
    const { companyName, city, country, postalCode, streetAddress } =
      shippingDetails;
    const { firstName, lastName, email, phoneNumber } = contactDetails;

    return {
      companyName,
      city,
      country,
      postalCode,
      streetAddress,
      firstName,
      lastName,
      email,
      phoneNumber
    };
  }
};
