import { Getter } from '../Decorators';

@Getter
class PaymentTypeSalesEntry {
  _paymentType;

  _totalAmount;

  constructor(args) {
    const { paymentType, totalAmount } = args;

    this._paymentType = paymentType;
    this._totalAmount = totalAmount;
  }

  static fromJSON(json) {
    const { paymentGroup, totalAmount } = json;
    return new this({
      paymentType: paymentGroup,
      totalAmount
    });
  }
}

export { PaymentTypeSalesEntry };
