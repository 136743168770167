export interface IAmount {
  amount: number;
  taxAmount: number;
  taxRate: number;
  currency: string;
}

interface Amount extends IAmount {}
class Amount {
  readonly amount;
  readonly taxAmount;
  readonly taxRate;
  readonly currency;

  constructor(args: IAmount) {
    const { amount, taxAmount, taxRate, currency } = args;
    this.amount = amount;
    this.taxAmount = taxAmount;
    this.taxRate = taxRate;
    this.currency = currency;
  }

  get isZero() {
    return this.amount === 0;
  }

  toJSON = (): IAmount => {
    const { amount, taxAmount, taxRate, currency } = this;

    return {
      amount,
      taxAmount,
      taxRate,
      currency
    };
  };

  static fromJSON(json: IAmount) {
    return new this(json);
  }
}

export { Amount };
