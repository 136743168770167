import { OrderPreviewPage } from '../../domain/OrderPreview/OrderPreviewPage';
import type { IOrderPreviewDTO } from './OrderPreviewDTO';
import { OrderPreviewDTO } from './OrderPreviewDTO';

export interface IOrderPreviewPageDTO {
  totalCount: number;
  orderPreviews: IOrderPreviewDTO[];
}

class OrderPreviewPageDTO {
  static toOrderPreviewPage = (orderPreviewPageDTO: IOrderPreviewPageDTO) => {
    const { totalCount, orderPreviews } = orderPreviewPageDTO;

    return new OrderPreviewPage({
      totalCount,
      orderPreviews: orderPreviews.map(OrderPreviewDTO.toOrderPreview)
    });
  };
}

export { OrderPreviewPageDTO };
