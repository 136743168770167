export interface IModifierGroupProductPreview {
  reference: string;
  name: string;
  description: string;
  type: string;
  price: number;
  currency: string;
  vatRate: number;
  sortWeight: number;
  productImages: any; // TODO: Add type
}
interface ModifierGroupProductPreview extends IModifierGroupProductPreview {}

class ModifierGroupProductPreview {
  readonly reference;
  readonly name;
  readonly description;
  readonly type;
  readonly price;
  readonly currency;
  readonly vatRate;
  readonly sortWeight;
  readonly productImages;

  constructor(args: IModifierGroupProductPreview) {
    const {
      reference,
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      sortWeight,
      productImages
    } = args;

    this.reference = reference;
    this.name = name;
    this.description = description;
    this.type = type;
    this.price = price;
    this.currency = currency;
    this.vatRate = vatRate;
    this.sortWeight = sortWeight;
    this.productImages = productImages;
  }

  static fromJSON(json: IModifierGroupProductPreview) {
    return new this(json);
  }

  toJSON = () => {
    const {
      reference,
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      sortWeight,
      productImages
    } = this;

    return {
      reference,
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      sortWeight,
      productImages
    };
  };
}

export { ModifierGroupProductPreview as default };
