import type { JCategorySection } from './Category/CategorySection';
import { CategorySection } from './Category/CategorySection';
import type { JOverviewSection } from './Overview/OverviewSection';
import { OverviewSection } from './Overview/OverviewSection';
import type { JPaymentGroupSection } from './PaymentGroup/PaymentGroupSection';
import { PaymentGroupSection } from './PaymentGroup/PaymentGroupSection';
import type { JPayoutSection } from './Payout/PayoutSection';
import { PayoutSection } from './Payout/PayoutSection';
import type { JVatRateSection } from './VatRate/VatRateSection';
import { VatRateSection } from './VatRate/VatRateSection';

interface ISummarySalesReport {
  from: string;
  to: string;
  totalAmount: string;
  totalCount: number;
  totalTip: string;
  overviewSection: OverviewSection;
  vatRateSection: VatRateSection;
  paymentGroupSection: PaymentGroupSection | null;
  payoutSection: PayoutSection | null;
  categorySection: CategorySection;
}
export interface JSummarySalesReport {
  periodFrom: string;
  periodTo: string;
  totalAmount: string;
  totalCount: number;
  totalTip: string;
  overviewSection: JOverviewSection;
  vatRateSection: JVatRateSection;
  paymentGroupSection: JPaymentGroupSection;
  payoutSection: JPayoutSection;
  categorySection: JCategorySection;
}

interface SummarySalesReport extends ISummarySalesReport {}
class SummarySalesReport {
  readonly from;
  readonly to;
  readonly totalAmount;
  readonly totalCount;
  readonly totalTip;
  readonly overviewSection;
  readonly vatRateSection;
  readonly paymentGroupSection;
  readonly payoutSection;
  readonly categorySection;

  constructor(args: ISummarySalesReport) {
    const {
      from,
      to,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection,
      vatRateSection,
      paymentGroupSection,
      payoutSection,
      categorySection
    } = args;

    this.from = from;
    this.to = to;
    this.totalAmount = totalAmount;
    this.totalCount = totalCount;
    this.totalTip = totalTip;
    this.overviewSection = overviewSection;
    this.vatRateSection = vatRateSection;
    this.paymentGroupSection = paymentGroupSection;
    this.payoutSection = payoutSection;
    this.categorySection = categorySection;
  }

  static fromJSON(json: JSummarySalesReport) {
    const {
      periodFrom,
      periodTo,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection,
      vatRateSection,
      paymentGroupSection,
      payoutSection,
      categorySection
    } = json;

    return new this({
      from: periodFrom,
      to: periodTo,
      totalAmount,
      totalCount,
      totalTip,
      overviewSection: OverviewSection.fromJSON(overviewSection),
      vatRateSection: VatRateSection.fromJSON(vatRateSection),
      paymentGroupSection: paymentGroupSection
        ? PaymentGroupSection.fromJSON(paymentGroupSection)
        : null,
      payoutSection: payoutSection
        ? PayoutSection.fromJSON(payoutSection)
        : null,
      categorySection: CategorySection.fromJSON(categorySection)
    });
  }
}

export { SummarySalesReport };
