import { WEIQ_ADMIN, WEIQ_SELLER } from '../../constants/index';

export const LOCALES = ['sv-SE', 'en-GB', 'da-DK'] as const;
export type Locale = (typeof LOCALES)[number];

export const LANGUAGES = {
  'sv-SE': {
    name: 'Svenska',
    flag: 'SE',
    locale: 'sv-SE'
  },
  'en-GB': {
    name: 'English',
    flag: 'GB',
    locale: 'en-GB'
  },
  'da-DK': {
    name: 'Dansk',
    flag: 'DK',
    locale: 'da-DK'
  }
} as const;

export const COMMON_FORM_STRINGS = {
  requiredField: {
    'sv-SE': 'Måste anges',
    'en-GB': 'Required field',
    'da-DK': 'Obligatorisk felt'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  noResultsFound: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Ingen resultater fundet'
  }
};

export const LEFT_SIDEBAR_STRINGS = {
  menuAndOrders: {
    'sv-SE': 'Meny och beställningar',
    'en-GB': 'Menu and orders',
    'da-DK': 'Menu og bestillinger'
  },
  dashboard: {
    'sv-SE': 'Dashboard',
    'en-GB': 'Dashboard',
    'da-DK': 'Dashboard'
  },
  getStarted: {
    'sv-SE': 'Kom igång',
    'en-GB': 'Get started',
    'da-DK': 'Kom i gang'
  },
  orders: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  menus: {
    'sv-SE': 'Menyer',
    'en-GB': 'Menus',
    'da-DK': 'Menuer'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  manageWeiq: {
    'sv-SE': 'Hantera WEIQ',
    'en-GB': 'Manage WEIQ',
    'da-DK': 'Håndtere WEIQ'
  },
  reports: {
    'sv-SE': 'Rapporter',
    'en-GB': 'Reports',
    'da-DK': 'Rapporter'
  },
  bookkeeping: {
    'sv-SE': 'Bokföring',
    'en-GB': 'Bookkeeping',
    'da-DK': 'Bogføring'
  },
  discounts: {
    'sv-SE': 'Rabattkoder',
    'en-GB': 'Discount codes',
    'da-DK': 'Rabatkoder'
  },
  marketing: {
    'sv-SE': 'Marknadsföring',
    'en-GB': 'Marketing',
    'da-DK': 'Markedsføring'
  },
  settings: {
    'sv-SE': 'Inställningar',
    'en-GB': 'Settings',
    'da-DK': 'Indstillinger'
  },
  developer: {
    'sv-SE': 'Utvecklare',
    'en-GB': 'Developer',
    'da-DK': 'Udvikler'
  },
  help: {
    'sv-SE': 'Hjälp',
    'en-GB': 'Help',
    'da-DK': 'Hjælp'
  }
};

export const FOOTER_STRINGS = {
  support: {
    'sv-SE': 'Support',
    'en-GB': 'Support',
    'da-DK': 'Support'
  },
  contact: {
    'sv-SE': 'Kontakta oss',
    'en-GB': 'Contact us',
    'da-DK': 'Kontakt os'
  }
};

export const PROFILE_DROPDOWN_STRINGS = {
  welcome: {
    'sv-SE': 'Välkommen!',
    'en-GB': 'Welcome!',
    'da-DK': 'Velkommen'
  },
  signOut: {
    'sv-SE': 'Logga ut',
    'en-GB': 'Sign out',
    'da-DK': 'Log ud'
  },
  support: {
    'sv-SE': 'Support',
    'en-GB': 'Support',
    'da-DK': 'Support'
  },
  darkMode: {
    'sv-SE': 'Mörkt läge',
    'en-GB': 'Dark mode',
    'da-DK': 'Mørk tilstand'
  },
  [WEIQ_ADMIN]: {
    'sv-SE': 'Superanvändare',
    'en-GB': 'Super Admin',
    'da-DK': 'Super bruger'
  },
  [WEIQ_SELLER]: {
    'sv-SE': 'Administratör',
    'en-GB': 'Admin',
    'da-DK': 'Administrator'
  }
};

export const PAGINATION_STRINGS = {
  displayingRows: {
    'sv-SE': 'Visar $FIRST_ROW$ till $LAST_ROW$ av $ROW_COUNT$ rader',
    'en-GB': 'Displaying $FIRST_ROW$ to $LAST_ROW$ of $ROW_COUNT$ rows',
    'da-DK': 'Viser $FIRST_ROW$ til $LAST_ROW$ af $ROW_COUNT$ rækker'
  }
};

export const TICKET_STATUS_STRINGS = {
  DELIVERED: {
    'sv-SE': 'Utlämnad',
    'en-GB': 'Delivered',
    'da-DK': 'Afleveret'
  },
  REJECTED: {
    'sv-SE': 'Avbruten',
    'en-GB': 'Rejected',
    'da-DK': 'Afbrudt'
  },
  CREDITED: {
    'sv-SE': 'Återbetald',
    'en-GB': 'Credited',
    'da-DK': 'Refunderet'
  },
  READY_FOR_PICKUP: {
    'sv-SE': 'Redo för upphämtning',
    'en-GB': 'Ready for pick-up',
    'da-DK': 'Klar til afhentning'
  },
  CREATED: {
    'sv-SE': 'Skapad',
    'en-GB': 'Created',
    'da-DK': 'Oprettet'
  },
  CONFIRMED: {
    'sv-SE': 'Bekräftad',
    'en-GB': 'Confirmed',
    'da-DK': 'Bekræftet'
  },
  IN_PROGRESS: {
    'sv-SE': 'Påbörjad',
    'en-GB': 'In progress',
    'da-DK': 'Begyndt'
  },
  INACTIVE: {
    'sv-SE': 'Inaktiv',
    'en-GB': 'Inactive',
    'da-DK': 'Inaktiv'
  },
  ENTRY_TICKET: {
    'sv-SE': 'Entrébiljett',
    'en-GB': 'Entry ticket',
    'da-DK': 'Entrébillet'
  }
};

export const SELLERS_PAGE_STRINGS = {
  title: {
    'sv-SE': 'Restauranger',
    'en-GB': 'Restaurants',
    'da-DK': 'Restauranter'
  },
  selectRestaurant: {
    'sv-SE': 'Välj restaurang att logga in på.',
    'en-GB': 'Select a restaurant to log in to.',
    'da-DK': 'Vælg en restaurant at logge ind på.'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök restaurang',
    'en-GB': 'Search restaurant',
    'da-DK': 'Søg restaurant'
  },
  createSeller: {
    'sv-SE': 'Skapa ny restaurang',
    'en-GB': 'Create new restaurant',
    'da-DK': 'Opret ny restaurant'
  }
};

export const ONBOARDING_PAGE_STRINGS = {
  title: {
    'sv-SE': 'Välkommen till $$name$$',
    'en-GB': 'Welcome to $$name$$',
    'da-DK': 'Velkommen til $$name$$'
  },
  bubble1: {
    'sv-SE':
      'Hej, jag heter Henrik och kommer hjälpa dig komma igång med WEIQ. Bara hojta, eller ',
    'en-GB':
      "Hello, I'm Henrik and will help you along the way to get started with WEIQ. Just call, or ",
    'da-DK':
      'Hej, mit navn er Henrik, og jeg vil hjælpe dig med at komme i gang med WEIQ. Bare råb, eller '
  },
  bubbleLink: {
    'sv-SE': 'klicka här',
    'en-GB': 'click here',
    'da-DK': 'klik her'
  },
  bubble2: {
    'sv-SE': ', om du behöver hjälp.',
    'en-GB': ', if you need help.',
    'da-DK': ', hvis du har brug for hjælp.'
  },
  terminalTitle: {
    'sv-SE': 'Allt-i-ett-terminal',
    'en-GB': 'All-in-one terminal',
    'da-DK': 'Alt-i-en terminal'
  },
  terminalDescription: {
    'sv-SE':
      'Allt ditt kassasystem gör i en smidig handterminal. Dessutom självbeställning via QR så du kan driva din restaurang effektivt helt utan kassasystem. Hör av dig så berättar vi mer!',
    'en-GB':
      "Everything your POS system does in one handy terminal. In addition, self-ordering via QR so you can run your restaurant efficiently without a POS system. Get in touch and we'll tell you more!",
    'da-DK':
      'Alt, hvad dit POS-system kan, i én praktisk håndholdt terminal. Derudover kan du selv bestille via QR, så du kan drive din restaurant effektivt uden et POS-system. Kontakt os, så fortæller vi dig mere!'
  },
  contactUsTerminal: {
    'sv-SE': 'Kontakta oss om terminalerna',
    'en-GB': 'Contact us about the terminals',
    'da-DK': 'Kontakt os om terminalerne'
  },
  previewMenu: {
    'sv-SE': 'Förhandsgranska menyn',
    'en-GB': 'Preview menu',
    'da-DK': 'Forhåndsvisning af menuen'
  },
  accountTitle: {
    'sv-SE': 'Skapa konto',
    'en-GB': 'Create account',
    'da-DK': 'Opret en konto'
  },
  describeRestaurant: {
    'sv-SE': 'Berätta om din restaurang',
    'en-GB': 'Tell us about your restaurant',
    'da-DK': 'Fortæl os om din restaurant'
  },
  verifyAccount: {
    'sv-SE': 'Verifiera konto',
    'en-GB': 'Verify account',
    'da-DK': 'Bekræft konto'
  },
  loginToAdmin: {
    'sv-SE': 'Logga in i WEIQ ADMIN',
    'en-GB': 'Log into WEIQ ADMIN',
    'da-DK': 'Log ind på WEIQ ADMIN'
  },
  menuTitle: {
    'sv-SE': 'Redigera menyn',
    'en-GB': 'Customize menu',
    'da-DK': 'Rediger menuen'
  },
  createInitialProducts: {
    'sv-SE': 'Skapa dina första produkter',
    'en-GB': 'Create your first products',
    'da-DK': 'Skab dine første produkter'
  },
  createMoreProducts: {
    'sv-SE': 'Lägg upp fler produkter',
    'en-GB': 'Add more products',
    'da-DK': 'Tilføj flere produkter'
  },
  meetingTitle: {
    'sv-SE': 'Boka möte och börja sälj',
    'en-GB': 'Set up a meeting and start selling',
    'da-DK': 'Book et møde og begynd at sælge'
  },
  bookIntroMeeting: {
    'sv-SE': 'Välj tid för intromöte',
    'en-GB': 'Pick a time for an introductory meeting',
    'da-DK': 'Vælg et tidspunkt for et introduktionsmøde'
  },
  submitBankDetails: {
    'sv-SE': 'Ange bank för utbetalningar',
    'en-GB': 'Specify bank for payments',
    'da-DK': 'Angiv bank for betalinger'
  }
};

export const MENU_TABS_STRINGS = {
  CATALOGS: {
    'sv-SE': 'Menyer',
    'en-GB': 'Catalogs',
    'da-DK': 'Menuer'
  },
  PRODUCTS: {
    'sv-SE': 'Produkter & Tillval',
    'en-GB': 'Products & Modifiers',
    'da-DK': 'Produkter og Tilpasninger'
  },
  MODIFIER_GROUPS: {
    'sv-SE': 'Tillvalsgrupper',
    'en-GB': 'Modifier Groups',
    'da-DK': 'Tilpasningsgrupper'
  },
  CATEGORIES: {
    'sv-SE': 'Kategorier',
    'en-GB': 'Categories',
    'da-DK': 'Kategorier'
  }
};

export const MENU_CATALOGS_PAGE_STRINGS = {
  title: {
    'sv-SE': 'Menyer',
    'en-GB': 'Catalogs',
    'da-DK': 'Menuer'
  },
  newCatalog: {
    'sv-SE': 'Ny meny',
    'en-GB': 'New catalog',
    'da-DK': 'Ny menu'
  },
  editCatalog: {
    'sv-SE': 'Redigera meny',
    'en-GB': 'Edit catalog',
    'da-DK': 'Rediger menu'
  },
  sortCatalogs: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort catalogs',
    'da-DK': 'Ændre rækkefølgen'
  },
  productsTab: {
    'sv-SE': 'Produkter i menyn',
    'en-GB': 'Catalog Products',
    'da-DK': 'Produkter i menuen'
  },
  modifiersTab: {
    'sv-SE': 'Tillval i menyn',
    'en-GB': 'Catalog Modifiers',
    'da-DK': 'Tilpasinger i menuen'
  },
  previewCatalog: {
    'sv-SE': 'Se meny',
    'en-GB': 'Show menu',
    'da-DK': 'Vis menu'
  }
};

export const MENU_CATALOGS_PRODUCT_TAB_STRINGS = {
  sortProducts: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort products',
    'da-DK': 'Ændre rækkefølgen'
  },
  add: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføje'
  },
  newProduct: {
    'sv-SE': 'Ny produkt',
    'en-GB': 'New product',
    'da-DK': 'Nyt produkt'
  },
  selectProducts: {
    'sv-SE': 'Välj produkter',
    'en-GB': 'Select products',
    'da-DK': 'Vælg produkter'
  },
  visible: {
    'sv-SE': 'Synlig',
    'en-GB': 'Visible',
    'da-DK': 'Synlig'
  },
  notVisible: {
    'sv-SE': 'Ej synlig',
    'en-GB': 'Not visible',
    'da-DK': 'Ikke synligt'
  },
  editProduct: {
    'sv-SE': 'Redigera produkt',
    'en-GB': 'Edit product',
    'da-DK': 'Rediger produkt'
  },
  copyProduct: {
    'sv-SE': 'Kopiera produkt',
    'en-GB': 'Copy product',
    'da-DK': 'Kopiér produktet'
  },
  removeFromCatalog: {
    'sv-SE': 'Ta bort från menyn',
    'en-GB': 'Remove from catalog',
    'da-DK': 'Fjern fra menuen'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  removeFromCatalogAlertTitle: {
    'sv-SE': 'Ta bort produkt från menyn',
    'en-GB': 'Remove from catalog',
    'da-DK': 'Fjern produktet fra menuen'
  },
  removeFromCatalogAlertMessage: {
    'sv-SE':
      'Är du säker på att du vill ta bort produkten "$$name$$" från menyn?',
    'en-GB':
      'Are you sure you want to remove the product "$$name$$" from the catalog?',
    'da-DK':
      'Er du sikker på, at du vil fjerne produktet "$$name$$" fra menuen?'
  },
  updateFailedAlertTitle: {
    'sv-SE': 'Uppdateringen misslyckades',
    'en-GB': 'Update failed',
    'da-DK': 'Opdateringen mislykkedes'
  },
  updateFailedAlertMessage: {
    'sv-SE':
      'Det gick inte att ändra synligheten för produkten "$$name$$". Var god försök igen.',
    'en-GB':
      'Could not change the visibility for the product "$$name$$". Please try again.',
    'da-DK':
      'Kunne ikke ændre synligheden af ​​produktet "$$name$$". Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  selectItemsModalTitle: {
    'sv-SE': 'Lägg till produkter',
    'en-GB': 'Add products',
    'da-DK': 'Tilføj produkter'
  },
  selectItemsModalDescription: {
    'sv-SE': 'Välj produkter att lägga till i menyn "$$catalogName$$"',
    'en-GB': 'Select products to add to the catalog "$$catalogName$$"',
    'da-DK': 'Vælg produkter, der skal tilføjes, i menuen "$$catalogName$$'
  },
  noProductsFound: {
    'sv-SE': 'Inga produkter hittades',
    'en-GB': 'No products found',
    'da-DK': 'Ingen produkter fundet'
  },
  all: {
    'sv-SE': 'Alla',
    'en-GB': 'All',
    'da-DK': 'Alle'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök produkt',
    'en-GB': 'Search products',
    'da-DK': 'Søg produkt'
  }
};

export const MENU_CATALOGS_MODIFIER_TAB_STRINGS = {
  visible: {
    'sv-SE': 'Synlig',
    'en-GB': 'Visible',
    'da-DK': 'Synlig'
  },
  notVisible: {
    'sv-SE': 'Ej synlig',
    'en-GB': 'Not visible',
    'da-DK': 'Ikke synlig'
  },
  editModifier: {
    'sv-SE': 'Redigera tillvalsprodukt',
    'en-GB': 'Edit modifier product',
    'da-DK': 'Rediger tilpasningsprodukt'
  },
  updateFailedAlertTitle: {
    'sv-SE': 'Uppdateringen misslyckades',
    'en-GB': 'Update failed',
    'da-DK': 'Opdateringen mislykkedes'
  },
  updateFailedAlertMessage: {
    'sv-SE':
      'Det gick inte att ändra synligheten för produkten "$$name$$". Var god försök igen.',
    'en-GB':
      'Could not change the visibility for the product "$$name$$". Please try again.',
    'da-DK':
      'Kunne ikke ændre synligheden af ​​produktet "$$name$$". Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök tillval',
    'en-GB': 'Search modifiers',
    'da-DK': 'Søg tilpasning'
  },
  noModifiersFound: {
    'sv-SE': 'Inga tillval hittades',
    'en-GB': 'No modifiers were found',
    'da-DK': 'Ingen tilpasninger fundet'
  }
};

export const SELECT_ITEMS_MODAL_STRINGS = {
  defaultSubmitText: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføje'
  },
  defaultCancelText: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  defaultNoEntriesText: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Ingen resultat fundet'
  },
  defaultOpenText: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføje'
  },
  search: {
    'sv-SE': 'Sök',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  alreadyAdded: {
    'sv-SE': 'Redan tillagd',
    'en-GB': 'Already added',
    'da-DK': 'Allerede tilføjet'
  }
};

export const MENU_HEADER_STRINGS = {
  menuTitle: {
    'sv-SE': 'Menyer',
    'en-GB': 'Menus',
    'da-DK': 'Menuer'
  }
};

export const MENU_PRODUCTS_PAGE_STRINGS = {
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök produkt eller tillval',
    'en-GB': 'Search products and modifiers',
    'da-DK': 'Søg efter produkt eller tilpasning'
  },
  editProduct: {
    'sv-SE': 'Redigera produkt',
    'en-GB': 'Edit product',
    'da-DK': 'Rediger produkt'
  },
  copyProduct: {
    'sv-SE': 'Kopiera produkt',
    'en-GB': 'Copy product',
    'da-DK': 'Kopiér produktet'
  },
  deleteProduct: {
    'sv-SE': 'Ta bort produkt',
    'en-GB': 'Delete product',
    'da-DK': 'Fjern produktet'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  deleteProductAlertTitle: {
    'sv-SE': 'Ta bort produkt',
    'en-GB': 'Delete product',
    'da-DK': 'Fjern produkt'
  },
  deleteProductAlertMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort produkten "$$name$$"?`',
    'en-GB': 'Are you sure you want to delete the product "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil slette produktet "$$name$$"?'
  },
  noProductsFound: {
    'sv-SE': 'Inga produkter hittades',
    'en-GB': 'No products were found',
    'da-DK': 'Ingen produkter fundet'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  price: {
    'sv-SE': 'Pris',
    'en-GB': 'Price',
    'da-DK': 'Pris'
  },
  type: {
    'sv-SE': 'Typ',
    'en-GB': 'Type',
    'da-DK': 'Type'
  },
  PRODUCT: {
    'sv-SE': 'Produkt',
    'en-GB': 'Product',
    'da-DK': 'Produkt'
  },
  MODIFIER: {
    'sv-SE': 'Tillval',
    'en-GB': 'Modifier',
    'da-DK': 'Tilpasning'
  }
};

export const EDIT_PRODUCT_STRINGS = {
  failedToSaveTitle: {
    'sv-SE': 'Kunde inte spara produkten',
    'en-GB': 'The product could not be saved',
    'da-DK': 'Kunne ikke gemme produktet'
  },
  failedToSaveMessage: {
    'sv-SE': 'Misslyckades att spara produkten. Vänligen försök igen',
    'en-GB': 'Failed to save the product. Please try again',
    'da-DK': 'Kunne ikke gemme produktet. Prøv igen'
  },
  productNotFoundTitle: {
    'sv-SE': 'Produkten hittades inte',
    'en-GB': 'Product not found',
    'da-DK': 'Produktet blev ikke fundet'
  },
  productNotFoundMessage: {
    'sv-SE': 'Produkten du sökte kunde tyvärr inte hittas',
    'en-GB': 'The product you requested could not be found',
    'da-DK': 'Desværre kunne det produkt du ledte efter ikke findes'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  saveInOneCatalog: {
    'sv-SE': 'Spara i 1 meny',
    'en-GB': 'Save in 1 catalog',
    'da-DK': 'Gem i 1 menu'
  },
  saveInMultipleCatalogs: {
    'sv-SE': 'Spara i $$N_CATALOGS$$ menyer',
    'en-GB': 'Save in $$N_CATALOGS$$ catalogs',
    'da-DK': 'Gem i $$N_CATALOGS$$ menuer'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  type: {
    'sv-SE': 'Typ*',
    'en-GB': 'Type*',
    'da-DK': 'Type*'
  },
  product: {
    'sv-SE': 'Produkt',
    'en-GB': 'Product',
    'da-DK': 'Produkt'
  },
  modifier: {
    'sv-SE': 'Tillval',
    'en-GB': 'Modifier',
    'da-DK': 'Tilpasning'
  },
  productAndModifier: {
    'sv-SE': 'Produkt och tillval',
    'en-GB': 'Product and modifier',
    'da-DK': 'Produkt og tilpasning'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn*'
  },
  nameToolTip: {
    'sv-SE': 'Namnet på produkten som kommer visas för användaren',
    'en-GB': 'The name of the product that will be displayed to the user',
    'da-DK': 'Navnet på det produkt, der vil blive vist for brugeren'
  },
  price: {
    'sv-SE': 'Pris*',
    'en-GB': 'Price*',
    'da-DK': 'Pris*'
  },
  priceToolTip: {
    'sv-SE': 'Priset på produkten inklusive moms',
    'en-GB': 'The price of the product, including VAT',
    'da-DK': 'Produktets pris inklusive moms'
  },
  vatRate: {
    'sv-SE': 'Momssats*',
    'en-GB': 'VAT Rate*',
    'da-DK': 'Momssats*'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  },
  descriptionToolTip: {
    'sv-SE': 'En beskrivning av produkten som kommer att visas för användaren',
    'en-GB': 'A description of the product that will be displayed to the user',
    'da-DK': 'En beskrivelse af produktet, der vil blive vist for brugeren'
  },
  selectCategory: {
    'sv-SE': 'Välj en kategori',
    'en-GB': 'Select a category',
    'da-DK': 'Vælg en kategori'
  },
  newCategory: {
    'sv-SE': 'Ny kategori: ',
    'en-GB': 'New category: ',
    'da-DK': 'Ny kategori: '
  },
  searchCategory: {
    'sv-SE': 'Sök eller skapa ny kategori',
    'en-GB': 'Search or create a new category',
    'da-DK': 'Søg eller opret ny kategori'
  },
  noResultsFound: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Ingen resultater fundet'
  },
  tags: {
    'sv-SE': 'Underkategorier',
    'en-GB': 'Subcategories',
    'da-DK': 'Underkategorier'
  },
  newTag: {
    'sv-SE': 'Ny underkategori: ',
    'en-GB': 'New subcategory: ',
    'da-DK': 'Ny underkategori: '
  },
  searchTag: {
    'sv-SE': 'Sök eller skapa ny underkategori',
    'en-GB': 'Search or create a new subcategory',
    'da-DK': 'Søg eller opret ny underkategori'
  },
  showInCatalogs: {
    'sv-SE': 'Visa i menyer',
    'en-GB': 'Show in catalogs',
    'da-DK': 'Vis i menuer'
  },
  otherCatalogs: {
    'sv-SE': 'Andra menyer',
    'en-GB': 'Other catalogs',
    'da-DK': 'Andre menuer'
  },
  modifiers: {
    'sv-SE': 'Tillval',
    'en-GB': 'Modifiers',
    'da-DK': 'Tilpasning'
  },
  loading: {
    'sv-SE': 'Laddar...',
    'en-GB': 'Loading...',
    'da-DK': 'Indlæser...'
  },
  newProduct: {
    'sv-SE': 'Ny produkt',
    'en-GB': 'New product',
    'da-DK': 'Ny produkt'
  },
  editProduct: {
    'sv-SE': 'Redigera produkt',
    'en-GB': 'Edit product',
    'da-DK': 'Rediger produkt'
  },
  uploadImage: {
    'sv-SE': 'Ladda upp bild',
    'en-GB': 'Upload image',
    'da-DK': 'Upload billede'
  },
  deleteImage: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Delete',
    'da-DK': 'Fjern'
  },
  chooseImageLayout: {
    'sv-SE': 'Välj produktens utseende i appen',
    'en-GB': 'Choose the product layout in the app',
    'da-DK': 'Vælg produktets udseende i appen'
  },
  weiqBurger: {
    'sv-SE': 'WEIQ BURGER',
    'en-GB': 'WEIQ BURGER',
    'da-DK': 'WEIQ BURGER'
  },
  weiqBurgerDescription: {
    'sv-SE': 'Hamburgare på nötfärs med sallad och dressing',
    'en-GB': 'Hamburger made of beef with salad and dressing',
    'da-DK': 'Hamburger på hakkebøf med salat og dressing'
  },
  weiqLager: {
    'sv-SE': 'WEIQ LAGER',
    'en-GB': 'WEIQ LAGER',
    'da-DK': 'WEIQ LAGER'
  },
  priceTag: {
    'sv-SE': '45 SEK',
    'en-GB': '45 SEK',
    'da-DK': '45 SEK'
  },
  wideImage: {
    'sv-SE': 'Bred bild',
    'en-GB': 'Wide image',
    'da-DK': 'Bredt billede'
  },
  wideImageDescription: {
    'sv-SE': 'Fungerar bäst för matbilder',
    'en-GB': 'Recommended for images of food',
    'da-DK': 'Fungerer bedst til madbilleder'
  },
  smallImage: {
    'sv-SE': 'Liten bild',
    'en-GB': 'Small image',
    'da-DK': 'Lille billede'
  },
  smallImageDescription: {
    'sv-SE': 'Fungerar bäst för bilder av flaskor och glas',
    'en-GB': 'Recommended for images of bottles and glasses',
    'da-DK': 'Fungerer bedst til billeder af flasker og glas'
  },
  noImage: {
    'sv-SE': 'Ingen bild',
    'en-GB': 'No image',
    'da-DK': 'Intet billede'
  },
  noImageDescription: {
    'sv-SE': 'Fungerar bra för övriga produkter',
    'en-GB': 'Recommended for other products',
    'da-DK': 'Fungerer godt til andre produkter'
  },
  uploadingWideImage: {
    'sv-SE': 'Uppladdning av bred bild',
    'en-GB': 'Upload of a wide image',
    'da-DK': 'Upload af et bredt billede'
  },
  uploadingSmallImage: {
    'sv-SE': 'Uppladdning av liten bild',
    'en-GB': 'Upload of a small image',
    'da-DK': 'Upload af et lille billede'
  },
  menuTitle: {
    'sv-SE': 'Bild för meny',
    'en-GB': 'Image for menu',
    'da-DK': 'Billede til menu'
  },
  menuDescription: {
    'sv-SE': 'Bilden syns i appen i din meny.',
    'en-GB': 'The image will be displayed in your menu.',
    'da-DK': 'Billedet er synligt i appen i din menu.'
  },
  orderTitle: {
    'sv-SE': 'Bild för beställningsvy',
    'en-GB': 'Image for order view',
    'da-DK': 'Billede til ordrevisning'
  },
  orderDescription: {
    'sv-SE': 'Bilden syns i beställningsvyn i appen.',
    'en-GB': 'The image will be displayed in the order view.',
    'da-DK': 'Billedet er synligt i ordrevisningen i appen.'
  },
  preview: {
    'sv-SE': 'Utkast',
    'en-GB': 'Preview',
    'da-DK': 'Udkast'
  },
  previewDescription: {
    'sv-SE': 'Se hur produkten kommer se ut i appen',
    'en-GB': 'See how the image will appear in the app',
    'da-DK': 'Se hvordan produktet kommer til at se ud i appen'
  }
};

export const MODIFIERS_FORM_STRINGS = {
  title: {
    'sv-SE': 'Använd tillval från följande tillvalsgrupper:',
    'en-GB': 'Use modifiers from the following modifier groups:',
    'da-DK': 'Brug tilpasninger fra følgende tilpasningsgrupper:'
  },
  noGroupsSelected: {
    'sv-SE': 'Inga tillvalsgrupper valda',
    'en-GB': 'No modifier groups selected',
    'da-DK': 'Der er ikke valgt nogen tilpasningsgrupper'
  },
  modalTitle: {
    'sv-SE': 'Lägg till tillvalsgrupper',
    'en-GB': 'Add modifier groups',
    'da-DK': 'Tilføj tilpasningsgrupper'
  },
  modalDescription: {
    'sv-SE': 'Välj tillvalsgrupper att lägga till',
    'en-GB': 'Select modifier groups to add',
    'da-DK': 'Vælg tilpasningsgrupper, der skal tilføjes'
  },
  modalNoResults: {
    'sv-SE': 'Inga tillvalsgrupper hittades',
    'en-GB': 'No modifier groups found',
    'da-DK': 'Ingen tilpasningsgrupper fundet'
  },
  removeModifierGroup: {
    'sv-SE': 'Ta bort tillvalsgruppen från produkten',
    'en-GB': 'Remove modifier group from product',
    'da-DK': 'Fjern tilpasningsgruppen fra produktet'
  },
  emptyModifierGroup: {
    'sv-SE': 'Tom tillvalsgrupp',
    'en-GB': 'Empty modifier group',
    'da-DK': 'tom tilpasningsgruppe'
  }
};

export const EDIT_CATALOG_STRINGS = {
  editCatalog: {
    'sv-SE': 'Redigera meny',
    'en-GB': 'Edit catalog',
    'da-DK': 'Rediger menu'
  },
  newCatalog: {
    'sv-SE': 'Ny meny',
    'en-GB': 'New catalog',
    'da-DK': 'Ny menu'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn*'
  },
  nameTooltip: {
    'sv-SE': 'Namnet på menyn. Kommer visas för användaren.',
    'en-GB': 'The name of the catalog. Will be displayed to the user.',
    'da-DK': 'Navnet på menuen. Vil blive vist for brugeren.'
  },
  description: {
    'sv-SE': 'Beskrivning*',
    'en-GB': 'Description*',
    'da-DK': 'Beskrivelse*'
  },
  descriptionTooltip: {
    'sv-SE': 'En beskrivning av menyn som kommer visas för användaren.',
    'en-GB': 'A description of the catalog that will be displayed to the user.',
    'da-DK': 'En beskrivelse af menuen, der vil blive vist for brugeren.'
  },
  catalogIcon: {
    'sv-SE': 'Menyikon*',
    'en-GB': 'Icon*',
    'da-DK': 'Menuikon*'
  },
  visibility: {
    'sv-SE': 'Synlighet',
    'en-GB': 'Visibility',
    'da-DK': 'Synlighed'
  },
  visibilityDescription: {
    'sv-SE': 'Avgör om menyn ska vara synlig för gästerna',
    'en-GB': 'Control if the catalog should be visible to the customers',
    'da-DK': 'Styr om menuen skal være synlig for gæsterne'
  },
  visible: {
    'sv-SE': 'Synlig',
    'en-GB': 'Visible',
    'da-DK': 'Synlig'
  },
  notVisible: {
    'sv-SE': 'Inte synlig',
    'en-GB': 'Not visible',
    'da-DK': 'Ikke synlig'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  deleteCatalog: {
    'sv-SE': 'Ta bort meny',
    'en-GB': 'Delete catalog',
    'da-DK': 'Fjern menu'
  },
  copyCatalog: {
    'sv-SE': 'Kopiera meny',
    'en-GB': 'Copy catalog',
    'da-DK': 'Kopier menu'
  },
  catalogNotFoundTitle: {
    'sv-SE': 'Menyn hittades inte',
    'en-GB': 'Catalog not found',
    'da-DK': 'Menuen blev ikke fundet'
  },
  catalogNotFoundMessage: {
    'sv-SE': 'Menyn du sökte kunde tyvärr inte hittas.',
    'en-GB': 'The catalog you requested could not be found.',
    'da-DK': 'Desværre kunne den menu, du ledte efter, ikke findes.'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  couldNotSaveTitle: {
    'sv-SE': 'Kunde inte spara menyn',
    'en-GB': 'Failed to save catalog',
    'da-DK': 'Kunne ikke gemme menuen'
  },
  couldNotSaveMessage: {
    'sv-SE': 'Misslyckades att spara menyn. Vänligen försök igen',
    'en-GB': 'Failed to save the catalog. Please try again',
    'da-DK': 'Menuen kunne ikke gemmes. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  deleteCatalogTitle: {
    'sv-SE': 'Ta bort meny',
    'en-GB': 'Delete catalog',
    'da-DK': 'Fjern menuen'
  },
  deleteCatalogMessage: {
    'sv-SE': 'Är du helt säker på att du vill ta bort menyn?',
    'en-GB': 'Are you sure you want to delete the catalog?',
    'da-DK': 'Er du helt sikker på, at du vil fjerne menuen?'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  servingA: {
    'sv-SE': 'Servering A',
    'en-GB': 'Serving A',
    'da-DK': 'Servering A'
  },
  servingB: {
    'sv-SE': 'Servering B',
    'en-GB': 'Serving B',
    'da-DK': 'Servering B'
  },
  servingC: {
    'sv-SE': 'Servering C',
    'en-GB': 'Serving C',
    'da-DK': 'Servering C'
  },
  takeAway: {
    'sv-SE': 'Take away',
    'en-GB': 'Take away',
    'da-DK': 'Take away'
  },
  tickets: {
    'sv-SE': 'Biljetter',
    'en-GB': 'Tickets',
    'da-DK': 'Billetter'
  },
  other: {
    'sv-SE': 'Annat',
    'en-GB': 'Other',
    'da-DK': 'Andet'
  },
  on: {
    'sv-SE': 'På',
    'en-GB': 'On',
    'da-DK': 'Til'
  },
  off: {
    'sv-SE': 'Av',
    'en-GB': 'Off',
    'da-DK': 'Fra'
  },
  tipEnabled: {
    'sv-SE': 'Dricks',
    'en-GB': 'Tips',
    'da-DK': 'Drikkepenge'
  },
  tipEnabledDescription: {
    'sv-SE': 'Avgör om gästerna ska kunna lämna dricks vid köp från menyn',
    'en-GB':
      'Control if guests should be able to tip the staff for orders from this catalog',
    'da-DK': 'Beslut om gæster skal kunne give drikkepenge ved køb fra menuen'
  },
  variablePricesEnabled: {
    'sv-SE': 'Beställa med fritt belopp',
    'en-GB': 'Order with free amount',
    'da-DK': 'Bestil med frit beløb'
  },
  variablePricesEnabledDescription: {
    'sv-SE':
      'Avgör om användare av terminalen ska kunna välja ett valfritt pris på produkter i menyn (rättigheter krävs).',
    'en-GB':
      'Decide whether users of the terminal should be able to choose an open amount for products in the menu (permissions required).',
    'da-DK':
      'Afgør om brugere i terminalen skal kunne vælge en vilkårlig pris på produkter i menuen (rettigheder kræves).'
  },
  prettyNameTitle: {
    'sv-SE': 'URL',
    'en-GB': 'URL',
    'da-DK': 'URL'
  },
  prettyNameDescription: {
    'sv-SE':
      'Välj vilka URLer som leder till menyn när gäster besöker WEIQ i en webbläsare, och vilken URL som är primär.',
    'en-GB':
      'Choose which URLs lead to the menu when guests visit WEIQ from a web browser, and which one that is primary.',
    'da-DK':
      'Vælg hvilke URLer der fører til menun, når gæster besøger WEIQ i en webbrowser, og hvilken der er primær.'
  },
  newPrettyNamePlaceholder: {
    'sv-SE': 'Unik URL',
    'en-GB': 'Unique URL',
    'da-DK': 'Unikk URL'
  },
  prettyNameToolTip: {
    'sv-SE':
      'Endast A-Z, 0-9, och bindestreck, och från 3 till 50 tecken lång.',
    'en-GB':
      'Only A-Z, 0-9, and hyphens, and between 3 and 50 characters long.',
    'da-DK':
      'Må kun bestå af A-Z, 0-9, og bindestreger, samt være mellem 3 og 50 tegn lange.'
  },
  failedToAddPrettyNamesTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToAddPrettyNamesValidationMessage: {
    'sv-SE': 'Url:erna kunde inte läggas till. Följande URL:er var ogiltiga: ',
    'en-GB': 'The URLs could not be added. The following URLs were invalid:',
    'da-DK': "URL'erne kunne ikke tilføjes. Følgende URL'er var forkerte:"
  },
  failedToAddPrettyNamesMessage: {
    'sv-SE': 'Url:erna kunde inte läggas till. Var god försök igen.',
    'en-GB': 'The URLs could not be added. Please try again.',
    'da-DK': "URL'erne kunne ikke tilføjes. Prøv igen."
  },
  INVALID_FORMAT_ERROR: {
    'sv-SE': 'har felaktigt format',
    'en-GB': 'has invalid format',
    'da-DK': 'har forkert format'
  },
  DUPLICATE_ERROR: {
    'sv-SE': 'existerar redan',
    'en-GB': 'already exists',
    'da-DK': 'eksisterer allerede'
  }
};

export const DAY_STRINGS = {
  MONDAY: {
    'sv-SE': 'Måndag',
    'en-GB': 'Monday',
    'da-DK': 'Mandag'
  },
  TUESDAY: {
    'sv-SE': 'Tisdag',
    'en-GB': 'Tuesday',
    'da-DK': 'Tirsdag'
  },
  WEDNESDAY: {
    'sv-SE': 'Onsdag',
    'en-GB': 'Wednesday',
    'da-DK': 'Onsdag'
  },
  THURSDAY: {
    'sv-SE': 'Torsdag',
    'en-GB': 'Thursday',
    'da-DK': 'Torsdag'
  },
  FRIDAY: {
    'sv-SE': 'Fredag',
    'en-GB': 'Friday',
    'da-DK': 'Fredag'
  },
  SATURDAY: {
    'sv-SE': 'Lördag',
    'en-GB': 'Saturday',
    'da-DK': 'Lørdag'
  },
  SUNDAY: {
    'sv-SE': 'Söndag',
    'en-GB': 'Sunday',
    'da-DK': 'Søndag'
  }
};

export const CATALOG_OPENING_HOURS_STRINGS = {
  ...DAY_STRINGS,
  title: {
    'sv-SE': 'Öppettider',
    'en-GB': 'Opening hours',
    'da-DK': 'Åbningstider'
  },
  description: {
    'sv-SE':
      'Styr menyn med öppettider. En meny utan öppettider är alltid öppen för köp',
    'en-GB':
      'Control the catalog with opening hours. A catalog without opening hours is always open for orders',
    'da-DK':
      'Styr menuen med åbningstider. En menu uden åbningstider er altid åben for køb'
  },
  on: {
    'sv-SE': 'På',
    'en-GB': 'On',
    'da-DK': 'Til'
  },
  off: {
    'sv-SE': 'Av',
    'en-GB': 'Off',
    'da-DK': 'Fra'
  },
  closed: {
    'sv-SE': 'Stängt',
    'en-GB': 'Closed',
    'da-DK': 'Lukket'
  },
  new: {
    'sv-SE': 'Ny',
    'en-GB': 'New',
    'da-DK': 'Ny'
  },
  remove: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Remove',
    'da-DK': 'Fjern'
  },
  closingHourMustBeAfterOpeningHour: {
    'sv-SE': 'Måste vara efter öppningstiden',
    'en-GB': 'Must be after opening hour',
    'da-DK': 'Skal være efter åbningstid'
  }
};

export const COPY_CATALOG_MODAL_STRINGS = {
  copyCatalog: {
    'sv-SE': 'Kopiera meny',
    'en-GB': 'Copy catalog',
    'da-DK': 'Kopier menu'
  },
  copyTicketGroup: {
    'sv-SE': 'Kopiera biljettgrupp',
    'en-GB': 'Copy ticket group',
    'da-DK': 'Kopiér billetgruppe'
  },
  selectCatalog: {
    'sv-SE': 'Välj meny*',
    'en-GB': 'Select catalog*',
    'da-DK': 'Vælg menu*'
  },
  selectTicketGroup: {
    'sv-SE': 'Välj biljettgrupp*',
    'en-GB': 'Select ticket group*',
    'da-DK': 'Vælg billetgruppe*'
  },
  catalogName: {
    'sv-SE': 'Namn på meny*',
    'en-GB': 'Catalog name*',
    'da-DK': 'Navn på menu*'
  },
  ticketGroupName: {
    'sv-SE': 'Namn på biljettgrupp*',
    'en-GB': 'Ticket group name*',
    'da-DK': 'Navn på billetgruppe*'
  },
  catalogDescription: {
    'sv-SE':
      'När du kopierar en meny behåller du dina produkter.\nÄndrar du t.ex. priset på en produkt så kommer det ändras i både\ndin nya meny och din gamla.',
    'en-GB':
      'Your products are kept when you copy a catalog.\nIf you for example change the price of a product, it will be changed\nin both your new and old catalog.',
    'da-DK':
      'Når du kopierer en menu, beholder du dine produkter.\nÆndrer du f.eks. prisen på et produkt, vil den ændre sig i både\nden nye menu og din gamle.'
  },
  ticketGroupDescription: {
    'sv-SE':
      'När du kopierar en biljettgrupp behåller du dina biljetter.\nÄndrar du t.ex. priset på en biljett så kommer det ändras i både\ndin nya biljettgrupp och din gamla.',
    'en-GB':
      'Your tickets are kept when you copy a ticket group.\nIf you for example change the price of a ticket, it will be changed\nin both your new and old ticket group.',
    'da-DK':
      'Når du kopierer en billetgruppe, beholder du dine billetter.\nÆndrer du f.eks. prisen på en billet, vil den ændre sig i både\nden nye billetgruppe og din gamle.'
  },
  prettyName: {
    'sv-SE': 'URL till meny*',
    'en-GB': 'URL to catalog*',
    'da-DK': 'URL til menu*'
  },
  newPrettyNamePlaceholder: {
    'sv-SE': 'Unik URL',
    'en-GB': 'Unique URL',
    'da-DK': 'Unikk URL'
  },
  prettyNameAlreadyExists: {
    'sv-SE': 'URLen finns redan. Försök igen med en annan URL.',
    'en-GB': 'The URL already exists. Please try again with a different URL.',
    'da-DK': "URL'en findes allerede. Prøv venligst igen med en anden URL."
  },
  copyTo: {
    'sv-SE': 'Kopiera till*',
    'en-GB': 'Copy to*',
    'da-DK': 'Kopier til*'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  failedToCopyTitle: {
    'sv-SE': 'Kunde inte kopiera menyn',
    'en-GB': 'Failed to copy catalog',
    'da-DK': 'Kunne ikke kopiere menuen'
  },
  failedToCopyMessage: {
    'sv-SE': 'Ett fel uppstod när menyn skulle kopieras. Vänligen försök igen',
    'en-GB':
      'An error occured while trying to copy the catalog. Please try again',
    'da-DK': 'Der opstod en fejl under forsøget på at kopiere menuen. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  }
};

export const MODIFIER_GROUPS_PAGE_STRINGS = {
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  edit: {
    'sv-SE': 'Redigera tillvalsgrupp',
    'en-GB': 'Edit modifier group',
    'da-DK': 'Rediger tilpasningsgruppe'
  },
  delete: {
    'sv-SE': 'Ta bort tillvalsgrupp',
    'en-GB': 'Delete modifier group',
    'da-DK': 'Fjern tilpasningsgruppe'
  },
  deleteAlertTitle: {
    'sv-SE': 'Ta bort tillvalsgrupp',
    'en-GB': 'Delete modifier group',
    'da-DK': 'Fjern tilpasningsgruppe'
  },
  deleteAlertMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort tillvalsgruppen "$$name$$"?',
    'en-GB': 'Are you sure you want to delete the modifier group "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil fjerne tilpasningsgruppen "$$name$$"?'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök tillvalsgrupper',
    'en-GB': 'Search modifier groups',
    'da-DK': 'Søg tilpasningsgrupper'
  },
  noModifierGroupsFound: {
    'sv-SE': 'Inga tillvalsgrupper hittades',
    'en-GB': 'No modifier groups were found',
    'da-DK': 'Ingen tilpasningsgrupper fundet'
  }
};

export const MODIFIER_GROUP_STRINGS = {
  more: {
    'sv-SE': 'till',
    'en-GB': 'more',
    'da-DK': 'til'
  },
  empty: {
    'sv-SE': 'Tom tillvalsgrupp',
    'en-GB': 'Empty modifier group',
    'da-DK': 'Tom tilpasningsgruppe'
  }
};

export const CATEGORIES_PAGE_STRINGS = {
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  edit: {
    'sv-SE': 'Redigera kategori',
    'en-GB': 'Edit category',
    'da-DK': 'Rediger kategori'
  },
  delete: {
    'sv-SE': 'Ta bort kategori',
    'en-GB': 'Delete category',
    'da-DK': 'Fjern kategori'
  },
  deleteAlertTitle: {
    'sv-SE': 'Ta bort kategori',
    'en-GB': 'Delete category',
    'da-DK': 'Fjern kategori'
  },
  deleteAlertMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort kategorin "$$name$$"?',
    'en-GB': 'Are you sure you want to delete the category "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil slette kategorien "$$navn$$"?'
  },
  sort: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort categories',
    'da-DK': 'Ændre rækkefølge'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök kategori',
    'en-GB': 'Search categories',
    'da-DK': 'Søg kategori'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  noCategoriesFound: {
    'sv-SE': 'Inga kategorier hittades',
    'en-GB': 'No categories were found',
    'da-DK': 'Ingen kategorier fundet'
  }
};

export const EDIT_CATEGORY_PAGE_STRINGS = {
  editCategory: {
    'sv-SE': 'Redigera kategori',
    'en-GB': 'Edit category',
    'da-DK': 'Rediger kategori'
  },
  newCategory: {
    'sv-SE': 'Ny kategori',
    'en-GB': 'New category',
    'da-DK': 'Ny kategori'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  failedToSaveTitle: {
    'sv-SE': 'Kunde inte spara kategorin',
    'en-GB': 'Failed to save category',
    'da-DK': 'Kunne ikke gemme kategorien'
  },
  failedToSaveMessage: {
    'sv-SE': 'Misslyckades att spara kategorin. Vänligen försök igen',
    'en-GB': 'Failed to save the category. Please try again',
    'da-DK': 'Kategorien kunne ikke gemmes. Prøv igen'
  },
  categoryNotFoundTitle: {
    'sv-SE': 'Kategorin hittades inte',
    'en-GB': 'Category not found',
    'da-DK': 'Kategorien blev ikke fundet'
  },
  categoryNotFoundMessage: {
    'sv-SE': 'Kategorin du sökte kunde tyvärr inte hittas.',
    'en-GB': 'The category you requested could not be found.',
    'da-DK': 'Desværre blev den kategori, du søgte efter, ikke fundet.'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn'
  },
  nameTooltip: {
    'sv-SE': 'Namnet på kategorin som kommer visas för användaren',
    'en-GB': 'The name of the category. Will be displayed to the user',
    'da-DK': 'Navnet på den kategori, der vil blive vist for brugeren'
  }
};

export const EDIT_MODIFIER_GROUP_PAGE_STRINGS = {
  newModifierGroup: {
    'sv-SE': 'Ny tillvalsgrupp',
    'en-GB': 'New modifier group',
    'da-DK': 'Ny tilpasningsgruppe'
  },
  editModifierGroup: {
    'sv-SE': 'Redigera tillvalsgrupp',
    'en-GB': 'Edit modifier group',
    'da-DK': 'Rediger tilpasningsgruppe'
  },
  modifierGroup: {
    'sv-SE': 'Tillvalsgrupp',
    'en-GB': 'Modifier group',
    'da-DK': 'Tilpasningsgruppe'
  },
  sort: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort products',
    'da-DK': 'Ændre rækkefølge'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök produkt',
    'en-GB': 'Search products',
    'da-DK': 'Søg produkt'
  },
  removeProduct: {
    'sv-SE': 'Ta bort från tillvalsgruppen',
    'en-GB': 'Remove from modifier gropup',
    'da-DK': 'Fjern fra tilpasningsgruppe'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn*'
  },
  nameTooltip: {
    'sv-SE': 'Namnet på tillvalsgruppen som kommer visas för användaren',
    'en-GB': 'The name of the modifier group. Will be displayed to the user',
    'da-DK': 'Navnet på den tilpasningsgruppe, der vil blive vist for brugeren'
  },
  minProducts: {
    'sv-SE': 'Gästen måste minst välja antal',
    'en-GB': 'Min products that must be selected',
    'da-DK': 'Gæsten skal som minimum vælge'
  },
  minTooltip: {
    'sv-SE':
      'Det minsta antalet tillval från den här gruppen som gästen måste välja vid beställning',
    'en-GB':
      'The minimum number of modifiers from this group that the guest must select to order',
    'da-DK':
      'Minimumsantallet af tilpasningerne fra denne gruppe, som gæsten skal vælge ved bestilling'
  },
  maxProducts: {
    'sv-SE': 'Gästen kan max välja antal (0 = obegränsat)',
    'en-GB': 'Max products that can be selected (0 = unlimited)',
    'da-DK': 'Maksimalt antal produkter, der kan vælges (0 = ubegrænset)'
  },
  maxTooltip: {
    'sv-SE':
      'Det högsta antalet tillval från den här kategorin som gästen kan välja vid beställning',
    'en-GB':
      'The maximum number of modifiers from this group that the guest can select to order',
    'da-DK':
      'Det maksimale antal tilpasninger fra denne kategori, som gæsten kan vælge ved bestilling'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem		'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  selectProducts: {
    'sv-SE': 'Tillval i tillvalsgruppen',
    'en-GB': 'Modifiers in the modifier group',
    'da-DK': 'Tilpasning i tilpasningsgruppen'
  },
  notFoundTitle: {
    'sv-SE': 'Tillvalsgruppen hittades inte',
    'en-GB': 'Modifier group not found',
    'da-DK': 'Tilpasningsgruppen blev ikke fundet'
  },
  notFoundMessage: {
    'sv-SE': 'Tillvalsgruppen du sökte kunde tyvärr inte hittas',
    'en-GB': 'The modifier group you requested could not be found',
    'da-DK': 'Desværre kunne den tilpasningsgruppe, du søgte efter, ikke findes'
  },
  goBack: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  failedToSaveTitle: {
    'sv-SE': 'Kunde inte spara tillvalsgruppen',
    'en-GB': 'Failed to save modifier group ',
    'da-DK': 'Kunne ikke gemme tilpasningsgruppen'
  },
  failedToSaveMessage: {
    'sv-SE': 'Misslyckades att spara tillvalsgruppen. Vänligen försök igen',
    'en-GB': 'Failed to save the modifier group. Please try again.',
    'da-DK': 'Tilpasningsgruppen kunne ikke gemmes. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  add: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add modifiers',
    'da-DK': 'tilføj'
  },
  emptyGroup: {
    'sv-SE':
      'Den här tillvalsgruppen är tom! Klicka på knappen för att lägga till några tillval',
    'en-GB':
      'This modifier group is empty! Press the button to add some modifiers.',
    'da-DK':
      'Denne tilpasningsgruppe er tom! Klik på knappen for at tilføje nogle tilpasninger'
  },
  addModifiersModalTitle: {
    'sv-SE': 'Lägg till tillval',
    'en-GB': 'Add modifiers',
    'da-DK': 'Tilføj tilpasning'
  },
  addModifiersModalDescription: {
    'sv-SE': 'Välj produkter att lägga till i tillvalsgruppen',
    'en-GB': 'Select modifiers to add to the modifier group',
    'da-DK': 'Vælg produkter, der skal tilføjes til tilpasningsgruppen'
  },
  addModifiersModalNoEntries: {
    'sv-SE': 'Inga tillval hittades',
    'en-GB': 'No modifiers found',
    'da-DK': 'Ingen tilpasninger fundet'
  },
  removeProductAlertTitle: {
    'sv-SE': 'Ta bort tillval från tillvalsgruppen?',
    'en-GB': 'Remove modifier from the modifier group?',
    'da-DK': 'Fjern tilpasning fra tilpasningsgruppe'
  },
  removeProductAlertMessage: {
    'sv-SE':
      'Är du säker på att du vill ta bort "$$name$$" från tillvalsgruppen?',
    'en-GB':
      'Are you sure you want to remove "$$name$$" from the modifier group?',
    'da-DK':
      'Er du sikker på, at du vil fjerne "$$navn$$" fra tilpasningsgruppen'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  }
};

export const SORTABLE_TABLE_STRINGS = {
  explainer: {
    'sv-SE': 'Dra i raderna för att ändra ordningen. Tryck sedan på spara.',
    'en-GB': 'Drag the rows to change the order. Then press save.',
    'da-DK': 'Træk rækkerne for at ændre rækkefølgen. Tryk derefter på gem.'
  },
  toTop: {
    'sv-SE': 'Flytta högst upp',
    'en-GB': 'Move to the top',
    'da-DK': 'Flyt til toppen'
  },
  toBottom: {
    'sv-SE': 'Flytta längst ned',
    'en-GB': 'Move to the bottom',
    'da-DK': 'Flyt til bunden'
  }
};

export const SORT_CATALOG_PRODUCTS_STRINGS = {
  title: {
    'sv-SE': 'Sortera produkter',
    'en-GB': 'Sort products',
    'da-DK': 'Sorter produkter'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  notice: {
    'sv-SE': 'Notera att produkter med små bilder alltid hamnar sist i menyn.',
    'en-GB':
      'Note that products with small images must be placed at the end of the catalog.',
    'da-DK': 'Bemærk at produkter med små billeder altid ender sidst i menuen.'
  }
};

export const SORT_CATALOGS_PAGE = {
  title: {
    'sv-SE': 'Sortera menyer & biljettgrupper',
    'en-GB': 'Sort catalogs & ticket groups',
    'da-DK': 'Sorter menuer og billetgrupper'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  failedToSaveTitle: {
    'sv-SE': 'Kunde inte spara nya ordningen',
    'en-GB': 'Failed to save sort order',
    'da-DK': 'Kunne ikke gemme den nye rekkefølje'
  },
  failedToSaveMessage: {
    'sv-SE': 'Misslyckades att spara den nya ordningen. Vänligen försök igen',
    'en-GB': 'The new sort order could not be saved. Please try again',
    'da-DK': 'Kunne ikke gemme den nye rekkefølje. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  catalog: {
    'sv-SE': 'Meny',
    'en-GB': 'Catalog',
    'da-DK': 'Menu'
  },
  ticketGroup: {
    'sv-SE': 'Biljettgrupp',
    'en-GB': 'Ticket group',
    'da-DK': 'Billetgruppe'
  },
  visible: {
    'sv-SE': 'Synlig',
    'en-GB': 'Visible',
    'da-DK': 'Synlig'
  },
  notVisible: {
    'sv-SE': 'Ej synlig',
    'en-GB': 'Not visible',
    'da-DK': 'Ikke synlig'
  },
  menus: {
    'sv-SE': 'Menyer',
    'en-GB': 'Menus',
    'da-DK': 'Menuer'
  },
  catalogs: {
    'sv-SE': 'Menyer',
    'en-GB': 'Catalogs',
    'da-DK': 'Menuer'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  ticketGroups: {
    'sv-SE': 'Biljettgrupper',
    'en-GB': 'Ticket groups',
    'da-DK': 'Billetgrupper'
  }
};

export const SORT_MODIFIERS_STRINGS = {
  title: {
    'sv-SE': 'Sortera tillval',
    'en-GB': 'Sort modifiers',
    'da-DK': 'Sorter Tilpasnings'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  }
};

export const SORT_CATEGORIES_STRINGS = {
  title: {
    'sv-SE': 'Sortera kategorier',
    'en-GB': 'Sort categories',
    'da-DK': 'Sorter kategorier'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  }
};

export const ENTRY_HEADER_STRINGS = {
  menuTitle: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  }
};

export const ENTRY_TABS_STRINGS = {
  CATALOGS: {
    'sv-SE': 'Biljettgrupper',
    'en-GB': 'Ticket groups',
    'da-DK': 'Billetgrupper'
  },
  PRODUCTS: {
    'sv-SE': 'Biljetter',
    'en-GB': 'Tickets',
    'da-DK': 'Billetter'
  }
};

export const ENTRY_GROUPS_PAGE_STRINGS = {
  searchPlaceholder: {
    'sv-SE': 'Sök biljetter',
    'en-GB': 'Search tickets',
    'da-DK': 'Søg billetter'
  },
  selectItemsModalTitle: {
    'sv-SE': 'Lägg till biljetter',
    'en-GB': 'Add tickets',
    'da-DK': 'Tilføj billetter'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  enableEntryTickets: {
    'sv-SE': 'Aktivera biljetter',
    'en-GB': 'Enable entry tickets',
    'da-DK': 'Aktiver billetter'
  },
  entryTicketsEnabled: {
    'sv-SE': 'Biljettfunktionen är aktiverad',
    'en-GB': 'Entry tickets are enabled',
    'da-DK': 'Billetter er aktiveret'
  },
  selectItemsModalDescription: {
    'sv-SE': 'Välj biljetter att lägga till i biljettgruppen "$$catalogName$$"',
    'en-GB': 'Select tickets to add to the ticket group "$$catalogName$$"',
    'da-DK':
      'Vælg billetter, der skal tilføjes til billetgruppen "$$catalogName$$"'
  },
  noProductsFound: {
    'sv-SE': 'Inga biljetter hittades',
    'en-GB': 'No tickets found',
    'da-DK': 'Ingen billetter fundet'
  },
  add: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'tilføj'
  },
  newProduct: {
    'sv-SE': 'Ny biljett',
    'en-GB': 'New ticket',
    'da-DK': 'Ny billet'
  },
  selectProducts: {
    'sv-SE': 'Välj biljetter',
    'en-GB': 'Select tickets',
    'da-DK': 'Vælg billetter'
  },
  sortProducts: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort tickets',
    'da-DK': 'Ændre rækkefølge'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  removeFromCatalogAlertTitle: {
    'sv-SE': 'Ta bort biljett från biljettgruppen',
    'en-GB': 'Remove ticket from ticket group',
    'da-DK': 'Fjern billet fra billetgruppe'
  },
  removeFromCatalogAlertMessage: {
    'sv-SE':
      'Är du säker på att du vill ta bort biljetten "$$name$$" från biljettgruppen?`',
    'en-GB':
      'Are you sure you want to remove the ticket "$$name$$" from the ticket group?',
    'da-DK':
      'Er du sikker på, at du vil fjerne billetten "$$name$$" fra billetgruppen?'
  },
  updateFailedAlertTitle: {
    'sv-SE': 'Uppdateringen misslyckades',
    'en-GB': 'Update failed',
    'da-DK': 'Opdateringen mislykkedes'
  },
  updateFailedAlertMessage: {
    'sv-SE':
      'Det gick inte att ändra synligheten för biljetten "$$name$$". Var god försök igen.',
    'en-GB':
      'Could not change the visibility for the ticket "$$name$$". Please try again.',
    'da-DK':
      'Kunne ikke ændre synligheden af ​​billetten "$$name$$". Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  editProduct: {
    'sv-SE': 'Redigera biljett',
    'en-GB': 'Edit ticket',
    'da-DK': 'Rediger billett'
  },
  copyProduct: {
    'sv-SE': 'Kopiera biljett',
    'en-GB': 'Copy ticket',
    'da-DK': 'Kopiér billet'
  },
  removeFromCatalog: {
    'sv-SE': 'Ta bort från biljettgrupp',
    'en-GB': 'Remove from ticket group',
    'da-DK': 'Fjern fra billetgruppe'
  },
  visible: {
    'sv-SE': 'Synlig',
    'en-GB': 'Visible',
    'da-DK': 'Synlig'
  },
  notVisible: {
    'sv-SE': 'Ej synlig',
    'en-GB': 'Not visible',
    'da-DK': 'Ikke synlig'
  },
  stockUpdateFailedMessage: {
    'sv-SE':
      'Det gick inte att ändra antal biljetter för "$$name$$". Var god försök igen.',
    'en-GB':
      'Could not update stock for the ticket "$$name$$". Please try again.',
    'da-DK': 'Kunne ikke ændre antallet af billetter til "$$name$$". Prøv igen.'
  },
  stockModalTitle: {
    'sv-SE': 'Ändra saldo',
    'en-GB': 'Update stock',
    'da-DK': 'Opdater lager'
  },
  stockModalSubtitle: {
    'sv-SE': 'Ändra antalet biljetter för "$$name$$"',
    'en-GB': 'Change the number of tickets for "$$name$$"',
    'da-DK': 'Skift antallet af billetter til "$$name$$"'
  },
  stockModalDescription: {
    'sv-SE':
      'Lämna fältet tomt för obegränsat antal biljetter.\nAntalet biljetter minskar automatiskt vid köp.',
    'en-GB':
      'Leave the field empty for an unlimited number of tickets. The number automatically decreases with each purchase.',
    'da-DK':
      'Lad feltet stå tomt for ubegrænsede billetter.\nAntallet af billetter reduceres automatisk ved køb.'
  },
  numberOfTickets: {
    'sv-SE': 'Antal biljetter',
    'en-GB': 'Number of tickets',
    'da-DK': 'Antal billetter'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  unlimited: {
    'sv-SE': 'Obegränsat',
    'en-GB': 'Unlimited',
    'da-DK': 'Ubegrænset'
  },
  editStock: {
    'sv-SE': 'Ändra antal biljetter',
    'en-GB': 'Change number of tickets',
    'da-DK': 'Ændre antal billetter'
  },
  remainingTickets: {
    'sv-SE': 'st',
    'en-GB': 'left',
    'da-DK': 'tilbage'
  },
  newCatalog: {
    'sv-SE': 'Ny biljettgrupp',
    'en-GB': 'New ticket group',
    'da-DK': 'Ny billetgruppe'
  },
  editCatalog: {
    'sv-SE': 'Redigera biljettgrupp',
    'en-GB': 'Edit ticket group',
    'da-DK': 'Rediger billetgruppe'
  },
  sortCatalogs: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Sort ticket groups',
    'da-DK': 'Ændre rækkefølge'
  },
  noEntryTicketsFound: {
    'sv-SE': 'Inga entrébiljetter hittades',
    'en-GB': 'No entry tickets found',
    'da-DK': 'Ingen entrébilletter fundet'
  }
};

export const EDIT_ENTRY_CATALOG_STRINGS = {
  editCatalog: {
    'sv-SE': 'Redigera biljettgrupp',
    'en-GB': 'Edit ticket group',
    'da-DK': 'Rediger billetgruppe'
  },
  newCatalog: {
    'sv-SE': 'Ny biljettgrupp',
    'en-GB': 'New ticket group',
    'da-DK': 'Ny billetgruppe'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn*'
  },
  nameTooltip: {
    'sv-SE': 'Namnet på biljettgruppen. Kommer visas för användaren.',
    'en-GB': 'The name of the ticket group. Will be displayed to the user.',
    'da-DK': 'Navnet på billetgruppen. Vil blive vist for brugeren.'
  },
  description: {
    'sv-SE': 'Beskrivning*',
    'en-GB': 'Description*',
    'da-DK': 'Beskrivelse*'
  },
  descriptionTooltip: {
    'sv-SE':
      'En beskrivning av biljettgruppen som kommer visas för användaren.',
    'en-GB':
      'A description of the ticket group that will be displayed to the user.',
    'da-DK': 'En beskrivelse af billetgruppen, der vil blive vist for brugeren.'
  },
  visibility: {
    'sv-SE': 'Synlighet',
    'en-GB': 'Visibility',
    'da-DK': 'Synlighed'
  },
  visibilityDescription: {
    'sv-SE': 'Avgör om biljettgruppen ska vara synlig för gästerna',
    'en-GB': 'Control if the ticket group should be visible to the customers',
    'da-DK': 'Bestem hvis billetgruppen skal være synlig for gæsterne'
  },
  catalogNotFoundTitle: {
    'sv-SE': 'Biljettgruppen hittades inte',
    'en-GB': 'Ticket group not found',
    'da-DK': 'Billetgruppen blev ikke fundet'
  },
  catalogNotFoundMessage: {
    'sv-SE': 'Biljettgruppen du sökte kunde tyvärr inte hittas.',
    'en-GB': 'The ticket group you requested could not be found.',
    'da-DK': 'Desværre kunne den billetgruppe, du ledte efter, ikke findes.'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  couldNotSaveTitle: {
    'sv-SE': 'Kunde inte spara biljettgruppen',
    'en-GB': 'Failed to save the ticket group',
    'da-DK': 'Kunne ikke gemme billetgruppen'
  },
  couldNotSaveMessage: {
    'sv-SE': 'Misslyckades att spara biljettgruppen. Vänligen försök igen',
    'en-GB': 'Failed to save the ticket group. Please try again',
    'da-DK': 'Billetgruppen kunne ikke gemmes. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  deleteCatalog: {
    'sv-SE': 'Ta bort biljettgrupp',
    'en-GB': 'Delete ticket group',
    'da-DK': 'Fjern billetgruppe'
  },
  copyCatalog: {
    'sv-SE': 'Kopiera biljettgrupp',
    'en-GB': 'Copy ticket group',
    'da-DK': 'Kopiér billetgruppe'
  },
  deleteCatalogTitle: {
    'sv-SE': 'Ta bort biljettgrupp',
    'en-GB': 'Delete ticket group',
    'da-DK': 'Fjern billetgruppe'
  },
  deleteCatalogMessage: {
    'sv-SE': 'Är du helt säker på att du vill ta bort biljettgruppen?',
    'en-GB': 'Are you sure you want to delete the ticket group?',
    'da-DK': 'Er du helt sikker på, at du vil slette billetgruppen?'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  }
};

export const ENTRY_PRODUCTS_PAGE_STRINGS = {
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  tickets: {
    'sv-SE': 'Biljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Billetter'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök biljett',
    'en-GB': 'Search tickets',
    'da-DK': 'Søg billet'
  },
  deleteTicketAlertTitle: {
    'sv-SE': 'Ta bort biljett',
    'en-GB': 'Delete ticket',
    'da-DK': 'Fjern billet'
  },
  deleteTicketAlertMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort biljetten "$$name$$"?`',
    'en-GB': 'Are you sure you want to delete the ticket "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil slette billetten "$$name$$"?'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  editTicket: {
    'sv-SE': 'Redigera biljett',
    'en-GB': 'Edit ticket',
    'da-DK': 'Rediger billet'
  },
  copyTicket: {
    'sv-SE': 'Kopiera biljett',
    'en-GB': 'Copy ticket',
    'da-DK': 'Kopiér billet'
  },
  deleteTicket: {
    'sv-SE': 'Ta bort biljett',
    'en-GB': 'Delete ticket',
    'da-DK': 'Fjern billet'
  },
  noEntryTicketsFound: {
    'sv-SE': 'Inga entrébiljetter hittades',
    'en-GB': 'No entry tickets found',
    'da-DK': 'Ingen entrébilletter fundet'
  }
};

export const EDIT_ENTRY_TICKET_STRINGS = {
  failedToSaveTitle: {
    'sv-SE': 'Kunde inte spara biljetten',
    'en-GB': 'The ticket could not be saved',
    'da-DK': 'Kunne ikke gemme billetten'
  },
  failedToSaveMessage: {
    'sv-SE': 'Misslyckades att spara biljetten. Vänligen försök igen',
    'en-GB': 'Failed to save the ticket. Please try again',
    'da-DK': 'Billetten kunne ikke gemmes. Prøv igen'
  },
  productNotFoundTitle: {
    'sv-SE': 'Entrébiljetten hittades inte',
    'en-GB': 'Entry ticket not found',
    'da-DK': 'Entrébilletten blev ikke fundet'
  },
  productNotFoundMessage: {
    'sv-SE': 'Biljetten du sökte kunde tyvärr inte hittas',
    'en-GB': 'The ticket you requested could not be found',
    'da-DK': 'Desværre kunne den billet, du ledte efter, ikke findes'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  saveInOneCatalog: {
    'sv-SE': 'Spara i 1 biljettgrupp',
    'en-GB': 'Save in 1 ticket group',
    'da-DK': 'Gem i 1 billetgruppe'
  },
  saveInMultipleCatalogs: {
    'sv-SE': 'Spara i $$N_CATALOGS$$ biljettgrupper',
    'en-GB': 'Save in $$N_CATALOGS$$ ticket groups',
    'da-DK': 'Gem i $$N_CATALOGS$$ billetgrupper'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  name: {
    'sv-SE': 'Namn*',
    'en-GB': 'Name*',
    'da-DK': 'Navn*'
  },
  nameToolTip: {
    'sv-SE': 'Namnet på entrébiljetten som kommer visas för användaren',
    'en-GB': 'The name of the entry ticket that will be displayed to the user',
    'da-DK': 'Navnet på entrébilletten, der vil blive vist for brugeren'
  },
  price: {
    'sv-SE': 'Pris*',
    'en-GB': 'Price*',
    'da-DK': 'Pris*'
  },
  priceToolTip: {
    'sv-SE': 'Priset på entrébiljetten inklusive moms',
    'en-GB': 'The price of the entry ticket, including VAT',
    'da-DK': 'Entrébillettens pris inklusive moms'
  },
  vatRate: {
    'sv-SE': 'Momssats*',
    'en-GB': 'VAT Rate*',
    'da-DK': 'Momssats*'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  },
  descriptionToolTip: {
    'sv-SE':
      'En beskrivning av entrébiljetten som kommer att visas för användaren',
    'en-GB':
      'A description of the entry ticket that will be displayed to the user',
    'da-DK': 'En beskrivelse af entrébilletten, der vil blive vist for brugeren'
  },
  showInCatalogs: {
    'sv-SE': 'Visa i biljettgrupper',
    'en-GB': 'Show in ticket groups',
    'da-DK': 'Vis i billetgrupper'
  },
  otherCatalogs: {
    'sv-SE': 'Andra biljettgrupper',
    'en-GB': 'Other ticket groups',
    'da-DK': 'Andre billetgrupper'
  },
  modifiers: {
    'sv-SE': 'Tillval',
    'en-GB': 'Modifiers',
    'da-DK': 'Tilpasning'
  },
  loading: {
    'sv-SE': 'Laddar...',
    'en-GB': 'Loading...',
    'da-DK': 'Indlæser...'
  },
  newProduct: {
    'sv-SE': 'Ny entrébiljett',
    'en-GB': 'New entry ticket',
    'da-DK': 'Ny entrébillet'
  },
  editProduct: {
    'sv-SE': 'Redigera entrébiljett',
    'en-GB': 'Edit entry ticket',
    'da-DK': 'Rediger entrébillet'
  }
};

export const ENABLE_ENTRY_TICKETS_SIDEBAR_STRINGS = {
  ...COMMON_FORM_STRINGS,
  image: {
    'sv-SE': 'Bild',
    'en-GB': 'Image',
    'da-DK': 'Billede'
  },
  failedToSaveTitle: {
    'sv-SE': 'Ett fel uppstod',
    'en-GB': 'An error occured',
    'da-DK': 'En fejl opstod'
  },
  failedToSaveMessage: {
    'sv-SE': 'Dina ändringar kunde inte sparas. Vänligen försök igen.',
    'en-GB': 'Your changes could not be saved. Please try again.',
    'da-DK': 'Dine ændringer kunne ikke gemmes. Prøv igen.'
  },
  enableEntryTicketsButton: {
    'sv-SE': 'Aktivera biljetter',
    'en-GB': 'Enable entry tickets',
    'da-DK': 'Aktiver billetter'
  },
  codeInput: {
    'sv-SE': 'Kod (4 tecken)',
    'en-GB': 'Code (4 characters)',
    'da-DK': 'Kode (4 tegn)'
  },
  validationSkin: {
    'sv-SE': 'Kontrollbild',
    'en-GB': 'Validation image',
    'da-DK': 'Kontrolbillede'
  },
  selectValidationSkin: {
    'sv-SE': 'Välj en kontrollbild',
    'en-GB': 'Select a validation image',
    'da-DK': 'Vælg et kontrolbillede'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  }
};

export const SORT_ENTRY_TICKETS_STRINGS = {
  title: {
    'sv-SE': 'Sortera biljetter',
    'en-GB': 'Sort tickets',
    'da-DK': 'Sorter billetter'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  }
};

export const TIME_SLOT_STRINGS = {
  title: {
    'sv-SE': 'Aktiveringsintervall*',
    'en-GB': 'Activation interval*',
    'da-DK': 'Aktiveringsinterval*'
  },
  description: {
    'sv-SE': 'Ange när biljetten kan förbrukas och användas',
    'en-GB': 'Control when ticket can be activated and used',
    'da-DK': 'Angiv hvornår billetten kan forbruges og bruges'
  },
  add: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføje'
  },
  remove: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Delete',
    'da-DK': 'Fjern'
  },
  toMustBeAfterFrom: {
    'sv-SE': 'Måste vara efter starttiden',
    'en-GB': 'Must be after start time',
    'da-DK': 'Skal være efter starttidspunktet'
  },
  from: {
    'sv-SE': 'Från',
    'en-GB': 'From',
    'da-DK': 'Fra'
  },
  to: {
    'sv-SE': 'Till',
    'en-GB': 'To',
    'da-DK': 'Til'
  }
};

export const ORDER_TABLE_PAGE_STRINGS = {
  ...TICKET_STATUS_STRINGS,
  manage: {
    'sv-SE': 'Hantera markerade',
    'en-GB': 'Manage checked',
    'da-DK': 'Håndter udvalgte'
  },
  deliver: {
    'sv-SE': 'Lämna ut',
    'en-GB': 'Deliver',
    'da-DK': 'Aflevere'
  },
  reject: {
    'sv-SE': 'Avvisa',
    'en-GB': 'Reject',
    'da-DK': 'Afvis'
  },
  credit: {
    'sv-SE': 'Återbetala',
    'en-GB': 'Credit',
    'da-DK': 'Tilbagebetale'
  },
  orderStatus: {
    'sv-SE': 'Orderstatus',
    'en-GB': 'Order status',
    'da-DK': 'Ordre status'
  },
  ticketNumber: {
    'sv-SE': 'Biljettnummer',
    'en-GB': 'Ticket number',
    'da-DK': 'Billetnummer'
  },
  purchaseDate: {
    'sv-SE': 'Köpdatum',
    'en-GB': 'Purchase date',
    'da-DK': 'Købsdato'
  },
  sum: {
    'sv-SE': 'Summa',
    'en-GB': 'Sum',
    'da-DK': 'Beløb'
  },
  paymentMethod: {
    'sv-SE': 'Betalmetod',
    'en-GB': 'Payment method',
    'da-DK': 'Betalingsmetode'
  },
  show: {
    'sv-SE': 'Visa',
    'en-GB': 'Show',
    'da-DK': 'Vis'
  },
  from: {
    'sv-SE': 'Från',
    'en-GB': 'From',
    'da-DK': 'Fra'
  },
  to: {
    'sv-SE': 'Till',
    'en-GB': 'To',
    'da-DK': 'Til'
  },
  filterByDate: {
    'sv-SE': 'Filtrera på datum',
    'en-GB': 'Filter by date',
    'da-DK': 'Filtrer på dato'
  },
  processingPayment: {
    'sv-SE': 'Behandlar betalning',
    'en-GB': 'Processing payment',
    'da-DK': 'Behandler betaling'
  },
  entryTicket: {
    'sv-SE': 'Entrébiljett',
    'en-GB': 'Entry ticket',
    'da-DK': 'Entrébillet'
  },
  swish: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  card: {
    'sv-SE': 'Kort',
    'en-GB': 'Card',
    'da-DK': 'Kort'
  },
  terminal: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  pac: {
    'sv-SE': 'Betala i kassa',
    'en-GB': 'Pay at counter',
    'da-DK': 'Betal ved kassen'
  },
  products: {
    'sv-SE': 'Produkter',
    'en-GB': 'Products',
    'da-DK': 'Produkter'
  },
  tip: {
    'sv-SE': 'Dricks',
    'en-GB': 'Tip',
    'da-DK': 'Drikkepenge'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  generateOrders: {
    'sv-SE': 'Visa fler',
    'en-GB': 'Show more',
    'da-DK': 'Vis flere'
  },
  rowsShown: {
    'sv-SE': 'Visar $rows$ beställningar',
    'en-GB': 'Showing $rows$ orders',
    'da-DK': 'Viser $rows$ ordrer'
  },
  modalHeader: {
    'sv-SE': 'Är du säker?',
    'en-GB': 'Are you sure?',
    'da-DK': 'Er du sikker?'
  },
  modalDescriptionSeveralDeliver: {
    'sv-SE': 'Är du säker på att du vill lämna ut $nbr$ beställningar?',
    'en-GB': 'Are you sure that you want to deliver $nbr$ orders?',
    'da-DK': 'Er du sikker på, at du vil levere $nbr$-ordrer?'
  },
  modalDescriptionSeveralReject: {
    'sv-SE': 'Är du säker på att du vill avvisa $nbr$ beställningar?',
    'en-GB': 'Are you sure that you want to reject $nbr$ orders?',
    'da-DK': 'Er du sikker på, at du vil afvise $nbr$-ordrer?'
  },
  modalDescriptionSeveralCredit: {
    'sv-SE':
      'Är du säker på att du vill återbetala $nbr$ beställningar med ett totalt värde av $amount$? Detta kan inte ångras.',
    'en-GB':
      'Are you sure that you want to credit $nbr$ orders for a total amount of $amount$? This cannot be undone.',
    'da-DK':
      'Er du sikker på, at du vil refundere $nbr$-ordrer med en samlet værdi af $amount$? Dette kan ikke fortrydes.'
  },
  modalDescriptionDeliver: {
    'sv-SE': 'Är du säker på att du vill lämna ut beställningen?',
    'en-GB': 'Are you sure that you want to deliver the order?',
    'da-DK': 'Er du sikker på, at du vil levere ordren?'
  },
  modalDescriptionReject: {
    'sv-SE': 'Är du säker på att du vill avvisa beställningen?',
    'en-GB': 'Are you sure that you want to reject the order?',
    'da-DK': 'Er du sikker på, at du vil afvise ordren?'
  },
  modalDescriptionCredit: {
    'sv-SE':
      'Är du säker på att du vill återbetala beställningen med ett totalt värde av $amount$? Detta kan inte ångras.',
    'en-GB':
      'Are you sure that you want to credit the order for a total amount of $amount$? This cannot be undone.',
    'da-DK':
      'Er du sikker på, at du vil refundere ordren med en samlet værdi af $amount$? Dette kan ikke fortrydes.'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  progressDeliver: {
    'sv-SE': 'Lämnar ut $nbr$ beställningar',
    'en-GB': 'Delivering $nbr$ orders',
    'da-DK': 'Leverer $nbr$-ordrer'
  },
  progressReject: {
    'sv-SE': 'Avvisar $nbr$ beställningar',
    'en-GB': 'Rejecting $nbr$ orders',
    'da-DK': 'Afviser $nbr$ ordrer'
  },
  progressCredit: {
    'sv-SE': 'Gör återköp på $nbr$ beställningar',
    'en-GB': 'Crediting $nbr$ orders',
    'da-DK': 'Gør tilbagekøb på $nbr$ ordrer'
  },
  errorMessageCredited: {
    'sv-SE': '$success$ av $total$ beställningar återbetalades.',
    'en-GB': '$success$ of $total$ orders were credited.',
    'da-DK': '$success$ af $total$ ordrer blev refunderet.'
  },
  errorMessageDelivered: {
    'sv-SE': '$success$ av $total$ beställningar lämnades ut.',
    'en-GB': '$success$ of $total$ orders were delivered.',
    'da-DK': '$success$ af $total$ ordrer blev levereret.'
  },
  errorMessageRejected: {
    'sv-SE': '$success$ av $total$ beställningar avvisades.',
    'en-GB': '$success$ of $total$ orders were rejected.',
    'da-DK': '$success$ af $total$ ordrer blev afvist.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  orders: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  noOrdersFoundTitle: {
    'sv-SE': 'Inga köp hittades',
    'en-GB': 'No purchases were found',
    'da-DK': 'Ingen køb fundet'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  searchTooltip: {
    'sv-SE':
      'Du kan söka efter e-post, biljettnummer, kvittonummer, användarreferenser, beställningsreferenser',
    'en-GB':
      'You can search for emails, ticket codes, receipt numbers, user references, and order references',
    'da-DK':
      'Du kan søge efter e-mail, billetnummer, kvitteringsnummer, brugerreferencer, bestillingsreferencer'
  },
  viewAll: {
    'sv-SE': 'Visa alla',
    'en-GB': 'View all',
    'da-DK': 'Vis alt'
  }
};

export const ORDER_PAGE_STRINGS = {
  title: {
    'sv-SE': 'Beställning',
    'en-GB': 'Order',
    'da-DK': 'Bestilling'
  },
  titleEntryTickets: {
    'sv-SE': 'Inträdesbiljett',
    'en-GB': 'Entry ticket',
    'da-DK': 'Entrébillett'
  },
  createdAt: {
    'sv-SE': 'Beställning påbörjad $date$',
    'en-GB': 'Order created $date$',
    'da-DK': 'Ordren begyndte $date$'
  },
  deliveryType: {
    'sv-SE': 'Leveranssätt: $deliveryType$',
    'en-GB': 'Delivery type: $deliveryType$',
    'da-DK': 'Leveranstyp: $deliveryType$'
  },
  pspReference: {
    'sv-SE': 'Betalningsreferens: $ref$',
    'en-GB': 'Payment reference: $ref$',
    'da-DK': 'Betalingsreference: $ref$'
  },
  orderCreated: {
    'sv-SE': 'Beställning skapad',
    'en-GB': 'Order created',
    'da-DK': 'Bestilling oprettet'
  },
  paymentReceived: {
    'sv-SE': 'Köp betalt',
    'en-GB': 'Payment received',
    'da-DK': 'Køb betalt'
  },
  confirmedProgressBar: {
    'sv-SE': 'Påbörjad',
    'en-GB': 'Confirmed',
    'da-DK': 'Begyndt'
  },
  delivered: {
    'sv-SE': 'Utlämnad',
    'en-GB': 'Delivered',
    'da-DK': 'Levereret'
  },
  products: {
    'sv-SE': 'Produkter',
    'en-GB': 'Products',
    'da-DK': 'Produkter'
  },
  unused: {
    'sv-SE': 'Oanvänd',
    'en-GB': 'Unused',
    'da-DK': 'Ubrugt'
  },
  used: {
    'sv-SE': 'Förbrukad',
    'en-GB': 'Used',
    'da-DK': 'Forbrugt'
  },
  expired: {
    'sv-SE': 'Utgången',
    'en-GB': 'Expired',
    'da-DK': 'Udløbet'
  },
  modifier: {
    'sv-SE': 'Tillval',
    'en-GB': 'Modifier',
    'da-DK': 'Tilpasning'
  },
  orderNotFoundTitle: {
    'sv-SE': 'Beställningen hittades inte',
    'en-GB': 'The order was not be found',
    'da-DK': 'Bestillingen blev ikke fundet'
  },
  orderNotFoundMessage: {
    'sv-SE': 'Den valda beställningen kunde inte hittas.',
    'en-GB': 'The requested order was not be found.',
    'da-DK': 'Den valgte ordre kunne ikke findes.'
  },
  noProductsFound: {
    'sv-SE': 'Inga produkter hittades',
    'en-GB': 'No products were found',
    'da-DK': 'Ingen produkter fundet'
  },
  noEventsFound: {
    'sv-SE': 'Inga händelser hittades',
    'en-GB': 'No events were found',
    'da-DK': 'Ingen begivenheder fundet'
  },
  noPaymentAttemptsFound: {
    'sv-SE': 'Inga betalförsök hittades',
    'en-GB': 'No payment attempts were found',
    'da-DK': 'Ingen betalingsforsøg fundet'
  },
  operationFailedTitle: {
    'sv-SE': 'Operationen misslyckades',
    'en-GB': 'The operation failed',
    'da-DK': 'Handlingen mislykkedes'
  },
  operationFailedMessage: {
    'sv-SE': 'Operationen kunde inte genomföras. Var god försök igen.',
    'en-GB': 'The operation did not succeed. Please try again.',
    'da-DK': 'Handlingen kunne ikke fuldføres. Prøv igen.'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  modalTitle: {
    'sv-SE': 'JSON för $ref$',
    'en-GB': 'JSON for $ref$',
    'da-DK': 'JSON for $ref$'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  promptTitleConfirmOrder: {
    'sv-SE': 'Påbörja beställning',
    'en-GB': 'Confirm order',
    'da-DK': 'Begynd ordre'
  },
  promptDescriptionConfirmOrder: {
    'sv-SE': 'Vill du markera beställningen som påbörjad?',
    'en-GB': 'Do you want to mark the order as confirmed?',
    'da-DK': 'Vil du markere ordren som begyndt?'
  },
  promptTitleUseTickets: {
    'sv-SE': 'Förbruka inträdesbiljetter',
    'en-GB': 'Use entry tickets',
    'da-DK': 'Forbrug entrébilletter'
  },
  promptDescriptionUseTickets: {
    'sv-SE':
      'Vill du markera de förbrukningsbara inträdesbiljetterna som förbrukade?',
    'en-GB': 'Do you want to mark the usable tickets as used?',
    'da-DK': 'Vil du markere de brugbare billetter som brugte?'
  },
  promptTitleReadyForPickup: {
    'sv-SE': 'Redo för upphämtning',
    'en-GB': 'Ready for pick-up',
    'da-DK': 'Klar til afhentning'
  },
  promptDescriptionReadyForPickup: {
    'sv-SE': 'Vill du markera beställningen som redo för upphämtning?',
    'en-GB': 'Do you want to mark the order as ready for pick-up?',
    'da-DK': 'Vil du markere ordren som klar til afhentning?'
  },
  promptTitleRejectOrder: {
    'sv-SE': 'Avvisa beställning',
    'en-GB': 'Reject order',
    'da-DK': 'Afvis ordre'
  },
  promptDescriptionRejectOrder: {
    'sv-SE': 'Vill du markera beställningen som avvisad?',
    'en-GB': 'Do you want to mark the order as rejected?',
    'da-DK': 'Vil du markere ordren som afvist?'
  },
  promptTitleDeliverOrder: {
    'sv-SE': 'Lämna ut beställning',
    'en-GB': 'Deliver order',
    'da-DK': 'Levere ordre'
  },
  promptDescriptionDeliverOrder: {
    'sv-SE': 'Vill du markera beställningen som utlämnad?',
    'en-GB': 'Do you want to mark the order as delivered?',
    'da-DK': 'Vil du markere ordren som leveret?'
  },
  promptTitleCreditOrder: {
    'sv-SE': 'Återbetala beställning',
    'en-GB': 'Credit order',
    'da-DK': 'Tilbagebetal ordre'
  },
  promptDescriptionCreditOrder: {
    'sv-SE': 'Vill du återbetala beställningen?',
    'en-GB': 'Do you want to credit the order?',
    'da-DK': 'Vil du refundere ordren?'
  },
  promptTitleCreditOrderError: {
    'sv-SE': 'Återbetalningen misslyckades',
    'en-GB': 'Failure',
    'da-DK': 'Mislykkedes'
  },
  promptDescriptionCreditOrderError: {
    'sv-SE':
      'Återbetalningen misslyckades. Det kan ha berott på att du endast kan återbetala köp upp till %s dagar efter köpet gjordes. Kontakta oss på support@weiq.tech för hjälp med din återbetalning.',
    'en-GB':
      'The refund failed. This may be because you can only refund purchases up to %s days after the purchase was made. Please contact us at support@weiq.tech for help with your refund.',
    'da-DK':
      'Tilbagekøbet mislykkedes. Det kan skyldes, at du kun kan refundere køb op til %s dage efter, at købet blev foretaget. Kontakt os venligst på support@weiq.tech for at få hjælp til din refundering.'
  },
  promptDescriptionCreditOrderErrorAdmin: {
    'sv-SE':
      'Återbetalningen misslyckades. Det kan bero på att det har gått för många dagar sedan köpet gjordes.',
    'en-GB':
      'The refund failed. This may be because too many days have passed since the purchase was made.',
    'da-DK':
      'Tilbagekøbet mislykkedes. Dette kan skyldes at det har gått for mange dager siden kjøpet ble gjort.'
  },
  downloadReceipt: {
    'sv-SE': 'Ladda ner kvitto',
    'en-GB': 'Download receipt',
    'da-DK': 'Download kvittering'
  },
  receipt: {
    'sv-SE': 'Kvitto',
    'en-GB': 'Receipt',
    'da-DK': 'Kvittering'
  },
  generatingReceiptTitle: {
    'sv-SE': 'Kvittot genereras',
    'en-GB': 'The receipt is being generated',
    'da-DK': 'Kvitteringen genereres'
  },
  generatingReceiptMessage: {
    'sv-SE':
      'Kvittot håller just nu på att skapas. Vänligen försök igen om en liten stund.',
    'en-GB':
      'The receipt is currently being generated. Please try again in a moment.',
    'da-DK':
      'Kvitteringen er ved at blive oprettet. Prøv venligst igen om lidt.'
  },
  refundReceipt: {
    'sv-SE': 'Returkvitto',
    'en-GB': 'Refund receipt',
    'da-DK': 'Retur kvittering'
  },
  controlData: {
    'sv-SE': 'Kontrolldata',
    'en-GB': 'Control data',
    'da-DK': 'Kontroldata'
  },
  receiptType: {
    'sv-SE': 'Kvittotyp',
    'en-GB': 'Receipt type',
    'da-DK': 'Kvitteringstype'
  },
  refund: {
    'sv-SE': 'Återköp',
    'en-GB': 'Refund',
    'da-DK': 'Genkøb'
  },
  payment: {
    'sv-SE': 'Köp',
    'en-GB': 'Payment',
    'da-DK': 'Køb'
  },
  serialNumber: {
    'sv-SE': 'Serienummer',
    'en-GB': 'Serial number',
    'da-DK': 'Serienummer'
  },
  controllerUnit: {
    'sv-SE': 'Kontrollenhet',
    'en-GB': 'Controller unit',
    'da-DK': 'Kontrolenhed'
  },
  controllerCode: {
    'sv-SE': 'Kontrollkod',
    'en-GB': 'Controller code',
    'da-DK': 'Kontrolkode'
  },
  controlDataNotFound: {
    'sv-SE': 'Ingen kontrolldata hittades. Vänligen kontakta WEIQs support.',
    'en-GB': 'No control data was found. Please contact the WEIQ support.',
    'da-DK': "Ingen kontroldata fundet. Kontakt venligst WEIQ's support."
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  quantity: {
    'sv-SE': 'Antal',
    'en-GB': 'Quantity',
    'da-DK': 'Antal'
  },
  price: {
    'sv-SE': 'Pris',
    'en-GB': 'Price',
    'da-DK': 'Pris'
  },
  status: {
    'sv-SE': 'Status',
    'en-GB': 'Status',
    'da-DK': 'Status'
  },
  summation: {
    'sv-SE': 'Summering',
    'en-GB': 'Summary',
    'da-DK': 'Oversigt'
  },
  sumProducts: {
    'sv-SE': 'Summa produkter',
    'en-GB': 'Product cost',
    'da-DK': 'Produkter i alt'
  },
  tax: {
    'sv-SE': 'Varav moms',
    'en-GB': 'Included tax',
    'da-DK': 'Heraf moms'
  },
  tip: {
    'sv-SE': 'Varav dricks',
    'en-GB': 'Included tip',
    'da-DK': 'Heraf drikkepenge'
  },
  discount: {
    'sv-SE': 'Rabatt',
    'en-GB': 'Discount',
    'da-DK': 'Rabat'
  },
  total: {
    'sv-SE': 'Totalt',
    'en-GB': 'Total cost',
    'da-DK': 'I alt'
  },
  paymentStatus: {
    'sv-SE': 'Betalförsök',
    'en-GB': 'Payment attempt',
    'da-DK': 'Betalingsforsøg'
  },
  swish: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  card: {
    'sv-SE': 'Kort',
    'en-GB': 'Card',
    'da-DK': 'Kort'
  },
  terminal: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  pac: {
    'sv-SE': 'Betala i kassa',
    'en-GB': 'Pay at counter',
    'da-DK': 'Betal ved kassen'
  },
  authorized: {
    'sv-SE': 'Auktoriserad',
    'en-GB': 'Authorized',
    'da-DK': 'Autoriseret'
  },
  confirmed: {
    'sv-SE': 'Genomförd',
    'en-GB': 'Confirmed',
    'da-DK': 'Udført'
  },
  initiated: {
    'sv-SE': 'Påbörjad',
    'en-GB': 'Initiated',
    'da-DK': 'Begyndt'
  },
  credited: {
    'sv-SE': 'Återbetald',
    'en-GB': 'Credited',
    'da-DK': 'Refunderet'
  },
  crediting: {
    'sv-SE': 'Återbetalas',
    'en-GB': 'Crediting',
    'da-DK': 'Refunderes'
  },
  failed: {
    'sv-SE': 'Misslyckad',
    'en-GB': 'Failed',
    'da-DK': 'Mislykket'
  },
  rejected: {
    'sv-SE': 'Beställning avvisad',
    'en-GB': 'Order rejected',
    'da-DK': 'Ordren afvist'
  },
  unknown: {
    'sv-SE': 'Okänd händelse',
    'en-GB': 'Unknown event',
    'da-DK': 'Ukendt begivenhed'
  },
  processingPayment: {
    'sv-SE': 'Behandlas',
    'en-GB': 'Processing',
    'da-DK': 'Behandles'
  },
  orders: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  show: {
    'sv-SE': 'Visa',
    'en-GB': 'Show',
    'da-DK': 'Vis'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  orderConfirmed: {
    'sv-SE': 'Markera som påbörjad',
    'en-GB': 'Confirm order',
    'da-DK': 'Markér som begyndt'
  },
  orderRejected: {
    'sv-SE': 'Markera som avvisad',
    'en-GB': 'Reject order',
    'da-DK': 'Markér som afvist'
  },
  ticketUsed: {
    'sv-SE': 'Markera som förbrukad',
    'en-GB': 'Mark as used',
    'da-DK': 'Markér som brugt'
  },
  orderReadyForPickUp: {
    'sv-SE': 'Markera som redo för upphämtning',
    'en-GB': 'Order ready for pick-up',
    'da-DK': 'Markér som klar til afhentning'
  },
  orderDelivered: {
    'sv-SE': 'Markera som utlämnad',
    'en-GB': 'Deliver order',
    'da-DK': 'Marker som leveret'
  },
  orderCredited: {
    'sv-SE': 'Återbetala beställning',
    'en-GB': 'Credit order',
    'da-DK': 'Tilbagebetal ordre'
  },
  sendReceipt: {
    'sv-SE': 'Skicka kvitto',
    'en-GB': 'Send receipt',
    'da-DK': 'Send kvittering'
  },
  events: {
    'sv-SE': 'Händelser',
    'en-GB': 'Events',
    'da-DK': 'Begivenheder'
  },
  event: {
    'sv-SE': 'Händelse',
    'en-GB': 'Event',
    'da-DK': 'Begivenhed'
  },
  time: {
    'sv-SE': 'Tidpunkt',
    'en-GB': 'Time of event',
    'da-DK': 'Tidspunkt'
  },
  email_updated: {
    'sv-SE': 'Email uppdaterad',
    'en-GB': 'Email updated',
    'da-DK': 'E-mail opdateret'
  },
  order_created: {
    'sv-SE': 'Beställning skapad',
    'en-GB': 'Order created',
    'da-DK': 'Ordre oprettet'
  },
  order_instant_captured: {
    'sv-SE': 'Beställning betalad',
    'en-GB': 'Order instant-captured',
    'da-DK': 'Ordre betalt'
  },
  order_closed: {
    'sv-SE': 'Beställning stängd',
    'en-GB': 'Order closed',
    'da-DK': 'Ordren lukket'
  },
  order_credited_push_notification_sent: {
    'sv-SE': 'Notis om återbetalning skickad',
    'en-GB': 'Notification regarding order credited sent',
    'da-DK': 'Besked om tilbagebetaling sendt'
  },
  order_inProgress_push_notification_sent: {
    'sv-SE': 'Notis om beställning påbörjad skickad',
    'en-GB': 'Notification regarding order in progress sent',
    'da-DK': 'Meddelelse om begyndt ordre er sendt'
  },
  order_kd_registered: {
    'sv-SE': 'Beställning registrerad i kontrollenhet',
    'en-GB': 'Order registered in controller unit',
    'da-DK': 'Ordre registreret i kontrolenhed'
  },
  order_ready_for_delivery_push_notification_sent: {
    'sv-SE': 'Notis om upphämtning skickad',
    'en-GB': 'Notification regarding pick-up sent',
    'da-DK': 'Meddelelse vedrørende afhentning sendt'
  },
  order_rejected_push_notification_sent: {
    'sv-SE': 'Notis om avvisad beställning skickad',
    'en-GB': 'Notification regarding rejected order sent',
    'da-DK': 'Meddelelse om afvist ordre sendt'
  },
  payment_authorized: {
    'sv-SE': 'Betalning auktoriserad',
    'en-GB': 'Payment authorized',
    'da-DK': 'Betaling godkendt'
  },
  payment_confirmed: {
    'sv-SE': 'Betalning bekräftad',
    'en-GB': 'Payment confirmed',
    'da-DK': 'Betaling bekræftet'
  },
  payment_credited: {
    'sv-SE': 'Betalning återbetald',
    'en-GB': 'Payment credited',
    'da-DK': 'Betaling refunderet'
  },
  payment_initiated: {
    'sv-SE': 'Betalning initierad',
    'en-GB': 'Payment initiated',
    'da-DK': 'Betaling påbegyndt'
  },
  payment_rejected_by_payment_provider: {
    'sv-SE': 'Betalning avvisad av betaltjänst',
    'en-GB': 'Payment rejected by payment provider',
    'da-DK': 'Betaling afvist af betalingsservice'
  },
  payment_rejected_by_weiq: {
    'sv-SE': 'Betalning avvisad',
    'en-GB': 'Payment rejected',
    'da-DK': 'Betaling afvist'
  },
  receipt_email_sent: {
    'sv-SE': 'Kvitto skickats till email',
    'en-GB': 'Receipt sent to email',
    'da-DK': 'Kvittering sendt til e-mail'
  },
  receipt_generated: {
    'sv-SE': 'Kvitto genererats',
    'en-GB': 'Receipt generated',
    'da-DK': 'Kvittering genereret'
  },
  refund_receipt_email_sent: {
    'sv-SE': 'Återbetalningskvitto skickats till email',
    'en-GB': 'Refund-receipt sent to email',
    'da-DK': 'Kvittering for tilbagebetaling sendt til e-mail'
  },
  refund_receipt_generated: {
    'sv-SE': 'Återbetalningskvitto genererats',
    'en-GB': 'Refund-receipt generated',
    'da-DK': 'Refusionskvittering genereret'
  },
  ticket_confirmed: {
    'sv-SE': 'Biljett bekräftad',
    'en-GB': 'Ticket confirmed',
    'da-DK': 'Billet bekræftet'
  },
  ticket_confirmed_push_notification_sent: {
    'sv-SE': 'Notis om bekräftad biljett skickad',
    'en-GB': 'Notification regarding ticket confirmed sent',
    'da-DK': 'Meddelelse om bekræftet billet sendt'
  },
  order_confirmed_push_notification_sent: {
    'sv-SE': 'Notis om bekräftad beställning skickad',
    'en-GB': 'Notification regarding order confirmed sent',
    'da-DK': 'Meddelelse om bekræftet ordre sendt'
  },
  ticket_created: {
    'sv-SE': 'Biljett skapad',
    'en-GB': 'Ticket created',
    'da-DK': 'Billet oprettet'
  },
  ticket_credited: {
    'sv-SE': 'Biljett återbetald',
    'en-GB': 'Ticket credited',
    'da-DK': 'Billet refunderet'
  },
  ticket_rejected_by_weiq: {
    'sv-SE': 'Biljett avvisad',
    'en-GB': 'Ticket rejected',
    'da-DK': 'Billet afvist'
  },
  // från core
  ticket_delivered: {
    'sv-SE': 'Biljett utlämnad',
    'en-GB': 'Ticket delivered',
    'da-DK': 'Billet levereret'
  },
  ticket_in_progress: {
    'sv-SE': 'Biljett påbörjad',
    'en-GB': 'Ticket in progress',
    'da-DK': 'Billet begyndt'
  },
  ticket_ready_for_pickup: {
    'sv-SE': 'Biljett redo för upphämtning',
    'en-GB': 'Ticket ready for pick-up',
    'da-DK': 'Billet klar til afhentning'
  },
  copyReference: {
    'sv-SE': 'Kopiera referens',
    'en-GB': 'Copy reference',
    'da-DK': 'Kopier reference'
  },
  showJSON: {
    'sv-SE': 'Visa JSON',
    'en-GB': 'Show JSON',
    'da-DK': 'Vis JSON'
  }
};

export const HELP_PAGE_STRINGS = {
  help: {
    'sv-SE': 'Hjälp',
    'en-GB': 'Help',
    'da-DK': 'Hjælp'
  },
  helpAndSupport: {
    'sv-SE': 'Hjälp och Support',
    'en-GB': 'Help and Support',
    'da-DK': 'Hjælp og support'
  },
  helpDescription: {
    'sv-SE':
      'Skulle du behöva hjälp om en viss funktion kan du besöka vårt hjälpcenter. Löser det sig inte är du alltid välkommen att kontakta oss, vi finns tillgängliga under kontorstid på vardagar i våra kanaler.',
    'en-GB':
      'If you need help you can often find the answer you are looking for in our help center. Otherwise you are free to contact us by email at any time, we are available during office hours on weekdays and will get back to you as soon as possible.',
    'da-DK':
      'Skulle du have brug for hjælp til en bestemt funktion, kan du besøge vores hjælpecenter. Hvis det ikke lykkes, er du altid velkommen til at kontakte os, vi står til rådighed i kontortiden på hverdage i vores kanaler.'
  },
  visitHelpCenter: {
    'sv-SE': 'Besök vårt hjälpcenter',
    'en-GB': 'Visit our help center',
    'da-DK': 'Besøg vores hjælpecenter'
  },
  contact: {
    'sv-SE': 'Kontakta oss',
    'en-GB': 'Contact us',
    'da-DK': 'Kontakt os'
  },
  faqContactTitle: {
    'sv-SE': 'Hur kontaktar jag er?',
    'en-GB': 'How do I get in contact?',
    'da-DK': 'Hvordan kontakter jeg jer?'
  },
  faqContactDescription: {
    'sv-SE':
      'Lättast är att du chattar med oss. Du ser ett chattfönster längst ner på denna sidan när chatten är öppen. Skicka annars ett mail till oss på ',
    'en-GB':
      'The easiest way is to chat directly with us. You should see a chat window at the bottom of this page when the chat is open. Otherwise send us an email at ',
    'da-DK':
      'Den nemmeste måde er at chatte med os. Du vil se et chatvindue nederst på denne side, når chatten er åben. Ellers send os en mail på '
  },
  faqSalesTitle: {
    'sv-SE': 'Hur ser jag hur mycket jag säljer i WEIQ?',
    'en-GB': 'How do I check how much I sell through WEIQ?',
    'da-DK': 'Hvordan kan jeg se, hvor meget jeg sælger i WEIQ?'
  },
  faqSalesDescription1: {
    'sv-SE':
      'Du hittar försäljningsstatistik från appen enklast under rubriken ',
    'en-GB': 'You find sales statistics through the app under the tab ',
    'da-DK': 'Du finder nemmest salgsstatistik fra appen under overskriften '
  },
  faqSalesDescription2: {
    'sv-SE':
      ' i menyn. Välj tidsintervall och se försäljningen sorterad på antingen transaktioner, momssats eller produkter.',
    'en-GB':
      ' in the menu. Pick a time interval and see sales sorted by either transactions, VAT or products.',
    'da-DK':
      'i menuen. Vælg tidsinterval og se salg sorteret efter enten transaktioner, momssats eller produkter.'
  },
  reports: {
    'sv-SE': 'Rapporter',
    'en-GB': 'Reports',
    'da-DK': 'Rapporter'
  },
  faqBookkeepingTitle: {
    'sv-SE': 'Hur fungerar ert bokföringssystem?',
    'en-GB': 'How does your bookkeeping system work?',
    'da-DK': 'Hvordan fungerer dit regnskabssystem?'
  },
  faqBookkeepingDescription1: {
    'sv-SE':
      'I WEIQ bokförs intäkterna automagiskt! När klockan slår 00:00 görs automatiskt ett dagsavslut (rekommenderad inställning) och en SIE-fil skickas till valfri mailadress som enkelt importeras i bokföringssystemet som används. Används Fortnox sker även importen av sig själv, smidigt va? Kontakta din kontaktperson på WEIQ eller hör av dig på ',
    'en-GB':
      'In WEIQ the income is entered automatically! When the clock strikes midnight a daily report is automatically generated (recommended setting) and a SIE-file is sent to as an email so you can easily import it into your preferred bookkeeping system. If Fortnox is used even the import is done by itself, simple huh? Contact you contact person or send us an email at ',
    'da-DK':
      'I WEIQ bogføres indtægten automatisk! Når klokken slår 00:00, lukkes en dag automatisk (anbefalet indstilling), og en SIE-fil sendes til enhver e-mailadresse, som nemt kan importeres til det anvendte regnskabssystem. Hvis Fortnox bruges, foregår importen også af sig selv, nemt ikke? Kontakt din kontaktperson hos WEIQ eller kontakt på '
  },
  faqBookkeepingDescription2: {
    'sv-SE': ' så hjälper vi dig att komma igång med bokföringen.',
    'en-GB': '. We gladly help you get started.',
    'da-DK': ' så hjælper vi dig godt i gang med bogføring.'
  },
  faqOpeningHoursTitle: {
    'sv-SE': 'Hur ändrar jag öppettiderna på min restaurang?',
    'en-GB': 'How do I change the opening hours for my restaurant?',
    'da-DK': 'Hvordan ændrer jeg åbningstiderne på min restaurant?'
  },
  faqOpeningHoursDescription1: {
    'sv-SE':
      'Öppettiderna i WEIQ styrs på varje enskild meny. Du gör det under ',
    'en-GB':
      'In WEIQ the opening hours are set for specific menus. You manage this from ',
    'da-DK':
      'Åbningstiderne i WEIQ styres på hver enkelt menu. Du gør det under '
  },
  faqOpeningHoursDescription2: {
    'sv-SE':
      ' och sedan ställer in öppettiderna. Detta möjliggör att en lunchmeny sömlöst kan övergå till eftermiddagsmeny, eller att kvällsmenyn byts ut till barmenyn när köket stänger.',
    'en-GB':
      ' and then pick opening hours. You can have a lunch menu that seamlessly transitions to dinner menu, or a dinner menu that switches to a bar menu when the kitchen closes.',
    'da-DK':
      'og derefter indstille åbningstiderne. Dette gør det muligt, at en frokostmenu problemfrit kan overgå til en eftermiddagsmenu, eller at aftenmenuen kan ændres til barmenuen, når køkkenet lukker.'
  },
  menus: {
    'sv-SE': 'Menyer',
    'en-GB': 'Menus',
    'da-DK': 'Menuer'
  },
  editCatalog: {
    'sv-SE': 'Redigera meny',
    'en-GB': 'Edit catalog',
    'da-DK': 'Rediger menu'
  }
};

export const MARKETING_PAGE_STRINGS = {
  marketing: {
    'sv-SE': 'Marknadsföring',
    'en-GB': 'Marketing',
    'da-DK': 'Markedsføring'
  },
  qrCode: {
    'sv-SE': 'QR-KOD',
    'en-GB': 'QR CODE',
    'da-DK': 'QR-KODE'
  },
  qrCodeDescription: {
    'sv-SE':
      'Denna unika QR-kod tar era gäster direkt in i WEIQ och till din meny. Ladda ner bilden och använd den som du vill.',
    'en-GB':
      'This unique QR code takes your guests directly to your menu in WEIQ. Download the image and use it however you like.',
    'da-DK':
      'Denne unikke QR-kode tager dine gæster direkte ind i WEIQ og til din menu. Download billedet og brug det som du ønsker.'
  },
  downloadPng: {
    'sv-SE': 'LADDA NER SOM PNG',
    'en-GB': 'DOWNLOAD AS PNG',
    'da-DK': 'DOWNLOAD SOM PNG'
  },
  downloadSvg: {
    'sv-SE': 'LADDA NER SOM SVG',
    'en-GB': 'DOWNLOAD AS SVG',
    'da-DK': 'DOWNLOAD SOM SVG'
  },
  linksToShare: {
    'sv-SE': 'LÄNKAR ATT DELA',
    'en-GB': 'LINKS TO SHARE',
    'da-DK': 'LINKS TIL AT DELE'
  },
  linksToShareDescription: {
    'sv-SE':
      'Dela gärna dessa länkar med dina gäster för att de enklare ska kunna ladda ner appen.',
    'en-GB':
      'Share these links with your guests to make it easier for them to download the app.',
    'da-DK':
      'Du er velkommen til at dele disse links med dine gæster for at gøre det nemmere for dem at downloade appen.'
  },
  menuLink: {
    'sv-SE': 'Direktlänk till din meny',
    'en-GB': 'Link to your menu',
    'da-DK': 'Direkte link til din menu'
  },
  downloadLink: {
    'sv-SE': 'Länk till sida för att ladda ner WEIQ',
    'en-GB': 'Link to page for downloading WEIQ',
    'da-DK': 'Link til side for at downloade WEIQ'
  },
  printingMaterial: {
    'sv-SE': 'TRYCKMATERIAL',
    'en-GB': 'PRINTING MATERIAL',
    'da-DK': 'TRYKMATERIALER'
  },
  printingMaterialDescription1: {
    'sv-SE':
      'Att informera om WEIQ är A&O när det gäller att öka sin försäljning. Vi hjälper er gärna med att ta fram material och diskutera idéer.',
    'en-GB':
      'Informing your guests about WEIQ is essential for increasing sales. We gladly help with producing printing material and discussing ideas.',
    'da-DK':
      'At informere om WEIQ er afgørende, når det kommer til at øge dit salg. Vi hjælper dig gerne med at udvikle materialer og diskutere ideer.'
  },
  printingMaterialDescription2: {
    'sv-SE':
      'Kolla gärna igenom våra guider nedan och lägg en beställning genom att kontakta oss på ',
    'en-GB':
      'Please check out our guides below and place an order by contacting us on ',
    'da-DK':
      'Tjek venligst vores guides nedenfor og afgiv en ordre ved at kontakte os på'
  },
  tableOrder: {
    'sv-SE': 'BORDSSERVERING',
    'en-GB': 'TABLE ORDER',
    'da-DK': 'BORDSERVERING'
  },
  tableOrderDescription: {
    'sv-SE': 'Tryckmaterial - WEIQ-brandat eller med egen design',
    'en-GB': 'Printing material - WEIQ-branded or your own design',
    'da-DK': 'Trykmateriale - WEIQ-brandet eller med eget design'
  },
  takeAway: {
    'sv-SE': 'TAKE-AWAY',
    'en-GB': 'TAKE AWAY',
    'da-DK': 'TAKE-AWAY'
  },
  takeAwayDescription: {
    'sv-SE': 'Så får du fler take-away beställningar',
    'en-GB': 'How to get more take away orders',
    'da-DK': 'Sådan får du flere take-away-bestillinger'
  }
};

export const SETTINGS_MENU_STRINGS = {
  settings: {
    'sv-SE': 'Inställningar',
    'en-GB': 'Settings',
    'da-DK': 'Indstillinger'
  },
  PROFILE: {
    'sv-SE': 'Profil',
    'en-GB': 'Profile',
    'da-DK': 'Profil'
  },
  SELLER_DETAILS: {
    'sv-SE': 'Dina uppgifter',
    'en-GB': 'Seller details',
    'da-DK': 'Din information'
  },
  TERMINALS: {
    'sv-SE': 'Terminaler',
    'en-GB': 'Terminals',
    'da-DK': 'Terminaler'
  },
  PAYMENT_METHODS: {
    'sv-SE': 'Betalmetoder',
    'en-GB': 'Payment methods',
    'da-DK': 'Betalingsmetoder'
  },
  USERS: {
    'sv-SE': 'Användare',
    'en-GB': 'Users',
    'da-DK': 'Bruger'
  },
  INTEGRATIONS: {
    'sv-SE': 'Integrationer',
    'en-GB': 'Integrations',
    'da-DK': 'Integrationer'
  },
  DELIVERY_LOCATIONS: {
    'sv-SE': 'Serveringssätt',
    'en-GB': 'Service setup',
    'da-DK': 'Serveringsmetode'
  },
  paymentMethods: {
    'sv-SE': 'Betalmetoder',
    'en-GB': 'Payment methods',
    'da-DK': 'Betalingsmetoder'
  },
  failedToFetchActivePaymentMethods: {
    'sv-SE': 'Hämtning av aktiva betalmetoder misslyckades',
    'en-GB': 'Failed to fetch active payment methods',
    'da-DK': 'Kunne ikke hente aktive betalingsmetoder'
  },
  failedToFetchActivePaymentMethodsDescription: {
    'sv-SE':
      'Hämtning av aktiva betalmetoder misslyckades. Var god försök igen.',
    'en-GB': 'Failed to fetch active payment methods. Please try again.',
    'da-DK': 'Kunne ikke hente aktive betalingsmetoder. Prøv igen.'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  createAccountAdyen: {
    'sv-SE': 'Skapa konto hos Adyen',
    'en-GB': 'Create account at Adyen',
    'da-DK': 'Opret en konto hos Adyen'
  },
  manageAdyenAccount: {
    'sv-SE': 'Hantera Adyen',
    'en-GB': 'Manage Adyen account',
    'da-DK': 'Håndtere Adyen'
  },
  createAccountSwish: {
    'sv-SE': 'Skapa konto hos Swish',
    'en-GB': 'Create account at Swish',
    'da-DK': 'Opret en konto hos Swish'
  },
  manageAccountSwish: {
    'sv-SE': 'Hantera Swish',
    'en-GB': 'Manage Swish account',
    'da-DK': 'Håndtere Swish'
  },
  manageWorldline: {
    'sv-SE': 'Hantera Worldline',
    'en-GB': 'Manage Worldline',
    'da-DK': 'Håndtere Worldline'
  },
  PAC: {
    'sv-SE': 'Pay at counter',
    'en-GB': 'Pay at counter',
    'da-DK': 'Pay at counter'
  },
  users: {
    'sv-SE': 'Användare',
    'en-GB': 'Users',
    'da-DK': 'Bruger'
  }
};

export const USER_STRINGS = {
  users: {
    'sv-SE': 'Användare',
    'en-GB': 'Users',
    'da-DK': 'Bruger'
  },
  addUser: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføj'
  },
  username: {
    'sv-SE': 'Användarnamn',
    'en-GB': 'Username',
    'da-DK': 'Brugernavn'
  },
  email: {
    'sv-SE': 'E-post',
    'en-GB': 'Email',
    'da-DK': 'E-mail'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  manageUser: {
    'sv-SE': 'Hantera användare',
    'en-GB': 'Manage user',
    'da-DK': 'Håndtere bruger'
  },
  openInKeycloak: {
    'sv-SE': 'Visa i Keycloak',
    'en-GB': 'Show in Keycloak',
    'da-DK': 'Se i Keycloak'
  },
  resetPassword: {
    'sv-SE': 'Återställ lösenord',
    'en-GB': 'Reset password',
    'da-DK': 'Nulstille adgangskoden'
  },
  resetPasswordAlertTitle: {
    'sv-SE': 'Återställ lösenord',
    'en-GB': 'Reset password',
    'da-DK': 'Nulstille adgangskoden'
  },
  resetPasswordAlertMessage: {
    'sv-SE':
      'Är du säker på att du vill återställa lösenordet för användaren $name$?',
    'en-GB':
      'Are you sure that you want to reset the password for the user $name$?',
    'da-DK':
      'Er du sikker på, at du vil nulstille adgangskoden for brugeren $name$?'
  },
  resetPasswordSuccessTitle: {
    'sv-SE': 'Återställningen lyckades',
    'en-GB': 'Reset succeeded',
    'da-DK': 'Nulstilling lykkedes'
  },
  resetPasswordSuccessMessage: {
    'sv-SE': 'Ett mail har skickats till användaren.',
    'en-GB': 'An email has been sent to the user.',
    'da-DK': 'Der er sendt en e-mail til brugeren.'
  },
  resetPasswordFailedTitle: {
    'sv-SE': 'Återställningen misslyckades',
    'en-GB': 'Reset failed',
    'da-DK': 'Nulstilling mislykkedes'
  },
  resetPasswordFailedMessage: {
    'sv-SE': 'Det gick inte att återställa lösenordet.',
    'en-GB': 'The password could not be reset.',
    'da-DK': 'Adgangskoden kunne ikke nulstilles.'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  createNew: {
    'sv-SE': 'Skapa ny',
    'en-GB': 'Create new',
    'da-DK': 'Opret ny'
  },
  addExisting: {
    'sv-SE': 'Lägg till befintlig',
    'en-GB': 'Add existing',
    'da-DK': 'Tilføj eksisterende'
  },
  password: {
    'sv-SE': 'Lösenord',
    'en-GB': 'Password',
    'da-DK': 'Adgangskode'
  },
  randomize: {
    'sv-SE': 'Slumpa',
    'en-GB': 'Randomize',
    'da-DK': 'Randomisere'
  },
  permissions: {
    'sv-SE': 'Rättigheter',
    'en-GB': 'Permissions',
    'da-DK': 'Rettigheder'
  },
  loginAdmin: {
    'sv-SE': 'WEIQ Admin - Standardrättigheter för restaurang',
    'en-GB': 'WEIQ Admin - Default permissions for restaurant',
    'da-DK': 'WEIQ Admin - Standardrettigheder for restaurant'
  },
  loginHub: {
    'sv-SE': 'WEIQ Hub',
    'en-GB': 'WEIQ Hub',
    'da-DK': 'WEIQ Hub'
  },
  refund: {
    'sv-SE': 'Göra återköp',
    'en-GB': 'Refund orders',
    'da-DK': 'Foretage genkøb'
  },
  reportViewer: {
    'sv-SE': 'WEIQ Admin - Enbart rapporter',
    'en-GB': 'WEIQ Admin - Only reports',
    'da-DK': 'WEIQ Admin - kun rapporter'
  },
  tappUser: {
    'sv-SE': 'WEIQ Terminal',
    'en-GB': 'WEIQ Terminal',
    'da-DK': 'WEIQ Terminal'
  },
  variablePrice: {
    'sv-SE': 'Beställa med fritt belopp',
    'en-GB': 'Order with free amount',
    'da-DK': 'Bestil med frit beløb'
  },
  failedToCreateNewUserMessage: {
    'sv-SE': 'Den nya användaren kunde inte skapas. Var god försök igen.',
    'en-GB': 'The new user could not be created. Please try again.',
    'da-DK': 'Den nye bruger kunne ikke oprettes. Prøv igen.'
  },
  updateUserFailedTitle: {
    'sv-SE': 'Uppdateringen misslyckades',
    'en-GB': 'Update failed',
    'da-DK': 'Opdateringen mislykkedes'
  },
  updateUserFailedMessage: {
    'sv-SE': 'Användaruppgifterna kunde inte uppdateras. Var god försök igen.',
    'en-GB': 'The user information was unable to update. Please try again.',
    'da-DK': 'Brugeroplysningerne kunne ikke opdateres. Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  searchUsername: {
    'sv-SE': 'Sök användarnamn',
    'en-GB': 'Search username',
    'da-DK': 'Søg brugernavn'
  },
  search: {
    'sv-SE': 'Sök',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  add: {
    'sv-SE': 'Lägg till',
    'en-GB': 'Add',
    'da-DK': 'Tilføj'
  },
  added: {
    'sv-SE': 'Tillagd',
    'en-GB': 'Added',
    'da-DK': 'Tilføjet'
  },
  noUserFound: {
    'sv-SE': 'Ingen användare hittades.',
    'en-GB': 'No user was found.',
    'da-DK': 'Ingen bruger fundet.'
  },
  failedToAddExistingUserTitle: {
    'sv-SE': 'Användaren kunde inte läggas till',
    'en-GB': 'The user could not be added',
    'da-DK': 'Brugeren kunne ikke tilføjes'
  },
  failedToAddExistingUserMessage: {
    'sv-SE': 'Användaren $name$ kunde inte läggas till. Var god försök igen.',
    'en-GB': 'The user $name$ could not be added. Please try again.',
    'da-DK': 'Brugeren $name$ kunne ikke tilføjes. Prøv igen.'
  },
  edit: {
    'sv-SE': 'Redigera',
    'en-GB': 'Edit',
    'da-DK': 'Rediger'
  },
  access: {
    'sv-SE': 'Access',
    'en-GB': 'Access',
    'da-DK': 'Access'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  noUsersFound: {
    'sv-SE': 'Inga användare hittades',
    'en-GB': 'No users were found',
    'da-DK': 'Ingen brugere fundet'
  }
};

export const PAC_STRINGS = {
  addPAC: {
    'sv-SE': 'Lägg till PAC',
    'en-GB': 'Add PAC',
    'da-DK': 'Tilføj PAC'
  },
  removePAC: {
    'sv-SE': 'Ta bort PAC',
    'en-GB': 'Remove PAC',
    'da-DK': 'Fjern PAC'
  },
  failedToAddPACTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToAddPACDescription: {
    'sv-SE': 'Det gick inte att lägga till PAC. Var god försök igen',
    'en-GB': 'PAC could not be added. Please try again',
    'da-DK': 'Kunne ikke tilføje PAC. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  }
};

export const SWISH_ACCOUNT_STRINGS = {
  swishNumber: {
    'sv-SE': 'Swishnummer',
    'en-GB': 'Swish number',
    'da-DK': 'Swishnummer'
  },
  failedToAddSwishAccountTitle: {
    'sv-SE': 'Det gick inte att lägga till Swish.',
    'en-GB': 'Swish could not be added.',
    'da-DK': 'Kunne ikke tilføje Swish.'
  },
  failedToAddSwishAccountDescription: {
    'sv-SE': 'Det gick inte att lägga till Swish-konto. Var god försök igen.',
    'en-GB': 'Swish account could not be added. Please try again.',
    'da-DK': 'Kunne ikke tilføje Swish-konto. Prøv igen.'
  },
  failedToUploadPEMTitle: {
    'sv-SE': 'Det gick inte att ladda upp PEM-certifikatet.',
    'en-GB': 'The PEM certificate failed to upload.',
    'da-DK': 'Kunne ikke uploade PEM-certifikatet.'
  },
  failedToUploadPEMDescription: {
    'sv-SE':
      'Det gick inte att ladda upp PEM-certifikatet. Var god försök igen.',
    'en-GB': 'The PEM certificate failed to upload. Please try again.',
    'da-DK': 'Kunne ikke uploade PEM-certifikatet. Prøv igen.'
  },
  updatePrefix: {
    'sv-SE': 'Uppdatera prefix',
    'en-GB': 'Update prefix',
    'da-DK': 'Opdater præfiks'
  },
  failedToUpdatePrefixTitle: {
    'sv-SE': 'Det gick inte att uppdatera Swishprefixet.',
    'en-GB': 'The Swish prefix could not be updated.',
    'da-DK': 'Swish-præfikset kunne ikke opdateres.'
  },
  failedToUpdatePrefixDescription: {
    'sv-SE': 'Det gick inte att uppdatera prefixet. Var god försök igen.',
    'en-GB': 'The prefix could not be updated. Please try again.',
    'da-DK': 'Præfikset kunne ikke opdateres. Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  inputSwishNumber: {
    'sv-SE':
      'Swishnummer (du måste ange ett nummer för Swish Handel. t.ex 123071234. Har du ett nummer för Swish Företag kommer det inte fungera):',
    'en-GB':
      'Swish number (you have to enter a number for Swish Commerce. for example 123071234. A Swish business number will not work):',
    'da-DK':
      'Swish-nummer (du skal indtaste et nummer for Swish Handel. f.eks. 123071234. Hvis du har et nummer til Swish Företag, fungerer det ikke):'
  },
  inputSwishPrefix: {
    'sv-SE':
      'Prefix (frivilligt. Fyll i om du vill att alla betalreferenser i din bank ska börja med dessa tecken. Max 4 tecken):',
    'en-GB':
      'Prefix (optional. Enter if you want every payment reference in your bank to start with these characters. Maximum of 4 characters):',
    'da-DK':
      'Præfiks (valgfrit. Udfyld hvis du ønsker at alle betalingsreferencer i din bank skal starte med disse tegn. Maks. 4 tegn):'
  },
  addAccount: {
    'sv-SE': 'Lägg till konto',
    'en-GB': 'Add account',
    'da-DK': 'Tilføj konto'
  },
  getCSR: {
    'sv-SE': 'Hämta CSR',
    'en-GB': 'Get CSR',
    'da-DK': 'Få CSR'
  },
  copyCSR: {
    'sv-SE': 'Kopiera CSR',
    'en-GB': 'Copy CSR',
    'da-DK': 'Kopier CSR'
  },
  downloadCSR: {
    'sv-SE': 'Ladda ner CSR',
    'en-GB': 'Download CSR',
    'da-DK': 'Download CSR'
  },
  PEMUploaded: {
    'sv-SE': 'Certifikat uppladdat',
    'en-GB': 'Certificate upploaded',
    'da-DK': 'Certifikatet er uploadet'
  },
  uploadPEM: {
    'sv-SE':
      'Ladda upp PEM (om du redan har laddat upp ett certifikat kommer detta skrivas över)',
    'en-GB':
      'Upload PEM (if you have already uploaded a certificate it will be overwritten)',
    'da-DK':
      'Upload PEM (hvis du allerede har uploadet et certifikat vil dette blive overskrevet)'
  },
  upload: {
    'sv-SE': 'Ladda upp',
    'en-GB': 'Upload',
    'da-DK': 'Upload'
  }
};

export const MANAGE_ADYEN_ACCOUNT_STRINGS = {
  incomplete: {
    'sv-SE': 'Ofullständig information - ',
    'en-GB': 'Incomplete information - ',
    'da-DK': 'Ufuldstændig information - '
  },
  incompleteDescription: {
    'sv-SE':
      'Det krävs mer information om din verksamhet innan du kan börja processera betalningar. Vänligen fortsätt onboardingen nedan.',
    'en-GB':
      'More information about your business is required before you can begin processing payments. Please continue the onboarding process below.',
    'da-DK':
      'Der kræves mere information om din virksomhed, før du kan begynde at behandle betalinger. Fortsæt venligst ombordstigningsprocessen nedenfor'
  },
  paymentMethods: {
    'sv-SE': 'Betalmetoder',
    'en-GB': 'Payment methods',
    'da-DK': 'Betalingsmetoder'
  },
  activatedPaymentMethodTypes: {
    'sv-SE': 'App',
    'en-GB': 'App',
    'da-DK': 'App'
  },
  ippPaymentMethods: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  payoutTitle: {
    'sv-SE': 'Utbetalningar',
    'en-GB': 'Payouts',
    'da-DK': 'Udbetalinger'
  },
  payoutDescription: {
    'sv-SE':
      'Utbetalningar sker till följande bankkonton enligt valt schema. Notera att det kan ta upp till en minut för ditt schema att uppdateras efter att du sparat.',
    'en-GB':
      'Payouts are made to the following bank accounts according to their chosen schedule. Note that updating the payout schedule may take up to one minute after saving.',
    'da-DK':
      'Udbetalinger foretages til følgende bankkonti i henhold til deres valde skemaer. Bemærk ppdatering af udbetalingsplanen kan tage op til et minut efter lagring.'
  },
  payoutBankAccount: {
    'sv-SE': 'Bankkonto: ',
    'en-GB': 'Bank account: ',
    'da-DK': 'Bankkonto: '
  },
  payoutTrigger: {
    'sv-SE': 'Betala ut direkt',
    'en-GB': 'Pay out now',
    'da-DK': 'Udbetale direkte'
  },
  payoutNoBankAccount: {
    'sv-SE': 'Ej kopplat',
    'en-GB': 'Not connected',
    'da-DK': 'Ikke tilsluttet'
  },
  payoutScheduleChoose: {
    'sv-SE': 'Välj utbetalningsschema',
    'en-GB': 'Choose payout schedule',
    'da-DK': 'Vælg udbetalingsskema'
  },
  payoutConnectBank: {
    'sv-SE': 'Koppla bankkonto',
    'en-GB': 'Connect bank account',
    'da-DK': 'Tilslut bankkonto'
  },
  DAILY: {
    'sv-SE': 'Dagligen 01:00 CET (105 SEK / månad)',
    'en-GB': 'Daily 01:00 CET (105 SEK / month)',
    'da-DK': 'Dagligt 01:00 CET (105 SEK / måned)'
  },
  DAILY_EU: {
    'sv-SE': 'Dagligen 05:50 CET (optimerat för EU-länder, 105 SEK / månad)',
    'en-GB': 'Daily 05:50 CET (optimized for EU countries, 105 SEK / month)',
    'da-DK': 'Dagligt 05:50 CET (optimeret til EU lande, 105 SEK / måned)'
  },
  WEEKLY: {
    'sv-SE': 'Veckovis (fredagar 01:00 CET, 20 SEK / månad)',
    'en-GB': 'Weekly (Fridays 01:00 CET, 20 SEK / month)',
    'da-DK': 'Ugentligt (fredage 01:00 CET, 20 SEK / måned)'
  },
  WEEKLY_EU: {
    'sv-SE': 'Veckovis (EU) (fredagar 05:50 CET, 20 SEK / månad)',
    'en-GB': 'Weekly (EU) (Fridays 05:50 CET, 20 SEK / month)',
    'da-DK': 'Ugentligt (EU) (fredage 05:50 CET, 20 SEK / måned)'
  },
  BIWEEKLY: {
    'sv-SE':
      '2 gånger per vecka (Tisdagar och fredagar 01:00 CET, 40 SEK / månad)',
    'en-GB': 'Twice a week (Tuesdays and Fridays 01:00 CET, 40 SEK / month)',
    'da-DK': 'To gange om ugen (tirsdage og fredage 01:00 CET, 40 SEK / måned)'
  },
  BIWEEKLY_EU: {
    'sv-SE':
      '2 gånger per vecka (EU) (Tisdagar och fredagar 05:50 CET, 40 SEK / månad)',
    'en-GB':
      'Twice a week (EU) (Tuesdays and Fridays 05:50 CET, 40 SEK / month)',
    'da-DK':
      'To gange om ugen (EU) (tirsdage og fredage 05:50 CET, 40 SEK / måned)'
  },
  FORTNIGHTLY: {
    'sv-SE': 'Varannan vecka (1a & 15e klockan 01:00 CET, 10 SEK / månad)',
    'en-GB': 'Biweekly (1st and 15th at 01:00 CET, , 10 SEK / month)',
    'da-DK': 'Hver anden uge (1. og 15. kl. 01:00 CET, , 10 SEK / måned)'
  },
  FORTNIGHTLY_EU: {
    'sv-SE': 'Varannan vecka (EU) (1a & 15e klockan 05:50 CET, 10 SEK / månad)',
    'en-GB': 'Biweekly (EU) (1st and 15th at 05:50 CET, , 10 SEK / month)',
    'da-DK': 'Hver anden uge (EU) (1. og 15. kl. 05:50 CET, , 10 SEK / måned)'
  },
  MONTHLY: {
    'sv-SE': 'Månatligen (1a klockan 07:00 CET, 5 SEK / månad)',
    'en-GB': 'Monthly (1st at 07:00 CET, 5 SEK / month)',
    'da-DK': 'Månedligt (1. kl. 07:00 CET, 5 SEK / måned)'
  },
  MONTHLY_EU: {
    'sv-SE': 'Månatligen (EU) (1a klockan 05:50 CET, 5 SEK / månad)',
    'en-GB': 'Monthly (EU) (1st at 05:50 CET, 5 SEK / month)',
    'da-DK': 'Månedligt (EU) (1. kl. 05:50 CET, 5 SEK / måned)'
  },
  HOLD: {
    'sv-SE': 'Paus (inga schemalagda utbetalningar)',
    'en-GB': 'Hold (no scheduled payouts)',
    'da-DK': 'Hold (ingen planlagte udbetalinger)'
  },
  AMEX: {
    'sv-SE': 'American express',
    'en-GB': 'American express',
    'da-DK': 'American express'
  },
  VISA: {
    'sv-SE': 'Visa',
    'en-GB': 'Visa',
    'da-DK': 'Visa'
  },
  MASTERCARD: {
    'sv-SE': 'Mastercard',
    'en-GB': 'Mastercard',
    'da-DK': 'Mastercard'
  },
  VISADANKORT: {
    'sv-SE': 'Visa Dankort',
    'en-GB': 'Visa Dankort',
    'da-DK': 'Visa Dankort'
  },
  MAESTRO: {
    'sv-SE': 'Maestro',
    'en-GB': 'Maestro',
    'da-DK': 'Maestro'
  },
  MAESTRO_UK: {
    'sv-SE': 'Maestro UK',
    'en-GB': 'Maestro UK',
    'da-DK': 'Maestro UK'
  },
  DANKORT: {
    'sv-SE': 'Dankort',
    'en-GB': 'Dankort',
    'da-DK': 'Dankort'
  },
  APPLE_PAY: {
    'sv-SE': 'Apple Pay',
    'en-GB': 'Apple Pay',
    'da-DK': 'Apple Pay'
  },
  GOOGLE_PAY: {
    'sv-SE': 'Google Pay',
    'en-GB': 'Google Pay',
    'da-DK': 'Google Pay'
  },
  MOBILE_PAY: {
    'sv-SE': 'Mobile Pay',
    'en-GB': 'Mobile Pay',
    'da-DK': 'Mobile Pay'
  },
  SWISH: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  get visa() {
    return this.VISA;
  },
  get mc() {
    return this.MASTERCARD;
  },
  get amex() {
    return this.AMEX;
  },
  get maestro() {
    return this.MAESTRO;
  },
  get swish() {
    return this.SWISH;
  },
  updatePaymentMethodTypes: {
    'sv-SE': 'Uppdatera',
    'en-GB': 'Update',
    'da-DK': 'Opdatere'
  },
  account: {
    'sv-SE': 'Konto',
    'en-GB': 'Account',
    'da-DK': 'Konto'
  },
  accountHolder: {
    'sv-SE': 'Kontoinnehavare: ',
    'en-GB': 'Account holder: ',
    'da-DK': 'Kontohaver'
  },
  accountCode: {
    'sv-SE': 'Kontonummer: ',
    'en-GB': 'Account code: ',
    'da-DK': 'Kontonummer'
  },
  balance: {
    'sv-SE': 'Saldo',
    'en-GB': 'Balance',
    'da-DK': 'Saldo'
  },
  available: {
    'sv-SE': 'Tillgängligt',
    'en-GB': 'Available',
    'da-DK': 'Tilgængelig'
  },
  incoming: {
    'sv-SE': 'Inkommande',
    'en-GB': 'Incoming',
    'da-DK': 'Indkommende'
  },
  salesDayClosingTimes: {
    'sv-SE': 'Försäljningsdagens slut',
    'en-GB': 'Sales day closing time',
    'da-DK': 'Salgsdagens afslutning'
  },
  salesDayClosingTimesTooltip: {
    'sv-SE':
      'En försäljningsdag är den 24-timmarsperiod där Adyen bearbetar dina betalningar. Matcha försäljningsdagens slut med dina öppettider för korrekta utbetalningar',
    'en-GB':
      'A sales day is the 24-hour period in which Adyen processes your payments. Match your opening hours to the end time for accurate payouts',
    'da-DK':
      'En salgsdag er den 24-timers periode, hvor Adyen behandler dine betalinger. Tilpas afslutningen på salgsdagen med dine åbningstider for korrekte udbetalinger'
  },
  settlementDelayDays: {
    'sv-SE': 'Behandlingstid för betalningar',
    'en-GB': 'Processing time for payments',
    'da-DK': 'Behandlingstid for betalinger'
  },
  settlementDelayDaysTooltip: {
    'sv-SE':
      'Hur snabbt som pengar, räknat i antal dagar från försäljningsdagen, tidigast kan betalas ut. En transaktionsavgift tillkommer med kortare utbetalningstider.',
    'en-GB':
      'How quickly funds, counted in number of days from the day of sale, can be paid out at the earliest. A transaction fee applies for shorter payout times.',
    'da-DK':
      'Hvor hurtigt penge, talt i antal dage fra salgsdagen, kan udbetales tidligst. Der pålægges en transaktionsafgift for kortere udbetalingstider.'
  },
  T_P0: {
    'sv-SE': 'T+0 (+0.04%)',
    'en-GB': 'T+0 (+0.04%)',
    'da-DK': 'T+0 (+0.04%)'
  },
  T_P1: {
    'sv-SE': 'T+1 (+0.02%)',
    'en-GB': 'T+1 (+0.02%)',
    'da-DK': 'T+1 (+0.02%)'
  },
  T_P2: {
    'sv-SE': 'T+2 (ingen kostnad)',
    'en-GB': 'T+2 (free of charge)',
    'da-DK': 'T+2 (ingen omkostning)'
  },
  accountCreated: {
    'sv-SE': 'Kontot skapades:',
    'en-GB': 'Account was created:',
    'da-DK': 'Kontot blev oprettet:'
  },
  priceLists: {
    'sv-SE': 'Prislistor',
    'en-GB': 'Price lists',
    'da-DK': 'Prislister'
  },
  created: {
    'sv-SE': 'Klart',
    'en-GB': 'Done',
    'da-DK': 'Færdigt'
  },
  onboardingKYC: {
    'sv-SE': 'Onboarding / KYC: ',
    'en-GB': 'Onboarding / KYC: ',
    'da-DK': 'Onboarding / KYC: '
  },
  alertTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'noget gik galt'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  tierTitle: {
    'sv-SE': 'Prislista för app',
    'en-GB': 'Pricelist for app',
    'da-DK': 'Prisliste om app'
  },
  ippTierTitle: {
    'sv-SE': 'Prislista för terminaler',
    'en-GB': 'Pricelist for terminals',
    'da-DK': 'Prisliste om terminaler'
  },
  saveChanges: {
    'sv-SE': 'Spara ändringar',
    'en-GB': 'Save changes',
    'da-DK': 'Gem ændringer'
  },
  unableToSaveChangesDescription: {
    'sv-SE': 'Ändringarna kunde inte sparas. Var god försök igen.',
    'en-GB': 'The changes could not be saved. Please try again.',
    'da-DK': 'Ændringerne kunne ikke gemmes. Prøv igen.'
  },
  confirmPayout: {
    'sv-SE': 'Bekräfta utbetalning',
    'en-GB': 'Confirm payout',
    'da-DK': 'Bekræft udbetaling'
  },
  confimPayoutModalAccount: {
    'sv-SE':
      'Vi kommer betala ut hela ditt tillgängliga saldo till följande bankkonto:',
    'en-GB':
      'We will pay out your entire available balance to the following bank account:',
    'da-DK':
      'Vi vil udbetale hele din tilgængelige saldo til følgende bankkonto:'
  },
  confirmPayoutModalCost: {
    'sv-SE':
      'Var god bekräfta att kontot är korrekt innan du påbörjar utbetalningen. Notera att vi kommer debitera 10 SEK från ditt konto för denna utbetalning.',
    'en-GB':
      'Please confirm that the account is correct before initiating the payout. Note that we will charge 10 SEK from your account for this payout.',
    'da-DK':
      'Venligst bekræft, at kontoen er korrekt, før du iværksætter udbetalingen. Bemærk, at vi vil opkræve 10 SEK fra din konto for denne udbetaling.'
  },
  payoutFailed: {
    'sv-SE': 'Utbetalningen misslyckades - ',
    'en-GB': 'Payout failed - ',
    'da-DK': 'Udbetalingen mislykkedes - '
  },
  confirmPayoutModalAlert: {
    'sv-SE':
      'Det gick inte att betala ut till ditt konto. Vänligen kontrollera att du har ett positivt saldo och försök igen.',
    'en-GB':
      'It was not possible to pay out to your account. Please check that you have a positive balance and try again.',
    'da-DK':
      'Det var ikke muligt at overføre til din konto. Venligst kontroller at du har en positiv saldo og prøv igen.'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  continueOnboarding: {
    'sv-SE': 'Fortsätt onboarding',
    'en-GB': 'Continue onboarding',
    'da-DK': 'Fortsæt onboarding'
  },
  updateInformation: {
    'sv-SE': 'Uppdatera kontouppgifter',
    'en-GB': 'Continue onboarding',
    'da-DK': 'Fortsæt onboarding'
  }
};

export const PAYMENT_METHODS_STRINGS = {
  swish: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  swishDescription: {
    'sv-SE':
      'Ta betalt med Swish genom att ansluta WEIQ till ditt konto för swish-handel.',
    'en-GB': 'Pay with Swish by connecting WEIQ to your swish-account.',
    'da-DK':
      'Bliv betalt med Swish ved at forbinde WEIQ til din swish-handelskonto.'
  },
  card: {
    'sv-SE': 'Kortbetalning',
    'en-GB': 'Pay by card',
    'da-DK': 'Kortbetaling'
  },
  adyen: {
    'sv-SE': 'Adyen',
    'en-GB': 'Adyen',
    'da-DK': 'Adyen'
  },
  adyenDescription: {
    'sv-SE':
      'Ta betalt med kort, Apple Pay, Google Pay genom att ansluta till Adyen.',
    'en-GB': 'Pay with card, Apple Pay, Google Pay by connecting to Adyen.',
    'da-DK':
      'Modtag betaling med kort, Apple Pay, Google Pay ved at oprette forbindelse til Adyen.'
  },
  pac: {
    'sv-SE': 'Pay at counter',
    'en-GB': 'Pay at counter',
    'da-DK': 'Pay at counter'
  },
  pacDescription: {
    'sv-SE': 'Sköt betalningen själv i existerande kassa.',
    'en-GB': 'Manage your payments yourself over the counter.',
    'da-DK': 'Håndter selv betalingen i et eksisterende kasseapparat.'
  },
  active: {
    'sv-SE': 'Anslutning aktiv',
    'en-GB': 'Connected',
    'da-DK': 'Forbindelse aktiv'
  },
  incomplete: {
    'sv-SE': 'Ofullständig information',
    'en-GB': 'Incomplete information',
    'da-DK': 'Ufuldstændig information'
  },
  missingBankAccount: {
    'sv-SE': 'Utbetalningskonto ej kopplat',
    'en-GB': 'Payout account not connected',
    'da-DK': 'Udbetalingskonto ikke tilsluttet'
  },
  waitingForPEMCertificate: {
    'sv-SE': 'Väntar på PEM',
    'en-GB': 'Waiting for PEM',
    'da-DK': 'Venter på PEM'
  },
  anotherAccount: {
    'sv-SE': 'Kopplad till existerande konto',
    'en-GB': 'Connected to existing account',
    'da-DK': 'Linket til eksisterende konto'
  }
};

export const DELETE_PAYMENT_METHOD_STRINGS = {
  dangerZone: {
    'sv-SE': 'FAROZONEN',
    'en-GB': 'DANGER ZONE',
    'da-DK': 'FAREZONEN'
  },
  warning: {
    'sv-SE':
      'VARNING - Om du tar bort betalmetoden kommer inte besökare kunna betala med $type$.',
    'en-GB':
      'WARNING - If you delete the payment method the customer will be unable to pay with $type$.',
    'da-DK':
      'ADVARSEL - Hvis du fjerner betalingsmetoden, vil besøgende ikke kunne betale med $type$.'
  },
  deletePaymentMethod: {
    'sv-SE': 'Ta bort betalmetod',
    'en-GB': 'Delete payment method',
    'da-DK': 'Fjern betalingsmetode'
  },
  modalTitle: {
    'sv-SE': 'Är du säker på att du vill ta bort $type$?',
    'en-GB': 'Are you sure you want to delete $type$?',
    'da-DK': 'Er du sikker på, at du vil fjerne $type$?'
  },
  modalDescription: {
    'sv-SE':
      'Är du helt säker på att du vill ta bort $type$? Besökare kommer inte längre kunna använda betalmetoden.',
    'en-GB':
      'Are you entirely sure you want to delete $type$? Customers will not be able to use the payment method.',
    'da-DK':
      'Er du helt sikker på, at du vil fjerne $type$? Besøgende vil ikke længere kunne bruge betalingsmetoden.'
  },
  PAY_AT_COUNTER: {
    'sv-SE': 'Pay at counter',
    'en-GB': 'Pay at counter',
    'da-DK': 'Pay at counter'
  },
  SWISH: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  ADYEN: {
    'sv-SE': 'Adyen',
    'en-GB': 'Adyen',
    'da-DK': 'Adyen'
  },
  delete: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Delete',
    'da-DK': 'Fjern'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  }
};

export const KYC_STRINGS = {
  title: {
    'sv-SE': 'Information för betalningar',
    'en-GB': 'Information for payments',
    'da-DK': 'Information for betalinger'
  },
  information: {
    'sv-SE':
      'För att aktivera betalningar i WEIQ behöver vår betalleverantör Adyen information från er. Klicka på knappen nedan för att påbörja processen. Ni kan återvända hit flera gånger för att fylla i mer information.',
    'en-GB':
      'To activate payments in WEIQ, our payment provider Adyen requires information from you. Click the button below to start the process. You can return here multiple times to fill in more information.',
    'da-DK':
      'For at aktivere betalinger i WEIQ har vores betalingsudbyder Adyen brug for oplysninger fra jer. Klik på knappen nedenfor for at starte processen. I kan vende tilbage hertil flere gange for at udfylde mere information.'
  },
  continue: {
    'sv-SE': 'Gå vidare',
    'en-GB': 'Continue',
    'da-DK': 'Fortsæt'
  },
  adyenAccountNotCreated: {
    'sv-SE':
      'Adyen-kontot har inte skapats ännu. Var god försök igen om en liten stund.',
    'en-GB':
      'The Adyen account has not been created yet. Please try again in a moment.',
    'da-DK': 'Adyen-kontoen er ikke oprettet endnu. Prøv venligst igen om lidt.'
  },
  sellerNotFoundTitle: {
    'sv-SE': 'Säljaren hittades inte',
    'en-GB': 'Seller not found',
    'da-DK': 'Sælgeren blev ikke fundet'
  },
  sellerNotFoundMessage: {
    'sv-SE':
      'Den begärda säljaren kunde inte hittas. Vänligen kontrollera att du är inloggad på rätt konto och har åtkomst till säljaren.',
    'en-GB':
      'The requested seller could not be found. Please verify that you are signed in to the correct account and have access to the seller.',
    'da-DK':
      'Den anmodede sælger kunne ikke findes. Tjek venligst, at du er logget ind på den rigtige konto og har adgang til sælgeren.'
  },
  failedToGetKycUrlTitle: {
    'sv-SE': 'Kunde inte ansluta till Adyen',
    'en-GB': 'Failed to connect to Adyen',
    'da-DK': 'Kunne ikke oprette forbindelse til Adyen'
  },
  failedToGetKycUrlMessage: {
    'sv-SE': 'Vänligen kontakta WEIQs support',
    'en-GB': 'Please contact the WEIQ Support',
    'da-DK': "Kontakt venligst WEIQ's support"
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  }
};

export const CREATE_ACCOUNT_ADYEN_STRINGS = {
  newOnboarding: {
    'sv-SE': 'Ny onboarding',
    'en-GB': 'New onboarding',
    'da-DK': 'Ny onboarding'
  },
  connectExistingAccount: {
    'sv-SE': 'Koppla existerande konto',
    'en-GB': 'Connect existing account',
    'da-DK': 'Link eksisterende konto'
  }
};

export const ADYEN_PRICE_LIST_STRINGS = {
  tier1: {
    'sv-SE': 'Tier 1',
    'en-GB': 'Tier 1',
    'da-DK': 'Tier 1'
  },
  tier2: {
    'sv-SE': 'Tier 2',
    'en-GB': 'Tier 2',
    'da-DK': 'Tier 2'
  },
  tier3: {
    'sv-SE': 'Tier 3',
    'en-GB': 'Tier 3',
    'da-DK': 'Tier 3'
  },
  showPriceList: {
    'sv-SE': 'Visa fullständiga prislistor',
    'en-GB': 'Show full price lists',
    'da-DK': 'Se komplette prislister'
  },
  hidePriceList: {
    'sv-SE': 'Dölj fullständiga prislistor',
    'en-GB': 'Hide full price lists',
    'da-DK': 'Skjul komplette prislister'
  },
  noPriceList: {
    'sv-SE': 'Ingen prislista att visa',
    'en-GB': 'No price list to show',
    'da-DK': 'Ingen prisliste at vise'
  },
  scheme: {
    'sv-SE': 'Visa & Mastercard',
    'en-GB': 'Visa & Mastercard',
    'da-DK': 'Visa & Mastercard'
  },
  card: {
    'sv-SE': 'Visa & Mastercard',
    'en-GB': 'Visa & Mastercard',
    'da-DK': 'Visa & Mastercard'
  },
  amex: {
    'sv-SE': 'Amex',
    'en-GB': 'Amex',
    'da-DK': 'Amex'
  },
  mobilepay: {
    'sv-SE': 'MobilePay',
    'en-GB': 'MobilePay',
    'da-DK': 'MobilePay'
  },
  swish: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  domesticCredit: {
    'sv-SE': 'Inhemska kreditkort',
    'en-GB': 'Domestic credit',
    'da-DK': 'Indenlandske kreditkort'
  },

  domesticDebit: {
    'sv-SE': 'Inhemska debitkort',
    'en-GB': 'Domestic debit',
    'da-DK': 'Indenlandske betalingskort'
  },

  internationalCredit: {
    'sv-SE': 'Internationella kreditkort',
    'en-GB': 'International credit',
    'da-DK': 'Internationale kreditkort'
  },

  internationalDebit: {
    'sv-SE': 'Internationella debitkort',
    'en-GB': 'International debit',
    'da-DK': 'Internationale betalingskort'
  },

  intraEEACredit: {
    'sv-SE': 'Kreditkort inom EEA ',
    'en-GB': 'Intra EEA credit',
    'da-DK': 'Kreditkort inden for EEA'
  },

  intraEEADebit: {
    'sv-SE': 'Debitkort inom EEA',
    'en-GB': 'Intra EEA debit',
    'da-DK': 'Debetkort inden for EEA'
  },
  failedLoadingPriceLists: {
    'sv-SE': 'Prislistorna kunde inte hämtas. Var god försök igen.',
    'en-GB': 'Could not load price lists. Please try again.',
    'da-DK': 'Prislisterne kunne ikke hentes. Prøv igen.'
  },
  minimum: {
    'sv-SE': 'Minst: $fee$',
    'en-GB': 'Minimum: $fee$',
    'da-DK': 'Minimum: $fee$'
  },
  consumer: {
    'sv-SE': 'Konsument',
    'en-GB': 'Consumer',
    'da-DK': 'Forbruger'
  },
  commercial: {
    'sv-SE': 'Kommersiell',
    'en-GB': 'Commercial',
    'da-DK': 'Kommerciel'
  }
};

export const INITIATE_ONBOARDING_STRINGS = {
  PRIVATE_COMPANY: {
    'sv-SE': 'Privatägt företag',
    'en-GB': 'Private company',
    'da-DK': 'Privat virksomhed'
  },
  SOLE_PROPRIETORSHIP: {
    'sv-SE': 'Enskild firma',
    'en-GB': 'Sole proprietorship',
    'da-DK': 'Enkeltmandsvirksomhed'
  },
  LISTED_PUBLIC_COMPANY: {
    'sv-SE': 'Publikt företag',
    'en-GB': 'Public company',
    'da-DK': 'Offentligt virksomhed'
  },
  NON_PROFIT: {
    'sv-SE': 'Förening',
    'en-GB': 'Public company',
    'da-DK': 'Offentligt virksomhed'
  },
  GOVERNMENTAL_ORGANIZATION: {
    'sv-SE': 'Statligt företag',
    'en-GB': 'Public company',
    'da-DK': 'Offentligt virksomhed'
  },
  chooseCategory: {
    'sv-SE': 'Välj kategori',
    'en-GB': 'Choose category',
    'da-DK': 'Vælg kategori'
  },
  category: {
    'sv-SE': 'Kategori*',
    'en-GB': 'Category*',
    'da-DK': 'Kategori*'
  },
  '7139A': {
    'sv-SE': '7139A - Offentliga golfbanor',
    'en-GB': '7139A - Public golf courses',
    'da-DK': '7139A - Offentlige golfbaner'
  },
  '7139B': {
    'sv-SE': '7139B - Privata golfbanor och countryklubbar',
    'en-GB': '7139B - Private golf courses and country clubs',
    'da-DK': '7139B - Private golfbaner og countryklubber'
  },
  '71392': {
    'sv-SE': '71392 - Skidanläggningar',
    'en-GB': '71392 - Skiing facilities',
    'da-DK': '71392 - Skianlæg'
  },
  '71395': {
    'sv-SE': '71395 - Bowlinghallar',
    'en-GB': '71395 - Bowling centers',
    'da-DK': '71395 - Bowlingbaner'
  },
  '7139C': {
    'sv-SE': '7139C - Alla andra nöjesanläggningar',
    'en-GB': '7139C - All other amusement and recreation industries',
    'da-DK': '7139C - Alle andre underholdningsfaciliteter'
  },
  '7139D': {
    'sv-SE': '7139D - Biljardhallar',
    'en-GB': '7139D - Pool and billiard establishments',
    'da-DK': '7139D - Billardsale'
  },
  '7139E': {
    'sv-SE': '7139E - Akvarium och zoon',
    'en-GB': '7139E - Aquariums and zoos',
    'da-DK': '7139E - Akvarium og zoor'
  },
  '72111': {
    'sv-SE': '72111 - Hotell och motell',
    'en-GB': '72111 - Hotels and motels',
    'da-DK': '72111 - Hoteller og moteller'
  },
  '722511': {
    'sv-SE': '722511 - Restauranger',
    'en-GB': '722511 - Restaurants',
    'da-DK': '722511 - Restauranter'
  },
  '722513': {
    'sv-SE': '722513 - Snabbmatsrestauranger',
    'en-GB': '722513 - Fast food restaurants',
    'da-DK': '722513 - Hurtigmatrestaurant'
  },
  '722514': {
    'sv-SE': '722514 - Kafeterior, grillbufféer och bufféer',
    'en-GB': '722514 - Cafeterias, grill buffets, and buffets',
    'da-DK': '722514 - Cafeterier, grillbuffeter og buffeter'
  },
  '722515': {
    'sv-SE': '722515 - Snacks- och alkoholfria dryckesbarer',
    'en-GB': '722515 - Snacks and nonalcoholic beverage bars',
    'da-DK': '722515 - Snack- og alkoholfrie drikkevarebarer'
  },
  '8121B': {
    'sv-SE': '8121B - Hälso- och skönhetssalonger',
    'en-GB': '8121B - Health and beauty spas',
    'da-DK': '8121B - Sundheds- og skønhedsspaer'
  },
  '71311': {
    'sv-SE': '71311 - Nöjes- och temaparker',
    'en-GB': '71311 - Amusement and theme parks',
    'da-DK': '71311 - Forlystelses- og temaparker'
  },
  '71312': {
    'sv-SE': '71312 - Spelhallar (arkad)',
    'en-GB': '71312 - Amusement arcades',
    'da-DK': '71312 - Spillehaller (arkade)'
  },
  companyInformation: {
    'sv-SE': 'Företagsinformation',
    'en-GB': 'Business information',
    'da-DK': 'Virksomhedsoplysninger'
  },
  companyType: {
    'sv-SE': 'Företagstyp*',
    'en-GB': 'Company type*',
    'da-DK': 'Virksomhedstyp*'
  },
  chooseCompanyType: {
    'sv-SE': 'Välj företagstyp',
    'en-GB': 'Choose company type',
    'da-DK': 'Vælg virksomhedstype'
  },
  priceLists: {
    'sv-SE': 'Prislistor',
    'en-GB': 'Price lists',
    'da-DK': 'Prislister'
  },
  priceList: {
    'sv-SE': 'Prislista',
    'en-GB': 'Price list',
    'da-DK': 'Prisliste'
  },
  tierTitle: {
    'sv-SE': 'Prislista för app*',
    'en-GB': 'Pricelist for app*',
    'da-DK': 'Prisliste om app*'
  },
  ippTierTitle: {
    'sv-SE': 'Prislista för terminaler*',
    'en-GB': 'Pricelist for terminals*',
    'da-DK': 'Prisliste om terminaler*'
  },
  legalName: {
    'sv-SE': 'Företagsnamn*',
    'en-GB': 'Business name*',
    'da-DK': 'Firmanavn*'
  },
  firstName: {
    'sv-SE': 'Förnamn*',
    'en-GB': 'First name*',
    'da-DK': 'Fornavn*'
  },
  lastName: {
    'sv-SE': 'Efternamn*',
    'en-GB': 'Last name*',
    'da-DK': 'Efternavn*'
  },
  registrationNumber: {
    'sv-SE': 'Organisationsnummer*',
    'en-GB': 'Corporate identification number*',
    'da-DK': 'CVR-nummer*'
  },
  vatNumber: {
    'sv-SE': 'Momsregistreringsnummer*',
    'en-GB': 'Vat identification number*',
    'da-DK': 'Momsregistreringsnummer*'
  },
  street: {
    'sv-SE': 'Gata*',
    'en-GB': 'Street*',
    'da-DK': 'Gade*'
  },
  postalCode: {
    'sv-SE': 'Postnummer*',
    'en-GB': 'Postal code*',
    'da-DK': 'Postnummer*'
  },
  city: {
    'sv-SE': 'Stad*',
    'en-GB': 'City*',
    'da-DK': 'By*'
  },
  country: {
    'sv-SE': 'Land*',
    'en-GB': 'Country*',
    'da-DK': 'Land*'
  },
  contactInformation: {
    'sv-SE': 'Kontaktinformation',
    'en-GB': 'Contact information',
    'da-DK': 'Kontaktinformation'
  },
  phoneNumber: {
    'sv-SE': 'Telefonnummer*',
    'en-GB': 'Phone number*',
    'da-DK': 'Telefonnummer*'
  },
  email: {
    'sv-SE': 'E-postadress*',
    'en-GB': 'Email address*',
    'da-DK': 'Email adresse*'
  },
  startOnboarding: {
    'sv-SE': 'Starta onboarding',
    'en-GB': 'Start onboarding',
    'da-DK': 'Start onboarding'
  },
  submissionFailedTitle: {
    'sv-SE': 'Misslyckades att skapa konto hos Adyen.',
    'en-GB': 'Failed to create Adyen account.',
    'da-DK': 'Kunne ikke oprette en konto hos Adyen.'
  },
  submissionFailedDescription: {
    'sv-SE': 'Det gick inte att skapa Adyen-kontot. Var god försök igen.',
    'en-GB': 'Adyen account could not be created. Please try again.',
    'da-DK': 'Kunne ikke oprette Adyen-konto. Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  failedOnboardingModalTitle: {
    'sv-SE': 'Misslyckades med att starta onboarding',
    'en-GB': 'Failed to start onboarding',
    'da-DK': 'Kunne ikke starte onboarding'
  },
  failedOnboardingModalDescription: {
    'sv-SE':
      'Följande felmeddelande returnerades av Adyen. Rätta till felen och försök igen.',
    'en-GB':
      'The following error message was given by Adyen. Please fix the errors and try again.',
    'da-DK':
      'Følgende fejlmeddelelse blev returneret af Adyen. Ret fejlene og prøv igen.'
  }
};

export const CONNECT_EXISTING_ACCOUNT_STRINGS = {
  connectAccount: {
    'sv-SE': 'Anslut konto',
    'en-GB': 'Connect account',
    'da-DK': 'Tilslut konto'
  },
  chooseSeller: {
    'sv-SE': 'Välj konto att koppla till',
    'en-GB': 'Choose an account to connect to',
    'da-DK': 'Vælg konto at knytte til'
  },
  connectionFailedTitle: {
    'sv-SE': 'Misslyckades att ansluta till kontot.',
    'en-GB': 'Failed to connect to account.',
    'da-DK': 'Kunne ikke oprette forbindelse til kontoen.'
  },
  connectionFailedDescription: {
    'sv-SE':
      'Det gick inte att ansluta till Adyen-kontot. Var god försök igen.',
    'en-GB': 'Could not connect to the Adyen account. Please try again.',
    'da-DK': 'Kunne ikke oprette forbindelse til Adyen-kontoen. Prøv igen.'
  },
  selectedSellerHasNoAdyenAccount: {
    'sv-SE': 'Den valda säljaren har inget Adyen-konto.',
    'en-GB': 'The chosen seller has no Adyen account.',
    'da-DK': 'Den valgte sælger har ikke en Adyen-konto.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  search: {
    'sv-SE': 'Sök säljare',
    'en-GB': 'Search sellers',
    'da-DK': 'Søg sælgers'
  },
  noMatchFound: {
    'sv-SE': 'Ingen matchning hittades',
    'en-GB': 'No match found',
    'da-DK': 'Ingen match fundet'
  }
};

export const TERMINAL_STRINGS = {
  terminalsNotActive: {
    'sv-SE': 'Terminaler ej aktivt',
    'en-GB': 'Terminals not active',
    'da-DK': 'Terminaler er ikke aktiveret'
  },
  terminalsNotActiveDescription: {
    'sv-SE':
      'Du kan inte ta emot betalningar via WEIQs kortterminaler just nu. Kontakta WEIQ Support för att aktivera funktionen och beställa en terminal.',
    'en-GB':
      'You cannot recieve payments through WEIQs card terminals right now. Contact WEIQ Support to activate this function and to order a terminal.',
    'da-DK':
      'Du kan ikke acceptere betalinger via WEIQs kortterminaler lige nu. Kontakt venligst WEIQ Support for at aktivere funktionen.'
  },
  terminalsActivatingDescription: {
    'sv-SE':
      'Du kan inte ta emot betalningar via WEIQs kortterminaler just nu. Kontakta WEIQ Support för att få hjälp med aktiveringen.',
    'en-GB':
      'You cannot recieve payments through WEIQs card terminals right now. Contact WEIQ Support to get help with the activation.',
    'da-DK':
      'Du kan ikke modtage betalinger gennem WEIQs kortterminaler lige nu. Kontakt WEIQ Support for at få hjælp med aktivering.'
  },
  activate: {
    'sv-SE': 'Aktivera',
    'en-GB': 'Activate',
    'da-DK': 'Aktivere'
  },
  activateTerminals: {
    'sv-SE': 'Aktivera terminaler',
    'en-GB': 'Activate terminals',
    'da-DK': 'Aktivere terminaler'
  },
  terminalsActivating: {
    'sv-SE': 'Terminaler aktiveras...',
    'en-GB': 'Terminals activating...',
    'da-DK': 'Terminaler aktiveres...'
  },
  terminalsActivatingBadge: {
    'sv-SE': 'Aktivering pågår',
    'en-GB': 'Activation in progress',
    'da-DK': 'Aktivering pågår'
  },
  activateTerminalsDescription: {
    'sv-SE':
      'För att aktivera terminaler behöver vi veta mer om den fysiska plats som terminalerna kommer användas på.',
    'en-GB':
      'In order to activate terminals we need information about the physical location where the terminals will be operating.',
    'da-DK':
      'For at aktivere terminaler behøver vi vide mere om den fysiske plads där den kommer brugt på.'
  },
  failedToActivateTerminals: {
    'sv-SE': 'Kunde inte aktivera terminaler. Var god försök igen.',
    'en-GB': 'Failed to activate terminals. Please try again.',
    'da-DK': 'Kunne ikke aktivere terminaler. Prøv igen.'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  houseNumber: {
    'sv-SE': 'Husnummer',
    'en-GB': 'House number',
    'da-DK': 'Husnummer'
  },
  street: {
    'sv-SE': 'Gata',
    'en-GB': 'Street',
    'da-DK': 'Gade'
  },
  postalCode: {
    'sv-SE': 'Postnummer',
    'en-GB': 'Postal code',
    'da-DK': 'Postnummer'
  },
  city: {
    'sv-SE': 'Stad',
    'en-GB': 'City',
    'da-DK': 'By'
  },
  country: {
    'sv-SE': 'Land',
    'en-GB': 'Country',
    'da-DK': 'Land'
  },
  SE: {
    'sv-SE': 'Sverige',
    'en-GB': 'Sweden',
    'da-DK': 'Sverige'
  },
  NO: {
    'sv-SE': 'Norge',
    'en-GB': 'Norway',
    'da-DK': 'Norge'
  },
  DK: {
    'sv-SE': 'Danmark',
    'en-GB': 'Denmark',
    'da-DK': 'Danmark'
  },
  county: {
    'sv-SE': 'Län',
    'en-GB': 'County',
    'da-DK': 'Amt'
  },
  chooseCounty: {
    'sv-SE': 'Välj län',
    'en-GB': 'Choose county',
    'da-DK': 'Vælg amt'
  },
  phoneNumber: {
    'sv-SE': 'Telefonnummer (med landsnummer)',
    'en-GB': 'Phone number (with country code)',
    'da-DK': 'Telefonnummer (med landekode)'
  },
  TERMINALS: {
    'sv-SE': 'Terminaler',
    'en-GB': 'Terminals',
    'da-DK': 'Terminaler'
  },
  ORDERS: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  REGISTRY: {
    'sv-SE': 'Terminalregister',
    'en-GB': 'Terminal registry',
    'da-DK': 'Terminalregister'
  },
  5812: {
    'sv-SE': '5812 - Restauranger',
    'en-GB': '5812 - Restaurants',
    'da-DK': '5812 - Restauranter'
  },
  5813: {
    'sv-SE': '5813 - Pubar, barer, och nattklubbar',
    'en-GB': '5813 - Pubs, bars, and nightclubs',
    'da-DK': '5813 - Pubber, barer og natklubber'
  },
  5814: {
    'sv-SE': '5814 - Snabbmatsrestauranger',
    'en-GB': '5814 - Fast food restaurants',
    'da-DK': '5814 - Fastfood restauranter'
  },
  7011: {
    'sv-SE': '7011 - Hotell, motell, och resorts',
    'en-GB': '7011 - Hotels, motels, and resorts',
    'da-DK': '7011 - Hoteller, moteller og resorts'
  },
  7832: {
    'sv-SE': '7832 - Biografer',
    'en-GB': '7832 - Motion picture theaters',
    'da-DK': '7832 - Biografer'
  },
  7932: {
    'sv-SE': '7932 - Biljardhallar',
    'en-GB': '7932 - Pool and billiard establishments',
    'da-DK': '7932 - Billardsale'
  },
  7933: {
    'sv-SE': '7933 - Bowlinghallar',
    'en-GB': '7933 - Bowling alleys',
    'da-DK': '7933 - Bowlingbaner'
  },
  7991: {
    'sv-SE': '7991 - Turistattraktioner',
    'en-GB': '7991 - Tourist attractions',
    'da-DK': '7991 - Turistattraktioner'
  },
  7994: {
    'sv-SE': '7994 - Spelhallar',
    'en-GB': '7994 - Video game arcades and establishments',
    'da-DK': '7994 - Arkader'
  },
  7996: {
    'sv-SE': '7996 - Nöjesfält, karnevaler, och cirkusar',
    'en-GB': '7996 - Amusement parks, carnivals, and circuses',
    'da-DK': '7996 - Forlystelsesparker, karnevaler og cirkus'
  },
  7997: {
    'sv-SE': '7997 - Countryklubbar',
    'en-GB': '7997 - Country clubs',
    'da-DK': '7997 - Country clubs'
  },
  7998: {
    'sv-SE': '7998 - Akvarium och zoon',
    'en-GB': '7998 - Aquariums and zoos',
    'da-DK': '7998 - Akvarium og zoo'
  }
};

const TERMINAL_TABLE_COMMON_STRINGS = {
  model: {
    'sv-SE': 'Modell',
    'en-GB': 'Model',
    'da-DK': 'Model'
  },
  serialNumber: {
    'sv-SE': 'Serienummer',
    'en-GB': 'Serial number',
    'da-DK': 'Serienummer'
  },
  show: {
    'sv-SE': 'Visa',
    'en-GB': 'Show',
    'da-DK': 'Vise'
  },
  noTerminalsFound: {
    'sv-SE': 'Inga terminaler hittades',
    'en-GB': 'No terminals found',
    'da-DK': 'Ingen terminaler fundet'
  }
};

export const TERMINAL_REGISTRY_TAB_STRINGS = {
  ...TERMINAL_TABLE_COMMON_STRINGS,
  inventory: {
    'sv-SE': 'Lager',
    'en-GB': 'Inventory',
    'da-DK': 'Lager'
  }
};

export const TERMINAL_SIDEBAR_STRINGS = {
  ...TERMINAL_TABLE_COMMON_STRINGS,
  terminal: {
    'sv-SE': 'Terminal - $serialNumber$',
    'en-GB': 'Terminal - $serialNumber$',
    'da-DK': 'Terminal - $serialNumber$'
  },
  overview: {
    'sv-SE': 'Överblick',
    'en-GB': 'Overview',
    'da-DK': 'Oversigt'
  },
  status: {
    'sv-SE': 'Status',
    'en-GB': 'Status',
    'da-DK': 'Status'
  },
  onlineToday: {
    'sv-SE': 'Online idag',
    'en-GB': 'Online today',
    'da-DK': 'Online i dag'
  },
  onlineYesterday: {
    'sv-SE': 'Online igår',
    'en-GB': 'Online yesterday',
    'da-DK': 'Online i går'
  },
  onlineXDaysAgo: {
    'sv-SE': 'Online för $days$ dagar sedan',
    'en-GB': 'Online $days$ days ago',
    'da-DK': 'Online for $days$ dage siden'
  },
  reassignmentToStorePending: {
    'sv-SE': 'Inväntar tilldelning till restaurang',
    'en-GB': 'Reassignment to restaurant pending',
    'da-DK': 'Afventer tildeling til restaurant'
  },
  reassignmentToInventoryPending: {
    'sv-SE': 'Inväntar tilldelning till lager',
    'en-GB': 'Reassignment to inventory pending',
    'da-DK': 'Afventer tildeling til lager'
  },
  switchedOff: {
    'sv-SE': 'Avstängd',
    'en-GB': 'Switched off',
    'da-DK': 'Slukket'
  },
  lastActive: {
    'sv-SE': 'Senast aktiv',
    'en-GB': 'Last active',
    'da-DK': 'Sidst aktive'
  },
  lastTransaction: {
    'sv-SE': 'Senaste transaktionen',
    'en-GB': 'Last transaction',
    'da-DK': 'Sidste transaktion'
  },
  technicalDetails: {
    'sv-SE': 'Teknisk information',
    'en-GB': 'Technical details',
    'da-DK': 'Teknisk information'
  },
  firmwareVersion: {
    'sv-SE': 'Firmwareversion',
    'en-GB': 'Firmware version',
    'da-DK': 'Firmwareversion'
  },
  wifi: {
    'sv-SE': 'WIFI IP / MAC',
    'en-GB': 'WIFI IP / MAC',
    'da-DK': 'WIFI IP / MAC'
  },
  ethernet: {
    'sv-SE': 'Ethernet IP / MAC',
    'en-GB': 'Ethernet IP / MAC',
    'da-DK': 'Ethernet IP / MAC'
  },
  bluetooth: {
    'sv-SE': 'Bluetooth IP / MAC',
    'en-GB': 'Bluetooth IP / MAC',
    'da-DK': 'Bluetooth IP / MAC'
  },
  simStatus: {
    'sv-SE': 'SIM status',
    'en-GB': 'SIM status',
    'da-DK': 'SIM status'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  selectRestaurant: {
    'sv-SE': 'Välj restaurang',
    'en-GB': 'Select restaurant',
    'da-DK': 'Vælg restaurant'
  },
  reassignModalTitle: {
    'sv-SE': 'Flytta terminal till restaurang',
    'en-GB': 'Assign terminal to restaurant',
    'da-DK': 'Flyt terminal til restaurant'
  },
  reassignModalSubtitle: {
    'sv-SE': 'Välj en restaurang att flytta terminalen till',
    'en-GB': 'Select a restaurant to assign the terminal to',
    'da-DK': 'Vælg en restaurant at flytte terminalen til'
  },
  reassignModalInfo: {
    'sv-SE':
      'Notera att det kan ta upp till 24h för flytten att gå igenom. Terminalen måste startas om och den nya platsen måste accepteras.',
    'en-GB':
      'Note that it may take up to 24 hours for the assignment to take effect. The terminal must be restarted, and the new location must be accepted.',
    'da-DK':
      'Bemærk, at det kan tage op til 24 timer for flytningen at træde i kraft. Terminalen skal genstartes, og den nye placering skal accepteres.'
  },
  reassignmentNoStoreError: {
    'sv-SE':
      'Terminalen kunde inte flyttas. Har terminaler aktiverats på den valda restaurangen?',
    'en-GB':
      'The terminal could not be assigned. Has the terminal function been activated for the selected restaurant?',
    'da-DK':
      'Terminalen kunne ikke flyttes. Er terminaler blevet aktiveret på den valgte restaurant?'
  },
  reassignmentUnknownError: {
    'sv-SE': 'Terminalen kunde inte flyttas. Vänligen försök igen.',
    'en-GB': 'The terminal could not be assigned. Please try again.',
    'da-DK': 'Terminalen kunne ikke flyttes. Prøv igen.'
  },
  moveTerminal: {
    'sv-SE': 'Flytta',
    'en-GB': 'Assign',
    'da-DK': 'Flyt'
  },
  confirmReassignmentTitle: {
    'sv-SE': 'Bekräfta flytt av terminal',
    'en-GB': 'Confirm terminal assignment',
    'da-DK': 'Bekræft terminal flytning'
  },
  confirmReassignmentMessage: {
    'sv-SE': 'Är du säker på att du vill flytta terminalen till $$SELLER$$?',
    'en-GB': 'Are you sure you want to assign the terminal to $$SELLER$$?',
    'da-DK': 'Er du sikker på, at du vil flytte terminalen til $$SELLER$$?'
  },
  moveToInventory: {
    'sv-SE': 'Flytta terminal till lager',
    'en-GB': 'Assign terminal to inventory',
    'da-DK': 'Flyt terminal til lager'
  },
  moveToRestaurant: {
    'sv-SE': 'Flytta terminal till restaurang',
    'en-GB': 'Assign terminal to restaurant',
    'da-DK': 'Flyt terminal til restaurant'
  },
  confirmAssignToInventory: {
    'sv-SE': 'Är du säker på att du vill flytta terminalen till lagret?',
    'en-GB': 'Are you sure you want to assign the terminal to the inventory?',
    'da-DK': 'Er du sikker på, at du vil flytte terminalen til lageret?'
  },
  assignToInventoryErrorTitle: {
    'sv-SE': 'Flytt av terminal misslyckades',
    'en-GB': 'Terminal assignment failed',
    'da-DK': 'Flytning af terminal mislykkedes'
  },
  assignToInventoryErrorMessage: {
    'sv-SE': 'Terminalen kunde inte flyttas till lagret. Vänligen försök igen.',
    'en-GB':
      'The terminal could not be assigned to the inventory. Please try again.',
    'da-DK': 'Terminalen kunne ikke flyttes til lageret. Prøv igen.'
  },
  noSearchResults: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Der blev ikke fundet nogen resultater'
  },
  assignmentPendingWarning: {
    'sv-SE':
      'Terminalen håller på att tilldelas. Den kan därför inte flyttas just nu.',
    'en-GB':
      'The terminal is currently being assigned. It can therefore not be reassigned right now.',
    'da-DK':
      'Terminalen er i øjeblikket ved at blive tildelt, og kan derfor ikke flyttes lige nu.'
  }
};

export const TERMINALS_TAB_STRINGS = {
  ...TERMINAL_TABLE_COMMON_STRINGS
};

export const ORDER_TAB_STRINGS = {
  orderNumber: {
    'sv-SE': 'Ordernummer',
    'en-GB': 'Order number',
    'da-DK': 'Ordernummer'
  },
  orderDate: {
    'sv-SE': 'Orderdatum',
    'en-GB': 'Order date',
    'da-DK': 'Bestillingsdato'
  },
  status: {
    'sv-SE': 'Status',
    'en-GB': 'Status',
    'da-DK': 'Status'
  },
  tracking: {
    'sv-SE': 'Spåra',
    'en-GB': 'Tracking',
    'da-DK': 'Spore'
  },
  show: {
    'sv-SE': 'Visa',
    'en-GB': 'Show',
    'da-DK': 'Vise'
  },
  newOrder: {
    'sv-SE': 'Ny beställning',
    'en-GB': 'New order',
    'da-DK': 'Ny bestilling'
  },
  noOrdersFound: {
    'sv-SE': 'Inga beställningar hittades',
    'en-GB': 'No orders found',
    'da-DK': 'Ingen bestilling fundet'
  },
  placed: {
    'sv-SE': 'Skapad',
    'en-GB': 'Placed',
    'da-DK': 'Oprettet'
  },
  confirmed: {
    'sv-SE': 'Accepterad',
    'en-GB': 'Confirmed',
    'da-DK': 'Acceptered'
  },
  cancelled: {
    'sv-SE': 'Avbruten',
    'en-GB': 'Cancelled',
    'da-DK': 'Afbrudt'
  },
  shipped: {
    'sv-SE': 'Skickad',
    'en-GB': 'Shipped',
    'da-DK': 'Sendt'
  },
  delivered: {
    'sv-SE': 'Levererad',
    'en-GB': 'Delivered',
    'da-DK': 'Levereret'
  },
  orderDetails: {
    'sv-SE': 'Beställningsöversikt',
    'en-GB': 'Order details',
    'da-DK': 'Bestillingsoverblik'
  },
  shippingAddress: {
    'sv-SE': 'Leveransadress',
    'en-GB': 'Shipping address',
    'da-DK': 'Leveringsadresse'
  },
  contactDetails: {
    'sv-SE': 'Kontaktperson för leverans',
    'en-GB': 'Contact for delivery',
    'da-DK': 'Kontaktperson for levering'
  },
  billingAddress: {
    'sv-SE': 'Faktureringsadress',
    'en-GB': 'Billing address',
    'da-DK': 'Betalingsadresse'
  },
  products: {
    'sv-SE': 'Produkter',
    'en-GB': 'Products',
    'da-DK': 'Produkter'
  },
  S1F2: {
    'sv-SE': 'S1F2',
    'en-GB': 'S1F2',
    'da-DK': 'S1F2'
  },
  S1F2Description: {
    'sv-SE': 'Kortterminal (S1F2) med inbyggd skrivare',
    'en-GB': 'Payment terminal (S1F2) with built-in printer',
    'da-DK': 'Kortterminal (S1F2) med built-in printer'
  },
  AMS1: {
    'sv-SE': 'AMS1',
    'en-GB': 'AMS1',
    'da-DK': 'AMS1'
  },
  AMS1Description: {
    'sv-SE': 'Kortterminal (AMS1) utan skrivare',
    'en-GB': 'Payment terminal (AMS1) without printer',
    'da-DK': 'Kortterminal (AMS1) uden printer'
  },
  charger: {
    'sv-SE': 'Laddare',
    'en-GB': 'Power adapter',
    'da-DK': 'Strømadapter'
  },
  chargerDescription: {
    'sv-SE': 'Extra laddare, en ingår med terminalen',
    'en-GB': 'Extra power adapter, one is included with the terminal',
    'da-DK': 'Extra strømadapter, en er inkluderet med terminalen'
  },
  receiptRoll: {
    'sv-SE': 'Kvittorulle',
    'en-GB': 'Receipt roll',
    'da-DK': 'Kvitteringsrulle'
  },
  receiptRollDescription: {
    'sv-SE': 'Extra kvittorulle, en ingår med terminalen',
    'en-GB': 'Extra receipt roll, one is included with the terminal',
    'da-DK': 'Extra kvitteringsrulle, en er inkluderet med terminalen'
  },
  testCard: {
    'sv-SE': 'Testkort',
    'en-GB': 'Test card',
    'da-DK': 'Testkort'
  },
  testCardDescription: {
    'sv-SE': 'Används för att göra testköp i terminalen',
    'en-GB': 'Used to make test payments on the terminal',
    'da-DK': 'Dette bruges til at teste køb i terminalen'
  },
  threePPowerCord: {
    'sv-SE': 'Strömkabel (EU)',
    'en-GB': 'Power cord (EU)',
    'da-DK': 'Strømkabel (EU)'
  },
  threePPowerCordDescription: {
    'sv-SE': 'EU-anpassad trepolig strömkabel. 2 meter.',
    'en-GB': 'EU compatible three point power cord. 2 meters.',
    'da-DK': 'EU-kompatibelt tre-polet strømkabel. 2 meter.'
  },
  usbCACable: {
    'sv-SE': 'Kabel: USB-C till USB-A',
    'en-GB': 'Cable: USB-C to USB-A',
    'da-DK': 'Kabel: USB-C til USB-A'
  },
  usbCACableDescription: {
    'sv-SE': 'USB-C till USB-A, rak kabel - AMS1, S1E',
    'en-GB': 'Cable USB-C to USB-A Straight connector - AMS1, S1E',
    'da-DK': 'USB-C til USB-A, lige kabel - AMS1, S1E'
  },
  s1f2Battery: {
    'sv-SE': 'Ersättningsbatteri till S1F2',
    'en-GB': 'Replacement battery for S1F2',
    'da-DK': 'Udskiftningsbatteri til S1F2'
  },
  s1f2BatteryDescription: {
    'sv-SE': 'Ersättningsbatteri för S1F2 (ett ingår i paketet)',
    'en-GB': 'Replacement battery for S1F2 units. (included in package)',
    'da-DK': 'Udskiftningsbatteri til S1F2 (et inkluderet i pakken)'
  },
  s1f2ChargingBase: {
    'sv-SE': 'Laddstation till S1F2',
    'en-GB': 'Charging Base for S1F2',
    'da-DK': 'Ladestation til S1F2'
  },
  s1f2ChargingBaseDescription: {
    'sv-SE': 'Strömkabel (EU) ej inkluderad',
    'en-GB': 'Power cord (EU) not included',
    'da-DK': 'Opladerkabel (EU) medfølger ikke'
  },
  s1f2LargeChargingStation: {
    'sv-SE': 'Laddstation för 4 S1F2-terminaler',
    'en-GB': 'Charging station for 4 S1F2 terminals',
    'da-DK': 'Ladestation til 4 S1F2 terminaler'
  },
  s1f2LargeChargingStationDescription: {
    'sv-SE': 'Laddare på 40W ingår.',
    'en-GB': '40W charger included.',
    'da-DK': '40W oplader medfølger.'
  },
  s1f2PrintRollers: {
    'sv-SE': 'S1F2 Skrivarrulle',
    'en-GB': 'S1F2 Print Rollers',
    'da-DK': 'S1F2 Udskriftsruller'
  },
  s1f2ProtectiveCase: {
    'sv-SE': 'S1F2 Skyddsfodral',
    'en-GB': 'S1F2 Protective Case',
    'da-DK': 'S1F2 Beskyttelsesetui'
  },
  s1f2ChargingBase2: {
    'sv-SE': 'Laddstation till S1F2',
    'en-GB': 'Charging Base for S1F2',
    'da-DK': 'Ladestation til S1F2'
  },
  s1f2ChargingBase2Description: {
    'sv-SE': 'Kompatibel med skyddsfodral',
    'en-GB': 'Compatible with protective case',
    'da-DK': 'Kompatibel med beskyttelsesetui'
  },
  s1f2PaperCover: {
    'sv-SE': 'S1F2 Paper Cover',
    'en-GB': 'S1F2 Paper Cover',
    'da-DK': 'S1F2 Paper Cover'
  },
  ams1ProtectiveCase: {
    'sv-SE': 'AMS1 Skyddsfodral',
    'en-GB': 'AMS1 Protective Case',
    'da-DK': 'AMS1 Beskyttelsesetui'
  },
  ams1ChargingBase: {
    'sv-SE': 'Laddstation till AMS1',
    'en-GB': 'Charging Base for AMS1',
    'da-DK': 'Ladestation til AMS1'
  },
  ams1ChargingBaseDescription: {
    'sv-SE': 'Kompatibel med skyddsfodral',
    'en-GB': 'Compatible with protective case',
    'da-DK': 'Kompatibel med beskyttelsesetui'
  },
  ams1ChargingDock: {
    'sv-SE': 'Laddstation till AMS1',
    'en-GB': 'Charging Dock for AMS1',
    'da-DK': 'Ladestation til AMS1'
  },
  ams1ChargingDockDescription: {
    'sv-SE': 'Kompatibel med laddaren som ingår till terminalen',
    'en-GB': 'Compatible with the charger included with the terminal',
    'da-DK': 'Kompatibel med den oplader, der følger med terminalen'
  },
  ams1LargeChargingStation: {
    'sv-SE': 'Laddstation för 4 AMS1-terminaler',
    'en-GB': 'Charging station for 4 AMS1 terminals',
    'da-DK': 'Ladestation til 4 AMS1 terminaler'
  },
  ams1LargeChargingStationDescription: {
    'sv-SE': 'Laddare på 40W ingår',
    'en-GB': '40W charger included',
    'da-DK': '40W oplader medfølger'
  },
  order: {
    'sv-SE': 'Lägg beställning',
    'en-GB': 'Place order',
    'da-DK': 'Bestille'
  },
  slowOrderTime: {
    'sv-SE':
      'Notera att det kan ta upp till 2 minuter att lägga en beställning. Stäng inte ner webbläsaren innan sidan har laddat klart och du har bekräftat att beställningen har skapats.',
    'en-GB':
      'Please note that it may take up to two minutes to place an order. Do not close your web browser before the page has finished loading.',
    'da-DK':
      'Bemærk, at det kan tage op til 2 minutter at afgive en ordre. Luk ikke browseren, før siden er færdig med at blive indlæst, og du har bekræftet, at ordren er oprettet.'
  },
  failedToCreateOrderTitle: {
    'sv-SE': 'Beställningen misslyckades',
    'en-GB': 'Order failed',
    'da-DK': 'Bestilling mislykkedes'
  },
  failedToCreateOrderDescription: {
    'sv-SE': 'Det gick inte att lägga beställningen. Var god försök igen.',
    'en-GB': 'Failed to place order. Please try again.',
    'da-DK': 'Bestillingen kunne ikke gennemføres. Prøv igen.'
  },
  serialNumber: {
    'sv-SE': 'Serienummer för terminaler',
    'en-GB': 'Terminal serial numbers',
    'da-DK': 'Serienummer for terminaler'
  },
  orderDescription: {
    'sv-SE': 'Orderinformation',
    'en-GB': 'Order description',
    'da-DK': 'Bestillingsinformation'
  },
  PLACED: {
    'sv-SE': 'Ordern skapades $date$',
    'en-GB': 'The order was placed $date$',
    'da-DK': 'Bestillingen blev afgivet $date$'
  },
  CONFIRMED: {
    'sv-SE': 'Ordern accepterades $date$',
    'en-GB': 'The order was confirmed $date$',
    'da-DK': 'Bestillingen blev acceptered $date$'
  },
  CANCELLED: {
    'sv-SE': 'Ordern avbröts $date$',
    'en-GB': 'The order was cancelled $date$',
    'da-DK': 'Bestillingen blev afbrudt $date$'
  },
  SHIPPED: {
    'sv-SE': 'Ordern skickades $date$',
    'en-GB': 'The order was shipped $date$',
    'da-DK': 'Bestillingen blev sendt $date$'
  },
  DELIVERED: {
    'sv-SE': 'Ordern levererades till kund $date$',
    'en-GB': 'The order was delivered to customer $date$',
    'da-DK': 'Bestillingen blev levereret til kunde $date$'
  },
  trackingUrlMissingTitle: {
    'sv-SE': 'Spårningslänk saknas',
    'en-GB': 'Tracking link is missing',
    'da-DK': 'Sporingslink findes ikke'
  },
  trackingUrlMissingText: {
    'sv-SE':
      'Var god vänta tills att länken har genererats eller fyll i spårningskoden på fraktbolagets hemsida. Observera att den fullständiga spårningskoden står i beställningsöversikten.',
    'en-GB':
      "Please wait until the link is generated or fill in the tracking code on the shipping company's webpage. Note that the entire tracking code can be found under order details.",
    'da-DK':
      'Vent indtil linket er blever genereret, eller indtast sporingskoden på fragtfirmaets hjemmeside. Bemærk at den komplete sporingskode er i bestillingsoverbliket.'
  },
  companyName: {
    'sv-SE': 'Företagsnamn*',
    'en-GB': 'Company name*',
    'da-DK': 'Virksomhedsnavn*'
  },
  streetAddress: {
    'sv-SE': 'Gatunamn och nummer*',
    'en-GB': 'Street name and number*',
    'da-DK': 'Gadenavn og nummer*'
  },
  postalCode: {
    'sv-SE': 'Postnummer*',
    'en-GB': 'Zip code*',
    'da-DK': 'Postnummer*'
  },
  city: {
    'sv-SE': 'Stad*',
    'en-GB': 'City*',
    'da-DK': 'By*'
  },
  country: {
    'sv-SE': 'Land*',
    'en-GB': 'Country*',
    'da-DK': 'Land*'
  },
  selectCountry: {
    'sv-SE': 'Välj land',
    'en-GB': 'Select country',
    'da-DK': 'Vælg land'
  },
  SE: {
    'sv-SE': 'Sverige',
    'en-GB': 'Sweden',
    'da-DK': 'Sverige'
  },
  NO: {
    'sv-SE': 'Norge',
    'en-GB': 'Norway',
    'da-DK': 'Norge'
  },
  DK: {
    'sv-SE': 'Danmark',
    'en-GB': 'Denmark',
    'da-DK': 'Danmark'
  },
  firstName: {
    'sv-SE': 'Förnamn*',
    'en-GB': 'First name*',
    'da-DK': 'Fornavn*'
  },
  lastName: {
    'sv-SE': 'Efternamn*',
    'en-GB': 'Last name*',
    'da-DK': 'Efternavn*'
  },
  email: {
    'sv-SE': 'E-post*',
    'en-GB': 'Email*',
    'da-DK': 'E-mail*'
  },
  phoneNumber: {
    'sv-SE': 'Telefonnummer med landsnummer (leveransdetaljer skickas hit)*',
    'en-GB': 'Phone number with country code (receives delivery updates)*',
    'da-DK': 'Telefonnummer med landekode (leveringsoplysninger sendes her)*'
  }
};

export const DEVELOPER_STRINGS = {
  developer: {
    'sv-SE': 'Utvecklare',
    'en-GB': 'Developer',
    'da-DK': 'Udvikler'
  },
  sellerRef: {
    'sv-SE': 'Seller reference',
    'en-GB': 'Seller reference',
    'da-DK': 'Seller reference'
  },
  sellerInfo: {
    'sv-SE': 'Seller info',
    'en-GB': 'Seller info',
    'da-DK': 'Seller info'
  },
  productRegisterRef: {
    'sv-SE': 'Product register reference',
    'en-GB': 'Product register reference',
    'da-DK': 'Product register reference'
  },
  adyenStoreReference: {
    'sv-SE': 'Adyen store reference',
    'en-GB': 'Adyen store reference',
    'da-DK': 'Adyen store reference'
  }
};

export const DISCOUNTS_STRINGS = {
  discounts: {
    'sv-SE': 'Rabattkoder',
    'en-GB': 'Discounts',
    'da-DK': 'Rabatkoder'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  },
  active: {
    'sv-SE': 'Aktiv',
    'en-GB': 'Active',
    'da-DK': 'Aktiv'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  createCampaign: {
    'sv-SE': 'Skapa kampanj',
    'en-GB': 'Create campaign',
    'da-DK': 'Opret kampagne'
  },
  notValid: {
    'sv-SE': 'Ej giltig',
    'en-GB': 'Not valid',
    'da-DK': 'Ikke gyldig'
  },
  deleteDiscountTitle: {
    'sv-SE': 'Ta bort kampanj',
    'en-GB': 'Remove campaign',
    'da-DK': 'Fjern kampagne'
  },
  deleteDiscountMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort kampanjen "$$name$$"?',
    'en-GB': 'Are you sure you want to remove the campaign "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil slette kampagnen "$$name$$"?'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  editDiscount: {
    'sv-SE': 'Redigera kampanj',
    'en-GB': 'Edit campaign',
    'da-DK': 'Rediger kampagne'
  },
  deleteDiscount: {
    'sv-SE': 'Ta bort kampanj',
    'en-GB': 'Delete campaign',
    'da-DK': 'Fjern kampagne'
  },
  noDiscounts: {
    'sv-SE': 'Inga kampanjer hittades',
    'en-GB': 'No campaigns were found',
    'da-DK': 'Ingen kampagner fundet'
  }
};

export const EDIT_DISCOUNT_STRINGS = {
  validMONDAY: {
    'sv-SE': 'Giltig måndagar',
    'en-GB': 'Valid Mondays',
    'da-DK': 'Gældende mandage'
  },
  validTUESDAY: {
    'sv-SE': 'Giltig tisdagar',
    'en-GB': 'Valid Tuesdays',
    'da-DK': 'Gældende tirsdage'
  },
  validWEDNESDAY: {
    'sv-SE': 'Giltig onsdagar',
    'en-GB': 'Valid Wednesdays',
    'da-DK': 'Gældende onsdage'
  },
  validTHURSDAY: {
    'sv-SE': 'Giltig torsdagar',
    'en-GB': 'Valid Thursdays',
    'da-DK': 'Gældende torsdage'
  },
  validFRIDAY: {
    'sv-SE': 'Giltig fredagar',
    'en-GB': 'Valid Fridays',
    'da-DK': 'Gældende fredage'
  },
  validSATURDAY: {
    'sv-SE': 'Giltig lördagar',
    'en-GB': 'Valid Saturdays',
    'da-DK': 'Gældende lørdage'
  },
  validSUNDAY: {
    'sv-SE': 'Giltig söndagar',
    'en-GB': 'Valid Sundays',
    'da-DK': 'Gældende søndage'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  loading: {
    'sv-SE': 'Laddar',
    'en-GB': 'Loading',
    'da-DK': 'Indlæser'
  },
  noResults: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Der blev ikke fundet nogen resultater'
  },
  discounts: {
    'sv-SE': 'Rabattkoder',
    'en-GB': 'Discounts',
    'da-DK': 'Rabatkoder'
  },
  editCampaign: {
    'sv-SE': 'Redigera kampanj',
    'en-GB': 'Edit campaign',
    'da-DK': 'Rediger kampagne'
  },
  newCampaign: {
    'sv-SE': 'Ny kampanj',
    'en-GB': 'New campaign',
    'da-DK': 'Ny kampagne'
  },
  campaign: {
    'sv-SE': 'Kampanj',
    'en-GB': 'Campaign',
    'da-DK': 'Kampagne'
  },
  name: {
    'sv-SE': 'Kampanjens namn (syns ej i appen)*',
    'en-GB': 'Campaign name (not visible in the app)*',
    'da-DK': 'Kampagnenavn (ikke synlig i appen)*'
  },
  description: {
    'sv-SE': 'Beskrivning (syns ej i appen)',
    'en-GB': 'Description (not visible in the app)',
    'da-DK': 'Beskrivelse (ikke synlig i appen)'
  },
  discountPercent: {
    'sv-SE': 'Procentuell rabatt*',
    'en-GB': 'Discount in percent*',
    'da-DK': 'Procent rabat*'
  },
  discountedProducts: {
    'sv-SE': 'Produkter med rabatt',
    'en-GB': 'Discounted products',
    'da-DK': 'Produkter med rabat'
  },
  entirePurchase: {
    'sv-SE': 'Gäller hela köpet',
    'en-GB': 'Applies to the entire purchase',
    'da-DK': 'Gælder hele købet'
  },
  certainCategories: {
    'sv-SE': 'Gäller vissa kategorier',
    'en-GB': 'Applies to certain categories',
    'da-DK': 'Gælder visse kategorier'
  },
  certainProducts: {
    'sv-SE': 'Gäller enstaka produkter',
    'en-GB': 'Applies to certain products',
    'da-DK': 'Gælder enkelte produkter'
  },
  selectCategories: {
    'sv-SE': 'Välj kategorier',
    'en-GB': 'Select categories',
    'da-DK': 'Vælg kategorier'
  },
  selectProducts: {
    'sv-SE': 'Välj produkter',
    'en-GB': 'Select products',
    'da-DK': 'Vælg produkter'
  },
  advancedSettings: {
    'sv-SE': 'Avancerade inställningar',
    'en-GB': 'Advanced settings',
    'da-DK': 'Avancerede indstillinger'
  },
  showAdvancedSettings: {
    'sv-SE': 'Visa avancerade inställningar',
    'en-GB': 'Show advanced settings',
    'da-DK': 'Se avancerede indstillinger'
  },
  hideAdvancedSettings: {
    'sv-SE': 'Dölj avancerade inställningar',
    'en-GB': 'Hide advanced settings',
    'da-DK': 'Skjul avancerede indstillinger'
  },
  limitedDateRange: {
    'sv-SE': 'Begränsad tidsperiod',
    'en-GB': 'Limited date range',
    'da-DK': 'Begrænset tidsrum'
  },
  from: {
    'sv-SE': 'Från',
    'en-GB': 'From',
    'da-DK': 'Fra'
  },
  to: {
    'sv-SE': 'Till',
    'en-GB': 'To',
    'da-DK': 'Til'
  },
  limitUsesPerUser: {
    'sv-SE': 'Begränsa antal förbrukningar per användare',
    'en-GB': 'Limit number of uses per user',
    'da-DK': 'Begræns antallet af anvendelser pr. bruger'
  },
  limitMinOrderAmount: {
    'sv-SE': 'Gäller endast på köp över en summa',
    'en-GB': 'Only apply to purchases over an amount',
    'da-DK': 'Gælder kun ved køb over et beløb'
  },
  limitWeekdays: {
    'sv-SE': 'Begränsa vilka veckodagar och tider kampanjen är giltig',
    'en-GB': 'Limit which weekdays and hours the campaign is valid',
    'da-DK': 'elimit, hvilke dage og tidspunkter på ugen kampagnen er gyldig'
  },
  validMondays: {
    'sv-SE': 'Giltig måndagar',
    'en-GB': 'Valid Mondays',
    'da-DK': 'Gyldig mandage'
  },
  validTuesdays: {
    'sv-SE': 'Giltig tisdagar',
    'en-GB': 'Valid Tuesdays',
    'da-DK': 'Gyldig tirsdage'
  },
  validWednesdays: {
    'sv-SE': 'Giltig onsdagar',
    'en-GB': 'Valid Wednesdays',
    'da-DK': 'Gyldig onsdage'
  },
  validThursdays: {
    'sv-SE': 'Giltig torsdagar',
    'en-GB': 'Valid Thursdays',
    'da-DK': 'Gyldig torsdage'
  },
  validFridays: {
    'sv-SE': 'Giltig fredagar',
    'en-GB': 'Valid Fridays',
    'da-DK': 'Gyldig fredage'
  },
  validSaturdays: {
    'sv-SE': 'Giltig lördagar',
    'en-GB': 'Valid Saturdays',
    'da-DK': 'Gyldig lørdage'
  },
  validSundays: {
    'sv-SE': 'Giltig söndagar',
    'en-GB': 'Valid Sundays',
    'da-DK': 'Gyldig søndage'
  },
  summary: {
    'sv-SE': 'Summering',
    'en-GB': 'Summary',
    'da-DK': 'Opsummering'
  },
  fieldRequired: {
    'sv-SE': 'Måste anges',
    'en-GB': 'Field is required',
    'da-DK': 'Skal angives'
  },
  dateRangeInvalid: {
    'sv-SE':
      'Båda fälten måste anges, och slutdatum måste vara efter startdatum.',
    'en-GB': 'Both fields are required, and end date must be after start date.',
    'da-DK':
      'Begge felter skal indtastes, og slutdatoen skal ligge efter startdatoen.'
  },
  timeRangeInvalid: {
    'sv-SE':
      'Båda fälten måste anges, och sluttiden måste vara efter starttiden.',
    'en-GB': 'Both fields are required, and end time must be after start time.',
    'da-DK':
      'Begge felter skal angives, og sluttidspunktet skal være efter starttidspunktet.'
  },
  validationErrorTitle: {
    'sv-SE': 'Kunde inte spara kampanjen',
    'en-GB': 'Could not save the campaign',
    'da-DK': 'Kampagnen kunne ikke gemmes'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen'
  },
  campaignNotFoundTitle: {
    'sv-SE': 'Kampanjen hittades inte',
    'en-GB': 'Campaign not found',
    'da-DK': 'Kampagnen blev ikke fundet'
  },
  campaignNotFoundMessage: {
    'sv-SE': 'Kampanjen du sökte kunde tyvärr inte hittas',
    'en-GB': 'The campaign you requested could not be found',
    'da-DK': 'Desværre kunne den kampagne, du ledte efter, ikke findes'
  },
  back: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  couldNotSaveTitle: {
    'sv-SE': 'Kunde inte spara kampanjen',
    'en-GB': 'Failed to save campaign',
    'da-DK': 'Kampagnen kunne ikke gemmes'
  },
  couldNotSaveMessage: {
    'sv-SE': 'Misslyckades att spara kampanjen. Vänligen försök igen',
    'en-GB': 'Failed to save the campaign. Please try again',
    'da-DK': 'Kampagnen kunne ikke gemmes. Prøv igen'
  },
  discountCodes: {
    'sv-SE': 'Rabattkoder',
    'en-GB': 'Discount codes',
    'da-DK': 'Rabatkoder'
  },
  discountCode: {
    'sv-SE': 'Rabattkod',
    'en-GB': 'Discount code',
    'da-DK': 'Rabatkode'
  },
  balance: {
    'sv-SE': 'Saldo',
    'en-GB': 'Balance',
    'da-DK': 'Saldo'
  },
  searchPlaceholder: {
    'sv-SE': 'Sök',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  deleteDiscountCodeTitle: {
    'sv-SE': 'Ta bort rabattkod',
    'en-GB': 'Delete discount code',
    'da-DK': 'Fjern rabatkode'
  },
  deleteDiscountCodeMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort rabattkoden "$$code$$"?',
    'en-GB': 'Are you sure you want to delete the discount code "$$code$$"?',
    'da-DK': 'Er du sikker på, at du vil fjerne rabatkoden "$$code$$"?'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  newDiscountCode: {
    'sv-SE': 'Ny kod',
    'en-GB': 'New code',
    'da-DK': 'Ny kode'
  },
  failedToDeleteDiscountCodeTitle: {
    'sv-SE': 'Kunde inte ta bort rabattkoden',
    'en-GB': 'Failed to delete discount code',
    'da-DK': 'Rabatkoden kunne ikke fjernes'
  },
  failedToDeleteDiscountCodeMessage: {
    'sv-SE':
      'Misslyckades att ta bort rabattkoden "$$code$$". Vänligen försök igen.',
    'en-GB': 'Failed to delete the discount code $$code$$. Please try again.',
    'da-DK': 'Kunne ikke fjerne rabatkoden "$$code$$". Prøv igen.'
  },
  showInTerminal: {
    'sv-SE': 'Visa i terminal',
    'en-GB': 'Show in terminal',
    'da-DK': 'Vis i terminal'
  },
  failedToUpdateTerminalVisibilityTitle: {
    'sv-SE': 'Kunde inte ändra rabattkodens synlighet i terminaler',
    'en-GB': 'Failed to update discount code visibility in terminals',
    'da-DK': 'Kunne ikke opdatere rabatkodens synlighed i terminaler'
  },
  failedToUpdateTerminalVisibilityMessage: {
    'sv-SE':
      'Misslyckades att ändra synligheten för rabattkoden "$$code$$". Vänligen försök igen.',
    'en-GB':
      'Failed to update visibility for discount code "$$code$$". Please try again.',
    'da-DK':
      'Kunne ikke opdatere synligheden for rabatkoden "$$code$$". Prøv igen.'
  },
  changeVisibility: {
    'sv-SE': 'Ändra synlighet',
    'en-GB': 'Change visibility',
    'da-DK': 'Ændre synlighed'
  },
  campaignCreated: {
    'sv-SE': 'Kampanj skapad!',
    'en-GB': 'Campaign created!',
    'da-DK': 'Kampagne oprettet!'
  },
  uniqueCodes: {
    'sv-SE': 'unika rabattkoder',
    'en-GB': 'unique discount codes',
    'da-DK': 'unikke rabatkoder'
  },
  discount: {
    'sv-SE': 'rabatt',
    'en-GB': 'discount',
    'da-DK': 'rabat'
  },
  appliesToCategories: {
    'sv-SE': 'Gäller valda produktkategorier',
    'en-GB': 'Applies to selected product categories',
    'da-DK': 'Gælder udvalgte produktkategorier'
  },
  appliesToProducts: {
    'sv-SE': 'Gäller valda produkter',
    'en-GB': 'Applies to selected products',
    'da-DK': 'Gælder udvalgte produkter'
  },
  appliesToPurchase: {
    'sv-SE': 'Gäller hela köpet',
    'en-GB': 'Applies to entire purchase',
    'da-DK': 'Gælder hele købet'
  },
  usablePeriod: {
    'sv-SE': 'Gäller under perioden $$startDate$$ till $$endDate$$',
    'en-GB': 'Valid from $$startDate$$ to $$endDate$$',
    'da-DK': 'Gyldig i perioden $$startDate$$ til $$endDate$$'
  },
  usableWeekdays: {
    'sv-SE': 'Gäller endast valda dagar och tider',
    'en-GB': 'Only valid selected weekdays and hours',
    'da-DK': 'Gælder kun på udvalgte dage og tidspunkter'
  },
  maxUses: {
    'sv-SE': 'Max $$maxUses$$ förbrukningar per användare',
    'en-GB': 'Max $$maxUses$$ uses per user',
    'da-DK': 'Maks. $$maxUses$$ forbrug pr. bruger'
  },
  minAmount: {
    'sv-SE': 'Gäller endast för köp på minst $$amount$$',
    'en-GB': 'Only applies to purchases over $$amount$$',
    'da-DK': 'Kun gyldig for køb på mindst $$amount$$'
  },
  noDiscountCodes: {
    'sv-SE': 'Kampanjen har inga rabattkoder',
    'en-GB': 'The campaign has no discount codes',
    'da-DK': 'Kampagnen har ingen rabatkoder'
  }
};

export const DISCOUNT_CODE_MODAL_STRINGS = {
  newCode: {
    'sv-SE': 'Ny kod',
    'en-GB': 'New code',
    'da-DK': 'Ny kode'
  },
  generateCodes: {
    'sv-SE': 'Generera unika koder',
    'en-GB': 'Generate unique codes',
    'da-DK': 'Generer unikke koder'
  },
  discountCode: {
    'sv-SE': 'Rabattkod*',
    'en-GB': 'Discount code*',
    'da-DK': 'Rabatkode*'
  },
  limit: {
    'sv-SE': 'Maxgräns (antal gånger varje kod kan användas)*',
    'en-GB': 'Limit (number of times each code can be used)*',
    'da-DK': 'Maksimal grænse (antal gange hver kode kan bruges)*'
  },
  unlimited: {
    'sv-SE': 'Obegränsat',
    'en-GB': 'Unlimited',
    'da-DK': 'Ubegrænset'
  },
  oneTime: {
    'sv-SE': 'En gång',
    'en-GB': 'One time',
    'da-DK': 'En gang'
  },
  max: {
    'sv-SE': 'Max:',
    'en-GB': 'Max:',
    'da-DK': 'Maks:'
  },
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  prefix: {
    'sv-SE': 'Prefix (alla koder börjar med dessa tecken)',
    'en-GB': 'Prefix (all codes begin with these characters)',
    'da-DK': 'Præfiks (alle koder starter med disse tegn)'
  },
  numberOfCodes: {
    'sv-SE': 'Antal koder att generera*',
    'en-GB': 'Number of codes to generate*',
    'da-DK': 'Antal koder, der skal genereres*'
  },
  failedToCreateDiscountTitle: {
    'sv-SE': 'Kunde inte skapa rabattkoden',
    'en-GB': 'The discount code could not be created',
    'da-DK': 'Kunne ikke oprette rabatkode'
  },
  failedToCreateDiscountMessage: {
    'sv-SE': 'Misslyckades att skapa rabattkoden. Vänligen försök igen',
    'en-GB': 'Failed to create the discount code. Please try again',
    'da-DK': 'Kunne ikke generere rabatkode. Prøv igen'
  },
  failedToGenerateDiscountCodesTitle: {
    'sv-SE': 'Kunde inte generera rabattkoderna',
    'en-GB': 'The discount codes could not be generated',
    'da-DK': 'Kunne ikke generere rabatkoderne'
  },
  failedToGenerateDiscountCodesMessage: {
    'sv-SE': 'Misslyckades att generera rabattkoder. Vänligen försök igen',
    'en-GB': 'Failed to generate discount codes. Please try again',
    'da-DK': 'Kunne ikke generere rabatkoder. Prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen'
  }
};

export const PROFILE_STRINGS = {
  restaurantName: {
    'sv-SE': 'Restaurangens namn',
    'en-GB': 'Restaurant name',
    'da-DK': 'Restaurantens navn'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  },
  descriptionPlaceholder: {
    'sv-SE': 'En beskrivande text om din restaurang',
    'en-GB': 'A descriptive text about your restaurant',
    'da-DK': 'En beskrivende tekst om din restaurant'
  },
  descriptionLength: {
    'sv-SE': '$$chars$$/250 tecken',
    'en-GB': '$$chars$$/250 characters',
    'da-DK': '$$chars$$/250 tegn'
  },
  coverImage: {
    'sv-SE': 'Omslagsbild',
    'en-GB': 'Cover image',
    'da-DK': 'Forsidebillede'
  },
  coverImageDescription: {
    'sv-SE':
      'Redigera bilden som syns som bakgrund i din meny samt i listan över alla restauranger i appen',
    'en-GB':
      'Edit the image that appears in the background of your menu and in the list of restaurants in the app',
    'da-DK':
      'Rediger billedet, der vises som baggrund i din menu samt på listen over alle restauranter i appen'
  },
  banner: {
    'sv-SE': 'Menybanners',
    'en-GB': 'Banners',
    'da-DK': 'Menu banners'
  },
  bannerDescription: {
    'sv-SE': 'Redigera bilderna som syns längst upp i menyn',
    'en-GB': 'Edit the images that appear at the top of the menu',
    'da-DK': 'Rediger de billeder, der vises øverst i menuen'
  },
  deleteBannerTitle: {
    'sv-SE': 'Ta bort menybanner',
    'en-GB': 'Delete banner',
    'da-DK': 'Fjern menubanner'
  },
  deleteBannerDescription: {
    'sv-SE': 'Är du säker att du vill ta bort menybannern?',
    'en-GB': 'Are you sure you want to delete the banner?',
    'da-DK': 'Er du sikker på, at du vil fjerne menubanneret?'
  },
  prettyNameTitle: {
    'sv-SE': 'URL',
    'en-GB': 'URL',
    'da-DK': 'URL'
  },
  prettyNameDescription: {
    'sv-SE':
      'Välj vilka URLer som leder till din restaurang när gäster besöker WEIQ i en webbläsare, och vilken URL som är primär.',
    'en-GB':
      'Choose which URLs lead to your restaurant when guests visit WEIQ from a web browser, and which one that is primary.',
    'da-DK':
      'Vælg hvilke URLer der fører til din restaurant, når gæster besøger WEIQ i en webbrowser, og hvilken der er primær.'
  },
  newPrettyNamePlaceholder: {
    'sv-SE': 'Unik URL',
    'en-GB': 'Unique URL',
    'da-DK': 'Unikk URL'
  },
  prettyNameToolTip: {
    'sv-SE':
      'Endast A-Z, 0-9, och bindestreck, och från 3 till 50 tecken lång.',
    'en-GB':
      'Only A-Z, 0-9, and hyphens, and between 3 and 50 characters long.',
    'da-DK':
      'Må kun bestå af A-Z, 0-9, og bindestreger, samt være mellem 3 og 50 tegn lange.'
  },
  failedToAddPrettyNamesTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToAddPrettyNamesMessage: {
    'sv-SE':
      'Det gick inte att lägga till de nya URL:erna. Detta beror troligtvis på att någon utav URL:erna redan existerar.',
    'en-GB':
      'It was not possible to add new URLs. This is probably because one of the URLs is not unique.',
    'da-DK':
      "Det var ikke muligt at tilføje nye URL'er. Dette skyldes sandsynligvis, at en af URL'erne ikke er unik."
  },
  restaurantAddress: {
    'sv-SE': 'Restaurangens adress',
    'en-GB': "Restaurant's address",
    'da-DK': 'Adressen på restauranten'
  },
  restaurantAddressPlaceholder: {
    'sv-SE': 'Sök efter adressen',
    'en-GB': 'Search for address',
    'da-DK': 'Søg efter adressen'
  },
  location: {
    'sv-SE': 'Plats',
    'en-GB': 'Location',
    'da-DK': 'Sted'
  },
  locationDescription: {
    'sv-SE':
      'Flytta knappnålen till den plats på kartan som din restaurang finns på',
    'en-GB': 'Move the pin to where your restaurant is located at',
    'da-DK':
      'Flyt knappenålen til det sted på kortet, hvor din restaurant ligger'
  },
  street: {
    'sv-SE': 'Gata',
    'en-GB': 'Street',
    'da-DK': 'Gade'
  },
  zipCode: {
    'sv-SE': 'Postnummer',
    'en-GB': 'Zip code',
    'da-DK': 'Postnummer'
  },
  city: {
    'sv-SE': 'Stad',
    'en-GB': 'City',
    'da-DK': 'By'
  },
  allergyMessage: {
    'sv-SE': 'Allergimeddelande',
    'en-GB': 'Allergy message',
    'da-DK': 'Allergimeddelelse'
  },
  allergyMessageDescription: {
    'sv-SE':
      'Välj om det ska vara tillåtet för gäster att fylla i ett allergimeddelande vid beställning.',
    'en-GB':
      'Choose if the guests should be able to leave an allergy message when ordering.',
    'da-DK':
      'Vælg om det skal være tilladt for gæster at udfylde en allergimeddelelse ved bestilling.'
  },
  dangerZone: {
    'sv-SE': 'FAROZONEN',
    'en-GB': 'DANGER ZONE',
    'da-DK': 'FAREZONEN'
  },
  ACTIVE: {
    'sv-SE': 'Aktiv',
    'en-GB': 'Active',
    'da-DK': 'Aktiv'
  },
  PREVIEW: {
    'sv-SE': 'Förhandsvisning',
    'en-GB': 'Preview',
    'da-DK': 'Forhåndsvisning'
  },
  DISABLED: {
    'sv-SE': 'Avstängd',
    'en-GB': 'Disabled',
    'da-DK': 'Deaktiveret'
  },
  visibility: {
    'sv-SE': 'Synlighet',
    'en-GB': 'Visibility',
    'da-DK': 'Synlighed'
  },
  dangerZoneDescription: {
    'sv-SE':
      'VARNING - Denna inställning måste vara "Aktiv" för att din restaurang ska synas för gästerna och kunna ta emot beställningar',
    'en-GB':
      'WARNING - This setting must be set to "Active" for your restaurant to be visible to guests and able to receive orders.',
    'da-DK':
      'ADVARSEL - Denne indstilling skal være sat til "Aktiv" for at din restaurant skal være synlig for gæsterne og kunne modtage bestillinger.'
  },
  savedSuccessfully: {
    'sv-SE': 'Inställningarna har sparats!',
    'en-GB': 'The settings have been saved!',
    'da-DK': 'Indstillingerne er blevet gemt!'
  },
  yes: {
    'sv-SE': 'Ja',
    'en-GB': 'Yes',
    'da-DK': 'Ja'
  },
  no: {
    'sv-SE': 'Nej',
    'en-GB': 'No',
    'da-DK': 'Nej'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Gem'
  },
  goBack: {
    'sv-SE': 'Gå tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Gå tilbage'
  },
  validationErrorTitle: {
    'sv-SE': 'Kunde inte spara inställningarna',
    'en-GB': 'Could not save the settings',
    'da-DK': 'Kunne ikke gemme indstillingerne'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen'
  },
  upload: {
    'sv-SE': 'Ladda upp ny bild',
    'en-GB': 'Upload new image',
    'da-DK': 'Upload nyt billede'
  },
  editCoverImage: {
    'sv-SE': 'Redigera omslagsbild',
    'en-GB': 'Edit cover image',
    'da-DK': 'Rediger forsidebillede'
  },
  uploadCoverImageTitle: {
    'sv-SE': 'Ändra omslagsbild',
    'en-GB': 'Change cover image',
    'da-DK': 'Skift forsidebillede'
  },
  failedToUpdateCoverImageTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToUpdateCoverImageDescription: {
    'sv-SE': 'Det gick inte att uppdatera omslagsbilden. Var god försök igen.',
    'en-GB': 'The cover image could not be updated. Please try again.',
    'da-DK': 'Forsidebilledet kunne ikke opdateres. Prøv igen.'
  },
  uploadBanner: {
    'sv-SE': 'Ladda upp banner',
    'en-GB': 'Upload banner',
    'da-DK': 'Upload banner'
  },
  editBanner: {
    'sv-SE': 'Redigera banner',
    'en-GB': 'Edit banner',
    'da-DK': 'Rediger banner'
  },
  failedToUploadBannerTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToUploadBannerDescription: {
    'sv-SE': 'Det gick inte att ladda upp menybannern. Var god försök igen.',
    'en-GB': 'The banner could not be uploaded. Please try again.',
    'da-DK': 'Menubanneret kunne ikke indlæses. Prøv igen.'
  },
  typeOfBanner: {
    'sv-SE': 'Välj typ av banner*',
    'en-GB': 'Choose type of banner*',
    'da-DK': 'Vælg type banner*'
  },
  basicBanner: {
    'sv-SE': 'Vanlig banner',
    'en-GB': 'Non-interactive banner',
    'da-DK': 'Almindelig banner'
  },
  linkedBanner: {
    'sv-SE': 'Banner med extern länk',
    'en-GB': 'Banner with external link',
    'da-DK': 'Banner med eksternt link'
  },
  link: {
    'sv-SE': 'Länk*',
    'en-GB': 'Link*',
    'da-DK': 'Link*'
  },
  url: {
    'sv-SE': 'URL',
    'en-GB': 'URL',
    'da-DK': 'URL'
  },
  changeOrder: {
    'sv-SE': 'Ändra ordning',
    'en-GB': 'Change order',
    'da-DK': 'Ændre rækkefølge'
  },
  saveOrder: {
    'sv-SE': 'Spara ordning',
    'en-GB': 'Save order',
    'da-DK': 'Gem rækkefølge'
  }
};

export const SELLER_DETAILS_STRINGS = {
  businessDetails: {
    'sv-SE': 'FÖRETAGSINFORMATION',
    'en-GB': 'BUSINESS DETAILS',
    'da-DK': 'VIRKSOMHED INFORMATION'
  },
  corporateName: {
    'sv-SE': 'Företagsnamn*',
    'en-GB': 'Corporate name*',
    'da-DK': 'Firmanavn*'
  },
  corporateIdentificationNumber: {
    'sv-SE': 'Organisationsnummer*',
    'en-GB': 'Corporate identification number*',
    'da-DK': 'CVR-nummer'
  },
  vatIdentificationNumber: {
    'sv-SE': 'Momsregistreringsnummer*',
    'en-GB': 'Vat identification number*',
    'da-DK': 'Momsregistreringsnummer*'
  },
  phoneNumber: {
    'sv-SE': 'Telefonnummer*',
    'en-GB': 'Phone number*',
    'da-DK': 'Telefonnummer*'
  },
  emailAddress: {
    'sv-SE': 'E-postadress*',
    'en-GB': 'E-mail*',
    'da-DK': 'Email adresse*'
  },
  duplicateCompanyAddress: {
    'sv-SE': 'Faktureringsadressen är samma som besöksadressen',
    'en-GB': "Billing address is the same as the restaurant's address",
    'da-DK': 'Faktureringsadressen er den samme som besøgsadressen'
  },
  street: {
    'sv-SE': 'Gata*',
    'en-GB': 'Street*',
    'da-DK': 'Gade*'
  },
  zipCode: {
    'sv-SE': 'Postnummer*',
    'en-GB': 'Zip code*',
    'da-DK': 'Postnummer*'
  },
  city: {
    'sv-SE': 'Stad*',
    'en-GB': 'City*',
    'da-DK': 'By*'
  },
  country: {
    'sv-SE': 'Land*',
    'en-GB': 'Country*',
    'da-DK': 'Land*'
  },
  paymentInformation: {
    'sv-SE': 'KÖPINFORMATION',
    'en-GB': 'PAYMENT INFORMATION',
    'da-DK': 'KØBSOPLYSNINGER'
  },
  linkToTerms: {
    'sv-SE': 'Länk till köpvillkor*',
    'en-GB': 'Link to purchase terms*',
    'da-DK': 'Link til købsbetingelser*'
  },
  SE: {
    'sv-SE': 'Sverige',
    'en-GB': 'Sweden',
    'da-DK': 'Sverige'
  },
  NO: {
    'sv-SE': 'Norge',
    'en-GB': 'Norway',
    'da-DK': 'Norge'
  },
  DK: {
    'sv-SE': 'Danmark',
    'en-GB': 'Denmark',
    'da-DK': 'Danmark'
  },
  currency: {
    'sv-SE': 'Valuta*',
    'en-GB': 'Currency*',
    'da-DK': 'Valuta*'
  },
  sek: {
    'sv-SE': 'Svenska kronor (SEK)',
    'en-GB': 'Swedish crowns (SEK)',
    'da-DK': 'Svenske kroner (SEK)'
  },
  dkk: {
    'sv-SE': 'Danska kronor (DKK)',
    'en-GB': 'Danish crowns (DKK)',
    'da-DK': 'Danske kroner (DKK)'
  },
  nok: {
    'sv-SE': 'Norska kronor (NOK)',
    'en-GB': 'Norwegian crowns (NOK)',
    'da-DK': 'Norske kroner (NOK)'
  },
  language: {
    'sv-SE': 'Språk*',
    'en-GB': 'Language*',
    'da-DK': 'Sprog*'
  },
  swedish: {
    'sv-SE': 'Svenska',
    'en-GB': 'Swedish',
    'da-DK': 'Svensk'
  },
  danish: {
    'sv-SE': 'Danska',
    'en-GB': 'Danish',
    'da-DK': 'Dansk'
  },
  norwegian: {
    'sv-SE': 'Norska',
    'en-GB': 'Norwegian',
    'da-DK': 'Norsk'
  },
  english: {
    'sv-SE': 'Engelska',
    'en-GB': 'English',
    'da-DK': 'Engelsk'
  },
  productRegister: {
    'sv-SE': 'Produktregister*',
    'en-GB': 'Product register*',
    'da-DK': 'Produktregister*'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  savedSuccessfully: {
    'sv-SE': 'Inställningarna har sparats!',
    'en-GB': 'The settings have been saved!',
    'da-DK': 'Indstillingerne er blevet gemt!'
  },
  validationErrorTitle: {
    'sv-SE': 'Kunde inte spara inställningarna',
    'en-GB': 'Could not save the settings',
    'da-DK': 'Kunne ikke gemme indstillingerne'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  }
};

export const DELIVERY_LOCATIONS_STRINGS = {
  ...COMMON_FORM_STRINGS,
  noDeliveryLocations: {
    'sv-SE': 'Inga serveringssätt hittades',
    'en-GB': 'No service setups found',
    'da-DK': 'Ingen serveringsmetoder fundet'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  deliveryFlowType: {
    'sv-SE': 'Typ',
    'en-GB': 'Type',
    'da-DK': 'Type'
  },
  open: {
    'sv-SE': 'Aktiv',
    'en-GB': 'Enabled',
    'da-DK': 'Aktiv'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  PICK_UP: {
    'sv-SE': 'Upphämtning',
    'en-GB': 'Pick up',
    'da-DK': 'Afhentning'
  },
  TABLE_DELIVERY: {
    'sv-SE': 'Bordsservering',
    'en-GB': 'Table delivery',
    'da-DK': 'Bordsservering'
  },
  TAKE_AWAY: {
    'sv-SE': 'Take away',
    'en-GB': 'Take away',
    'da-DK': 'Take away'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  failedToOpenCloseTitle: {
    'sv-SE': 'Misslyckades att aktivera/inaktivera serveringssätt',
    'en-GB': 'Failed to enable/disable service setup',
    'da-DK': 'Kunne ikke aktivere/deaktivere serveringsmåde'
  },
  failedToOpenCloseMessage: {
    'sv-SE':
      'Det gick inte att ändra status för serveringssättet. Vänligen försök igen.',
    'en-GB':
      'It was not possible to change the status of the service setup. Please try again.',
    'da-DK':
      'Det var ikke muligt at ændre status for serveringsmåde på restauranten. Prøv igen.'
  },
  deleteTitle: {
    'sv-SE': 'Ta bort serveringssätt',
    'en-GB': 'Delete service setup',
    'da-DK': 'Fjern serveringsmåde'
  },
  deleteMessage: {
    'sv-SE': 'Är du säker på att du vill ta bort serveringssättet "$$name$$"?`',
    'en-GB': 'Are you sure you want to delete the service setup "$$name$$"?',
    'da-DK': 'Er du sikker på, at du vil fjerne serveringsmåden "$$name$$"?`'
  },
  edit: {
    'sv-SE': 'Redigera serveringssätt',
    'en-GB': 'Edit service setup',
    'da-DK': 'Rediger serveringsmåde'
  },
  delete: {
    'sv-SE': 'Ta bort serveringssätt',
    'en-GB': 'Delete service setup',
    'da-DK': 'Fjern serveringsmåde'
  },
  failedToDeleteTitle: {
    'sv-SE': 'Misslyckades att ta bort serveringssättet',
    'en-GB': 'Failed to delete service setup',
    'da-DK': 'Kunne ikke fjerne serveringsmåden'
  },
  failedToDeleteMessage: {
    'sv-SE':
      'Det gick inte att ta bort serveringssättet. Vänligen försök igen.',
    'en-GB':
      'It was not possible to delete the service setup. Please try again.',
    'da-DK': 'Serveringsmåden kunne ikke fjernes. Prøv igen.'
  },
  createNew: {
    'sv-SE': 'Skapa ny',
    'en-GB': 'Create new',
    'da-DK': 'Opret ny'
  },
  newDeliveryLocation: {
    'sv-SE': 'Nytt serveringssätt',
    'en-GB': 'New service setup',
    'da-DK': 'Ny serveringsmåde'
  },
  enableEntryTickets: {
    'sv-SE': 'Aktivera biljetter',
    'en-GB': 'Enable entry tickets',
    'da-DK': 'Aktiver billetter'
  },
  editDeliveryLocation: {
    'sv-SE': 'Redigera serveringssätt',
    'en-GB': 'Edit service setup',
    'da-DK': 'Rediger serveringsmåde'
  },
  description: {
    'sv-SE': 'Beskrivning',
    'en-GB': 'Description',
    'da-DK': 'Beskrivelse'
  },
  userChoices: {
    'sv-SE': 'Val för användaren vid beställning',
    'en-GB': 'User choices',
    'da-DK': 'Valg for brugeren ved bestilling'
  },
  noChoices: {
    'sv-SE': 'Inga val',
    'en-GB': 'No choices',
    'da-DK': 'Ingen valg'
  },
  selectTable: {
    'sv-SE': 'Val av bord',
    'en-GB': 'Table number',
    'da-DK': 'Valg af bord'
  },
  selectDeliveryTime: {
    'sv-SE': 'Val av leveranstid',
    'en-GB': 'Delivery time',
    'da-DK': 'Valg af leveringstid'
  },
  enableTabs: {
    'sv-SE': 'Aktivera notor för serveringssätt i terminal',
    'en-GB': 'Enable tabs for delivery location in terminal',
    'da-DK': 'Aktivere reknelser for serveringsmåde in terminal'
  },
  catalogChoices: {
    'sv-SE': 'Menyval',
    'en-GB': 'Catalog options',
    'da-DK': 'Menuvalg'
  },
  availableOnAllCatalogs: {
    'sv-SE': 'Tillgänglig på alla menyer',
    'en-GB': 'Available on all catalogs',
    'da-DK': 'Tilgængelig på alle menuer'
  },
  availableOnSelectedCatalogs: {
    'sv-SE': 'Begränsat antal menyer',
    'en-GB': 'Available on specific catalogs',
    'da-DK': 'Begrænset antal menuer'
  },
  autoConfirmChoices: {
    'sv-SE': 'Hantering i WEIQ Hub',
    'en-GB': 'Options for WEIQ Hub',
    'da-DK': 'Håndtering i WEIQ Hub'
  },
  autoConfirmEnabled: {
    'sv-SE': 'Beställningar accepteras automatiskt',
    'en-GB': 'Orders are accepted automatically',
    'da-DK': 'Ordrer accepteres automatisk'
  },
  autoConfirmDisabled: {
    'sv-SE': 'Alla beställningar måste accepteras',
    'en-GB': 'All orders must be accepted',
    'da-DK': 'Alle ordrer skal accepteres'
  },
  addNewTable: {
    'sv-SE': 'Lägg till:',
    'en-GB': 'Add:',
    'da-DK': 'Tilføj:'
  },
  addTablePlaceholder: {
    'sv-SE': 'Skriv för att lägga till bord...',
    'en-GB': 'Type to add a table...',
    'da-DK': 'Skriv for at tilføje bord...'
  },
  typeToAdd: {
    'sv-SE': 'Skriv för att lägga till',
    'en-GB': 'Type to add',
    'da-DK': 'Skriv for at tilføje'
  },
  selectSuggestedDuration: {
    'sv-SE': 'Välj leveranstider',
    'en-GB': 'Select delivery times',
    'da-DK': 'Vælg leveringstider'
  },
  selectCatalogs: {
    'sv-SE': 'Välj menyer',
    'en-GB': 'Select catalogs',
    'da-DK': 'Vælg menuer'
  },
  failedToSaveTitle: {
    'sv-SE': 'Ett fel uppstod',
    'en-GB': 'An error occured',
    'da-DK': 'En fejl opstod'
  },
  failedToSaveMessage: {
    'sv-SE': 'Dina ändringar kunde inte sparas. Vänligen försök igen.',
    'en-GB': 'Your changes could not be saved. Please try again.',
    'da-DK': 'Dine ændringer kunne ikke gemmes. Prøv igen.'
  },
  prefixPlaceholder: {
    'sv-SE': 'Prefix - Ex. Bord',
    'en-GB': 'Prefix - Ex. Table',
    'da-DK': 'Præfiks - Eks. Bord'
  },
  intervalPlaceholder: {
    'sv-SE': 'Ex. 1-5,10-15',
    'en-GB': 'Ex. 1-5,10-15',
    'da-DK': 'Eks. 1-5,10-15'
  },
  addInterval: {
    'sv-SE': 'Lägg till intervall',
    'en-GB': 'Add interval',
    'da-DK': 'Tilføj interval'
  },
  allowInstantDeliveryTime: {
    'sv-SE': 'Tillåt tidsvalet "snarast"',
    'en-GB': 'Allow choice "ASAP"',
    'da-DK': 'Tillad tidsvalg "snarest"'
  },
  allowCustomDeliveryTime: {
    'sv-SE': 'Tillåt fria tidsval',
    'en-GB': 'Allow custom delivery time',
    'da-DK': 'Tillad tilpasset leveringstid'
  },
  intervalMinMaxErrorTitle: {
    'sv-SE': 'Ogiltigt intervall',
    'en-GB': 'Invalid interval',
    'da-DK': 'Ugyldigt interval'
  },
  intervalMinMaxErrorMessage: {
    'sv-SE':
      'Fel i följande intervall: "$$interval$$". Startnumret måste vara lägre än slutnumret.',
    'en-GB':
      'Error in the following interval: "$$interval$$. Start number must be lower than end number.',
    'da-DK':
      'Fejl i følgende interval: "$$interval$$". Startnummeret skal være lavere end slutnummeret.'
  },
  intervalTooLargeTitle: {
    'sv-SE': 'För stort intervall',
    'en-GB': 'Too large interval',
    'da-DK': 'For stort interval'
  },
  intervalTooLargeMessage: {
    'sv-SE': 'Det går inte att lägga till mer än 1000 bord åt gången.',
    'en-GB': 'It is not possible to add more than 1000 tables at a time.',
    'da-DK': 'Det er ikke muligt at tilføje mere end 1000 borde ad gangen.'
  },
  newDeliveryLocationButton: {
    'sv-SE': 'Nytt serveringssätt',
    'en-GB': 'New service setup',
    'da-DK': 'Ny serveringsmåde'
  },
  enableEntryTicketsButton: {
    'sv-SE': 'Aktivera biljetter',
    'en-GB': 'Enable entry tickets',
    'da-DK': 'Aktiver billetter'
  },
  codeInput: {
    'sv-SE': 'Kod (4 tecken)',
    'en-GB': 'Code (4 characters)',
    'da-DK': 'Kode (4 tegn)'
  },
  validationSkin: {
    'sv-SE': 'Kontrollbild',
    'en-GB': 'Validation image',
    'da-DK': 'Kontrolbillede'
  },
  selectValidationSkin: {
    'sv-SE': 'Välj en kontrollbild',
    'en-GB': 'Select a validation image',
    'da-DK': 'Vælg et kontrolbillede'
  },
  image: {
    'sv-SE': 'Bild',
    'en-GB': 'Image',
    'da-DK': 'Billede'
  },
  autoCaptureDelayLabel: {
    'sv-SE': 'Automatisk utlämning av beställningar',
    'en-GB': 'Automatically deliver orders',
    'da-DK': 'Automatisk levering af ordrer'
  },
  noAutoCaptureDelay: {
    'sv-SE': 'Aldrig (manuell utlämning)',
    'en-GB': 'Never (manual delivery)',
    'da-DK': 'Aldrig (manuel levering)'
  },
  PT1H: {
    'sv-SE': 'Efter 1 timme',
    'en-GB': 'After 1 hour',
    'da-DK': 'Efter 1 time'
  },
  PT3H: {
    'sv-SE': 'Efter 3 timmar',
    'en-GB': 'After 3 hours',
    'da-DK': 'Efter 3 timer'
  },
  PT6H: {
    'sv-SE': 'Efter 6 timmar',
    'en-GB': 'After 6 hours',
    'da-DK': 'Efter 6 timer'
  },
  PT8H: {
    'sv-SE': 'Efter 8 timmar',
    'en-GB': 'After 8 hours',
    'da-DK': 'Efter 8 timer'
  },
  PT24H: {
    'sv-SE': 'Efter 24 timmar',
    'en-GB': 'After 24 hours',
    'da-DK': 'Efter 24 timer'
  }
};

export const INTEGRATIONS_STRINGS = {
  integration: {
    'sv-SE': 'Integration',
    'en-GB': 'Integration',
    'da-DK': 'Integration'
  },
  activeIntegrations: {
    'sv-SE': 'Aktiva integrationer',
    'en-GB': 'Active integrations',
    'da-DK': 'Aktive integrationer'
  },
  availableIntegrations: {
    'sv-SE': 'Tillgängliga integrationer',
    'en-GB': 'Available integrations',
    'da-DK': 'Tilgængelige integrationer'
  },
  srv4pos_integration: {
    'sv-SE': 'Kontrollenhet & kassaregister',
    'en-GB': 'Control unit & cash register',
    'da-DK': 'Kontrolenhed & kasseapparat'
  },
  srv4PosDescription: {
    'sv-SE':
      'Skaffa en digital kontrollenhet som är certifierad av Skatteverket.',
    'en-GB':
      'Acquire a digital control unit that is certified by the Swedish Tax Agency.',
    'da-DK':
      'Få en digital kontrolenhed, der er certificeret af den svenske skattemyndighed.'
  },
  fortnox_integration: {
    'sv-SE': 'Fortnox',
    'en-GB': 'Fortnox',
    'da-DK': 'Fortnox'
  },
  fortnoxDescription: {
    'sv-SE':
      'Exportera bokföringen till Fortnox automatiskt och slipp allt trassel.',
    'en-GB':
      'Automatically export your bookkeeping to Fortnox, and avoid all the hassle. ',
    'da-DK': 'Eksporter regnskabet til Fortnox automatisk og undgå alt rod.'
  },
  onslip_integration: {
    'sv-SE': 'Onslip',
    'en-GB': 'Onslip',
    'da-DK': 'Onslip'
  },
  onslipDescription: {
    'sv-SE':
      'Integrera med din Onslip-kassa och samla all försäljningsdata på ett ställe.',
    'en-GB':
      'Integrate with your Onslip cash register and gather all sales data in one place.',
    'da-DK': 'Integrer med din Onslip-kasse og saml alle salgsdata ét sted.'
  },
  winpos_integration: {
    'sv-SE': 'Winpos',
    'en-GB': 'Winpos',
    'da-DK': 'Winpos'
  },
  winposDescription: {
    'sv-SE':
      'Integrera med din Winpos-kassa och samla all försäljningsdata på ett ställe.',
    'en-GB':
      'Integrate with your Winpos cash register and gather all sales data in one place.',
    'da-DK':
      'Integrer med dit Winpos kasseapparat og saml alle salgsdata ét sted.'
  },
  caspeco_integration: {
    'sv-SE': 'Caspeco',
    'en-GB': 'Caspeco',
    'da-DK': 'Caspeco'
  },
  caspecoDescription: {
    'sv-SE':
      'Integrera med Caspeco och samla all försäljningsdata på ett ställe.',
    'en-GB': 'Integrate with Caspeco and gather all sales data in one place.',
    'da-DK': 'Integrer med Caspeco og saml alle salgsdata ét sted.'
  },
  personalkollen_integration: {
    'sv-SE': 'Personalkollen',
    'en-GB': 'Personalkollen',
    'da-DK': 'Personalkollen'
  },
  personalkollenDescription: {
    'sv-SE':
      'Integrera med Personalkollen och samla all försäljningsdata på ett ställe.',
    'en-GB':
      'Integrate with Personalkollen and gather all sales data in one place.',
    'da-DK': 'Integrer med Personalkollen og saml alle salgsdata ét sted.'
  },
  intermail_integration: {
    'sv-SE': 'Intermail',
    'en-GB': 'Intermail',
    'da-DK': 'Intermail'
  },
  intermailDescription: {
    'sv-SE':
      'Skaffa ett lojalitetsystem hos InterMail och koppla ihop det med WEIQ.',
    'en-GB': 'Acquire a loyalty system from Intermail and connect it to WEIQ.',
    'da-DK': 'Få et loyalitetssystem med InterMail og tilslut det med WEIQ.'
  },
  active: {
    'sv-SE': 'Anslutning aktiv',
    'en-GB': 'Integration enabled',
    'da-DK': 'Forbindelse aktiv'
  },
  notAvailable: {
    'sv-SE': 'Inte tillgänglig för dig',
    'en-GB': 'Not available for you',
    'da-DK': 'Ikke tilgængelig for dig'
  },
  purpose: {
    'sv-SE': 'Typ',
    'en-GB': 'Type',
    'da-DK': 'Type'
  },
  ippCashRegisterPurpose: {
    'sv-SE': 'Kassaregister för IPP',
    'en-GB': 'Cash register for IPP',
    'da-DK': 'Kasseapparat IPP'
  },
  terminal: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  app: {
    'sv-SE': 'App',
    'en-GB': 'App',
    'da-DK': 'App'
  },
  controlUnitsTitle: {
    'sv-SE': 'Kontrollenheter',
    'en-GB': 'Control units',
    'da-DK': 'Kontrolenheder'
  },
  controlUnitsDescription: {
    'sv-SE':
      'Alla kontrollenheter nedan måste registreras hos skatteverket. För hjälp, se vårt ',
    'en-GB':
      'All control units below must be registered with the tax agency. For assistance, see our ',
    'da-DK':
      'Alle kontrolenheder nedenfor skal registreres hos skattevæsenet. For hjælp, se vores '
  },
  cashRegisterTitle: {
    'sv-SE': 'Kassaregister',
    'en-GB': 'Cash registers',
    'da-DK': 'Kasseapparater'
  },
  cashRegisterDescription: {
    'sv-SE':
      'Alla kassaregister nedan måste registreras hos skatteverket. För hjälp, se vårt ',
    'en-GB':
      'All cash registers below must be registered with the tax agency. For assistance, see our ',
    'da-DK':
      'Alle kasseapparater nedenfor skal registreres hos skattevæsenet. For hjælp, se vores '
  },
  helpCenter: {
    'sv-SE': 'hjälpcenter',
    'en-GB': 'help center',
    'da-DK': 'hjælpecenter'
  },
  cashRegisterName: {
    'sv-SE': 'Beteckning',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  productionNumber: {
    'sv-SE': 'Tillverkningsnummer',
    'en-GB': 'Manufacturing number',
    'da-DK': 'Fremstillingsnummer'
  },
  connectedControlUnit: {
    'sv-SE': 'Kopplad kontrollenhet',
    'en-GB': 'Connected control unit',
    'da-DK': 'Tilsluttet styreenhed'
  },
  nonActiveTerminalsWarning: {
    'sv-SE': '⚠️ Varning - Ni har terminaler som ej är aktiverade ⚠️',
    'en-GB': '⚠️ Warning - There are terminals that are not activated ⚠️',
    'da-DK': '⚠️ Varning - Der er terminaler, der ikke er aktiveret ⚠️'
  },
  activateTerminal: {
    'sv-SE': 'Aktivera',
    'en-GB': 'Activate',
    'da-DK': 'Aktivere'
  },
  controlUnit: {
    'sv-SE': 'Kontrollenhet',
    'en-GB': 'Control unit',
    'da-DK': 'Kontrolenhed'
  },
  address: {
    'sv-SE': 'Adress',
    'en-GB': 'Address',
    'da-DK': 'Adresse'
  },
  controlUnitCompany: {
    'sv-SE': 'Obehosting AB',
    'en-GB': 'Obehosting AB',
    'da-DK': 'Obehosting AB'
  },
  controlUnitAddress: {
    'sv-SE': 'Torshamnsgatan 48',
    'en-GB': 'Torshamnsgatan 48',
    'da-DK': 'Torshamnsgatan 48'
  },
  controlUnitPostalAddress: {
    'sv-SE': '16440 Kista',
    'en-GB': '16440 Kista',
    'da-DK': '16440 Kista'
  },
  registerControlUnit: {
    'sv-SE': 'Registrera kontrollenhet för ECOM med följande information:',
    'en-GB': 'Register control unit for ECOM with the following information:',
    'da-DK': 'Registrer kontrolenhed til ECOM med følgende oplysninger:'
  },
  corporateIdentificationNumber: {
    'sv-SE': 'Organisationsnummer',
    'en-GB': 'Corporate identification number',
    'da-DK': 'CVR-nummer'
  },
  currency: {
    'sv-SE': 'Valuta',
    'en-GB': 'Currency',
    'da-DK': 'Valuta'
  },
  registerControlunitButton: {
    'sv-SE': 'Registrera kontrollenhet',
    'en-GB': 'Register control unit',
    'da-DK': 'Registrer kontrolenhed'
  },
  failedToRegisterControlUnitTitle: {
    'sv-SE': 'Kunde inte registrera kontrollenheten',
    'en-GB': 'Failed to register control unit',
    'da-DK': 'Kunne ikke registrere kontrolenheden'
  },
  failedToRegisterControlUnitMessage: {
    'sv-SE':
      'Misslyckades att registrera kontrollenheten. Vänligen försök igen.',
    'en-GB': 'Failed to register the control unit. Pleas try again.',
    'da-DK': 'Kunne ikke registrere kontrolenheden. Prøv igen.'
  },
  contactSupportTitle: {
    'sv-SE': 'Denna integration kan endast hanteras av WEIQ Support.',
    'en-GB': 'This integration can only be managed by WEIQ Support.',
    'da-DK': 'Denne integration kan kun håndteres af WEIQ Support.'
  },
  contactSupportDescription: {
    'sv-SE':
      'Hör av dig till din kontaktperson på WEIQ eller skicka ett mail till $$SUPPORT_EMAIL$$ för att diskutera integrationen.',
    'en-GB':
      'Get in touch with your contact person at WEIQ or send an e-mail to $$SUPPORT_EMAIL$$ to discuss the integration.',
    'da-DK':
      'Kontakt din kontaktperson hos WEIQ eller send en e-mail til $$SUPPORT_EMAIL$$ for at diskutere integrationen.'
  },
  instructions: {
    'sv-SE': 'Instruktioner',
    'en-GB': 'Instructions',
    'da-DK': 'Instruktioner'
  },
  fortnoxInstructions1: {
    'sv-SE':
      'För att skapa kopplingen mellan WEIQ och Fortnox måste du tillåta WEIQ att komma åt din bokföring.',
    'en-GB':
      'In order to create the integration between WEIQ and Fortnox, you must allow WEIQ to access your bookkeeping.',
    'da-DK':
      'For at oprette forbindelsen mellem WEIQ og Fortnox skal du give WEIQ adgang til dit regnskab.'
  },
  fortnoxInstructions2: {
    'sv-SE': 'Följ stegen nedan för att sätta upp kopplingen:',
    'en-GB': 'Follow the steps below to configure the integration:',
    'da-DK': 'Følg nedenstående trin for at konfigurere forbindelse:'
  },
  fortnoxInstructions3: {
    'sv-SE': 'Klicka på knappen "Aktivera koppling".',
    'en-GB': 'Click on the button "Enable integration".',
    'da-DK': 'Klik på knappen "Aktiver forbindelse".'
  },
  fortnoxInstructions4: {
    'sv-SE':
      'Du kommer då tas till Fortnox där du får logga in och godkänna att WEIQ kommer åt din bokföring.',
    'en-GB':
      'You will then be redirected to Fortnox, where you can log in and give WEIQ permission to access your bookkeeping.',
    'da-DK':
      'Du bliver herefter ført til Fortnox, hvor du kan logge ind og godkende WEIQs adgang til dit regnskab.'
  },
  fortnoxInstructions5: {
    'sv-SE': 'Allt klart. Du får en bekräftelse på att kopplingen är skapad.',
    'en-GB':
      'Everything is done. You will receive a confirmation that the integration has been created.',
    'da-DK':
      'Alt færdigt. Du modtager en bekræftelse på, at forbindelsen er oprettet.'
  },
  enableIntegration: {
    'sv-SE': 'Aktivera koppling',
    'en-GB': 'Enable integration',
    'da-DK': 'Aktiver forbindelse'
  },
  fortnoxIsEnabled: {
    'sv-SE': 'WEIQ är kopplat till Fortnox för bokföring',
    'en-GB': 'WEIQ is integrated with Fortnox for bookkeeping',
    'da-DK': 'WEIQ er forbundet med Fortnox til regnskab'
  },
  integrationCreatedAt: {
    'sv-SE': 'Integration skapad',
    'en-GB': 'Integration created at',
    'da-DK': 'Integration oprettet'
  },
  fortnoxAlertTitle: {
    'sv-SE': 'Transaktioner exporteras till Fortnox vid dagsavslut',
    'en-GB': 'Transactions are exported to Fortnox with each daily report ',
    'da-DK': 'Transaktioner eksporteres til Fortnox med hver daglig rapport'
  },
  fortnoxAlertMessage: {
    'sv-SE':
      'Dagsavslut kan göras manuellt i WEIQ Hub eller automatiskt vid midnatt. Fråga din kontakt på WEIQ om du vill aktivera automatiska dagsavslut.',
    'en-GB':
      'Daily reports can be generated manually in WEIQ Hub, or automatically at midnight. Get in touch with your contact person at WEIQ to enable automatic daily reports.',
    'da-DK':
      'Daglige rapporter kan genereres manuelt i WEIQ Hub eller automatisk ved midnat. Kom i kontakt med din kontaktperson hos WEIQ for at aktivere automatiske daglige rapporter.'
  },
  systemName: {
    'sv-SE': 'Systemnamn*',
    'en-GB': 'System name*',
    'da-DK': 'Systemnavn*'
  },
  companyName: {
    'sv-SE': 'Företagsnamn*',
    'en-GB': 'Company name*',
    'da-DK': 'Virksomhedsnavn*'
  },
  corporateIdNumber: {
    'sv-SE': 'Organisationsnummer*',
    'en-GB': 'Corporate identification number*',
    'da-DK': 'Organisationsnummer*'
  },
  computerName: {
    'sv-SE': 'Computer name*',
    'en-GB': 'Computer name*',
    'da-DK': 'Computer name*'
  },
  guid: {
    'sv-SE': 'GUID*',
    'en-GB': 'GUID*',
    'da-DK': 'GUID*'
  },
  url: {
    'sv-SE': 'URL*',
    'en-GB': 'URL*',
    'da-DK': 'URL*'
  },
  username: {
    'sv-SE': 'Username*',
    'en-GB': 'Username*',
    'da-DK': 'Username*'
  },
  password: {
    'sv-SE': 'Password*',
    'en-GB': 'Password*',
    'da-DK': 'Password*'
  },
  cashier: {
    'sv-SE': 'Cashier*',
    'en-GB': 'Cashier*',
    'da-DK': 'Cashier*'
  },
  stockCenter: {
    'sv-SE': 'Stock center*',
    'en-GB': 'Stock center*',
    'da-DK': 'Stock center*'
  },
  profitCenter: {
    'sv-SE': 'Profit center*',
    'en-GB': 'Profit center*',
    'da-DK': 'Profit center*'
  },
  paymentNumber: {
    'sv-SE': 'Payment method number*',
    'en-GB': 'Payment method number*',
    'da-DK': 'Payment method number*'
  },
  tipPosTransfer: {
    'sv-SE': 'Tip pos transfer',
    'en-GB': 'Tip pos transfer',
    'da-DK': 'Tip pos transfer'
  },
  terminalId: {
    'sv-SE': 'Terminal id',
    'en-GB': 'Terminal id',
    'da-DK': 'Terminal id'
  },
  realm: {
    'sv-SE': 'Realm*',
    'en-GB': 'Realm*',
    'da-DK': 'Realm*'
  },
  keyIdentifier: {
    'sv-SE': 'Key identifier*',
    'en-GB': 'Key identifier*',
    'da-DK': 'Key identifier*'
  },
  key: {
    'sv-SE': 'Key*',
    'en-GB': 'Key*',
    'da-DK': 'Key*'
  },
  deliveryLocationId: {
    'sv-SE': 'Delivery location id*',
    'en-GB': 'Delivery location id*',
    'da-DK': 'Delivery location id*'
  },
  secret: {
    'sv-SE': 'Secret*',
    'en-GB': 'Secret*',
    'da-DK': 'Secret*'
  },
  externalStoreId: {
    'sv-SE': 'External store id*',
    'en-GB': 'External store id*',
    'da-DK': 'External store id*'
  },
  createIntegration: {
    'sv-SE': 'Skapa integration',
    'en-GB': 'Create integration',
    'da-DK': 'Skab integration'
  },
  integrationCreated: {
    'sv-SE': 'Integrationen skapades',
    'en-GB': 'Integration created',
    'da-DK': 'Integration oprettet'
  },
  dataToSendToCaspeco: {
    'sv-SE': 'Glöm inte att skicka följande data i mail till Caspeco:',
    'en-GB': "Don't forget to email the data to Caspeco:",
    'da-DK': 'Glem ikke at sende følgende data til Caspeco:'
  },
  failedToSetUpIntegration: {
    'sv-SE': 'Kunde inte skapa integration',
    'en-GB': 'Failed to set up integration',
    'da-DK': 'Kunne ikke oprette integration'
  },
  failedToSetUpIntegrationMessage: {
    'sv-SE': 'Misslyckades att sätta up integrationen. Vänligen försök igen.',
    'en-GB': 'Failed to set up the integration. Please try again.',
    'da-DK': 'Kunne ikke oprette integration. Prøv igen.'
  },
  dangerZone: {
    'sv-SE': 'FAROZONEN',
    'en-GB': 'DANGER ZONE',
    'da-DK': 'FAREZONEN'
  },
  deleteIntegration: {
    'sv-SE': 'Ta bort integration',
    'en-GB': 'Delete integration',
    'da-DK': 'Fjern integration'
  },
  reallyDeleteTitle: {
    'sv-SE': 'Är du säker på att du vill ta bort integrationen?',
    'en-GB': 'Are you sure you want to delete the integration?',
    'da-DK': 'Er du sikker på, at du vil fjerne integrationen?'
  },
  reallyDeleteMessage: {
    'sv-SE':
      'Är du helt säker på att du vill ta bort integrationen "$$INTEGRATION$$"?',
    'en-GB':
      'Are you really sure you want to delete the integration "$$INTEGRATION$$"?',
    'da-DK':
      'Er du helt sikker på, at du vil fjerne integrationen "$$INTEGRATION$$"?'
  },
  cancel: {
    'sv-SE': 'Avbryt',
    'en-GB': 'Cancel',
    'da-DK': 'Annuller'
  },
  delete: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Delete',
    'da-DK': 'Fjern'
  }
};

export const FORTNOX_AUTH_RESPONSE_STRINGS = {
  failedToIntegrateTitle: {
    'sv-SE': 'Fortnox-integrationen kunde inte skapas',
    'en-GB': 'The Fortnox integration could not be created',
    'da-DK': 'Fortnox-integrationen kunne ikke oprettes'
  },
  failedToIntegrateMessage: {
    'sv-SE': 'Vänligen försök igen eller kontakta supporten.',
    'en-GB': 'Please try again or contact the support.',
    'da-DK': 'Prøv venligst igen eller kontakt support.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  integrationCreated: {
    'sv-SE': 'Fortnox-integrationen har skapats!',
    'en-GB': 'The Fortnox integration has been created!',
    'da-DK': 'Fortnox-integrationen er blevet oprettet!'
  }
};

const BOOKKEEPING_PAGES_STRINGS = {
  INDEX: {
    'sv-SE': 'Bokföring',
    'en-GB': 'Bookkeeping',
    'da-DK': 'Bogføring'
  },
  EXPORTS: {
    'sv-SE': 'Exporter',
    'en-GB': 'Exports',
    'da-DK': 'Eksporter'
  },
  MAPPINGS: {
    'sv-SE': 'Mappningar',
    'en-GB': 'Mappings',
    'da-DK': 'Forbindelser'
  },
  ACCOUNTS: {
    'sv-SE': 'Kontoplan',
    'en-GB': 'Accounts',
    'da-DK': 'Kontoplan'
  },
  SETTINGS: {
    'sv-SE': 'Inställningar',
    'en-GB': 'Settings',
    'da-DK': 'Indstillinger'
  }
};

export const BOOKKEEPING_EXPORTS_STRINGS = {
  ...BOOKKEEPING_PAGES_STRINGS,
  serial: {
    'sv-SE': 'Serie',
    'en-GB': 'Serial',
    'da-DK': 'Serie'
  },
  transactionDate: {
    'sv-SE': 'Transaktionsdatum',
    'en-GB': 'Transaction date',
    'da-DK': 'Transaktionsdato'
  },
  type: {
    'sv-SE': 'Typ',
    'en-GB': 'Type',
    'da-DK': 'Type'
  },
  zReport: {
    'sv-SE': 'Dagsavslut',
    'en-GB': 'Daily report',
    'da-DK': 'Daglig rapport'
  },
  download: {
    'sv-SE': 'Ladda ner',
    'en-GB': 'Download',
    'da-DK': 'Download'
  },
  FORTNOX: {
    'sv-SE': 'Fortnox',
    'en-GB': 'Fortnox',
    'da-DK': 'Fortnox'
  },
  SIE: {
    'sv-SE': 'SIE',
    'en-GB': 'SIE',
    'da-DK': 'SIE'
  },
  JSON: {
    'sv-SE': 'JSON',
    'en-GB': 'JSON',
    'da-DK': 'JSON'
  },
  CSV: {
    'sv-SE': 'CSV',
    'en-GB': 'CSV',
    'da-DK': 'CSV'
  },
  manualExport: {
    'sv-SE': 'Manuell export',
    'en-GB': 'Manual export',
    'da-DK': 'Manuel eksport'
  },
  manualExportLimitationInformation: {
    'sv-SE': 'Manuella exporter är begränsade till 7 dygn',
    'en-GB': 'Manual exports are limited to 7 days',
    'da-DK': 'Manuel eksport er begrænset til intervaller på 7 dage'
  },
  exportDownloadFailedTitle: {
    'sv-SE': 'Bokföringsexporten kunde inte hämtas',
    'en-GB': 'Could not fetch bookkeeping export',
    'da-DK': 'Regnskabseksporten kunne ikke hentes'
  },
  exportDownloadFailedMessage: {
    'sv-SE':
      'Det gick inte att ladda ner bokföringsexporten. Vänligen försök igen.',
    'en-GB': 'Could not download bookkeeping export. Please try again.',
    'da-DK': 'Regnskabseksporten kunne ikke downloades. Prøv igen.'
  },
  zReportDownloadFailedTitle: {
    'sv-SE': 'Dagsavslutet kunde inte hämtas',
    'en-GB': 'Could not fetch daily report',
    'da-DK': 'Dagsrapporten kunne ikke hentes'
  },
  zReportDownloadFailedMessage: {
    'sv-SE': 'Det gick inte att ladda ner dagsavslutet. Vänligen försök igen.',
    'en-GB': 'Could not download the daily report. Please try again.',
    'da-DK': 'Kunne ikke downloade den daglige rapport. Prøv igen.'
  },
  noExports: {
    'sv-SE': 'Inga bokföringsexporter hittades',
    'en-GB': 'No bookkeeping exports were found',
    'da-DK': 'Der blev ikke fundet nogen regnskabseksport'
  },
  fromDate: {
    'sv-SE': 'Från och med',
    'en-GB': 'From date',
    'da-DK': 'Fra'
  },
  toDate: {
    'sv-SE': 'Till och med',
    'en-GB': 'To date',
    'da-DK': 'Indtil'
  },
  selectTime: {
    'sv-SE': 'Välj tid',
    'en-GB': 'Select time',
    'da-DK': 'Vælg tid'
  },
  createExport: {
    'sv-SE': 'Exportera',
    'en-GB': 'Export',
    'da-DK': 'Eksporter'
  },
  selectType: {
    'sv-SE': 'Välj typ',
    'en-GB': 'Select type',
    'da-DK': 'Vælg type'
  },
  validationErrorTitle: {
    'sv-SE': 'Bokföringsexporten kunde inte skapas',
    'en-GB': 'Failed to create bookkeeping export',
    'da-DK': 'Regnskabseksporten kunne ikke oprettes'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen.',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen.'
  }
};

export const BOOKKEEPING_MAPPINGS_STRINGS = {
  ...BOOKKEEPING_PAGES_STRINGS,
  ...COMMON_FORM_STRINGS,
  product: {
    'sv-SE': 'Produkt',
    'en-GB': 'Product',
    'da-DK': 'Produkt'
  },
  products: {
    'sv-SE': 'Produkter',
    'en-GB': 'Products',
    'da-DK': 'Produkter'
  },
  account: {
    'sv-SE': 'Konto',
    'en-GB': 'Account',
    'da-DK': 'Konto'
  },
  vatAccount: {
    'sv-SE': 'Momskonto',
    'en-GB': 'VAT Account',
    'da-DK': 'momskonto'
  },
  searchPlaceholder: {
    'sv-SE': 'Filtrera',
    'en-GB': 'Filter',
    'da-DK': 'Filtrer'
  },
  updateSelected: {
    'sv-SE': 'Uppdatera markerade',
    'en-GB': 'Update selected',
    'da-DK': 'Opdatering valgt'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  accountMissing: {
    'sv-SE': 'Ej angett',
    'en-GB': 'Missing',
    'da-DK': 'Ikke specificeret'
  },
  setSalesAccount: {
    'sv-SE': 'Sätt försäljningskonto',
    'en-GB': 'Set sales account',
    'da-DK': 'Indstil salgskonto'
  },
  setVatAccount: {
    'sv-SE': 'Sätt momskonto',
    'en-GB': 'Set VAT account',
    'da-DK': 'Indstil momskonto'
  },
  setAccounts: {
    'sv-SE': 'Sätt försäljningskonto och momskonto',
    'en-GB': 'Sett sales account and VAT account',
    'da-DK': 'Indstil salgskonto og momskonto'
  },
  paymentMethods: {
    'sv-SE': 'Betalmetoder',
    'en-GB': 'Payment methods',
    'da-DK': 'Betalingsmetoder'
  },
  specialAccounts: {
    'sv-SE': 'Specialkonton',
    'en-GB': 'Special accounts',
    'da-DK': 'Særlige konti'
  },
  paymentMethod: {
    'sv-SE': 'Betalmetod',
    'en-GB': 'Payment method',
    'da-DK': 'Betalingsmetode'
  },
  PAY_AT_COUNTER: {
    'sv-SE': 'Pay at counter',
    'en-GB': 'Pay at counter',
    'da-DK': 'Pay at counter'
  },
  BAMBORA: {
    'sv-SE': 'Worldline',
    'en-GB': 'Worldline',
    'da-DK': 'Worldline'
  },
  SWISH: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  ADYEN: {
    'sv-SE': 'Adyen',
    'en-GB': 'Adyen',
    'da-DK': 'Adyen'
  },
  edit: {
    'sv-SE': 'Redigera',
    'en-GB': 'Edit',
    'da-DK': 'Rediger'
  },
  type: {
    'sv-SE': 'Typ',
    'en-GB': 'Type',
    'da-DK': 'Type'
  },
  tip: {
    'sv-SE': 'Dricks',
    'en-GB': 'Tip',
    'da-DK': 'Drikkepenge'
  },
  discounts: {
    'sv-SE': 'Rabatter',
    'en-GB': 'Discounts',
    'da-DK': 'Rabatter'
  },
  missingMappingsAlert: {
    'sv-SE':
      'Det finns en eller flera saker som inte har ett bokföringskonto. Se varningar nedan!',
    'en-GB':
      'One or more items are missing a bookkeeping account. See warnings below!',
    'da-DK':
      'Der er en eller flere poster, der ikke har en regnskabskonto. Se advarsler nedenfor!'
  },
  editProductMapping: {
    'sv-SE': 'Välj konton för produkt',
    'en-GB': 'Select accounts for product',
    'da-DK': 'Vælg konti for produktet'
  },
  editProductMappings: {
    'sv-SE': 'Välj konton för produkter',
    'en-GB': 'Select accounts for products',
    'da-DK': 'Vælg konti for produkter'
  },
  editPaymentMethodMapping: {
    'sv-SE': 'Välj konto för betalmetod',
    'en-GB': 'Select account for payment method',
    'da-DK': 'Vælg konto til betalingsmetode'
  },
  editTipMapping: {
    'sv-SE': 'Välj konto för dricks',
    'en-GB': 'Select Tip account',
    'da-DK': 'Vælg konto til drikkepenge'
  },
  editDiscountMapping: {
    'sv-SE': 'Ställ in konto för rabatter',
    'en-GB': 'Configure discount account',
    'da-DK': 'Opret konto for rabatter'
  },
  saveMappingForProducts: {
    'sv-SE': 'Spara ändringar för $nbrOfProducts$ produkter',
    'en-GB': 'Save mappings for $nbrOfProducts$ produkter',
    'da-DK': 'Gem ændringer for $nbrOfProducts$-produkter'
  },
  salesAccount: {
    'sv-SE': 'Försäljningskonto',
    'en-GB': 'Sales account',
    'da-DK': 'Salgskonto'
  },
  selectSalesAccount: {
    'sv-SE': 'Välj försäljningskonto',
    'en-GB': 'Select sales account',
    'da-DK': 'Vælg salgskonto'
  },
  selectVatAccount: {
    'sv-SE': 'Välj momskonto',
    'en-GB': 'Select VAT account',
    'da-DK': 'Vælg momskonto'
  },
  selectAccount: {
    'sv-SE': 'Välj konto',
    'en-GB': 'Select account',
    'da-DK': 'Vælg konto'
  },
  noResultsFound: {
    'sv-SE': 'Inga resultat hittades',
    'en-GB': 'No results found',
    'da-DK': 'Der blev ikke fundet nogen resultater'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  accountName: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  accountNumber: {
    'sv-SE': 'Kontonummer',
    'en-GB': 'Account number',
    'da-DK': 'Kontonummer'
  },
  costCenter: {
    'sv-SE': 'Kostnadsställe',
    'en-GB': 'Cost center',
    'da-DK': 'Omkostningssted'
  },
  failedToSaveTitle: {
    'sv-SE': 'Ett fel uppstod',
    'en-GB': 'An error occured',
    'da-DK': 'En fejl opstod'
  },
  failedToSaveMessage: {
    'sv-SE': 'Dina ändringar kunde inte sparas. Vänligen försök igen.',
    'en-GB': 'Your changes could not be saved. Please try again.',
    'da-DK': 'Dine ændringer kunne ikke gemmes. Prøv igen.'
  }
};

export const BOOKKEEPING_SETTINGS_STRINGS = {
  ...BOOKKEEPING_PAGES_STRINGS,
  ...COMMON_FORM_STRINGS,
  verificationSeries: {
    'sv-SE': 'Verifikationsserie',
    'en-GB': 'Verification series',
    'da-DK': 'Verifikationsserie'
  },
  verificationSeriesPlaceholder: {
    'sv-SE': 'Ange verifikationsserie',
    'en-GB': 'Enter verification series',
    'da-DK': 'Indtast verifikationsserie'
  },
  reportEmailAddresses: {
    'sv-SE':
      'Mailadresser för rapporter (SIE-fil och felmeddelande skickas hit)',
    'en-GB':
      'E-mail address for reports (SIE-files and error messages are sent here)',
    'da-DK':
      'E-mail-adresser til rapporter (SIE-fil og fejlmeddelelse sendes her)'
  },
  reportEmailAddressesPlaceholder: {
    'sv-SE': 'Mailadress',
    'en-GB': 'E-mail address',
    'da-DK': 'Email adresse'
  },
  fiscalYearStart: {
    'sv-SE': 'Första dagen i räkenskapsåret',
    'en-GB': 'Fiscal year start',
    'da-DK': 'Regnskabsårets første dag'
  },
  fiscalYearStartPlaceholder: {
    'sv-SE': '1 Januari',
    'en-GB': 'January 1',
    'da-DK': '1 januar'
  },
  sendSieAsEmail: {
    'sv-SE':
      'Skicka en SIE-fil per mail vid bokföringsexport (gäller ej för direkt integration)',
    'en-GB':
      'Send exported SIE-files by e-mail (not applicable for direct integrations)',
    'da-DK':
      'Send en SIE-fil via e-mail ved regnskabseksport (gælder ikke direkte integration)'
  },
  automaticDailyReports: {
    'sv-SE': 'Generera dagsavslut automatiskt klockan',
    'en-GB': 'Generate daily reports automatically at',
    'da-DK': 'Generer daglige rapport automatisk kl'
  },
  addNewReportEmailPlaceholder: {
    'sv-SE': 'Skriv för att lägga till en ny mailadress ',
    'en-GB': 'Type to add a new e-mail address',
    'da-DK': 'Skriv for at tilføje en ny e-mailadresse'
  },
  addNewReportEmail: {
    'sv-SE': 'Lägg till:',
    'en-GB': 'Add:',
    'da-DK': 'Tilføje:'
  },
  integrationsInfo: {
    'sv-SE': 'Du aktiverar integrationer mot bokföringsprogram under',
    'en-GB': 'You can enable integrations with accounting software on the',
    'da-DK': 'Du aktiverer integrationer mod regnskabsprogrammer under'
  },
  integrationSettings: {
    'sv-SE': 'inställningar',
    'en-GB': 'settings page',
    'da-DK': 'indstillinger'
  },
  savedSuccessfully: {
    'sv-SE': 'Inställningarna har sparats!',
    'en-GB': 'The settings have been saved!',
    'da-DK': 'Indstillingerne er blevet gemt!'
  },
  failedToSaveTitle: {
    'sv-SE': 'Inställningarna kunde inte sparas',
    'en-GB': 'Failed to save settings',
    'da-DK': 'Indstillingerne kunne ikke gemmes'
  },
  failedToSaveMessage: {
    'sv-SE': 'Det gick inte att spara inställningarna. Vänligen försök igen.',
    'en-GB': 'The settings could not be saved. Please try again.',
    'da-DK': 'Indstillingerne kunne ikke gemmes. Prøv igen.'
  }
};

export const BOOKKEEPING_ACCOUNTS_STRINGS = {
  ...BOOKKEEPING_PAGES_STRINGS,
  ...COMMON_FORM_STRINGS,
  accountNumber: {
    'sv-SE': 'Kontonummer',
    'en-GB': 'Account number',
    'da-DK': 'Kontonummer'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  searchPlaceholder: {
    'sv-SE': 'Filtrera',
    'en-GB': 'Filter',
    'da-DK': 'Filtrer'
  },
  manage: {
    'sv-SE': 'Hantera',
    'en-GB': 'Manage',
    'da-DK': 'Håndtere'
  },
  edit: {
    'sv-SE': 'Redigera',
    'en-GB': 'Edit',
    'da-DK': 'Rediger'
  },
  save: {
    'sv-SE': 'Spara',
    'en-GB': 'Save',
    'da-DK': 'Gem'
  },
  costCenter: {
    'sv-SE': 'Kostnadsställe',
    'en-GB': 'Cost center',
    'da-DK': 'Omkostningssted'
  },
  createAccount: {
    'sv-SE': 'Skapa konto',
    'en-GB': 'Create account',
    'da-DK': 'Opret konto'
  },
  noAccounts: {
    'sv-SE': 'Inga konton hittades',
    'en-GB': 'No accounts found',
    'da-DK': 'Ingen konti fundet'
  },
  editAccount: {
    'sv-SE': 'Redigera konto',
    'en-GB': 'Edit account',
    'da-DK': 'Rediger konto'
  },
  failedToSaveTitle: {
    'sv-SE': 'Ett fel uppstod',
    'en-GB': 'An error occured',
    'da-DK': 'En fejl opstod'
  },
  failedToSaveMessage: {
    'sv-SE': 'Dina ändringar kunde inte sparas. Vänligen försök igen.',
    'en-GB': 'Your changes could not be saved. Please try again.',
    'da-DK': 'Dine ændringer kunne ikke gemmes. Prøv igen.'
  },
  accountNumberAlreadyUsed: {
    'sv-SE': 'Kontonumret används redan',
    'en-GB': 'The account number is already used',
    'da-DK': 'Kontonummeret er allerede i brug'
  },
  accountTag: {
    'sv-SE': 'Ska kontot vara ett standardkonto?',
    'en-GB': 'Should the account be a default account?',
    'da-DK': 'Skal kontoen være en standardkonto?'
  },
  DEFAULT_SALES: {
    'sv-SE': 'Standardkonto för försäljning',
    'en-GB': 'Default account for sales',
    'da-DK': 'Standardkonto for salg'
  },
  DEFAULT_VAT: {
    'sv-SE': 'Standardkonto för moms',
    'en-GB': 'Default account for VAT',
    'da-DK': 'Standardkonto for moms'
  },
  defaultSalesAccountAlreadyExists: {
    'sv-SE': 'Det finns redan ett standardkonto för försäljning',
    'en-GB': 'A default sales account already exists',
    'da-DK': 'Der er allerede en standardkonto til salg'
  },
  defaultVatAccountAlreadyExists: {
    'sv-SE': 'Det finns redan ett standardkonto för moms',
    'en-GB': 'A default VAT account already exists',
    'da-DK': 'Der findes allerede en standardkonto for moms'
  }
};

export const ENABLE_BOOKKEEPING_STRINGS = {
  ...BOOKKEEPING_PAGES_STRINGS,
  title: {
    'sv-SE': 'Bokföring är inte aktiverat',
    'en-GB': 'Bookkeeping is not enabled',
    'da-DK': 'Regnskab er ikke aktiveret'
  },
  info1: {
    'sv-SE':
      'Bokföring är inte aktiverat. Du kan läsa mer om funktionerna för bokföring i vårt',
    'en-GB':
      'Bookkeeping is not enabled. You can read more about our bookkeeping features in the',
    'da-DK':
      'Regnskab er ikke aktiveret. Du kan læse mere om regnskabsfunktionerne i vores'
  },
  helpCenter: {
    'sv-SE': 'hjälpcenter',
    'en-GB': 'help center',
    'da-DK': 'hjælpecenter'
  },
  info2: {
    'sv-SE': 'eller kontakta din säljare på WEIQ.',
    'en-GB': 'or reach out to your contact person at WEIQ.',
    'da-DK': 'eller kontakt din sælger på WEIQ.'
  },
  enableBookkeeping: {
    'sv-SE': 'Aktivera bokföring',
    'en-GB': 'Enable bookkeeping',
    'da-DK': 'Aktiver bogføring'
  },
  failedToEnableBookkeepingTitle: {
    'sv-SE': 'Misslyckades att aktivera bokföring',
    'en-GB': 'Failed to enable bookkeeping',
    'da-DK': 'Kunne ikke aktivere regnskab'
  },
  failedToEnableBookkeepingMessage: {
    'sv-SE': 'Det gick inte att aktivera bokföring. Vänligen försök igen.',
    'en-GB': 'Bookkeeping could not be enabled. Please try again.',
    'da-DK': 'Kunne ikke aktivere regnskab. Prøv igen.'
  }
};

const REPORT_PAGES_STRINGS = {
  INDEX: {
    'sv-SE': 'Rapporter',
    'en-GB': 'Reports',
    'da-DK': 'Rapporter'
  },
  SUMMARY_REPORT: {
    'sv-SE': 'Översiktsrapport',
    'en-GB': 'Summary report',
    'da-DK': 'Oversigtsrapport'
  },
  DETAILED_REPORT: {
    'sv-SE': 'Detaljrapport',
    'en-GB': 'Detailed report',
    'da-DK': 'Detaljered rapport'
  },
  PAYOUT_REPORT: {
    'sv-SE': 'Utbetalningar',
    'en-GB': 'Payouts',
    'da-DK': 'Udbetalinger'
  },
  Z_REPORT: {
    'sv-SE': 'Dagsavslut',
    'en-GB': 'Daily reports',
    'da-DK': 'Daglige rapporter'
  }
};
export const SUMMARY_REPORT_STRINGS = {
  ...REPORT_PAGES_STRINGS,
  title: {
    'sv-SE': 'Översiktsrapport',
    'en-GB': 'Summary report',
    'da-DK': 'Overblikksrapport'
  },
  createReport: {
    'sv-SE': 'Skapa översiktsrapport',
    'en-GB': 'Create summary report',
    'da-DK': 'Skab overblikksrapport'
  },
  quantity: {
    'sv-SE': 'Antal',
    'en-GB': 'Quantity',
    'da-DK': 'Antal'
  },
  netValue: {
    'sv-SE': 'Exkl. moms',
    'en-GB': 'Excl. VAT',
    'da-DK': 'Ekskl. moms'
  },
  vatPercentage: {
    'sv-SE': '$rate$% Moms',
    'en-GB': '$rate$% VAT',
    'da-DK': '$rate$% Moms'
  },
  vatValue: {
    'sv-SE': 'Moms',
    'en-GB': 'VAT',
    'da-DK': 'Moms'
  },
  total: {
    'sv-SE': 'Totalt',
    'en-GB': 'Total',
    'da-DK': 'Totalt'
  },
  sum: {
    'sv-SE': 'Summa',
    'en-GB': 'Sum',
    'da-DK': 'Beløb'
  },
  editDate: {
    'sv-SE': 'Ändra datum',
    'en-GB': 'Edit date',
    'da-DK': 'Ændre dato'
  },
  noData: {
    'sv-SE': 'Ingen data finns för den valda tidsperioden',
    'en-GB': 'No data exists for the selected date range',
    'da-DK': 'Der er ingen tilgængelige data for det valgte tidsrum'
  },
  totalGross: {
    'sv-SE': 'Totalt brutto',
    'en-GB': 'Total gross',
    'da-DK': 'Samlet brutto'
  },
  totalTip: {
    'sv-SE': 'Total dricks',
    'en-GB': 'Total tip',
    'da-DK': 'Samlet drikkepeng'
  },
  totalNumberOfPurchases: {
    'sv-SE': 'Totalt antal köp',
    'en-GB': 'Total purchases',
    'da-DK': 'Totalt antal'
  },
  productQuantity: {
    'sv-SE': 'st',
    'en-GB': '',
    'da-DK': 'stk'
  },
  display: {
    'sv-SE': 'Visa',
    'en-GB': 'Display',
    'da-DK': 'Vis'
  },
  to: {
    'sv-SE': 'till',
    'en-GB': 'to',
    'da-DK': 'til'
  },
  summary: {
    'sv-SE': 'ÖVERSIKT',
    'en-GB': 'SUMMARY',
    'da-DK': 'OVERBLIKK'
  },
  SALES: {
    'sv-SE': 'Försäljning',
    'en-GB': 'Sales',
    'da-DK': 'Salg'
  },
  REFUNDS: {
    'sv-SE': 'Returer',
    'en-GB': 'Refunds',
    'da-DK': 'Refunderer'
  },
  TIPS: {
    'sv-SE': 'Dricks',
    'en-GB': 'Tips',
    'da-DK': 'Drikkepenge'
  },
  salesVatRate: {
    'sv-SE': 'FÖRSÄLJNING PER MOMSSATS',
    'en-GB': 'SALES BY VAT RATE',
    'da-DK': 'SALG AF MOMS'
  },
  salesPaymentType: {
    'sv-SE': 'FÖRSÄLJNING PER BETALMETOD',
    'en-GB': 'SALES BY PAYMENT TYPE',
    'da-DK': 'SALG AF BETALINGSMETOD'
  },
  salesPayout: {
    'sv-SE': 'UTBETALNINGAR I PERIODEN',
    'en-GB': 'PAYOUT DURING PERIOD',
    'da-DK': 'BETALINGER I PERIODEN'
  },
  salesCategory: {
    'sv-SE': 'FÖRSÄLJNING PER KATEGORI',
    'en-GB': 'SALES BY CATEGORY',
    'da-DK': 'SALG AF KATEGORI'
  },
  ADYEN_PAYMENT: {
    'sv-SE': 'Kort (Adyen)',
    'en-GB': 'Card (Adyen)',
    'da-DK': 'Kort (Adyen)'
  },
  NO_PAYMENT: {
    'sv-SE': 'Gratisköp',
    'en-GB': 'Free orders',
    'da-DK': 'Gratiskøb'
  },
  BAMBORA_PAYMENT: {
    'sv-SE': 'Kort (Worldline)',
    'en-GB': 'Card (Worldline)',
    'da-DK': 'Kort (Worldline)'
  },
  SWISH_PAYMENT: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  PAY_AT_COUNTER_PAYMENT: {
    'sv-SE': 'Kassaköp',
    'en-GB': 'Pay at counter',
    'da-DK': 'Betal ved kassen'
  },
  TERMINAL: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  CARD: {
    'sv-SE': 'Kort (via app)',
    'en-GB': 'Card (via app)',
    'da-DK': 'Kort (via app)'
  },
  BAMBORA: {
    'sv-SE': 'Kort (Worldline)',
    'en-GB': 'Card (Worldline)',
    'da-DK': 'Kort (Worldline)'
  },
  SWISH: {
    'sv-SE': 'Swish (via app)',
    'en-GB': 'Swish (via app)',
    'da-DK': 'Swish (via app)'
  },
  PAY_AT_COUNTER: {
    'sv-SE': 'Kassaköp',
    'en-GB': 'Pay at counter',
    'da-DK': 'Betal ved kassen'
  },
  payoutInfo: {
    'sv-SE':
      'Betalningarna kommer i regel in till ert konto hos Adyen två arbetsdagar efter faktisk försäljning.',
    'en-GB':
      'The payments usually arrive at your Adyen account two working days after the sale.',
    'da-DK':
      'Betalingerne kommer normalt til din Adyen-konto to hverdage efter salget.'
  },
  payoutInfoTime: {
    'sv-SE':
      'Som standard töms kontot genom utbetalningar tisdagar 00:00 och fredagar 00:00.',
    'en-GB':
      'By default, payouts are made on Tuesdays 00:00 and Fridays 00:00.',
    'da-DK':
      'Som standard foretages udbetalinger tirsdage 00:00 og fredage 00:00.'
  },
  turnover: {
    'sv-SE': 'Omsättning',
    'en-GB': 'Turnover',
    'da-DK': 'Indtægter'
  },
  commission: {
    'sv-SE': 'Avgifter',
    'en-GB': 'Commission',
    'da-DK': 'Afgifter'
  },
  payout: {
    'sv-SE': 'Utbetalt',
    'en-GB': 'Payout',
    'da-DK': 'Utbetaling'
  },
  uncategorized: {
    'sv-SE': 'Okategoriserat',
    'en-GB': 'Uncategorized',
    'da-DK': 'Ukategoriseret'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  downloadFailedTitle: {
    'sv-SE': 'Rapporten kunde inte hämtas',
    'en-GB': 'The report could not be fetched',
    'da-DK': 'Rapporten kunne ikke hentes'
  },
  downloadFailedMessage: {
    'sv-SE':
      'Det gick inte att ladda ner översiktsrapporten. Vänligen försök igen.',
    'en-GB': 'Failed to download the summary report. Please try again.',
    'da-DK': 'Overblikksrapporten kunne ikke downloades. Prøv igen.'
  }
};

export const DETAILED_REPORT_STRINGS = {
  ...SUMMARY_REPORT_STRINGS,
  title: {
    'sv-SE': 'Detaljrapport',
    'en-GB': 'Detailed report',
    'da-DK': 'Detaljeret rapport'
  },
  createReport: {
    'sv-SE': 'Skapa detaljrapport',
    'en-GB': 'Create detailed report',
    'da-DK': 'Skab detaljeret rapport'
  },
  salesDeliveryFlowType: {
    'sv-SE': 'FÖRSÄLJNING PER LEVERANSSÄTT',
    'en-GB': 'SALES BY DELIVERY TYPE',
    'da-DK': 'SALG AF LEVERANSTYP'
  },
  salesCatalog: {
    'sv-SE': 'FÖRSÄLJNING PER MENY',
    'en-GB': 'SALES BY CATALOG',
    'da-DK': 'SALG AF MENU'
  },
  salesProduct: {
    'sv-SE': 'FÖRSÄLJNING PER PRODUKT',
    'en-GB': 'SALES BY PRODUCT',
    'da-DK': 'SALG AF PRODUKT'
  },
  PICK_UP: {
    'sv-SE': 'Upphämtning',
    'en-GB': 'Pick up',
    'da-DK': 'Afhentning'
  },
  TABLE_DELIVERY: {
    'sv-SE': 'Bordsservering',
    'en-GB': 'Table delivery',
    'da-DK': 'Bordsservering'
  },
  TAKE_AWAY: {
    'sv-SE': 'Take away',
    'en-GB': 'Take away',
    'da-DK': 'Take away'
  },
  noCatalogName: {
    'sv-SE': 'Meny',
    'en-GB': 'Catalog',
    'da-DK': 'Menu'
  },
  NO_PRODUCT_NAME: {
    'sv-SE': 'Inget produktnamn',
    'en-GB': 'No product name',
    'da-DK': 'Intet produktnavn'
  },
  downloadFailedTitle: {
    'sv-SE': 'Rapporten kunde inte hämtas',
    'en-GB': 'The report could not be fetched',
    'da-DK': 'Rapporten kunne ikke hentes'
  },
  downloadFailedMessage: {
    'sv-SE':
      'Det gick inte att ladda ner den detaljerade rapporten. Vänligen försök igen.',
    'en-GB': 'Failed to download the detailed report. Please try again.',
    'da-DK': 'Den detaljerede rapporten kunne ikke downloades. Prøv igen.'
  }
};

export const PAYOUT_REPORT_STRINGS = {
  ...REPORT_PAGES_STRINGS,
  downloadReport: {
    'sv-SE': 'Ladda ner utbetalningsrapport',
    'en-GB': 'Download payout report',
    'da-DK': 'Download udbetalingsrapport'
  },
  download: {
    'sv-SE': 'Ladda ner',
    'en-GB': 'Download',
    'da-DK': 'Download'
  },
  pdf: {
    'sv-SE': 'PDF',
    'en-GB': 'PDF',
    'da-DK': 'PDF'
  },
  excel: {
    'sv-SE': 'Excel',
    'en-GB': 'Excel',
    'da-DK': 'Excel'
  },
  to: {
    'sv-SE': 'till',
    'en-GB': 'to',
    'da-DK': 'til'
  },
  period: {
    'sv-SE': 'Period',
    'en-GB': 'Period',
    'da-DK': 'Periode'
  },
  selectManually: {
    'sv-SE': 'Välj period manuellt',
    'en-GB': 'Select period manually',
    'da-DK': 'Vælg periode manuelt'
  },
  noData: {
    'sv-SE': 'Ingen utbetalningsrapporter hittades',
    'en-GB': 'No payout reports were found',
    'da-DK': 'Ingen udbetalingsrapporter fundet'
  },
  adyenNotConfiguredTitle: {
    'sv-SE': 'Adyen är inte konfigurerat',
    'en-GB': 'Adyen is not configured',
    'da-DK': 'Adyen er ikke konfigureret'
  },
  adyenNotConfiguredMessage: {
    'sv-SE':
      'Denna sida används endast av kunder som tar emot betalningar via Adyen. Använder du endast Swish idag kan du ignorera denna sida.',
    'en-GB':
      'This page is only for customers that accept payments through Adyen. If you are only using Swish, you may ignroe this page.',
    'da-DK':
      'Denne side bruges kun af kunder, der modtager betalinger gennem Adyen. Hvis du kun bruger Swish i dag, kan du ignorere denne side.'
  },
  bestRegards: {
    'sv-SE': 'Mvh.',
    'en-GB': 'Best regards',
    'da-DK': 'Mvh.'
  },
  theWeiqTeam: {
    'sv-SE': 'Teamet bakom WEIQ',
    'en-GB': 'The WEIQ team',
    'da-DK': 'Holdet bag WEIQ'
  },
  info: {
    'sv-SE':
      'Här laddar du ner utbetalningsrapporter för transaktioner gjorda genom WEIQ.',
    'en-GB':
      'This is where you download payout reports for transactions made through WEIQ.',
    'da-DK':
      'Her downloader du udbetalingsrapporter for transaktioner foretaget gennem WEIQ.'
  },
  downloadFailedTitle: {
    'sv-SE': 'Rapporten kunde inte hämtas',
    'en-GB': 'The report could not be fetched',
    'da-DK': 'Rapporten kunne ikke hentes'
  },
  downloadFailedMessage: {
    'sv-SE':
      'Det gick inte att ladda ner utbetalningsrapporten. Vänligen försök igen.',
    'en-GB': 'Failed to download the payout report. Please try again.',
    'da-DK': 'Udbetalingsrapporten kunne ikke downloades. Prøv igen.'
  }
};

export const Z_REPORT_STRINGS = {
  ...REPORT_PAGES_STRINGS,
  serialNumber: {
    'sv-SE': 'Serie',
    'en-GB': 'Serial',
    'da-DK': 'Serie'
  },
  createdAt: {
    'sv-SE': 'Skapad',
    'en-GB': 'Created',
    'da-DK': 'Oprettet'
  },
  download: {
    'sv-SE': 'Ladda ner',
    'en-GB': 'Download',
    'da-DK': 'Download'
  },
  zReportDownloadFailedTitle: {
    'sv-SE': 'Dagsavslutet kunde inte hämtas',
    'en-GB': 'Could not fetch daily report',
    'da-DK': 'Dagsrapporten kunne ikke hentes'
  },
  zReportDownloadFailedMessage: {
    'sv-SE': 'Det gick inte att ladda ner dagsavslutet. Vänligen försök igen.',
    'en-GB': 'Could not download the daily report. Please try again.',
    'da-DK': 'Kunne ikke downloade den daglige rapport. Prøv igen.'
  },
  howToGenerate: {
    'sv-SE':
      'Gå in i WEIQ Hub på en tablet för att skapa ett dagsavslut. Läs mer om hur du gör på våra',
    'en-GB':
      'Open WEIQ Hub on a tablet to create a daily report. You can read more about this in the',
    'da-DK':
      'Åbn WEIQ Hub på en tablet for at oprette en daglig rapport. Du kan læse mere om dette i'
  },
  helpCenter: {
    'sv-SE': 'hjälpsidor',
    'en-GB': 'help center',
    'da-DK': 'hjælpesider'
  },
  noReports: {
    'sv-SE': 'Inga dagsavslut hittades',
    'en-GB': 'No daily reports were found',
    'da-DK': 'Der blev ikke fundet daglige rapporter'
  }
};

export const DATE_RANGE_PICKER_STRINGS = {
  today: {
    'sv-SE': 'Idag',
    'en-GB': 'Today',
    'da-DK': 'I dag'
  },
  thisWeek: {
    'sv-SE': 'Denna veckan',
    'en-GB': 'This week',
    'da-DK': 'Denne uge'
  },
  thisMonth: {
    'sv-SE': 'Denna månaden',
    'en-GB': 'This month',
    'da-DK': 'Denne måned'
  },
  yesterday: {
    'sv-SE': 'Igår',
    'en-GB': 'Yesterday',
    'da-DK': 'I går'
  },
  lastWeek: {
    'sv-SE': 'Förra veckan',
    'en-GB': 'Last week',
    'da-DK': 'Sidste uge'
  },
  lastMonth: {
    'sv-SE': 'Förra månaden',
    'en-GB': 'Last month',
    'da-DK': 'Sidste måned'
  },
  from: {
    'sv-SE': 'Från',
    'en-GB': 'From',
    'da-DK': 'Fra'
  },
  to: {
    'sv-SE': 'Till',
    'en-GB': 'To',
    'da-DK': 'Til'
  },
  invalidDates: {
    'sv-SE': 'Ogiltiga datum',
    'en-GB': 'Invalid dates',
    'da-DK': 'Ugyldige datoer'
  }
};

export const MENU_ITEM_PLACEHOLDER_STRINGS = {
  weiqBurger: {
    'sv-SE': 'WEIQ BURGER',
    'en-GB': 'WEIQ BURGER',
    'da-DK': 'WEIQ BURGER'
  },
  weiqBurgerDescription: {
    'sv-SE': 'Hamburgare på nötfärs med sallad och dressing',
    'en-GB': 'Hamburger made of beef with salad and dressing',
    'da-DK': 'Hamburger på hakkebøf med salat og dressing'
  },
  weiqLager: {
    'sv-SE': 'WEIQ LAGER',
    'en-GB': 'WEIQ LAGER',
    'da-DK': 'WEIQ LAGER'
  },
  priceTag: {
    'sv-SE': '45 SEK',
    'en-GB': '45 SEK',
    'da-DK': '45 SEK'
  }
};

export const ITEM_PREVIEW_STRINGS = {
  yourOrder: {
    'sv-SE': 'Din beställning',
    'en-GB': 'Your order',
    'da-DK': 'Din bestilling'
  },
  product: {
    'sv-SE': 'PRODUKT',
    'en-GB': 'PRODUCT',
    'da-DK': 'PRODUKT'
  },
  price: {
    'sv-SE': 'PRIS',
    'en-GB': 'PRICE',
    'da-DK': 'PRIS'
  },
  quantity: {
    'sv-SE': 'ANTAL',
    'en-GB': 'QUANTITY',
    'da-DK': 'ANTAL'
  },
  weiq: {
    'sv-SE': 'WEIQ',
    'en-GB': 'WEIQ',
    'da-DK': 'WEIQ'
  },
  amount: {
    'sv-SE': '123.00 SEK',
    'en-GB': '123.00 SEK',
    'da-DK': '123.00 SEK'
  },
  discount: {
    'sv-SE': 'Ange rabattkod',
    'en-GB': 'Enter discount code',
    'da-DK': 'Indtast rabatkode'
  },
  terms1: {
    'sv-SE': 'Genom att gå vidare godkänner du säljarens',
    'en-GB': 'By continuing, you accept the',
    'da-DK': 'Ved at fortsætte accepterer du sælgers'
  },
  terms2: {
    'sv-SE': 'köpvillkor',
    'en-GB': 'terms and conditions',
    'da-DK': 'købsbetingelser'
  },
  vat: {
    'sv-SE': 'Moms',
    'en-GB': 'VAT',
    'da-DK': 'Moms'
  },
  vatAmount: {
    'sv-SE': '24.60 SEK',
    'en-GB': '24.60 SEK',
    'da-DK': '24.60 SEK'
  },
  continue: {
    'sv-SE': 'FORTSÄTT',
    'en-GB': 'CONTINUE',
    'da-DK': 'FORTSÆT'
  },
  testaurant: {
    'sv-SE': 'Testaurangen',
    'en-GB': 'Testaurant',
    'da-DK': 'Testauranten'
  },
  myMenu: {
    'sv-SE': 'Min meny',
    'en-GB': 'My menu',
    'da-DK': 'Min menu'
  },
  all: {
    'sv-SE': 'ALLA',
    'en-GB': 'ALL',
    'da-DK': 'ALLE'
  },
  weiqLager: {
    'sv-SE': 'WEIQ LAGER',
    'en-GB': 'WEIQ LAGER',
    'da-DK': 'WEIQ LAGER'
  },
  weiqBurger: {
    'sv-SE': 'WEIQ BURGER',
    'en-GB': 'WEIQ BURGER',
    'da-DK': 'WEIQ BURGER'
  },
  weiqBurgerDescription: {
    'sv-SE': 'Hamburgare på nötfärs med sallad och dressing',
    'en-GB': 'Burger made of beef with salad and dressing',
    'da-DK': 'Hamburger på hakkebøf med salat og dressing'
  },
  menuItemPrice: {
    'sv-SE': '45 SEK',
    'en-GB': '45 SEK',
    'da-DK': '45 SEK'
  },
  restaurants: {
    'sv-SE': 'Restauranger',
    'en-GB': 'Restaurants',
    'da-DK': 'Restauranter'
  },
  menu: {
    'sv-SE': 'Meny',
    'en-GB': 'Menu',
    'da-DK': 'Menu'
  },
  orders: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  }
};

export const DELIVERY_TIME_OPTION_STRINGS = {
  PT5M: {
    'sv-SE': '5 min',
    'en-GB': '5 min',
    'da-DK': '5 min'
  },
  PT10M: {
    'sv-SE': '10 min',
    'en-GB': '10 min',
    'da-DK': '10 min'
  },
  PT15M: {
    'sv-SE': '15 min',
    'en-GB': '15 min',
    'da-DK': '15 min'
  },
  PT20M: {
    'sv-SE': '20 min',
    'en-GB': '20 min',
    'da-DK': '20 min'
  },
  PT25M: {
    'sv-SE': '25 min',
    'en-GB': '25 min',
    'da-DK': '25 min'
  },
  PT30M: {
    'sv-SE': '30 min',
    'en-GB': '30 min',
    'da-DK': '30 min'
  },
  PT40M: {
    'sv-SE': '40 min',
    'en-GB': '40 min',
    'da-DK': '40 min'
  },
  PT50M: {
    'sv-SE': '50 min',
    'en-GB': '50 min',
    'da-DK': '50 min'
  },
  PT1H: {
    'sv-SE': '1 timme',
    'en-GB': '1 hour',
    'da-DK': '1 time'
  },
  PT2H: {
    'sv-SE': '2 timmar',
    'en-GB': '2 hours',
    'da-DK': '2 timer'
  },
  PT3H: {
    'sv-SE': '3 timmar',
    'en-GB': '3 hours',
    'da-DK': '3 timer'
  }
};

export const NOT_FOUND_STRINGS = {
  whoops: {
    'sv-SE': 'Whoops, den här sidan finns inte.',
    'en-GB': 'Whoops, this page does not exist.',
    'da-DK': 'Hov, denne side findes ikke.'
  },
  returnHome: {
    'sv-SE': 'Tillbaka till startsidan',
    'en-GB': 'Return home',
    'da-DK': 'Tilbage til hovedsiden'
  }
};

export const UNAUTHORIZED_STRINGS = {
  description1: {
    'sv-SE':
      'Din användare har inte behörighet att logga in i WEIQ Admin. Om du saknar rätt användare för att logga in, kontakta ',
    'en-GB':
      'This user lacks permission to log into WEIQ Admin. If you are having trouble logging in, please contact '
  },
  description2: {
    'sv-SE': ' så hjälper vi dig.',
    'en-GB': ' and we will help you.'
  },
  returnToLoginPage: {
    'sv-SE': 'Tillbaka till inloggningen',
    'en-GB': 'Return to log in'
  }
};

export const NEW_SELLER_STRINGS = {
  ...PROFILE_STRINGS,
  ...SELLER_DETAILS_STRINGS,
  title: {
    'sv-SE': 'Ny säljare',
    'en-GB': 'New seller',
    'da-DK': 'Ny sælger'
  },
  createSeller: {
    'sv-SE': 'Skapa säljare',
    'en-GB': 'Create seller',
    'da-DK': 'Opret sælger'
  },
  prettyNameDescription: {
    'sv-SE':
      'Välj vilken URL som leder till din restaurang när gäster besöker WEIQ i en webbläsare.',
    'en-GB':
      'Choose which URL that leads to your restaurant when guests visit WEIQ from a web browser.',
    'da-DK':
      'Vælg hvilken URL der fører til din restaurant, når gæster besøger WEIQ i en webbrowser.'
  },
  validationErrorTitle: {
    'sv-SE': 'Kunde inte skapa säljare',
    'en-GB': 'Could not create seller',
    'da-DK': 'Kunne ikke oprette sælger'
  },
  validationErrorMessage: {
    'sv-SE': 'Kontrollera att alla fält är korrekt ifyllda och försök igen',
    'en-GB':
      'Please verify that all fields are filled in correctly and try again.',
    'da-DK': 'Tjek, at alle felter er udfyldt korrekt, og prøv igen'
  },
  alertTitle: {
    'sv-SE': 'Något gick fel',
    'en-GB': 'Something went wrong',
    'da-DK': 'Noget gik galt'
  },
  failedToCreateSeller: {
    'sv-SE': 'Säljaren kunde inte skapas',
    'en-GB': 'The seller could not be created',
    'da-DK': 'Sælgeren kunne ikke oprettes'
  },
  failedToCreateSellerCheckFields: {
    'sv-SE': 'Säljaren kunde inte skapas. Var god kontrollera fälten.',
    'en-GB': 'The seller could not be created. Please check the fields.',
    'da-DK': 'Sælgeren kunne ikke oprettes. Tjek alle felter.'
  },
  failedToGetProductRegisters: {
    'sv-SE': 'Kunde inte hämta listan av produktregister',
    'en-GB': 'Failed to get the list of product registers',
    'da-DK': 'Kunne ikke hente listen over produktregistre'
  },
  failedToUploadCoverImageTitle: {
    'sv-SE': 'Omslagsbilden kunde inte sparas',
    'en-GB': 'Failed to save the cover image',
    'da-DK': 'Forsidebilledet kunne ikke gemmes'
  },
  failedToUploadCoverImageMessage: {
    'sv-SE': 'Omslagsbilden sparades inte, försök igen på profilsidan.',
    'en-GB':
      'The cover image was not saved, please try again on the profile page.',
    'da-DK':
      'Forsidebilledet blev ikke gemt, prøv venligst igen på profilsiden.'
  },
  failedToAddOriginalPrettyNameTitle: {
    'sv-SE': 'URLen finns redan',
    'en-GB': 'The URL already exists',
    'da-DK': "URL'en findes allerede"
  },
  failedToAddOriginalPrettyNameMessage: {
    'sv-SE':
      'Den valda URLen var inte unik, en modifierad URL las till istället.',
    'en-GB': 'The chosen URL was not unique, a modified URL was added instead.',
    'da-DK':
      'Den valgte URL var ikke unik, en ændret URL blev tilføjet i stedet.'
  },
  goBack: {
    'sv-SE': 'Tillbaka',
    'en-GB': 'Go back',
    'da-DK': 'Tilbage'
  },
  menu: {
    'sv-SE': 'Meny',
    'en-GB': 'Menu',
    'da-DK': 'Menu'
  },
  ourMenu: {
    'sv-SE': 'Vår meny',
    'en-GB': 'Our menu',
    'da-DK': 'Vores menu'
  },
  restaurantDetails: {
    'sv-SE': 'RESTAURANGINFORMATION',
    'en-GB': 'RESTAURANT DETAILS',
    'da-DK': 'RESTAURANTINFORMATION'
  },
  createNewProductRegister: {
    'sv-SE': 'Skapa ett nytt produktregister',
    'en-GB': 'Create a new product register',
    'da-DK': 'Opret et nyt produktregister'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  fieldRequired: {
    'sv-SE': 'Måste anges',
    'en-GB': 'Required field',
    'da-DK': 'Skal angives'
  },
  subscription: {
    'sv-SE': 'PRENUMERATION FÖR SYSTEMMEDDELANDEN',
    'en-GB': 'SUBSCRIPTION TO SYSTEM MESSAGES',
    'da-DK': 'ABONNEMENT FØR SYSTEMBESKEDER'
  },
  subscriptionName: {
    'sv-SE': 'Namn på prenumerationen*',
    'en-GB': 'Subscription name*',
    'da-DK': 'Navn på abonnementet*'
  },
  emailAddresses: {
    'sv-SE': 'Emailadresser*',
    'en-GB': 'Email addresses*',
    'da-DK': 'Email adresse*'
  },
  addEmailAddresses: {
    'sv-SE': 'Lägg till emailadresser...',
    'en-GB': 'Add email addresses...',
    'da-DK': 'Tilføj e-mailadresser...'
  },
  receiveSMS: {
    'sv-SE': 'Få SMS vid brådskande notiser',
    'en-GB': 'Receive SMS notification for urgent matters',
    'da-DK': 'Modtag SMS-besked for presserende sager'
  },
  addPhoneNumbers: {
    'sv-SE': 'Lägg till telefonnummer...',
    'en-GB': 'Add phone numbers...',
    'da-DK': 'Tilføj telefonnummer...'
  }
};

export const DASHBOARD_STRINGS = {
  dashboard: {
    'sv-SE': 'Dashboard',
    'en-GB': 'Dashboard',
    'da-DK': 'Dashboard'
  },
  salesToday: {
    'sv-SE': 'Försäljning - idag',
    'en-GB': 'Sales - today',
    'da-DK': 'Salg - i dag '
  },
  salesPastMonth: {
    'sv-SE': 'Försäljning - denna månad',
    'en-GB': 'Sales - this month',
    'da-DK': 'Salg - denne måned'
  },
  rating: {
    'sv-SE': 'Medelbetyg',
    'en-GB': 'Avg. rating',
    'da-DK': 'Medelbedømmelse'
  },
  sales: {
    'sv-SE': 'Försäljning',
    'en-GB': 'Sales',
    'da-DK': 'Salg'
  },
  totalGross: {
    'sv-SE': 'Total netto',
    'en-GB': 'Total gross',
    'da-DK': 'Samlet brutto'
  },
  totalVat: {
    'sv-SE': 'Total moms',
    'en-GB': 'Total VAT',
    'da-DK': 'Samlet moms'
  },
  total: {
    'sv-SE': 'Totalt',
    'en-GB': 'Total',
    'da-DK': 'I alt'
  },
  PAST_7_DAYS: {
    'sv-SE': 'Senaste 7 dagarna',
    'en-GB': 'Past 7 days',
    'da-DK': 'sidste 7 dage'
  },
  PAST_14_DAYS: {
    'sv-SE': 'Senaste 14 dagarna',
    'en-GB': 'Past 14 days',
    'da-DK': 'sidste 14 dage'
  },
  PAST_MONTH: {
    'sv-SE': 'Senaste månaden',
    'en-GB': 'Past month',
    'da-DK': 'sidste måned'
  },
  loading: {
    'sv-SE': 'Laddar...',
    'en-GB': 'Loading...',
    'da-DK': 'Indlæser...'
  },
  productTopList: {
    'sv-SE': 'Topp 10 bästsäljande produkter',
    'en-GB': 'Top 10 best selling products',
    'da-DK': 'Top 10 bedstsælgende produkter'
  },
  name: {
    'sv-SE': 'Namn',
    'en-GB': 'Name',
    'da-DK': 'Navn'
  },
  ratingCount: {
    'sv-SE': ' ($count$ st)',
    'en-GB': ' ($count$)',
    'da-DK': ' ($count$)'
  },
  quantity: {
    'sv-SE': 'Antal',
    'en-GB': 'Qty',
    'da-DK': 'Antal'
  },
  sum: {
    'sv-SE': 'Summa',
    'en-GB': 'Sum',
    'da-DK': 'Beløb'
  },
  noProductName: {
    'sv-SE': 'Inget produktnamn',
    'en-GB': 'No product name',
    'da-DK': 'Intet produktnavn'
  },
  noTopList: {
    'sv-SE': 'Inga sålda produkter',
    'en-GB': 'No sold products',
    'da-DK': 'Ingen solgte varer'
  },
  noRatings: {
    'sv-SE': 'Inga betyg',
    'en-GB': 'No ratings',
    'da-DK': 'Ingen bedømmelser'
  },
  PICK_UP: {
    'sv-SE': 'Upphämtning',
    'en-GB': 'Pick up',
    'da-DK': 'Afhentning'
  },
  TABLE_DELIVERY: {
    'sv-SE': 'Bordsservering',
    'en-GB': 'Table delivery',
    'da-DK': 'Bordsservering'
  },
  TAKE_AWAY: {
    'sv-SE': 'Take away',
    'en-GB': 'Take away',
    'da-DK': 'Take away'
  },
  CATEGORY: {
    'sv-SE': 'Topp 5 - Kategorier',
    'en-GB': 'Top 5 - Categories',
    'da-DK': 'Top 5 - Kategorier'
  },
  DELIVERY_FLOW_TYPE: {
    'sv-SE': 'Försäljningskanaler',
    'en-GB': 'Sales channels',
    'da-DK': 'Salgkanaler'
  },
  PAYMENT_TYPE: {
    'sv-SE': 'Topp 5 - Betalmetoder',
    'en-GB': 'Top 5 - Payment types',
    'da-DK': 'Top 5 - Betalingsmetoder'
  },
  TERMINAL: {
    'sv-SE': 'Terminal',
    'en-GB': 'Terminal',
    'da-DK': 'Terminal'
  },
  CARD: {
    'sv-SE': 'Kort',
    'en-GB': 'Card',
    'da-DK': 'Kort'
  },
  NO_PAYMENT: {
    'sv-SE': 'Gratisköp',
    'en-GB': 'Free orders',
    'da-DK': 'Gratiskøb'
  },
  BAMBORA: {
    'sv-SE': 'Kort (Worldline)',
    'en-GB': 'Card (Worldline)',
    'da-DK': 'Kort (Worldline)'
  },
  SWISH: {
    'sv-SE': 'Swish',
    'en-GB': 'Swish',
    'da-DK': 'Swish'
  },
  PAY_AT_COUNTER: {
    'sv-SE': 'Kassaköp',
    'en-GB': 'Pay at counter',
    'da-DK': 'Betal ved kassen'
  },
  CATALOG: {
    'sv-SE': 'Topp 5 - Menyer',
    'en-GB': 'Top 5 - Catalogs',
    'da-DK': 'Top 5 - Menuer'
  },
  uncategorized: {
    'sv-SE': 'Okategoriserat',
    'en-GB': 'Uncategorized',
    'da-DK': 'Ukategoriseret'
  },
  entryTickets: {
    'sv-SE': 'Entrébiljetter',
    'en-GB': 'Entry tickets',
    'da-DK': 'Entrébilletter'
  },
  noCatalogName: {
    'sv-SE': 'Meny',
    'en-GB': 'Catalog',
    'da-DK': 'Menu'
  },
  BOOKKEEPING: {
    'sv-SE': 'Bokföring',
    'en-GB': 'Bookkeeping',
    'da-DK': 'Bogføring'
  },
  PAYMENTS: {
    'sv-SE': 'Betalningar',
    'en-GB': 'Payments',
    'da-DK': 'Betalinger'
  },
  ORDERS: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  INTEGRATIONS: {
    'sv-SE': 'Integrationer',
    'en-GB': 'Integrations',
    'da-DK': 'Integrationer'
  },
  PROFILE: {
    'sv-SE': 'Profil',
    'en-GB': 'Profile',
    'da-DK': 'Profil'
  },
  ALERT: {
    'sv-SE': 'Varning',
    'en-GB': 'Alert',
    'da-DK': 'Advarsel'
  },
  readMore: {
    'sv-SE': 'Läs mer',
    'en-GB': 'Read more',
    'da-DK': 'Læs mere'
  }
};

export const NOTIFICATION_STRINGS = {
  notifications: {
    'sv-SE': 'Notiser',
    'en-GB': 'Notifications',
    'da-DK': 'Meddelelser'
  },
  noNotifications: {
    'sv-SE': 'Här var det tomt.',
    'en-GB': 'Nothing to report.',
    'da-DK': 'Her var det tomt.'
  },
  everythingIsFine: {
    'sv-SE': 'Allt funkar som det ska!',
    'en-GB': 'Everything looks great!',
    'da-DK': 'Alt fungerer som det skal!'
  },
  showAllNotifications: {
    'sv-SE': 'Visa alla notiser',
    'en-GB': 'Show all notifications',
    'da-DK': 'Vise alle meddelelser'
  },
  notVisibleToRestaurants: {
    'sv-SE': 'Visas ej för restauranger',
    'en-GB': 'Not visible for restaurants',
    'da-DK': 'Ikke vist for restauranter'
  },
  BOOKKEEPING: {
    'sv-SE': 'Bokföring',
    'en-GB': 'Bookkeeping',
    'da-DK': 'Bogføring'
  },
  PAYMENTS: {
    'sv-SE': 'Betalningar',
    'en-GB': 'Payments',
    'da-DK': 'Betalinger'
  },
  ORDERS: {
    'sv-SE': 'Beställningar',
    'en-GB': 'Orders',
    'da-DK': 'Bestillinger'
  },
  INTEGRATIONS: {
    'sv-SE': 'Integrationer',
    'en-GB': 'Integrations',
    'da-DK': 'Integrationer'
  },
  PROFILE: {
    'sv-SE': 'Profil',
    'en-GB': 'Profile',
    'da-DK': 'Profil'
  },
  ALERT: {
    'sv-SE': 'Varning',
    'en-GB': 'Alert',
    'da-DK': 'Advarsel'
  },
  today: {
    'sv-SE': 'Idag',
    'en-GB': 'Today',
    'da-DK': 'I dag'
  },
  yesterday: {
    'sv-SE': 'Igår',
    'en-GB': 'Yesterday',
    'da-DK': 'I går'
  },
  lastWeek: {
    'sv-SE': 'Senaste veckan',
    'en-GB': 'Last week',
    'da-DK': 'Sidste uge'
  },
  older: {
    'sv-SE': 'Äldre',
    'en-GB': 'Older',
    'da-DK': 'Ældre'
  },
  globalAlerts: {
    'sv-SE': 'Varningar',
    'en-GB': 'Alerts',
    'da-DK': 'Advarsler'
  },
  viewMore: {
    'sv-SE': 'Visa fler',
    'en-GB': 'Show more',
    'da-DK': 'Vis flere'
  }
};

export const NOTIFICATION_PAGE_STRINGS = {
  ...NOTIFICATION_STRINGS,
  NEW_NOTICES: {
    'sv-SE': 'Nya notiser',
    'en-GB': 'New notices',
    'da-DK': 'Nye Meddelelser'
  },
  RESOLVED_NOTICES: {
    'sv-SE': 'Lösta notiser',
    'en-GB': 'Resolved notices',
    'da-DK': 'Løste Meddelelser'
  },
  subscribe: {
    'sv-SE': 'Prenumerera',
    'en-GB': 'Subscribe',
    'da-DK': 'Abonnere'
  },
  subscriptions: {
    'sv-SE': 'Prenumerationer',
    'en-GB': 'Subscriptions',
    'da-DK': 'Abonnementer'
  },
  newSubscription: {
    'sv-SE': 'Ny prenumeration',
    'en-GB': 'New subscription',
    'da-DK': 'Ny abonnement'
  },
  subscriptionName: {
    'sv-SE': 'Namn på prenumerationen',
    'en-GB': 'Subscription name',
    'da-DK': 'Navn på abonnementet'
  },
  emailAddresses: {
    'sv-SE': 'Emailadresser',
    'en-GB': 'Email addresses',
    'da-DK': 'Email adresse'
  },
  addEmailAddresses: {
    'sv-SE': 'Lägg till emailadresser...',
    'en-GB': 'Add email addresses...',
    'da-DK': 'Tilføj e-mailadresser...'
  },
  receiveSMS: {
    'sv-SE': 'Få SMS vid brådskande notiser',
    'en-GB': 'Receive SMS notification for urgent matters',
    'da-DK': 'Modtag SMS-besked for presserende sager'
  },
  addPhoneNumbers: {
    'sv-SE': 'Lägg till telefonnummer...',
    'en-GB': 'Add phone numbers...',
    'da-DK': 'Tilføj telefonnummer...'
  },
  notificationTypes: {
    'sv-SE': 'Notistyper',
    'en-GB': 'Notification types',
    'da-DK': 'Meddelelsestyper'
  },
  addSubscription: {
    'sv-SE': 'Lägg till prenumeration',
    'en-GB': 'Add subscription',
    'da-DK': 'Tilføj abonnement'
  },
  deleteSubscription: {
    'sv-SE': 'Ta bort prenumeration',
    'en-GB': 'Delete subscription',
    'da-DK': 'Opsige abonnement'
  },
  create: {
    'sv-SE': 'Skapa',
    'en-GB': 'Create',
    'da-DK': 'Opret'
  },
  update: {
    'sv-SE': 'Uppdatera',
    'en-GB': 'Update',
    'da-DK': 'Opdater'
  },
  delete: {
    'sv-SE': 'Ta bort',
    'en-GB': 'Delete',
    'da-DK': 'Fjern'
  },
  failedToCreateSubscription: {
    'sv-SE': 'Kunde inte skapa prenumeration',
    'en-GB': 'Subscription could not be created',
    'da-DK': 'Kunne ikke gemme abonnement'
  },
  failedToCreateSubscriptionDescription: {
    'sv-SE': 'Misslyckades att skapa prenumeration. Vänligen försök igen',
    'en-GB': 'Failed to create subscription. Please try again',
    'da-DK': 'Abonnement kunne ikke gemmes. Prøv igen'
  },
  failedToUpdateSubscription: {
    'sv-SE': 'Kunde inte uppdatera prenumeration',
    'en-GB': 'Subscription could not be updated',
    'da-DK': 'Kunne ikke opdater abonnement'
  },
  failedToUpdateSubscriptionDescription: {
    'sv-SE': 'Misslyckades att uppdatera prenumeration. Vänligen försök igen',
    'en-GB': 'Failed to update subscription. Please try again',
    'da-DK': 'Abonnement kunne ikke opdater. Prøv igen'
  },
  subscriptionErrorMessage: {
    'sv-SE': 'Följande fel returnerades: $$error$$. Vänligen försök igen',
    'en-GB': 'The following error was return: $$error$$. Please try again',
    'da-DK': 'Følgende fejl blev returneret: $$error$$ Prøv igen'
  },
  failedToDeleteSubscription: {
    'sv-SE': 'Kunde inte ta bort prenumeration',
    'en-GB': 'Subscription could not be deleted',
    'da-DK': 'Kunne ikke gemme abonnement'
  },
  failedToDeleteSubscriptionDescription: {
    'sv-SE': 'Misslyckades att ta bort prenumeration. Vänligen försök igen',
    'en-GB': 'Failed to delete subscription. Please try again',
    'da-DK': 'Abonnement kunne ikke fjernes. Prøv igen'
  },
  sendGlobalAlert: {
    'sv-SE': 'Skicka varningsmeddelande',
    'en-GB': 'Send alert',
    'da-DK': 'Sende advarselsmeddelelse'
  }
};

export const SYSTEM_MESSAGE_THREAD_STRINGS = {
  ...NOTIFICATION_STRINGS,
  markAsResolved: {
    'sv-SE': 'Markera som löst',
    'en-GB': 'Mark as resolved',
    'da-DK': 'Markere som løst'
  },
  created: {
    'sv-SE': 'Skapat',
    'en-GB': 'Created',
    'da-DK': 'Oprettet'
  },
  nbrOfOccurrences: {
    'sv-SE': 'Antal förekomster',
    'en-GB': 'Number of occurrences',
    'da-DK': 'Antal forekomster'
  },
  latest: {
    'sv-SE': 'Senaste',
    'en-GB': 'Latest',
    'da-DK': 'Sidste'
  },
  emailNotification: {
    'sv-SE': 'Email-notifikation',
    'en-GB': 'Email notified',
    'da-DK': 'E-mail meddelt'
  },
  smsNotification: {
    'sv-SE': 'SMS-notifikation',
    'en-GB': 'Phone notified',
    'da-DK': 'Telefon meddelt'
  },
  resolved: {
    'sv-SE': 'Löst',
    'en-GB': 'Resolved',
    'da-DK': 'Løst'
  },
  GLOBAL_ALERT: {
    'sv-SE': 'Varning',
    'en-GB': 'Alert',
    'da-DK': 'Advarsel'
  },
  HIGH: { 'sv-SE': 'Hög', 'en-GB': 'High', 'da-DK': 'Høj' },
  MODERATE: { 'sv-SE': 'Medel', 'en-GB': 'Moderate', 'da-DK': 'Mellem' },
  LOW: { 'sv-SE': 'Låg', 'en-GB': 'Low', 'da-DK': 'Lav' },
  INFORMATION: {
    'sv-SE': 'Infomation',
    'en-GB': 'Information',
    'da-DK': 'Information'
  }
};

export const CUSTOM_MESSAGE_PAGE_STRINGS = {
  notifications: {
    'sv-SE': 'Notiser',
    'en-GB': 'Notifications',
    'da-DK': 'Meddelelser'
  },
  newCustomMessage: {
    'sv-SE': 'Nytt varningsmeddelande',
    'en-GB': 'New custom message',
    'da-DK': 'Ny advarselsmeddelelse'
  },
  warning: {
    'sv-SE': 'Varning',
    'en-GB': 'Warning',
    'da-DK': 'Advarsel'
  },
  alertText: {
    'sv-SE':
      'Detta meddelande kommer skickas ut per SMS och mail till alla restauranger och användare som prenumererar på notiser.',
    'en-GB':
      'This message will be sent by SMS and email to every restaurant and user that subscribe to notifications.',
    'da-DK':
      'Denne besked vil blive sendt ud via SMS og e-mail til alle restauranter og brugere, der abonnerer på notifikationer.'
  },
  swedish: {
    'sv-SE': 'Svenska',
    'en-GB': 'Swedish',
    'da-DK': 'Svensk'
  },
  english: {
    'sv-SE': 'Engelska',
    'en-GB': 'English',
    'da-DK': 'Engelsk'
  },
  title: {
    'sv-SE': 'Titel ($lang$)',
    'en-GB': 'Title ($lang$)',
    'da-DK': 'Titel ($lang$)'
  },
  shortDescription: {
    'sv-SE':
      'Kort beskrivning ($lang$. skickas i SMS och syns som ett larm i Admin och WEIQ Hub)',
    'en-GB':
      'Short description ($lang$. sent by SMS and will appear as an alert in Admin and WEIQ Hub)',
    'da-DK':
      'Kort beskrivelse ($lang$. sendt i SMS og synlig som alarm i Admin og WEIQ Hub)'
  },
  longDescription: {
    'sv-SE': 'Lång beskrivning ($lang$)',
    'en-GB': 'Long description ($lang$)',
    'da-DK': 'Lang beskrivelse ($lang$)'
  },
  sendGlobalAlert: {
    'sv-SE': 'Skicka varningsmeddelande',
    'en-GB': 'Send alert',
    'da-DK': 'Sende advarselsmeddelelse'
  },
  confirm: {
    'sv-SE': 'Bekräfta',
    'en-GB': 'Confirm',
    'da-DK': 'Bekræfte'
  },
  warningText: {
    'sv-SE':
      'Varning - Du håller på att skicka ett meddelande till alla användare som prenumererar på notiser. Använd endast denna funktion om du måste och en allvarlig incident pågår.',
    'en-GB':
      'Warning - you are about to send a message to all users subscribed to notifications. Only use this feature if you have to and a serious incifent is in progress.',
    'da-DK':
      'Advarsel - Du er ved at sende en besked til alle brugere, der abonnerer på notifikationer. Brug kun denne funktion, hvis du er nødt til det, og en alvorlig hændelse er i gang.'
  },
  toConfirm: {
    'sv-SE': 'För att bekräfta, skriv $message$ i rutan nedan.',
    'en-GB': 'To confirm, write $message$ in the text field below.',
    'da-DK': 'For at bekræfte, skriv $message$ i boksen nedenfor'
  },
  sendMessage: {
    'sv-SE': 'Skicka meddelande',
    'en-GB': 'Send message',
    'da-DK': 'Send besked'
  },
  send: {
    'sv-SE': 'Skicka',
    'en-GB': 'Send',
    'da-DK': 'Send'
  },
  sendingCustomMessageFailedTitle: {
    'sv-SE': 'Meddelandet skickades inte',
    'en-GB': 'Custom message did not send',
    'da-DK': 'Beskedet blev ikke sendt'
  },
  sendingCustomMessageFailedDescription: {
    'sv-SE': 'Det gick inte att skicka meddelandet. Var god försök igen.',
    'en-GB': 'Could not send custom message. Please try again.',
    'da-DK': 'Kunne ikke sende besked. Prøv igen.'
  },
  ok: {
    'sv-SE': 'OK',
    'en-GB': 'OK',
    'da-DK': 'OK'
  },
  identifierNotUnique: {
    'sv-SE': 'Den valda identifieraren är inte unik',
    'en-GB': 'The chosen identifier is not unique',
    'da-DK': 'Den valgte identifikator er ikke entydig'
  }
};

export const FILTER_TAG_BAR_STRINGS = {
  ...TICKET_STATUS_STRINGS,
  SEARCH: {
    'sv-SE': 'Sökning',
    'en-GB': 'Search',
    'da-DK': 'Søg'
  },
  TICKET_STATUS: {
    'sv-SE': 'Status',
    'en-GB': 'Status',
    'da-DK': 'Status'
  },
  FROM: {
    'sv-SE': 'Från',
    'en-GB': 'From',
    'da-DK': 'Fra'
  },
  TO: {
    'sv-SE': 'Till',
    'en-GB': 'To',
    'da-DK': 'Til'
  }
};
