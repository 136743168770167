import { observable, computed, action } from 'mobx';
import { stringIsEmpty } from '../../helpers/Functions';

interface EditCategoryArgs {
  name?: string;
}

class EditCategory {
  @observable
  name;

  constructor(args: EditCategoryArgs = {}) {
    const { name = '' } = args;
    this.name = name;
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @computed
  get isValid() {
    return !stringIsEmpty(this.name);
  }

  toJSON = () => {
    const { name } = this;

    return { name };
  };
}

export { EditCategory as default };
