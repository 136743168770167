import { ToJSON } from '../../domain/Decorators';

@ToJSON
class CreatePersonalkollenIntegrationDTO {
  _secret;

  constructor(args) {
    const { secret } = args;

    this._secret = secret;
  }

  static fromCreatePersonalkollenIntegration(createPersonalkollenIntegration) {
    const { secret } = createPersonalkollenIntegration;

    return new this({
      secret
    });
  }
}

export { CreatePersonalkollenIntegrationDTO };
