import { autorun, computed } from 'mobx';
import { GlobalMessageThread } from '../domain/SystemMessage/GlobalMessageThread/GlobalMessageThread';
import { SystemMessageThread } from '../domain/SystemMessage/SystemMessageThread';
import { CustomMessageDTO } from '../dto/SystemMessage/CustomMessageDTO';

class SystemMessageManager {
  _misoClient;

  _sellerStore;

  _systemMessageStore;

  _initialized = false;

  _disposer;

  constructor(misoClient, sellerStore, systemMessageStore) {
    this._misoClient = misoClient;
    this._sellerStore = sellerStore;
    this._systemMessageStore = systemMessageStore;
  }

  async initialize() {
    if (!this._initialized) {
      // Fetches ActiveSystemMessageThreads each time selectedSellerReference changes
      this._disposer = autorun(async () => {
        await Promise.all([
          this.fetchActiveSystemMessageThreads(),
          this.fetchActiveGlobalMessageThreads()
        ]);
      });
      this._initialized = true;
    }
  }

  unmount() {
    this._disposer?.();
  }

  fetchActiveSystemMessageThreads = async () => {
    const {
      _misoClient: misoClient,
      _sellerStore: sellerStore,
      _systemMessageStore: systemMessageStore
    } = this;
    const { sellerReference } = sellerStore;

    if (!sellerReference) {
      return;
    }

    const jsonSystemMessageThreads =
      await misoClient.getActiveSystemMessageThreads(sellerReference);

    if (Array.isArray(jsonSystemMessageThreads)) {
      const systemMessageThreads = jsonSystemMessageThreads.map(systemMessage =>
        SystemMessageThread.fromJSON(systemMessage)
      );
      systemMessageStore.setActiveSystemMessageThreads(systemMessageThreads);
    }
  };

  getInactiveSystemMessageThreads = async args => {
    const { offsetReference = null } = args;
    const { _misoClient: misoClient, _sellerStore: sellerStore } = this;
    const { sellerReference } = sellerStore;

    const jsonSystemMessageThreads =
      await misoClient.getInactiveSystemMessageThreads(
        sellerReference,
        offsetReference
      );

    if (
      Array.isArray(jsonSystemMessageThreads) &&
      jsonSystemMessageThreads.length
    ) {
      return jsonSystemMessageThreads.map(systemMessage =>
        SystemMessageThread.fromJSON(systemMessage)
      );
    }
    return [];
  };

  fetchActiveSystemMessageThreadsForAdmin = async () => {
    const {
      _misoClient: misoClient,
      _sellerStore: sellerStore,
      _systemMessageStore: systemMessageStore
    } = this;
    const { sellerReference } = sellerStore;

    const jsonAdminSystemMessageThreads =
      await misoClient.getActiveSystemMessageThreads(
        sellerReference,
        true // isAdmin
      );

    if (Array.isArray(jsonAdminSystemMessageThreads)) {
      systemMessageStore.setActiveSystemMessageThreadsForAdmin(
        jsonAdminSystemMessageThreads.map(systemMessage =>
          SystemMessageThread.fromJSON(systemMessage)
        )
      );
    }
  };

  fetchActiveGlobalMessageThreads = async () => {
    const { _misoClient: misoClient, _systemMessageStore: systemMessageStore } =
      this;

    const jsonGlobalMessageThreads =
      await misoClient.getActiveGlobalMessageThreads();

    if (Array.isArray(jsonGlobalMessageThreads)) {
      const activeGlobalMessageThreads = jsonGlobalMessageThreads.map(
        globalMessageThread => GlobalMessageThread.fromJSON(globalMessageThread)
      );

      systemMessageStore.setActiveGlobalMessageThreads(
        activeGlobalMessageThreads
      );
    }
  };

  getInactiveGlobalMessageThreads = async args => {
    const { offsetReference = null } = args;
    const { _misoClient: misoClient } = this;

    const jsonGlobalMessageThreads =
      await misoClient.getInactiveGlobalMessageThreads(offsetReference);

    if (Array.isArray(jsonGlobalMessageThreads)) {
      return jsonGlobalMessageThreads.map(globalMessageThread =>
        GlobalMessageThread.fromJSON(globalMessageThread)
      );
    }
    return [];
  };

  markThreadAsRead = async threadReference => {
    const { _misoClient: misoClient } = this;

    return misoClient.markSystemMessageThreadAsRead(threadReference);
  };

  resolveThread = async threadReference => {
    const { _misoClient: misoClient } = this;

    return misoClient.resolveSystemMessageThread(threadReference);
  };

  sendCustomMessage = async customMessage => {
    const { _misoClient: misoClient } = this;
    const customMessageDTO = CustomMessageDTO.fromCustomMessage(customMessage);

    return misoClient.sendCustomMessage(customMessageDTO);
  };

  @computed
  get activeSystemMessageThreads() {
    const { activeSystemMessageThreads } = this._systemMessageStore;
    return activeSystemMessageThreads;
  }

  @computed
  get activeSystemMessageThreadsForAdmin() {
    const { activeSystemMessageThreadsForAdmin } = this._systemMessageStore;
    return activeSystemMessageThreadsForAdmin;
  }

  @computed
  get activeGlobalMessageThreads() {
    const { activeGlobalMessageThreads } = this._systemMessageStore;
    return activeGlobalMessageThreads;
  }

  @computed
  get anyUnreadActiveSystemMessageThreads() {
    const { activeSystemMessageThreads } = this;
    return activeSystemMessageThreads.some(thread => thread.unread);
  }
}

export { SystemMessageManager };
