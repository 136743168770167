import type { IProductRow } from './ProductRow';
import { ProductRow } from './ProductRow';
import type { JProductSummaryRow } from './ProductSummaryRow';
import { ProductSummaryRow } from './ProductSummaryRow';

interface IProductSection {
  salesRows: ProductRow[];
  summaryRow: ProductSummaryRow;
}

export interface JProductSection {
  salesRows: IProductRow[];
  summaryRow: JProductSummaryRow;
}

type ProductSectionRow = ProductRow | ProductSummaryRow;

interface ProductSection extends IProductSection {}
class ProductSection {
  readonly salesRows;
  readonly summaryRow;

  constructor(args: IProductSection) {
    const { salesRows, summaryRow } = args;
    this.salesRows = salesRows;
    this.summaryRow = summaryRow;
  }

  toArray = (): ProductSectionRow[] => {
    const { salesRows, summaryRow } = this;
    return [...salesRows, summaryRow];
  };

  static fromJSON(json: JProductSection) {
    const { salesRows, summaryRow } = json;
    const rows = salesRows.map(row => ProductRow.fromJSON(row));

    return new this({
      salesRows: rows,
      summaryRow: ProductSummaryRow.fromJSON(summaryRow)
    });
  }

  static keyExtractor(row: ProductSectionRow) {
    return row.key;
  }
}

export { ProductSection };
