import type { DiscountLimitation } from '../../domain/Discount/DiscountLimitation';
import type { DiscountModel } from '../../domain/Discount/DiscountModel';
import type { EditDiscount } from '../../domain/Discount/EditDiscount';

// NOTE: This DTO is both for create and update, as Core uses the same DTO for both

interface IDiscountDTO {
  name: string;
  description: string;
  model: DiscountModel;
  limitations: DiscountLimitation[];
}

class DiscountDTO {
  name;
  description;
  model;
  limitations;

  constructor(args: IDiscountDTO) {
    const { name, description, model, limitations } = args;

    this.name = name;
    this.description = description;
    this.model = model;
    this.limitations = limitations;
  }

  static fromEditDiscount(editDiscount: EditDiscount) {
    const {
      name,
      description,
      model: editModel,
      limitations: editLimitations
    } = editDiscount;

    const model = editModel.toModel();
    const limitations = Object.values(editLimitations).map(editLimitation =>
      editLimitation.toLimitation()
    );

    return new this({
      name,
      description,
      model,
      limitations
    });
  }

  toJSON = () => {
    const { name, description, model, limitations } = this;

    return {
      name,
      description,
      model: model.toJSON(),
      limitations: limitations.map(limitation => limitation.toJSON())
    };
  };
}

export { DiscountDTO };
