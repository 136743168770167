import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { baseManager } from '../../managers/Manager';
import { ExtendedComponent } from '../ExtendedComponent';
import LoadingButton from '../LoadingButton';
import type { AlertButton, AlertInstance } from '../../domain/Alert/Alert';

const { alertManager } = baseManager;

type AlertModalProps = {
  alert: AlertInstance;
};

type AlertButtonComponentProps = AlertButton & {
  isLast: boolean;
  loading: boolean;
  withLoading: (promise: Promise<void>) => Promise<void>;
  hide: () => void;
};

class AlertButtonComponent extends Component<AlertButtonComponentProps> {
  private onClick = async () => {
    const { onClick, withLoading, hide } = this.props;

    const promiseOrUndef = onClick?.();
    if (promiseOrUndef) {
      await withLoading(promiseOrUndef).catch(err => {
        console.error('Promise rejected while loading', err);
      });
    }

    hide();
  };

  render() {
    const {
      title,
      isLast,
      variant = isLast ? 'primary' : 'outline-primary',
      className,
      loading
    } = this.props;

    return (
      <LoadingButton
        variant={variant}
        className={classNames(className, 'ms-2')}
        onClick={this.onClick}
        loading={loading}
      >
        {title}
      </LoadingButton>
    );
  }
}

type AlertModalState = {
  loading: boolean;
};

@observer
class AlertModal extends ExtendedComponent<AlertModalProps, AlertModalState> {
  state: AlertModalState = {
    loading: false
  };

  private hide = () => {
    const { alert } = this.props;
    alertManager.hide(alert);
  };

  private onOuterPress = () => {
    const { alert } = this.props;
    const { options } = alert;

    if (options && !options.cancelable) {
      return;
    }

    alertManager.hide(alert);
  };

  private withLoading = (promise: Promise<void>) =>
    this.withBoolState('loading', () => promise);

  private renderButton = (button: AlertButton, index: number) => {
    const { alert } = this.props;
    const { loading } = this.state;
    const { buttons } = alert;
    const buttonCount = buttons?.length ?? 0;

    return (
      <AlertButtonComponent
        {...button}
        key={`${button.title}-${index}`}
        isLast={index === buttonCount - 1}
        loading={loading}
        withLoading={this.withLoading}
        hide={this.hide}
      />
    );
  };

  render() {
    const { alert } = this.props;
    const { title, content, buttons = [] } = alert;

    return (
      <Modal show onHide={this.onOuterPress} centered>
        <Modal.Body>
          <div>
            <h4>{title}</h4>
            <div className="content">{content}</div>
            <div className="d-flex justify-content-end mt-2">
              {buttons.map(this.renderButton)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export { AlertModal };
