export interface IAdyenBillingEntity {
  id: string;
  name: string;
  city: string;
  country: string;
  postalCode: string;
  streetAddress: string;
}

class AdyenBillingEntity {
  readonly id;
  readonly name;
  readonly city;
  readonly country;
  readonly postalCode;
  readonly streetAddress;

  constructor(args: IAdyenBillingEntity) {
    const { id, name, city, country, postalCode, streetAddress } = args;

    this.id = id;
    this.name = name;
    this.city = city;
    this.country = country;
    this.postalCode = postalCode;
    this.streetAddress = streetAddress;
  }

  static fromJSON(json: IAdyenBillingEntity) {
    return new this(json);
  }
}

export { AdyenBillingEntity };
