import { Getter, ToJSON } from '../Decorators';

@Getter
@ToJSON
class BasicProductPreview {
  _reference;

  _name;

  _description;

  _type;

  _price;

  _currency;

  _vatRate;

  _productImages;

  constructor(args) {
    const {
      reference,
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      productImages
    } = args;

    this._reference = reference;
    this._name = name;
    this._description = description;
    this._type = type;
    this._price = price;
    this._currency = currency;
    this._vatRate = vatRate;
    this._productImages = productImages;
  }

  static fromJSON(...args) {
    return new this(...args);
  }
}

export { BasicProductPreview as default };
