import type { EditDiscountCode } from '../../domain/DiscountCode/EditDiscountCode';

interface ICreateDiscountCodeDTO {
  code: string;
  balance: number | null;
}

class CreateDiscountCodeDTO {
  readonly code;
  readonly balance;

  constructor(args: ICreateDiscountCodeDTO) {
    const { code, balance } = args;

    this.code = code;
    this.balance = balance;
  }

  static fromEditDiscountCode(editDiscountCode: EditDiscountCode) {
    return new this(editDiscountCode);
  }

  toJSON = () => {
    const { code, balance } = this;
    return { code, balance };
  };
}

export { CreateDiscountCodeDTO };
