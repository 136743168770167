import { Getter } from '../Decorators';

@Getter
class Subscription {
  _reference;

  _name;

  _telephoneNumbers;

  _emailAddresses;

  _categories;

  constructor(args) {
    const { reference, name, telephoneNumbers, emailAddresses, categories } =
      args;

    this._reference = reference;
    this._name = name;
    this._telephoneNumbers = telephoneNumbers;
    this._emailAddresses = emailAddresses;
    this._categories = categories;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { Subscription };
