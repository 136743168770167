export interface IBookkeepingAccount {
  number: string;
  name: string;
  accountTag: string | null;
  costCenter: string | null;
}

interface BookkeepingAccount extends IBookkeepingAccount {}
class BookkeepingAccount {
  readonly number;
  readonly name;
  readonly accountTag;
  readonly costCenter;

  constructor(args: IBookkeepingAccount) {
    const { number, name, accountTag, costCenter } = args;

    this.number = number;
    this.name = name;
    this.accountTag = accountTag;
    this.costCenter = costCenter;
  }

  get displayName() {
    const { number, name } = this;
    return `${number} - ${name}`;
  }

  static fromJSON(json: IBookkeepingAccount) {
    return new this(json);
  }

  static keyExtractor(account: BookkeepingAccount) {
    return account.number;
  }
}

const ACCOUNT_TAGS = Object.freeze({
  DEFAULT_VAT: 'DEFAULT_VAT',
  DEFAULT_SALES: 'DEFAULT_SALES',
  DISCOUNT_ACCOUNT: 'DISCOUNT_ACCOUNT'
});

export { BookkeepingAccount, ACCOUNT_TAGS };
