import type { MutableOnboardingDetails } from '../../domain/Adyen/MutableOnboardingDetails';
import { stringNotEmpty } from '../../helpers/Functions';

export interface IInitiateAdyenOnboardingDTO {
  companyType: string;
  industryCode: string;
  companyDetails: ICompanyDetailsDTO;
  individualDetails: IIndividualDetailsDTO | null;
  tier: string;
  ippTier: string;
  email: string;
  phone: string;
}

export interface ICompanyDetailsDTO {
  legalName: string;
  registrationNumber: string;
  vatNumber: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
}

export interface IIndividualDetailsDTO {
  firstName: string;
  lastName: string;
}

export const InitiateAdyenOnboardingDTO = {
  fromOnboardingDetails(
    onboardingDetails: MutableOnboardingDetails
  ): IInitiateAdyenOnboardingDTO {
    const {
      companyType,
      industryCode,
      legalName,
      registrationNumber,
      vatNumber,
      street,
      postalCode,
      city,
      country,
      tier,
      ippTier,
      email,
      phone,
      firstName,
      lastName
    } = onboardingDetails;

    const companyDetails = {
      legalName,
      registrationNumber,
      vatNumber,
      street,
      postalCode,
      city,
      country
    };

    const individualDetails =
      onboardingDetails.isSoleProp &&
      stringNotEmpty(firstName) &&
      stringNotEmpty(lastName)
        ? { firstName, lastName }
        : null;

    return {
      companyType,
      industryCode,
      companyDetails,
      individualDetails,
      tier,
      ippTier,
      email,
      phone
    };
  }
};
