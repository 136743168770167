export interface IOrderLineItemPreview {
  reference: string;
  categoryReference: string | null;
  type: string;
  name: string;
  quantity: number;
  message: string | null;
  deliveryLocationReference: string;
  catalogLineItemReference: string;
}

class OrderLineItemPreview {
  readonly reference;
  readonly categoryReference;
  readonly type;
  readonly name;
  readonly quantity;
  readonly message;
  readonly deliveryLocationReference;
  readonly catalogLineItemReference;

  constructor(args: IOrderLineItemPreview) {
    const {
      reference,
      categoryReference,
      type,
      name,
      quantity,
      message,
      deliveryLocationReference,
      catalogLineItemReference
    } = args;

    this.reference = reference;
    this.categoryReference = categoryReference;
    this.type = type;
    this.name = name;
    this.quantity = quantity;
    this.message = message;
    this.deliveryLocationReference = deliveryLocationReference;
    this.catalogLineItemReference = catalogLineItemReference;
  }
}

export { OrderLineItemPreview };
