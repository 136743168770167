import type { IMonetaryAmount } from '../../domain/Amount/MonetaryAmount';
import { MonetaryAmount } from '../../domain/Amount/MonetaryAmount';
import { OrderPreview } from '../../domain/OrderPreview/OrderPreview';
import type { ITicketPreviewDTO } from './TicketPreviewDTO';
import { TicketPreviewDTO } from './TicketPreviewDTO';
import type { IOrderLineItemPreviewDTO } from './OrderLineItemPreviewDTO';
import { OrderLineItemPreviewDTO } from './OrderLineItemPreviewDTO';
import type { IPaymentPreviewDTO } from './PaymentPreviewDTO';
import { PaymentPreviewDTO } from './PaymentPreviewDTO';

export interface IOrderPreviewDTO {
  reference: string;
  status: string;
  initialDebt: IMonetaryAmount;
  deliveryLocationOption: string | null;
  createdAt: string;
  closedAt: string | null;
  ticket: ITicketPreviewDTO;
  orderLineItems: IOrderLineItemPreviewDTO[];
  payments: IPaymentPreviewDTO[];
  tip: number;
}

class OrderPreviewDTO {
  static toOrderPreview(orderPreviewDTO: IOrderPreviewDTO) {
    const {
      reference,
      status,
      initialDebt,
      ticket,
      orderLineItems,
      payments,
      deliveryLocationOption,
      createdAt,
      closedAt,
      tip
    } = orderPreviewDTO;

    return new OrderPreview({
      reference,
      status,
      initialDebt: MonetaryAmount.fromJSON(initialDebt),
      ticket: TicketPreviewDTO.toTicketPreview(ticket),
      orderLineItems: orderLineItems.map(
        OrderLineItemPreviewDTO.toOrderLineItemPreview
      ),
      payments: payments.map(PaymentPreviewDTO.toPaymentPreview),
      deliveryLocationOption,
      createdAt,
      closedAt,
      tip
    });
  }
}

export { OrderPreviewDTO };
