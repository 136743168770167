export interface IDiscountCode {
  reference: string;
  discountReference: string;
  code: string;
  balance: number | null;
  showInTerminal: boolean;
}

class DiscountCode {
  readonly reference;
  readonly discountReference;
  readonly code;
  readonly balance;
  readonly showInTerminal;

  constructor(args: IDiscountCode) {
    const { reference, discountReference, code, balance, showInTerminal } =
      args;

    this.reference = reference;
    this.discountReference = discountReference;
    this.code = code;
    this.balance = balance;
    this.showInTerminal = showInTerminal;
  }

  static fromJSON(json: IDiscountCode) {
    return new this(json);
  }
}

export { DiscountCode };
