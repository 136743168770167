import { ToJSON } from '../../domain/Decorators';

@ToJSON
class UpdateSwishPaymentMethodDTO {
  _paymentReferencePrefix;

  constructor(args) {
    const { paymentReferencePrefix } = args;

    this._paymentReferencePrefix = paymentReferencePrefix;
  }

  static fromPaymentReferencePrefix(paymentReferencePrefix) {
    return new this({ paymentReferencePrefix });
  }
}

export { UpdateSwishPaymentMethodDTO };
