export interface ICatalogPreview {
  reference: string;
  name: string;
  description: string;
  type: string;
  openingHours: any[]; // TODO: Add type
  visible: boolean;
  thumbnailIcon: string | null;
}

class CatalogPreview {
  readonly reference;
  readonly name;
  readonly description;
  readonly type;
  readonly openingHours;
  readonly visible;
  readonly thumbnailIcon;

  constructor(args: ICatalogPreview) {
    const {
      reference,
      name,
      description,
      type,
      openingHours,
      visible,
      thumbnailIcon
    } = args;

    this.reference = reference;
    this.name = name;
    this.description = description;
    this.type = type;
    this.openingHours = openingHours;
    this.visible = visible;
    this.thumbnailIcon = thumbnailIcon;
  }

  static fromJSON(json: ICatalogPreview) {
    return new this(json);
  }

  toJSON = () => {
    const {
      reference,
      name,
      description,
      type,
      openingHours,
      visible,
      thumbnailIcon
    } = this;

    return {
      reference,
      name,
      description,
      type,
      openingHours,
      visible,
      thumbnailIcon
    };
  };
}

export { CatalogPreview as default };
