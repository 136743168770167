import { MODIFIER, PRODUCT_AND_MODIFIER } from '../../constants';
import { ToJSON } from '../../domain/Decorators';
import ProductImagesDTO from './ProductImagesDTO';

@ToJSON
class UpdateProductDTO {
  _name;

  _description;

  _type;

  _price;

  _vatRate;

  _catalogs;

  _availableTimeSlots;

  _modifierCategories;

  _categoryReferences;

  _tagReferences;

  _productImagesDTO;

  constructor(args) {
    const {
      name,
      description,
      type,
      price,
      vatRate,
      catalogs,
      availableTimeSlots,
      modifierCategories,
      categoryReferences,
      tagReferences,
      productImagesDTO
    } = args;

    this._name = name;
    this._description = description;
    this._type = type;
    this._price = price;
    this._vatRate = vatRate;
    this._catalogs = catalogs;
    this._availableTimeSlots = availableTimeSlots;
    this._modifierCategories = modifierCategories;
    this._categoryReferences = categoryReferences;
    this._tagReferences = tagReferences;
    this._productImagesDTO = productImagesDTO;
  }

  static fromEditProduct(editProduct) {
    const {
      name,
      description,
      type: editProductType,
      price,
      currency,
      vatRate,
      catalogs: editProductCatalogs,
      availableTimeSlots,
      modifierCategoryReferences: editProductModifierCategoryReferences,
      categoryReferences: editProductCategoryReferences,
      tagReferences: editProductTagReferences,
      editProductImages
    } = editProduct;

    const type = this.mapEditProductType(editProductType);
    const catalogs = Array.from(editProductCatalogs);
    const categoryReferences = Array.from(editProductCategoryReferences);
    const tagReferences = Array.from(editProductTagReferences);

    const productImagesDTO = editProductImages
      ? new ProductImagesDTO(editProductImages)
      : null;

    const modifierCategories =
      editProductModifierCategoryReferences?.map(
        (reference, index, { length }) => ({
          modifierCategoryReference: reference,
          sortWeight: length - index
        })
      ) ?? [];

    return new this({
      name,
      description,
      type,
      price,
      currency,
      vatRate,
      catalogs,
      availableTimeSlots,
      modifierCategories,
      categoryReferences,
      tagReferences,
      productImagesDTO
    });
  }

  static mapEditProductType(editProductType) {
    if (editProductType === PRODUCT_AND_MODIFIER) {
      return MODIFIER;
    } else {
      return editProductType;
    }
  }
}

export { UpdateProductDTO as default };
