const TYPE = 'categories' as const;

export interface ICategoriesDiscountLimitation {
  categories: string[];
  type: typeof TYPE;
}

class CategoriesDiscountLimitation {
  readonly categories;
  readonly type = TYPE;

  constructor(args: ICategoriesDiscountLimitation) {
    const { categories } = args;
    this.categories = categories;
  }

  static fromJSON(json: ICategoriesDiscountLimitation) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { categories, type } = this;
    return { categories, type };
  };
}

export { CategoriesDiscountLimitation };
