export interface IOnboardFromAdyenAccountDTO {
  accountSellerReference: string;
}

export const OnboardFromAdyenAccountDTO = {
  fromAccountSellerReference(
    accountSellerReference: string
  ): IOnboardFromAdyenAccountDTO {
    return {
      accountSellerReference
    };
  }
};
