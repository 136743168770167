import type { AdyenMethod } from './AdyenMethod';
import type { PacMethod } from './PacMethod';
import type { SwishMethod } from './SwishMethod';

export enum PaymentMethodType {
  ADYEN = 'ADYEN',
  SWISH = 'SWISH',
  PAY_AT_COUNTER = 'PAY_AT_COUNTER'
}

export type PaymentMethod = AdyenMethod | SwishMethod | PacMethod;
