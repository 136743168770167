import { Getter } from '../Decorators';
import { ContactRecord } from './ContactRecord';

@Getter
class SystemMessage {
  _createdAt;

  _updatedAt;

  _readAt;

  _reference;

  _status;

  _shortDescription;

  _longDescription;

  _count;

  _contactRecord;

  constructor(args) {
    const {
      createdAt,
      updatedAt,
      readAt,
      reference,
      status,
      shortDescription,
      longDescription,
      count,
      contactRecord
    } = args;

    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
    this._readAt = readAt;
    this._reference = reference;
    this._status = status;
    this._shortDescription = shortDescription;
    this._longDescription = longDescription;
    this._count = count;
    this._contactRecord = contactRecord;
  }

  static fromJSON(json) {
    const { contactRecord: jsonContactRecord, ...rest } = json;

    const contactRecord = jsonContactRecord
      ? ContactRecord.fromJSON(jsonContactRecord)
      : {};

    return new this({
      contactRecord,
      ...rest
    });
  }
}

export { SystemMessage };
