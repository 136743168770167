const TYPE = 'catalog_line_items' as const;

export interface ICatalogLineItemsDiscountLimitation {
  catalogLineItems: string[];
  type: typeof TYPE;
}

class CatalogLineItemsDiscountLimitation {
  readonly catalogLineItems;
  readonly type = TYPE;

  constructor(args: ICatalogLineItemsDiscountLimitation) {
    const { catalogLineItems } = args;
    this.catalogLineItems = catalogLineItems;
  }

  static fromJSON(json: ICatalogLineItemsDiscountLimitation) {
    return new this(json);
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { catalogLineItems, type } = this;
    return { catalogLineItems, type };
  };
}

export { CatalogLineItemsDiscountLimitation };
