import { Getter } from '../Decorators';

@Getter
class BookkeepingSettings {
  _verificationSeries;

  _exportType;

  constructor(args) {
    const { verificationSeries, exportType } = args;

    this._verificationSeries = verificationSeries;
    this._exportType = exportType;
  }

  static fromJSON(json) {
    return new this(json);
  }
}

export { BookkeepingSettings };
