const TYPE = 'weekday_time_range' as const;

interface ConstructorArgs {
  timeRanges: WeekdayTimeRange[];
  type: typeof TYPE;
}

export interface JWeekdayTimeRangeDiscountLimitation {
  timeRanges: IWeekdayTimeRange[];
  type: typeof TYPE;
}

class WeekdayTimeRangeDiscountLimitation {
  readonly timeRanges;
  readonly type = TYPE;

  constructor(args: ConstructorArgs) {
    const { timeRanges } = args;
    this.timeRanges = timeRanges;
  }

  static fromJSON(json: JWeekdayTimeRangeDiscountLimitation) {
    const { timeRanges: jsonTimeRanges, type } = json;
    const timeRanges = jsonTimeRanges.map(jsonTimeRange =>
      WeekdayTimeRange.fromJSON(jsonTimeRange)
    );

    return new this({ timeRanges, type });
  }

  static get TYPE() {
    return TYPE;
  }

  toJSON = () => {
    const { timeRanges, type } = this;

    return {
      timeRanges: timeRanges.map(timeRange => timeRange.toJSON()),
      type
    };
  };
}

interface IWeekdayTimeRange {
  dayOfWeek: string;
  startTime: string;
  endTime: string;
}

class WeekdayTimeRange {
  readonly dayOfWeek;
  readonly startTime;
  readonly endTime;

  constructor(args: IWeekdayTimeRange) {
    const { dayOfWeek, startTime, endTime } = args;

    this.dayOfWeek = dayOfWeek;
    this.startTime = startTime;
    this.endTime = endTime;
  }

  static fromJSON(json: IWeekdayTimeRange) {
    return new this(json);
  }

  toJSON = () => {
    const { dayOfWeek, startTime, endTime } = this;
    return { dayOfWeek, startTime, endTime };
  };
}

export { WeekdayTimeRangeDiscountLimitation, WeekdayTimeRange };
