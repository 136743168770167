import { action, observable } from 'mobx';
import type { AlertInstance } from '../domain/Alert/Alert';

class AlertStore {
  @observable
  readonly alerts: Record<string, AlertInstance> = {};

  @action
  add = (alert: AlertInstance) => {
    const { reference } = alert;
    this.alerts[reference] = alert;
  };

  @action
  remove = (alert: AlertInstance) => {
    const { alerts } = this;
    const { reference } = alert;

    if (reference in alerts) {
      delete alerts[reference];
    }
  };
}

export { AlertStore };
