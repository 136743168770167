import { v4 as uuidv4 } from 'uuid';
import { RowTypes } from '../RowTypes';

const ROW_TYPE = RowTypes.SUM;

export interface IProductSummaryRow {
  count: number;
  amountVatExcluded: string;
  amount: string;
}

export interface JProductSummaryRow {
  totalCount: number;
  totalAmountVatExcluded: string;
  totalAmount: string;
}

interface ProductSummaryRow extends IProductSummaryRow {}
class ProductSummaryRow {
  readonly count;
  readonly amountVatExcluded;
  readonly amount;
  readonly key = uuidv4();

  constructor(args: IProductSummaryRow) {
    const { count, amountVatExcluded, amount } = args;

    this.count = count;
    this.amountVatExcluded = amountVatExcluded;
    this.amount = amount;
  }

  get rowType() {
    return ROW_TYPE;
  }

  static fromJSON(json: JProductSummaryRow) {
    const { totalCount, totalAmountVatExcluded, totalAmount } = json;

    return new this({
      count: totalCount,
      amountVatExcluded: totalAmountVatExcluded,
      amount: totalAmount
    });
  }
}

export { ProductSummaryRow };
