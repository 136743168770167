import type { CreateBookkeepingExport } from '../../domain/Bookkeeping/CreateBookkeepingExport';

type ICreateBookkeepingExportDTO = {
  from: string | null;
  to: string | null;
  type: string;
};

class CreateBookkeepingExportDTO {
  readonly from;
  readonly to;
  readonly type;

  constructor(args: ICreateBookkeepingExportDTO) {
    const { from, to, type } = args;

    this.from = from;
    this.to = to;
    this.type = type;
  }

  static fromCreateBookkeepingExport(
    createBookkeepingExport: CreateBookkeepingExport
  ) {
    return new this(createBookkeepingExport);
  }

  toJSON = (): ICreateBookkeepingExportDTO => {
    const { from, to, type } = this;
    return { from, to, type };
  };
}

export { CreateBookkeepingExportDTO };
