export interface IAdyenShippingEntity {
  city: string;
  country: string;
  postalCode: string;
  streetAddress: string;
  id: string;
  name: string;
  companyName: string;
}

class AdyenShippingEntity {
  readonly city;
  readonly country;
  readonly postalCode;
  readonly streetAddress;
  readonly id;
  readonly name;
  readonly companyName;

  constructor(args: IAdyenShippingEntity) {
    const { city, country, postalCode, streetAddress, id, name, companyName } =
      args;

    this.city = city;
    this.country = country;
    this.postalCode = postalCode;
    this.streetAddress = streetAddress;
    this.id = id;
    this.name = name;
    this.companyName = companyName;
  }

  static fromJSON(json: IAdyenShippingEntity) {
    return new this(json);
  }
}

export { AdyenShippingEntity };
