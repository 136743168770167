import { SellerPrettyName } from '../../domain/PrettyName/SellerPrettyName';

export interface ISellerPrettyNameDTO {
  reference: string;
  prettyName: string;
  preferred: boolean;
}

export const SellerPrettyNameDTO = {
  toSellerPrettyName(sellerPrettyNameDTO: ISellerPrettyNameDTO) {
    const { reference, prettyName, preferred } = sellerPrettyNameDTO;
    return new SellerPrettyName({ reference, name: prettyName, preferred });
  }
};
