import type { AxiosInstance } from 'axios';
import qs from 'qs';
import type { SubscriptionDTO } from '../../dto/Subscription/SubscriptionDTO';
import type { CustomMessageDTO } from '../../dto/SystemMessage/CustomMessageDTO';
import axios from 'axios';

const MISO_URL =
  process.env.REACT_APP_MISO_API_URL || 'https://miso.staging.weiq.meiq.se';
const V1_API = `${MISO_URL}/api/v1`;

class MisoClient {
  private readonly client;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getSubscriptions(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/subscription/seller/${sellerReference}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get subscriptions for seller. Miso responded with,',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get subscriptions for seller', exception);
    }
    return null;
  }

  async createSubscription(
    sellerReference: string,
    subscriptionDTO: SubscriptionDTO
  ) {
    const { client } = this;
    const url = `${V1_API}/subscription/seller/${sellerReference}`;

    try {
      const response = await client.post(url, subscriptionDTO);

      if (response?.status === 200) {
        return { success: true, message: null };
      } else {
        console.error(
          'Failed to create subscription. Miso responded with',
          response.status
        );
        return { success: false, message: null };
      }
    } catch (exception) {
      console.error(`Failed to create subscription`, exception);
      let errorMessage: string | null = null;
      if (axios.isAxiosError(exception)) {
        errorMessage = exception.response?.data?.message;
      }

      return {
        success: false,
        message: errorMessage
      };
    }
  }

  async updateSubscription(
    subscriptionReference: string,
    subscriptionDTO: SubscriptionDTO
  ) {
    const { client } = this;
    const url = `${V1_API}/subscription/${subscriptionReference}`;

    try {
      const response = await client.put(url, subscriptionDTO);
      if (response?.status === 204) {
        return { success: true, message: null };
      } else {
        console.error(
          'Failed to update subscription. Miso responded with',
          response.status
        );
        return { success: false, message: null };
      }
    } catch (exception) {
      console.error(`Failed to update subscription`, exception);
      let errorMessage: string | null = null;
      if (axios.isAxiosError(exception)) {
        errorMessage = exception.response?.data?.message;
      }

      return {
        success: false,
        message: errorMessage
      };
    }
  }

  async deleteSubscription(subscriptionReference: string) {
    const { client } = this;
    const url = `${V1_API}/subscription/${subscriptionReference}`;

    try {
      const response = await client.delete(url);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to delete subscription. Miso responded with',
          response.status
        );
        return false;
      }
    } catch (exception) {
      console.error(`Failed to delete subscription`, exception);
    }
    return false;
  }

  async getActiveSystemMessageThreads(
    sellerReference: string,
    isAdmin = false
  ) {
    const { client } = this;

    const url = `${V1_API}/messages/seller/${sellerReference}/active${
      isAdmin ? '?channel=admin' : ''
    }`;

    try {
      const response = await client.get(url);
      if (response?.status === 200) {
        return response.data;
      } else {
        console.error(
          'Failed to get active system message threads. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error('Failed to get active system message threads.', exception);
    }
    return null;
  }

  async getInactiveSystemMessageThreads(
    sellerReference: string,
    offsetReference: string | null,
    limit = 15
  ) {
    const { client } = this;
    const query = qs.stringify(
      {
        limit,
        'offset-reference': offsetReference
      },
      { skipNulls: true }
    );

    const url = `${V1_API}/messages/seller/${sellerReference}/inactive?${query}`;
    try {
      const response = await client.get(url);
      if (response?.status === 200) {
        return response.data;
      } else {
        console.error(
          'Failed to get inactive system message threads. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get inactive system message threads.',
        exception
      );
    }
    return null;
  }

  async getActiveGlobalMessageThreads() {
    const { client } = this;

    const url = `${V1_API}/messages/global/active`;

    try {
      const response = await client.get(url);
      if (response?.status === 200) {
        return response.data;
      } else {
        console.error(
          'Failed to get active global message threads. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error('Failed to get active global message threads.', exception);
    }
    return null;
  }

  async getInactiveGlobalMessageThreads(
    offsetReference: string | null,
    limit = 15
  ) {
    const { client } = this;
    const query = qs.stringify(
      {
        limit,
        'offset-reference': offsetReference
      },
      { skipNulls: true }
    );

    const url = `${V1_API}/messages/global/inactive?${query}`;

    try {
      const response = await client.get(url);
      if (response?.status === 200) {
        return response.data;
      } else {
        console.error(
          'Failed to get inactive global message threads. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error(
        'Failed to get inactive global message threads.',
        exception
      );
    }
    return null;
  }

  async markSystemMessageThreadAsRead(threadReference: string) {
    const { client } = this;
    const url = `${V1_API}/messages/message-thread/${threadReference}/read`;
    try {
      const response = await client.patch(url);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to mark system message thread as read. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error('Failed to mark system message thread as read.', exception);
    }
    return false;
  }

  async resolveSystemMessageThread(threadReference: string) {
    const { client } = this;
    const url = `${V1_API}/messages/message-thread/${threadReference}/resolve`;
    try {
      const response = await client.post(url);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to resolve system message thread. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error('Failed to resolve system message thread.', exception);
    }
    return false;
  }

  async sendCustomMessage(customMessageDTO: CustomMessageDTO) {
    const { client } = this;
    const url = `${V1_API}/messages/custom`;
    try {
      const response = await client.post(url, customMessageDTO);
      if (response?.status === 204) {
        return true;
      } else {
        console.error(
          'Failed to send custom message. Miso responded with',
          response.status
        );
      }
    } catch (exception) {
      console.error('Failed to send custom message.', exception);
    }
    return false;
  }
}

export { MisoClient };
