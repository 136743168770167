import BasicProductPreview from './BasicProductPreview';
import { sortByName } from '../../helpers/Functions';
import { TIP } from '../../constants';

export interface IProductRegister {
  reference: string;
  currency: string;
  name: string;
  products: BasicProductPreview[];
  mappedSellers: Set<string>;
}

export interface JProductRegister {
  reference: string;
  currency: string;
  name: string;
  products: any[]; // TODO: Introduce JBasicProductPreview type
  mappedSellers: string[];
}

interface ProductRegister extends IProductRegister {}
class ProductRegister {
  readonly reference;
  readonly currency;
  readonly name;
  readonly products;
  readonly mappedSellers;

  constructor(args: IProductRegister) {
    const { reference, currency, name, products, mappedSellers } = args;

    this.reference = reference;
    this.currency = currency;
    this.name = name;
    this.products = products || [];
    this.mappedSellers = mappedSellers;
  }

  static fromJSON(json: JProductRegister) {
    const { mappedSellers, products, ...rest } = json;

    return new this({
      mappedSellers: new Set(mappedSellers),
      products: products
        .sort(sortByName)
        .reduce((accumulator, jsonProductPreview) => {
          if (jsonProductPreview.type !== TIP) {
            accumulator.push(BasicProductPreview.fromJSON(jsonProductPreview));
          }

          return accumulator;
        }, []),
      ...rest
    });
  }
}

export { ProductRegister as default };
