import type { AxiosInstance } from 'axios';
import type { IDailyReportDTO } from '../../dto/DailyReport/DailyReportDTO';

const RICE_URL =
  process.env.REACT_APP_RICE_API_URL || 'https://rice.staging.weiq.meiq.se';
const V1_API = `${RICE_URL}/api/v1`;

type GetReportArgs = {
  sellerReference: string;
  from: string;
  to: string;
};

class RiceClient {
  private readonly client;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getSummarySalesReport(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/summary_report?from=${from}&to=${to}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get summary sales report. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get summary sales report', exception);
    }

    return null;
  }

  async getSummarySalesReportPdf(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/summary_report_pdf?from=${from}&to=${to}`;
    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download summary sales report pdf. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download summary sales report pdf', exception);
    }

    return null;
  }

  async getSummarySalesReportXlsx(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/summary_report_xlsx?from=${from}&to=${to}`;
    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download summary sales report xlsx. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download summary sales report xlsx', exception);
    }

    return null;
  }

  async getDetailedSalesReport(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/detailed_report?from=${from}&to=${to}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get detailed sales report. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get detailed sales report', exception);
    }

    return null;
  }

  async getDetailedSalesReportPdf(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/detailed_report_pdf?from=${from}&to=${to}`;
    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download detailed sales report pdf. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download detailed sales report pdf', exception);
    }

    return null;
  }

  async getDetailedSalesReportXlsx(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/sales/report/seller/${sellerReference}/detailed_report_xlsx?from=${from}&to=${to}`;
    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download detailed sales report xlsx. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download detailed sales report xlsx', exception);
    }

    return null;
  }

  async getPayoutReportPdf(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/seller-report/seller/${sellerReference}/payout_report_pdf?from=${from}&to=${to}`;

    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download payout report pdf. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download payout report pdf', exception);
    }
    return null;
  }

  async getPayoutReportXlsx(args: GetReportArgs) {
    const { client } = this;
    const { sellerReference, from, to } = args;
    const url = `${V1_API}/seller-report/seller/${sellerReference}/payout_report_xlsx?from=${from}&to=${to}`;

    try {
      const result = await client.get(url, {
        responseType: 'blob'
      });

      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to download payout report xlsx. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to download payout report xlsx', exception);
    }
    return null;
  }

  async getSalesGraph(sellerReference: string, from: string, to: string) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/sales_graph?from=${from}&to=${to}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get sales graph. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get sales graph report', exception);
    }

    return null;
  }

  async getRecentSales(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/sales_status`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get recent sales. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get recent sales', exception);
    }

    return null;
  }

  async getProductTopList(
    sellerReference: string,
    from: string,
    to: string,
    limit: number
  ) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/product_sales?from=${from}&to=${to}&limit=${limit}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get product toplist. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get product toplist', exception);
    }

    return null;
  }

  async getDeliveryFlowTypeSales(
    sellerReference: string,
    from: string,
    to: string
  ) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/delivery_type_sales?from=${from}&to=${to}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get delivery flow type sales. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get delivery flow type sales', exception);
    }

    return null;
  }

  async getCategorySales(
    sellerReference: string,
    from: string,
    to: string,
    limit: number
  ) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/category_sales?from=${from}&to=${to}&limit=${limit}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get category sales. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get category sales', exception);
    }

    return null;
  }

  async getPaymentTypeSales(sellerReference: string, from: string, to: string) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/payment_group_sales?from=${from}&to=${to}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get payment type sales. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get payment type sales', exception);
    }

    return null;
  }

  async getCatalogSales(
    sellerReference: string,
    from: string,
    to: string,
    limit: number
  ) {
    const { client } = this;
    const url = `${V1_API}/sales/dashboard/seller/${sellerReference}/catalog_sales?from=${from}&to=${to}&limit=${limit}`;
    try {
      const result = await client.get(url);
      if (result?.status === 200) {
        return result.data;
      } else {
        console.error(
          'Failed to get catalog sales. Rice responded with',
          result?.status
        );
      }
    } catch (exception) {
      console.error('Failed to get catalog sales', exception);
    }

    return null;
  }

  async getDailyReports(sellerReference: string) {
    const { client } = this;
    const url = `${V1_API}/sales/seller/${sellerReference}/zreport`;

    try {
      const result = await client.get<IDailyReportDTO[]>(url);
      return result.data;
    } catch (exception) {
      console.error('Failed to get daily reports', exception);
      return null;
    }
  }

  async getDailyReportPdf(sellerReference: string, serialNumber: number) {
    const { client } = this;
    const url = `${V1_API}/sales/seller/${sellerReference}/zreport/${serialNumber}`;

    try {
      const result = await client.get<Blob>(url, {
        responseType: 'blob'
      });
      return result.data;
    } catch (exception) {
      console.error('Failed to get daily report pdf', exception);
    }

    return null;
  }
}

export { RiceClient };
