import { Getter } from '../Decorators';
import { BookkeepingSettings } from './BookkeepingSettings';

@Getter
class Bookkeeping {
  _reference;

  _settings;

  constructor(args) {
    const { reference, settings } = args;

    this._reference = reference;
    this._settings = settings;
  }

  static fromJSON(json) {
    const { reference, settings } = json;
    return new this({
      reference,
      settings: BookkeepingSettings.fromJSON(settings)
    });
  }
}

export { Bookkeeping };
