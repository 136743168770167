import { computed, action } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { ManagedSeller } from '../domain/ManagedSeller/index';
import { DeliveryLocationDTO } from '../dto/DeliveryLocation/DeliveryLocationDTO';
import EnrollSellerDTO from '../dto/Moms/EnrollSellerDTO';
import { CreatePrettyNamesDTO } from '../dto/PrettyName/CreatePrettyNamesDTO';
import { SellerPrettyNameDTO } from '../dto/PrettyName/SellerPrettyNameDTO';
import { SellerDTO } from '../dto/Seller/SellerDTO';
import type { WeiqCoreClient } from '../config/clients/WeiqCoreClient';
import type { SellerStore } from '../stores/SellerStore';
import type { AuthManager } from './AuthManager';
import { assertNotNullUndefined } from '../helpers/Functions';
import type { MutableSeller } from '../domain/Seller/MutableSeller';
import type { EditDeliveryLocation } from '../domain/DeliveryLocation/EditDeliveryLocation';
import type { IEnrollSeller } from '../domain/Moms/EnrollSeller';
import type { IBannerData } from '../domain/Banner/BannerData';

class SellerManager {
  private readonly coreClient;
  private readonly sellerStore;
  private readonly authManager;

  constructor(
    coreClient: WeiqCoreClient,
    sellerStore: SellerStore,
    authManager: AuthManager
  ) {
    this.coreClient = coreClient;
    this.sellerStore = sellerStore;
    this.authManager = authManager;
  }

  fetchManagedSellers = async () => {
    const { coreClient, sellerStore, authManager } = this;

    const { userReference } = authManager;
    if (!userReference) {
      return false;
    }

    const managedSellers = (
      await coreClient.getManagedSellers(userReference)
    )?.reduce((accumulator: Record<string, ManagedSeller>, jsonSeller) => {
      const { sellerReference } = jsonSeller;
      accumulator[sellerReference] = ManagedSeller.fromJSON(jsonSeller);

      return accumulator;
    }, {});

    if (managedSellers) {
      sellerStore.setManagedSellers(managedSellers);
      return true;
    } else {
      return false;
    }
  };

  getOrFetchSelectedSeller = async () => {
    const { coreClient, sellerStore } = this;
    const { sellerReference, selectedSeller } = sellerStore;
    assertNotNullUndefined(sellerReference);

    if (selectedSeller?.reference === sellerReference) {
      return true;
    } else {
      const fetchedSelectedSeller = await coreClient.getSeller(sellerReference);

      if (fetchedSelectedSeller) {
        sellerStore.setSelectedSeller(fetchedSelectedSeller);
        return true;
      } else {
        return false;
      }
    }
  };

  fetchSelectedSeller = async () => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const fetchedSelectedSeller = await coreClient.getSeller(sellerReference);
    if (fetchedSelectedSeller) {
      sellerStore.setSelectedSeller(fetchedSelectedSeller);
      return true;
    } else {
      return false;
    }
  };

  createSeller = async (mutableSeller: MutableSeller) => {
    const { coreClient } = this;
    const sellerDTO = SellerDTO.fromMutableSeller(mutableSeller);
    return coreClient.createSeller(sellerDTO);
  };

  enrollSeller = async (sellerReference: string, enroll: IEnrollSeller) => {
    const { coreClient } = this;
    const enrollSellerDTO = new EnrollSellerDTO(enroll);
    return coreClient.enrollSellerForMoms(sellerReference, enrollSellerDTO);
  };

  updateSeller = async (mutableSeller: MutableSeller) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const sellerDTO = SellerDTO.fromMutableSeller(mutableSeller);
    const result = await coreClient.updateSeller(sellerReference, sellerDTO);
    if (result) {
      return true;
    }
    return false;
  };

  addPrettyNamesToSeller = async (prettyNames: string[]) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const createPrettyNamesDTO =
      CreatePrettyNamesDTO.fromPrettyNames(prettyNames);
    return coreClient.addPrettyNames({
      sellerReference,
      createPrettyNamesDTO
    });
  };

  setPreferredPrettyName = async (prettyNameReference: string) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    return coreClient.setPreferredPrettyName({
      sellerReference,
      prettyNameReference
    });
  };

  updateCoverImage = async (image: string | Blob) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    return coreClient.updateCoverImage(sellerReference, image);
  };

  addBanner = async (bannerImage: string | Blob, bannerData: IBannerData) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    return coreClient.addBanner(sellerReference, bannerImage, bannerData);
  };

  deleteBanner = async (bannerUrl: string) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    return coreClient.deleteBanner(sellerReference, bannerUrl);
  };

  createDeliveryLocation = async (
    editDeliveryLocation: EditDeliveryLocation
  ) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const deliveryLocationDTO =
      DeliveryLocationDTO.fromEditDeliveryLocation(editDeliveryLocation);

    return coreClient.createDeliveryLocation(
      sellerReference,
      deliveryLocationDTO
    );
  };

  updateDeliveryLocation = async (
    deliveryLocationReference: string,
    editDeliveryLocation: EditDeliveryLocation
  ) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const deliveryLocationDTO =
      DeliveryLocationDTO.fromEditDeliveryLocation(editDeliveryLocation);

    return coreClient.updateDeliveryLocation(
      sellerReference,
      deliveryLocationReference,
      deliveryLocationDTO
    );
  };

  deleteDeliveryLocation = async (deliveryLocationReference: string) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    return coreClient.deleteDeliveryLocation(
      sellerReference,
      deliveryLocationReference
    );
  };

  openOrCloseDeliveryLocation = async (
    deliveryLocationReference: string,
    open: boolean
  ) => {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    if (open) {
      return coreClient.openDeliveryLocation(
        sellerReference,
        deliveryLocationReference
      );
    } else {
      return coreClient.closeDeliveryLocation(
        sellerReference,
        deliveryLocationReference
      );
    }
  };

  async getSellerPrettyNames() {
    const { coreClient, sellerStore } = this;
    const { sellerReference } = sellerStore;
    assertNotNullUndefined(sellerReference);

    const jsonPrettyNames =
      await coreClient.getSellerPrettyNames(sellerReference);

    if (jsonPrettyNames.length) {
      return jsonPrettyNames.map(SellerPrettyNameDTO.toSellerPrettyName);
    }
    return [];
  }

  @computed
  get selectedSeller() {
    const { selectedSeller } = this.sellerStore;
    return selectedSeller;
  }

  @computed
  get managedSellers() {
    const { managedSellers } = this.sellerStore;
    return Object.values(managedSellers);
  }

  @computed
  get managedSellerMap() {
    const { managedSellers } = this.sellerStore;
    return managedSellers;
  }

  getManagedSeller = createTransformer((sellerReference: string) => {
    const { managedSellers } = this.sellerStore;
    return managedSellers[sellerReference];
  });

  @computed
  get selectedSellerReference() {
    return this.sellerStore.selectedSellerReference;
  }

  @computed
  get productRegisterReference() {
    return this.sellerStore.productRegisterReference;
  }

  @action
  selectSeller = (sellerReference: string) => {
    this.sellerStore.selectSeller(sellerReference);
  };
}

export { SellerManager };
