export interface ICategory {
  reference: string;
  name: string;
  sortWeight: number;
}

class Category {
  readonly reference;
  readonly name;
  readonly sortWeight;

  constructor(args: ICategory) {
    const { reference, name, sortWeight } = args;

    this.reference = reference;
    this.name = name;
    this.sortWeight = sortWeight;
  }

  static fromJSON(json: ICategory) {
    return new this(json);
  }

  toJSON = () => {
    const { reference, name, sortWeight } = this;

    return {
      reference,
      name,
      sortWeight
    };
  };
}

export { Category as default };
