export interface IManagedSeller {
  sellerReference: string;
  sellerName: string;
  imageUrl: string;
  customerNumber: number;
  createdAt: string;
  moms: IMomsMapping | null;
  currency: string;
}

interface IMomsMapping {
  defaultCatalog: string;
  entryCatalog: string | null;
  productRegisterReference: string;
}

interface ManagedSeller extends IManagedSeller {}
class ManagedSeller {
  readonly sellerReference;
  readonly sellerName;
  readonly imageUrl;
  readonly customerNumber;
  readonly createdAt;
  readonly moms;
  readonly currency;

  constructor(args: IManagedSeller) {
    const {
      sellerReference,
      sellerName,
      imageUrl,
      customerNumber,
      createdAt,
      moms,
      currency
    } = args;

    this.sellerReference = sellerReference;
    this.sellerName = sellerName;
    this.imageUrl = imageUrl;
    this.customerNumber = customerNumber;
    this.createdAt = createdAt;
    this.moms = moms;
    this.currency = currency;
  }

  static fromJSON(args: IManagedSeller) {
    return new this(args);
  }

  static keyExtractor(managedSeller: ManagedSeller) {
    return managedSeller.sellerReference;
  }
}

export { ManagedSeller };
