import { action, computed, observable } from 'mobx';

class Changeable {
  @observable
  _value;

  _initialValue;

  _comparer;

  constructor(value, comparer = strictlyEqual) {
    this._value = value;
    this._initialValue = value;
    this._comparer = comparer;

    Object.seal(this);
  }

  @computed
  get value() {
    return this._value;
  }

  @action
  setValue = value => {
    this._value = value;
  };

  didChange = () => {
    const {
      _value: value,
      _initialValue: initialValue,
      _comparer: comparer
    } = this;

    return !comparer(value, initialValue);
  };

  ifChanged = callback => {
    const { _value: value, _initialValue: initialValue } = this;

    if (this.didChange()) {
      callback(value, initialValue);
    }
  };
}

function strictlyEqual(valueA, valueB) {
  return valueA === valueB;
}

export { Changeable };
