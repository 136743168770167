import { observable, computed, action } from 'mobx';
import { MODIFIER, PRODUCT, PRODUCT_AND_MODIFIER } from '../../constants';
import {
  referenceKeyExtractor,
  stringIsEmpty,
  validNonNegativeNumber
} from '../../helpers/Functions';
import EditProductImages from './EditProductImages';
import EditTimeSlot from './EditTimeSlot';
import { ObservableSet } from '../GeneralSet';
import type Product from './Product';
import type ModifierGroup from '../ModifierGroup';
import type { ProductModifier, ProductModifierCategory } from './Product';
import type ModifierGroupProductPreview from '../ModifierGroup/ProductPreview';

const DEFAULT_TYPE = PRODUCT;
const DEFAULT_VAT_RATE = 1200;

interface IEditProduct {
  name: string;
  description: string;
  type: string;
  price: number | null;
  vatRate: number;
  currency: string;
  catalogs: ObservableSet<string, string>;
  modifierCategories: EditProductModifierCategory[];
  availableTimeSlots: EditTimeSlot[];
  categoryReferences: ObservableSet<string, string>;
  tagReferences: ObservableSet<string, string>;
  editProductImages: EditProductImages | null;
}
interface EditProduct extends IEditProduct {}

class EditProduct {
  @observable
  name;
  @observable
  description;
  @observable
  type;
  @observable
  price;
  @observable
  currency;
  @observable
  vatRate;
  @observable
  catalogs;
  @observable
  availableTimeSlots;
  @observable
  modifierCategories;
  @observable
  categoryReferences;
  @observable
  tagReferences;
  @observable
  editProductImages;

  constructor(args: Partial<IEditProduct> = {}) {
    const {
      name = '',
      description = '',
      type = DEFAULT_TYPE,
      price = null,
      currency = '',
      vatRate = DEFAULT_VAT_RATE,
      catalogs = new ObservableSet(),
      availableTimeSlots = [],
      categoryReferences = new ObservableSet(),
      tagReferences = new ObservableSet(),
      modifierCategories = [],
      editProductImages = new EditProductImages()
    } = args;

    this.name = name;
    this.description = description;
    this.type = type;
    this.price = price;
    this.currency = currency;
    this.vatRate = vatRate;
    this.catalogs = catalogs;
    this.availableTimeSlots = availableTimeSlots;
    this.categoryReferences = categoryReferences;
    this.tagReferences = tagReferences;
    this.modifierCategories = modifierCategories;
    this.editProductImages = editProductImages;
  }

  static fromProduct(
    product: Product,
    options: { copyOverrides?: boolean } = {}
  ) {
    const {
      name,
      description,
      price,
      vatRate,
      currency,
      catalogs,
      modifierCategories,
      categories,
      tags,
      productImages,
      timeSlots
    } = product;
    const { copyOverrides = false } = options;

    const editProductImages = productImages
      ? EditProductImages.fromProductImages(productImages)
      : null;

    const availableTimeSlots = timeSlots.map(json =>
      EditTimeSlot.fromJSON(json)
    );
    const type = this.getTypeFromProduct(product);

    return new this({
      name,
      description,
      type,
      price,
      vatRate,
      currency,
      availableTimeSlots,
      editProductImages,
      catalogs: new ObservableSet(catalogs.map(referenceKeyExtractor)),
      categoryReferences: new ObservableSet(
        categories.map(referenceKeyExtractor)
      ),
      tagReferences: new ObservableSet(tags.map(referenceKeyExtractor)),
      modifierCategories: modifierCategories.map(c =>
        EditProductModifierCategory.fromProductModifierCategory(
          c,
          copyOverrides
        )
      )
    });
  }

  static getTypeFromProduct(product: Product) {
    const { type, catalogs } = product;

    if (type === MODIFIER && catalogs.length) {
      return PRODUCT_AND_MODIFIER;
    } else {
      return type;
    }
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @action
  setDescription = (description: string) => {
    this.description = description;
  };

  @action
  setType = (type: string) => {
    this.type = type;
  };

  @action
  setPrice = (price: number | null) => {
    this.price = price;
  };

  @action
  setVatRate = (vatRate: number) => {
    this.vatRate = vatRate;
  };

  @action
  setCurrency = (currency: string) => {
    this.currency = currency;
  };

  @action
  setCatalogs = (catalogs: ObservableSet<string, string>) => {
    this.catalogs = catalogs;
  };

  @action
  setModifierCategories = (
    modifierCategories: EditProductModifierCategory[]
  ) => {
    this.modifierCategories = modifierCategories;
  };

  @action
  setAvailableTimeSlots = (availableTimeSlots: EditTimeSlot[]) => {
    this.availableTimeSlots = availableTimeSlots;
  };

  @action
  setCategoryReferences = (
    categoryReferences: ObservableSet<string, string>
  ) => {
    this.categoryReferences = categoryReferences;
  };

  @action
  setTagReferences = (tagReferences: ObservableSet<string, string>) => {
    this.tagReferences = tagReferences;
  };

  @action
  setEditProductImages = (editProductImages: EditProductImages | null) => {
    this.editProductImages = editProductImages;
  };

  @computed
  get isValid() {
    const validTimeSlots = this.availableTimeSlots.every(
      timeSlot => timeSlot.isValid
    );

    if (
      stringIsEmpty(this.name) ||
      stringIsEmpty(this.type) ||
      stringIsEmpty(this.currency) ||
      !validNonNegativeNumber(this.price) ||
      !validNonNegativeNumber(this.vatRate) ||
      !validTimeSlots
    ) {
      return false;
    } else {
      return true;
    }
  }

  @computed
  get modifierCategoryReferences() {
    return this.modifierCategories.map(referenceKeyExtractor);
  }
}

interface IEditProductModifierCategory {
  reference: string;
  name: string;
  modifiers: EditProductModifier[];
}

interface EditProductModifierCategory extends IEditProductModifierCategory {}

class EditProductModifierCategory {
  reference;
  @observable
  name;
  @observable
  modifiers;

  constructor(args: IEditProductModifierCategory) {
    const { reference, name, modifiers } = args;

    this.reference = reference;
    this.name = name;
    this.modifiers = modifiers;
  }

  static fromProductModifierCategory(
    productModifierCategory: ProductModifierCategory,
    copyOverrides = false
  ) {
    const { reference, name, modifiers } = productModifierCategory;

    return new this({
      reference,
      name,
      modifiers: modifiers.map(m =>
        EditProductModifier.fromProductModifier(m, copyOverrides)
      )
    });
  }

  static fromModifierGroup(modifierGroup: ModifierGroup) {
    const { reference, name, products } = modifierGroup;
    return new this({
      reference,
      name,
      modifiers: products.map(m => EditProductModifier.fromProductPreview(m))
    });
  }

  @action
  setName = (name: string) => {
    this.name = name;
  };

  @action
  setModifiers = (modifiers: EditProductModifier[]) => {
    this.modifiers = modifiers;
  };

  toJSON = () => {
    const { reference, name, modifiers } = this;

    return {
      reference,
      name,
      modifiers: modifiers.map(modifier => modifier.toJSON())
    };
  };
}

interface IEditProductModifier {
  reference: string;
  name: string;
  productOverrideEnabled: boolean;
  price: number;
  currency: string;
}

export class EditProductModifier {
  readonly reference;
  readonly name;
  @observable
  productOverrideEnabled;
  @observable
  modified = false;
  readonly price;
  readonly currency;

  constructor(args: IEditProductModifier) {
    const { reference, name, productOverrideEnabled, price, currency } = args;

    this.reference = reference;
    this.name = name;
    this.productOverrideEnabled = productOverrideEnabled;
    this.price = price;
    this.currency = currency;
  }

  setProductOverrideEnabled = (value: boolean) => {
    this.productOverrideEnabled = value;
    this.modified = true;
  };

  static fromProductModifier(
    productModifier: ProductModifier,
    copyOverrides = false
  ) {
    const editProductModifier = new this(productModifier);

    if (copyOverrides && !editProductModifier.productOverrideEnabled) {
      editProductModifier.modified = true;
    }

    return editProductModifier;
  }

  static fromProductPreview(productPreview: ModifierGroupProductPreview) {
    const { reference, name, price, currency } = productPreview;

    return new this({
      reference,
      name,
      price,
      currency,
      productOverrideEnabled: true
    });
  }

  toJSON = () => {
    const { reference, name, productOverrideEnabled, price, currency } = this;

    return {
      reference,
      name,
      productOverrideEnabled,
      price,
      currency
    };
  };
}

export { EditProduct as default, EditProductModifierCategory };
