export * from './layout';

export const MAX_COOKIE_DATE = new Date(2147483647 * 1000);

// App environments
export const PRODUCTION_ENV = 'production';
export const STAGING_ENV = 'staging';
export const DEVELOPMENT_ENV = 'development';

// User roles
export const WEIQ_ADMIN = 'weiq_admin';
export const WEIQ_SELLER = 'weiq_seller';
export const WEIQ_TAPP_USER = 'weiq_tapp_user';
export const WEIQ_REFUND_USER = 'weiq_refund_user';
export const WEIQ_BAPP_USER = 'weiq_bapp_user';
export const WEIQ_REPORT_VIEWER = 'weiq_report_viewer';
export const TUNA_DEVELOPER = 'tuna_developer';
export const WEIQ_VARIABLE_PRICE = 'weiq_variable_price';

// Product/catalog types
export const PRODUCT = 'PRODUCT';
export const MODIFIER = 'MODIFIER';
export const PRODUCT_AND_MODIFIER = 'PRODUCT_AND_MODIFIER';
export const ENTRY_TICKET = 'ENTRY_TICKET';
export const ENTRY_PRODUCT = 'ENTRY';
export const TIP = 'TIP';

// Catalog types
export const PRODUCT_CATALOG = 'products';
export const ENTRY_CATALOG = 'entry';

// Image types
export const WIDE_IMAGE = 'WIDE_IMAGE';
export const SMALL_IMAGE = 'SMALL_IMAGE';

// Payment Method types
export const BAMBORA_PAYMENT = 'BAMBORA_PAYMENT';
export const ADYEN = 'ADYEN';
export const ADYEN_PAYMENT = 'ADYEN_PAYMENT';
export const ADYEN_SWISH_METHOD = 'swish';
export const SWISH = 'SWISH';
export const SWISH_PAYMENT = 'SWISH_PAYMENT';
export const PAC = 'PAY_AT_COUNTER';
export const PAC_PAYMENT = 'PAY_AT_COUNTER_PAYMENT';
export const NO_PAYMENT = 'NO_PAYMENT';

// Adyen Payment Method types
export const APPLE_PAY = 'APPLE_PAY';
export const GOOGLE_PAY = 'GOOGLE_PAY';
export const MOBILE_PAY = 'MOBILE_PAY';
export const AMEX = 'AMEX';
export const VISA = 'VISA';
export const MASTERCARD = 'MASTERCARD';
export const VISADANKORT = 'VISADANKORT';
export const MAESTRO = 'MAESTRO';
export const MAESTRO_UK = 'MAESTRO_UK';
export const DANKORT = 'DANKORT';
export const IPP = 'ipp';

// Payment status
export const AUTHORIZED = 'AUTHORIZED';
export const INITIATED = 'INITIATED';
export const CREDIT_IN_PROGRESS = 'CREDIT_IN_PROGRESS';
export const REJECTED_BY_PAYMENT_PROVIDER = 'REJECTED_BY_PAYMENT_PROVIDER';
export const CONFIRMED_BY_PAYMENT_PROVIDER = 'CONFIRMED_BY_PAYMENT_PROVIDER';
export const REJECTED_BY_WEIQ = 'REJECTED_BY_WEIQ';
export const FULLY_CREDITED = 'FULLY_CREDITED';
export const CONFIRMATION_IN_PROGRESS = 'CONFIRMATION_IN_PROGRESS';

// Order status
export const OPEN = 'OPEN';
export const CLOSED = 'CLOSED';

// Ticket status
export const CREATED = 'CREATED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CONFIRMED = 'CONFIRMED';
export const READY_FOR_PICKUP = 'READY_FOR_PICKUP';
export const DELIVERED = 'DELIVERED';
export const REJECTED = 'REJECTED';
export const INACTIVE = 'INACTIVE';
export const CREDITED = 'CREDITED';

// Delivery flow type
export const PICK_UP = 'PICK_UP';

// Constants for banner images
export const NON_INTERACTIVE_BANNER = 'IMAGE';
export const URL_LINK_BANNER = 'LINK';

// Legal entities
export const INDIVIDUAL = 'INDIVIDUAL';
export const BUSINESS = 'BUSINESS';
export const NON_PROFIT = 'NON_PROFIT';
export const PUBLIC_COMPANY = 'PUBLIC_COMPANY';

// Tiers
export const TIER_1 = 'tier1';
export const TIER_2 = 'tier2';

// Shareholder type
export const OWNER = 'OWNER';
export const CONTROLLER = 'CONTROLLER';

// Locale constants
export const sv = 'sv';
export const en = 'en';
export const nb = 'nb';
export const da = 'da';
export const svSE = 'sv-SE';
export const enGB = 'en-GB';
export const nbNO = 'nb-NO';
export const daDK = 'da-DK';
export const SE = 'SE';
export const DK = 'DK';
export const NO = 'NO';

// Currency constants
export const SEK = 'SEK';
export const DKK = 'DKK';
export const NOK = 'NOK';
export const EUR = 'EUR';

// Report overview types
export const SALES = 'SALES';
export const REFUNDS = 'REFUNDS';

export const NO_CATALOG_NAME = 'NO_CATALOG_NAME';
export const UNCATEGORIZED = 'UNCATEGORIZED';
export const ENTRY_TICKET_CATEGORY = 'ENTRY_TICKET';

// Terminal order status
export const PLACED = 'PLACED';
export const CANCELLED = 'CANCELLED';
export const SHIPPED = 'SHIPPED';

export const ECOM = 'ECOM';

// Global system message thread category
export const ALERT = 'ALERT';

// System message status
export const FIRE = 'FIRE';
export const RESOLVED = 'RESOLVED';

// Common URLs
export const WEIQ_TECH_URL = 'https://weiq.tech';
export const WEIQ_TERMS_URL = 'https://weiq.tech/kopvillkor/';
export const WEIQ_HELP_CENTER_URL = 'https://help.weiq.tech';
export const WEIQ_CONTACT_URL = 'https://weiq.tech/kontakta-oss';
export const WEIQ_GAPP_URL = 'https://weiq.tech/gapp';
export const SUPPORT_EMAIL = 'support@weiq.tech';
