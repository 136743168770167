import { AuthClient } from '../config/clients/AuthClient';
import { AxiosClientBuilder } from '../config/clients/AxiosClientBuilder';
import { MomsClient } from '../config/clients/MomsClient';
import { TokenRequestInterceptor } from '../config/clients/RequestInterceptor';
import { TokenResponseInterceptor } from '../config/clients/ResponseInterceptor';
import { WeiqCoreClient } from '../config/clients/WeiqCoreClient';
import { ConfigStore } from '../stores/ConfigStore';
import { SellerStore } from '../stores/SellerStore';
import { AuthManager } from './AuthManager';
import { CatalogManager } from './CatalogManager';
import { CatalogPreviewManager } from './CatalogPreviewManager';
import { CategoryManager } from './CategoryManager';
import { TagManager } from './TagManager';
import { ModifierGroupManager } from './ModifierGroupManager';
import { ProductManager } from './ProductManager';
import { ProductRegisterManager } from './ProductRegisterManager';
import { SellerCatalogPreviewManager } from './SellerCatalogPreviewManager';
import { SellerManager } from './SellerManager';
import { PaymentMethodManager } from './PaymentMethodManager';
import { AlertStore } from '../stores/AlertStore';
import { OrderManager } from './OrderManager';
import { AlertManager } from './AlertManager';
import { RouterStore } from '../stores/RouterStore';
import { RouterManager } from './RouterManager';
import { UserManager } from './UserManager';
import { PaymentMethodStore } from '../stores/PaymentMethodStore';
import { CopyMenu } from '../lib/CopyMenu';
import { DiscountManager } from './DiscountManager';
import { DatePickerConfig } from '../config/DatePickerConfig';
import { BookkeepingManager } from './BookkeepingManager';
import PosgClient from '../config/clients/PosgClient';
import { PosIntegrationManager } from './PosIntegrationManager';
import { ReportManager } from './ReportManager';
import { RiceClient } from '../config/clients/RiceClient';
import { IntegrationManager } from './IntegrationManager';
import { DashboardManager } from './DashboardManager';
import { TerminalManager } from './TerminalManager';
import { MisoClient } from '../config/clients/MisoClient';
import { SubscriptionManager } from './SubscriptionManager';
import { SystemMessageManager } from './SystemMessageManager';
import { SystemMessageStore } from '../stores/SystemMessageStore';
import { PermissionManager } from './PermissionManager';
import { WasabiClient } from '../config/clients/WasabiClient';
import { OnboardManager } from './OnboardManager';

class BaseManager {
  /*
   * Stores
   */
  readonly configStore = new ConfigStore();
  readonly sellerStore = new SellerStore();
  readonly paymentMethodStore = new PaymentMethodStore();
  readonly alertStore = new AlertStore();
  readonly routerStore = new RouterStore();
  readonly systemMessageStore = new SystemMessageStore();

  /*
   * Configurations
   */
  readonly datePickerConfig = new DatePickerConfig(this.configStore);

  /*
   * Clients
   */
  readonly #authClient = new AuthClient();
  readonly #axiosClient = this.makeAxiosClient();
  readonly #coreClient = new WeiqCoreClient(this.#axiosClient);
  readonly #momsClient = new MomsClient(this.#axiosClient);
  readonly #posgClient = new PosgClient(this.#axiosClient);
  readonly #riceClient = new RiceClient(this.#axiosClient);
  readonly #misoClient = new MisoClient(this.#axiosClient);
  readonly #wasabiClient = new WasabiClient(this.#axiosClient);

  /*
   * Managers
   */
  readonly authManager = new AuthManager(this.#authClient);
  readonly sellerManager = new SellerManager(
    this.#coreClient,
    this.sellerStore,
    this.authManager
  );
  readonly userManager = new UserManager(this.#coreClient, this.sellerStore);
  readonly orderManager = new OrderManager(this.#coreClient, this.sellerStore);
  readonly catalogManager = new CatalogManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly catalogPreviewManager = new CatalogPreviewManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly categoryManager = new CategoryManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly modifierGroupManager = new ModifierGroupManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly productManager = new ProductManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly productRegisterManager = new ProductRegisterManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly sellerCatalogPreviewManager = new SellerCatalogPreviewManager(
    this.sellerStore,
    this.#momsClient
  );
  readonly paymentMethodManager = new PaymentMethodManager(
    this.#coreClient,
    this.sellerStore,
    this.paymentMethodStore
  );
  readonly tagManager = new TagManager(this.sellerStore, this.#momsClient);
  readonly alertManager = new AlertManager(this.alertStore);
  readonly routerManager = new RouterManager(this.routerStore);
  readonly copyMenuManager = new CopyMenu(this.#momsClient);
  readonly discountManager = new DiscountManager(
    this.#coreClient,
    this.sellerStore
  );
  readonly bookkeepingManager = new BookkeepingManager(
    this.#coreClient,
    this.sellerStore
  );
  readonly posIntegrationManager = new PosIntegrationManager(
    this.#posgClient,
    this.sellerStore
  );
  readonly integrationManager = new IntegrationManager(
    this.#coreClient,
    this.sellerStore,
    this.sellerManager
  );
  readonly reportManager = new ReportManager(
    this.#riceClient,
    this.sellerStore
  );
  readonly dashboardManager = new DashboardManager(
    this.#coreClient,
    this.#riceClient,
    this.sellerStore
  );
  readonly terminalManager = new TerminalManager(
    this.#coreClient,
    this.sellerStore
  );
  readonly subscriptionManager = new SubscriptionManager(
    this.#misoClient,
    this.sellerStore
  );
  readonly systemMessageManager = new SystemMessageManager(
    this.#misoClient,
    this.sellerStore,
    this.systemMessageStore
  );
  readonly permissionManager = new PermissionManager(this.authManager);
  readonly onboardManager = new OnboardManager(
    this.#wasabiClient,
    this.sellerStore
  );

  /*
   * Methods
   */
  private makeAxiosClient() {
    return new AxiosClientBuilder()
      .withInterceptor(new TokenRequestInterceptor(this.#authClient))
      .withInterceptor(new TokenResponseInterceptor(this.#authClient))
      .build();
  }
}

const baseManager = new BaseManager();
export { baseManager };
